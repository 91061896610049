import _extends from "@babel/runtime/helpers/extends";
import { IconButton } from "@components/atoms";
import React from "react";
import Overlay from "./Overlay";
import * as S from "./styles";
export var Modal = function Modal(_ref) {
  var testingContext = _ref.testingContext,
      children = _ref.children,
      show = _ref.show,
      hide = _ref.hide,
      target = _ref.target;
  return React.createElement(Overlay, _extends({
    testingContext: testingContext,
    position: "center"
  }, {
    show: show,
    hide: hide,
    target: target
  }), React.createElement(S.Header, null, React.createElement(IconButton, {
    name: "x",
    size: 18,
    onClick: hide,
    testingContext: "closeOverlayButton"
  })), React.createElement(S.Content, null, children));
};
Modal.displayName = "ModalImages";
export default Modal;