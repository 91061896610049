import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query OrderDetailsByToken($token: UUID!) {\n    orderByToken(token: $token) {\n      invoices {\n        id\n        status\n        number\n        createdAt\n        url\n      }\n      ...OrderDetailsFragment\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedQuery } from "@temp/core/queries";
import gql from "graphql-tag";
import { fragmentOrderDetails } from "../../../fragments/orders";
export var orderDetailsQuery = gql(_templateObject(), fragmentOrderDetails);
export var TypedOrderDetailsQuery = TypedQuery(orderDetailsQuery);