import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query VendorProducts(\n    $id: ID!\n    $attributes: [AttributeInput]\n    $after: String\n    $pageSize: Int\n    $sortBy: ProductOrder\n    $priceLte: Float\n    $priceGte: Float\n  ) {\n    products(\n      after: $after\n      first: $pageSize\n      sortBy: $sortBy\n      filter: {\n        attributes: $attributes\n        vendor: $id\n        minimalPrice: { gte: $priceGte, lte: $priceLte }\n      }\n    ) {\n      totalCount\n      edges {\n        node {\n          ...BasicProductFields\n          ...ProductPricingField\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query Vendor($slug: String!) {\n    vendor(slug: $slug) {\n      id\n      name\n      slug\n      products {\n        attributes {\n          id\n          name\n          slug\n          values {\n            id\n            name\n            slug\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedQuery } from "@temp/core/queries";
import { basicProductFragment, productPricingFragment } from "@temp/views/Product/queries";
import gql from "graphql-tag";
export var vendorQuery = gql(_templateObject());
export var TypedVendorQuery = TypedQuery(vendorQuery);
export var vendorProductsQuery = gql(_templateObject2(), basicProductFragment, productPricingFragment);
export var TypedVendorProductsQuery = TypedQuery(vendorProductsQuery);