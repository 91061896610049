import "./scss/index.scss";
import OrderFulfillmentLine from "./OrderFulfillmentLine";
import { FormattedDate, FormattedTime } from "react-intl";
import React from "react";

var OrderFulfillment = function OrderFulfillment(props) {
  var fulfillment = props.fulfillment;
  return React.createElement("div", {
    className: "fulfillment-container"
  }, React.createElement("div", {
    className: "fulfillment-lines-container"
  }, fulfillment.lines.map(function (fulfillmentLine) {
    return React.createElement(OrderFulfillmentLine, {
      line: fulfillmentLine,
      status: fulfillment.status,
      key: fulfillmentLine.id
    });
  })), React.createElement("div", {
    className: "status-container"
  }, fulfillment.status === "DELIVERED" && React.createElement(React.Fragment, null, React.createElement("p", null, "Delivered"), React.createElement("p", null, React.createElement(FormattedDate, {
    value: fulfillment.delivery.at,
    year: "numeric",
    month: "long",
    day: "2-digit"
  }), "- ", React.createElement(FormattedTime, {
    value: fulfillment.delivery.at,
    hour12: false
  }), "- ", fulfillment.delivery.to)), fulfillment.status === "FULFILLED" && React.createElement(React.Fragment, null, React.createElement("p", null, "In Transit"), React.createElement("p", null, "Your product has left our warehouses at"), React.createElement(FormattedDate, {
    value: fulfillment.created
  }))));
};

export default OrderFulfillment;