import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { AddNewTile, ErrorMessage, TileGrid } from "@components/atoms";
import { AddressTileOption } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import { AddressFormModal } from "../AddressFormModal";

/**
 * Addresses tiles to select with add new address tile opening address form addition modal.
 */
var AddressGridSelector = function AddressGridSelector(_ref) {
  var addresses = _ref.addresses,
      selectedAddressId = _ref.selectedAddressId,
      countriesOptions = _ref.countriesOptions,
      userId = _ref.userId,
      errors = _ref.errors,
      onSelect = _ref.onSelect,
      formId = _ref.formId,
      formRef = _ref.formRef,
      addNewModalTarget = _ref.addNewModalTarget,
      newAddressFormId = _ref.newAddressFormId,
      testingContext = _ref.testingContext;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      displayNewModal = _useState2[0],
      setDisplayNewModal = _useState2[1];

  var errorRef = React.useRef();
  var intl = useIntl();

  var _useForm = useForm({
    defaultValues: {
      addressTileOption: selectedAddressId
    }
  }),
      register = _useForm.register,
      handleSubmit = _useForm.handleSubmit,
      watch = _useForm.watch,
      setValue = _useForm.setValue;

  React.useEffect(function () {
    if (errors.length && errorRef.current) {
      var yOffset = -220;
      var y = errorRef.current.getBoundingClientRect().top + yOffset + window.pageYOffset;
      window.scrollTo({
        behavior: "smooth",
        top: y
      });
    }
  }, [errors]);
  var addNewTile = React.createElement(AddNewTile, {
    "data-test": "".concat(testingContext, "AddressTileAddNew"),
    key: "newTile",
    type: intl.formatMessage(_defineProperty({
      defaultMessage: "address",
      "id": "@next.components.organisms.AddressGridSelector.AddressGridSelector.2132027491"
    }, "id", "@next.components.organisms.AddressGridSelector.AddressGridSelector.2132027491")),
    onClick: function onClick() {
      return setDisplayNewModal(true);
    }
  });

  var onSubmit = function onSubmit(data) {
    var address = addresses.find(function (addr) {
      return addr.id === data.addressTileOption;
    });
    onSelect(address === null || address === void 0 ? void 0 : address.address, data.addressTileOption);
  };

  return React.createElement(React.Fragment, null, React.createElement("form", {
    onSubmit: handleSubmit(onSubmit),
    ref: formRef,
    id: formId
  }, React.createElement(TileGrid, {
    columns: 2,
    elements: addresses.reduce(function (elements, _ref2, index) {
      var id = _ref2.id,
          address = _ref2.address;
      elements.push(React.createElement(AddressTileOption, {
        testingContext: testingContext,
        "data-test": "".concat(testingContext, "AddressTileOption"),
        "data-test-id": index,
        key: "addressTile-".concat(id),
        id: id,
        inputName: "addressTileOption",
        address: address,
        register: register,
        checked: watch("addressTileOption") && watch("addressTileOption") === id
      }));
      return elements;
    }, [addNewTile])
  }), React.createElement(ErrorMessage, {
    errors: errors,
    ref: errorRef
  })), displayNewModal && React.createElement(AddressFormModal, {
    hideModal: function hideModal() {
      setDisplayNewModal(false);
    },
    submitBtnText: "Ajouter",
    title: intl.formatMessage(checkoutMessages.addNewAddress),
    countriesOptions: countriesOptions,
    formId: newAddressFormId,
    userId: userId,
    target: addNewModalTarget,
    selectAddressAfterSubmit: function selectAddressAfterSubmit(id) {
      setValue("addressTileOption", id);
    }
  }));
};

export { AddressGridSelector };
/* 
 <Formik
        initialValues={{
          addressTileOption: selectedAddressId,
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          if (onSelect) {
            const address = addresses.find(
              addr => addr.id === values.addressTileOption
            );
            onSelect(address?.address, values.addressTileOption);
          }
          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <form id={formId} ref={formRef} onSubmit={handleSubmit}>
              <TileGrid
                columns={2}
                elements={addresses.reduce(
                  (elements, { id, address }, index) => {
                    elements.push(
                      <AddressTileOption
                        testingContext={testingContext}
                        data-test={`${testingContext}AddressTileOption`}
                        data-test-id={index}
                        key={`addressTile-${id}`}
                        id={id}
                        inputName="addressTileOption"
                        address={address}
                        onChange={() => setFieldValue("addressTileOption", id)}
                        checked={
                          !!values.addressTileOption &&
                          values.addressTileOption === id
                        }
                      />
                    );
                    return elements;
                  },
                  [addNewTile]
                )}
              />
              <ErrorMessage errors={errors} ref={errorRef} />
            </form>
          );
        }}
      </Formik>
      {displayNewModal && (
        <AddressFormModal
          hideModal={() => {
            setDisplayNewModal(false);
          }}
          submitBtnText="Ajouter"
          title={intl.formatMessage(checkoutMessages.addNewAddress)}
          countriesOptions={countriesOptions}
          formId={newAddressFormId}
          userId={userId}
          target={addNewModalTarget}
        />
      )}

      */