import React, { useContext } from "react";
import { Redirect, useLocation, useHistory } from "react-router";
import { useAuth } from "@lastmaj/saleor-sdk";
import { Offline, OfflinePlaceholder, Online, OverlayContext } from "..";
import SignInForm from "./SignInForm";
import { OverlayType, OverlayTheme } from "../Overlay";

var Login = function Login() {
  var overlay = useContext(OverlayContext);

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var show = overlay.show;
  var history = useHistory();

  var useQuery = function useQuery() {
    return new URLSearchParams(useLocation().search);
  };

  var query = useQuery();

  var showPasswordResetOverlay = function showPasswordResetOverlay() {
    show(OverlayType.password, OverlayTheme.right);
  };

  if (user) {
    if (query.get("redirect")) history.push(query.get("redirect"));else return React.createElement(Redirect, {
      to: "/"
    });
  }

  return React.createElement("div", {
    className: "container"
  }, React.createElement(Online, null, React.createElement("div", {
    className: "checkout-login"
  }, React.createElement("div", {
    className: "checkout-login__user"
  }, React.createElement(SignInForm, {
    onForgottenPasswordClick: showPasswordResetOverlay
  })))), React.createElement(Offline, null, React.createElement(OfflinePlaceholder, null)));
};

export default Login;