import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import ReactSelect from "react-select";
import { ThemeContext } from "styled-components";
import { ErrorMessage } from "../ErrorMessage";
import * as S from "./styles";

var optionStyle = function optionStyle(customTheme) {
  return {
    option: function option(provided, state) {
      return _objectSpread({}, provided, {
        alignItems: "center",
        backgroundColor: state.isSelected ? customTheme.colors.primaryLight : state.isFocused ? customTheme.colors.primaryTransparent : "white",
        color: state.isDisabled ? customTheme.colors.lightFont : customTheme.colors.dark,
        display: "flex",
        fontWeight: state.isSelected && customTheme.typography.boldFontWeight,
        margin: "0 auto",
        minHeight: "34px",
        verticalAlign: "middle",
        width: "95%"
      });
    }
  };
};

export var Select = React.forwardRef(function (props, ref) {
  var value = props.value,
      onChange = props.onChange,
      clearable = props.clearable,
      clearValue = props.clearValue,
      name = props.name,
      options = props.options,
      isOptionDisabled = props.isOptionDisabled,
      customComponents = props.customComponents,
      defaultValue = props.defaultValue,
      menuIsOpen = props.menuIsOpen,
      customStyles = props.customStyles,
      _props$optionLabelKey = props.optionLabelKey,
      optionLabelKey = _props$optionLabelKey === void 0 ? "label" : _props$optionLabelKey,
      _props$optionValueKey = props.optionValueKey,
      optionValueKey = _props$optionValueKey === void 0 ? "value" : _props$optionValueKey,
      errors = props.errors,
      _props$noErrors = props.noErrors,
      noErrors = _props$noErrors === void 0 ? false : _props$noErrors;
  var customTheme = React.useContext(ThemeContext);

  var handleChange = function handleChange(value) {
    if (onChange) {
      if (name) onChange(value, name);else onChange(value);
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement(ReactSelect, _extends({
    defaultValue: defaultValue,
    onChange: handleChange,
    value: value,
    clearValue: clearValue,
    menuIsOpen: menuIsOpen,
    menuShouldScrollIntoView: true,
    tabSelectsValue: false,
    getOptionLabel: function getOptionLabel(option) {
      return option[optionLabelKey];
    },
    getOptionValue: function getOptionValue(option) {
      return option[optionValueKey];
    },
    openMenuOnFocus: true,
    styles: _objectSpread({}, optionStyle(customTheme), customStyles),
    options: options,
    isOptionDisabled: isOptionDisabled,
    placeholder: "",
    components: customComponents,
    isClearable: clearable,
    ref: ref
  }, props)), !noErrors && React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
});