import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n  font-weight: 400;\n  color: #3f3f3f;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: #ecb801;\n  height: 100%;\n  border-radius: 7px;\n  width: ", "%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin: auto 1rem;\n  height: 0.5rem;\n  width: 10rem;\n  background: #e6e6e6;\n  position: relative;\n  border-radius: 7px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 0.5rem;\n  font-weight: 700;\n  margin-right: 0.5rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin: 0.5rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var SummaryItem = styled.div(_templateObject());
export var Stars = styled.div(_templateObject2());
export var Gauge = styled.div(_templateObject3());
export var PaintedGauge = styled.div(_templateObject4(), function (props) {
  return props.percentage;
});
export var CountSpan = styled.span(_templateObject5());