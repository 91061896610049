import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as S from "./styles";
import { CachedImage } from "..";
export var ProductGroup = function ProductGroup(_ref) {
  var group = _ref.group,
      onGroupHover = _ref.onGroupHover,
      productId = _ref.productId,
      _ref$isMobile = _ref.isMobile,
      isMobile = _ref$isMobile === void 0 ? false : _ref$isMobile;
  return React.createElement(S.Wrap, null, group && group.products.length !== 0 && React.createElement(React.Fragment, null, React.createElement("p", null, group.displayName, " :"), React.createElement(S.Items, null, group.products.map(function (product) {
    return React.createElement(Link, {
      to: {
        state: {
          scroll: isMobile && window.scrollY
        },
        pathname: "/product/".concat(product.slug, "/")
      }
    }, React.createElement(S.Thumb, {
      selected: productId === product.id
    }, React.createElement(CachedImage, _extends({}, product.thumbnail, {
      onMouseEnter: function onMouseEnter() {
        if (productId !== product.id) onGroupHover(product.firstImage.url);
      },
      onMouseLeave: function onMouseLeave() {
        return onGroupHover("");
      },
      title: product.productGroupDisplay
    }))));
  }))));
};
ProductGroup.displayName = "ProductGroup";
export default ProductGroup;