import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  right: -3rem;\n\n  svg {\n    margin-left: 2px;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  left: -3rem;\n\n  svg {\n    transform: rotate(180deg);\n    margin-right: 2px;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  width: 36px;\n  height: 36px;\n  border-radius: 50%;\n  position: absolute;\n  top: 40%;\n  transform: translate(0, -40%);\n  display: block;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    height: 20px;\n    width: auto;\n  }\n\n  &:hover {\n    transition: all 0.5s;\n    background-color: #7f7f7f;\n\n    svg {\n      path {\n        fill: white;\n        transition: all 0.5s;\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: 232px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 1000px;\n  margin: auto;\n\n  .slick-track {\n    height: 500px;\n  }\n\n  .prev-arrow {\n    position: absolute;\n    top: 40%;\n    left: -2rem;\n    transform: translate(0, -40%);\n    width: 1rem;\n    display: block;\n    cursor: pointer;\n  }\n\n  .next-arrow {\n    position: absolute;\n    top: 40%;\n    right: -2rem;\n    transform: translate(0, -40%) rotate(180deg);\n    width: 1rem;\n    display: block;\n    cursor: pointer;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var TileContainer = styled.div(_templateObject2());
export var ChevronContainer = styled.div(_templateObject3());
export var ChevronContainerPrevious = styled(ChevronContainer)(_templateObject4());
export var ChevronContainerNext = styled(ChevronContainer)(_templateObject5());