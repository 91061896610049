import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n  line-height: unset;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-weight: 600;\n  font-size: 0.875rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n\n  padding: 0.5rem 1rem 1rem;\n  margin-top: -0.5rem;\n\n  border-radius: 10px;\n\n  svg {\n    display: inline-block;\n    width: 2rem;\n    height: auto;\n    margin-right: 0.5rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.notification[props.color].background;
}, function (props) {
  return props.theme.notification[props.color].fontColor;
});
export var Header = styled.div(_templateObject2());
export var Title = styled.h3(_templateObject3());
export var Expl = styled.p(_templateObject4());