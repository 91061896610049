import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  color: #ecb801;\n  font-size: 1rem;\n\n  @media (min-width: ", ") {\n    font-size: 2rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 1.5rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin-right: auto;\n  justify-content: space-around;\n  align-items: center;\n  flex-direction: column;\n\n  @media (min-width: ", ") {\n    margin-left: auto;\n    margin-right: auto;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Title = styled.h4(_templateObject2());
export var RatingText = styled.p(_templateObject3(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var RatingTotal = styled.p(_templateObject4());