import { createContext } from "react";
export var defaultCountry = {
  __typename: "CountryDisplay",
  code: "TN",
  country: "Tunisia"
};
export var defaultContext = {
  __typename: "Shop",
  countries: [],
  defaultCountry: defaultCountry,
  displayGrossPrices: true,
  geolocalization: {
    __typename: "Geolocalization",
    country: defaultCountry
  }
};
export var ShopContext = createContext(defaultContext);
ShopContext.displayName = "ShopContext";