import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 200px;\n  justify-content: flex-start;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 0.5rem;\n\n  box-shadow: 6px 0px 30px rgba(0, 0, 0, 0.15);\n  box-shadow: unset;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles"; // fixme : boxshadow

export var Wrapper = styled.div(_templateObject()); // export const Header = styled.div`
//   display: flex;
//   width: 80%;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 1.5rem;
//   margin-bottom: 4rem;
//   padding: 0;
//   font-weight: ${props => props.theme.typography.boldFontWeight};
//   font-size: ${props => props.theme.typography.h3FontSize};
// `;