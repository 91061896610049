import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query BestSellersCollection {\n    collection(slug: \"bestsellers\") {\n      products(first: 8) {\n        edges {\n          node {\n            ...BasicProductFields\n            ...ProductPricingField\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query NewArrivalsCollection {\n    collection(slug: \"newarrivals\") {\n      products(first: 8) {\n        edges {\n          node {\n            ...BasicProductFields\n            ...ProductPricingField\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  query FlashSaleEndDate {\n    flashSaleEndDate\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query FlashSaleCollection {\n    collection(slug: \"flashsale\") {\n      products(first: 3) {\n        edges {\n          node {\n            ...BasicProductFields\n            ...ProductPricingField\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query HotDealsCollection {\n    collection(slug: \"hotdeals\") {\n      products(first: 3) {\n        edges {\n          node {\n            ...BasicProductFields\n            ...ProductPricingField\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query HomepageCollections {\n    collections(\n      first: 4\n      filter: { search: \"homepage\", published: PUBLISHED }\n      sortBy: { field: NAME, direction: ASC }\n    ) {\n      edges {\n        node {\n          id\n          name\n          slug\n          backgroundImage {\n            url\n            alt\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query ProductsList {\n    shop {\n      description\n      name\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductFragment, productPricingFragment } from "../Product/queries";
export var homePageQuery = gql(_templateObject());
export var TypedHomePageQuery = TypedQuery(homePageQuery);
export var homepageCollectionsQuery = gql(_templateObject2());
export var TypedHomepageCollectionsQuery = TypedQuery(homepageCollectionsQuery);
export var hotDealsCollection = gql(_templateObject3(), basicProductFragment, productPricingFragment);
export var TypedHotDealsCollectionQuery = TypedQuery(hotDealsCollection);
export var flashSaleCollection = gql(_templateObject4(), basicProductFragment, productPricingFragment);
export var TypedFlashSaleCollectionQuery = TypedQuery(flashSaleCollection);
export var flashSaleEndDate = gql(_templateObject5());
export var TypedFlashSaleEndDate = TypedQuery(flashSaleEndDate);
export var newArrivalsCollection = gql(_templateObject6(), productPricingFragment, basicProductFragment);
export var TypedNewArrivalsCollectionProductsQuery = TypedQuery(newArrivalsCollection);
export var bestSellersCollection = gql(_templateObject7(), productPricingFragment, basicProductFragment);
export var TypedBestSellersCollectionProductsQuery = TypedQuery(bestSellersCollection);