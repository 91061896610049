import React from "react";
import { RelatedProducts } from "@components/organisms";
export var HomepageBestSellers = function HomepageBestSellers(_ref) {
  var products = _ref.products,
      title = _ref.title;
  return React.createElement(RelatedProducts, {
    products: products,
    title: title
  });
};
HomepageBestSellers.displayName = "HomepageBestSellers";
export default HomepageBestSellers;