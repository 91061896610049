import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  background: #ff00007a;\n  border: 2px solid red;\n  color: white;\n  position: absolute;\n  top: 1%;\n  right: 2%;\n  padding: 1px 5px;\n  font-size: 0.75rem;\n  font-weight: 600;\n  border-radius: 7px;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 70%;\n  margin: auto;\n\n  button {\n    width: 100%;\n    visibility: hidden;\n    margin-bottom: 0.25rem;\n    background: linear-gradient(\n          ", ",\n          ", "\n        )\n        padding-box,\n      linear-gradient(\n          90deg,\n          rgba(147, 199, 195, 1) 0%,\n          rgba(152, 211, 241, 1) 25%,\n          rgba(186, 186, 221, 1) 50%,\n          rgba(216, 235, 219, 1) 75%,\n          rgba(243, 148, 184, 1) 100%\n        )\n        border-box;\n    border-radius: 30px;\n    border: 3px solid transparent;\n  }\n  ", ":hover & {\n    button {\n      visibility: unset;\n    }\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  font-size: 0.75rem;\n\n  @media (min-width: ", ") {\n    width: 220px;\n    font-size: unset;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  text-decoration: line-through;\n  color: ", ";\n  font-size: 0.75rem;\n  font-weight: regular;\n  margin-right: 5px;\n\n  @media (min-width: ", ") {\n    font-size: unset;\n    margin-right: 10px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: fit-content;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n\n  span {\n    font-size: 0.8rem;\n    color: gray;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  height: 2rem;\n  font-size: 0.8rem;\n  line-height: normal;\n  letter-spacing: -0.2px;\n  overflow: hidden;\n  margin-bottom: ", ";\n\n  @media (min-width: ", ") {\n    height: 2.2rem;\n    font-size: 0.875rem;\n  }\n\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding-bottom: 8px;\n  padding: 0 5px;\n  @media (min-width: ", ") {\n    padding: 10px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  img {\n    width: 100%;\n    height: auto;\n  }\n\n  @media (min-width: ", ") {\n    width: 232px;\n    height: 347px;\n\n    img {\n      width: 232px;\n      height: 347px;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border-radius: 7px;\n  overflow: hidden;\n  position: relative;\n\n  @media (max-width: ", ") {\n    background-color: white;\n    box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 1px inset,\n      rgb(203 203 203 / 90%) 0px 0px 0px 1px;\n  }\n\n  @media (min-width: ", ") {\n    margin: auto;\n    margin-bottom: 5px;\n    height: 490px;\n    width: 232px;\n    &:hover {\n      box-shadow: rgba(0, 0, 0, 15%) 0px 2px 10px;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Thumb = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Content = styled.div(_templateObject3(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Name = styled.h6(_templateObject4(), function (props) {
  return props.rating ? "2px" : "22px";
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Rating = styled.div(_templateObject5());
export var StarsContainer = styled.div(_templateObject6());
export var Undiscounted = styled.span(_templateObject7(), function (props) {
  return props.theme.colors.redDiscount;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Price = styled.p(_templateObject8(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var ButtonContainer = styled.div(_templateObject9(), function (props) {
  return props.theme.colors.buttonBackgroundBlack;
}, function (props) {
  return props.theme.colors.buttonBackgroundBlack;
}, Wrapper);
export var OutOfStock = styled.span(_templateObject10());