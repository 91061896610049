import { AccountErrorCode } from "@lastmaj/saleor-sdk";
import { RequestReasonsEnum, RequestReplacement, RequestStatus } from "gqlTypes/globalTypes";
import { defineMessages } from "react-intl";
export var commonMessages = defineMessages({
  search: {
    "id": "intl.commonMessages.search",
    defaultMessage: "Recherchez des produits, des catégories ou des marques"
  },
  searchShort: {
    "id": "intl.commonMessages.searchShort",
    defaultMessage: "Recherche"
  },
  outOfStock: {
    "id": "intl.commonMessages.outOfStock",
    defaultMessage: "Stock épuisé"
  },
  lowStock: {
    "id": "intl.commonMessages.lowStock",
    defaultMessage: "Derniers {left} articles!"
  },
  noItemsAvailable: {
    "id": "intl.commonMessages.noItemsAvailable",
    defaultMessage: "Pas de produits"
  },
  noPurchaseAvailable: {
    "id": "intl.commonMessages.noPurchaseAvailable",
    defaultMessage: "Indisponible"
  },
  purchaseAvailableOn: {
    "id": "intl.commonMessages.purchaseAvailableOn",
    defaultMessage: "Sera disponible pour achat le {date} \xE0 {time}"
  },
  youMightLike: {
    "id": "intl.commonMessages.youMightLike",
    defaultMessage: "Vous pourriez aimer"
  },
  choosePaymentMethod: {
    "id": "intl.commonMessages.choosePaymentMethod",
    defaultMessage: "Veuillez choisir la méthode de paiement"
  },
  provideEmailAddress: {
    "id": "intl.commonMessages.provideEmailAddress",
    defaultMessage: "On a besoin de votre mail"
  },
  account: {
    "id": "intl.commonMessages.account",
    defaultMessage: "Compte"
  },
  myAccount: {
    "id": "intl.commonMessages.myAccount",
    defaultMessage: "Mon Compte"
  },
  orderHistory: {
    "id": "intl.commonMessages.orderHistory",
    defaultMessage: "Mes commandes"
  },
  addressBook: {
    "id": "intl.commonMessages.addressBook",
    defaultMessage: "Mes adresses"
  },
  logOut: {
    "id": "intl.commonMessages.logOut",
    defaultMessage: "Se déconnecter"
  },
  firstName: {
    "id": "intl.commonMessages.firstName",
    defaultMessage: "Prénom"
  },
  lastName: {
    "id": "intl.commonMessages.lastName",
    defaultMessage: "Nom"
  },
  password: {
    "id": "intl.commonMessages.password",
    defaultMessage: "Mot de passe"
  },
  quantity: {
    "id": "intl.commonMessages.quantity",
    defaultMessage: "Quantité"
  },
  sku: {
    "id": "intl.commonMessages.sku",
    defaultMessage: "SKU"
  },
  maxQtyIs: {
    "id": "intl.commonMessages.maxQtyIs",
    defaultMessage: "Maximum d'articles: {maxQuantity}"
  },
  qty: {
    "id": "intl.commonMessages.qty",
    defaultMessage: "Quantité"
  },
  subtotal: {
    "id": "intl.commonMessages.subtotal",
    defaultMessage: "Sous-total"
  },
  shipping: {
    "id": "intl.commonMessages.shipping",
    defaultMessage: "Livraison"
  },
  promoCode: {
    "id": "intl.commonMessages.promoCode",
    defaultMessage: "Promo code"
  },
  appliedPromoCode: {
    "id": "intl.commonMessages.appliedPromoCode",
    defaultMessage: "Code appliqué"
  },
  total: {
    "id": "intl.commonMessages.total",
    defaultMessage: "Total (TTC)"
  },
  totalOrder: {
    "id": "intl.commonMessages.totalOrder",
    defaultMessage: "Total"
  },
  totalPrice: {
    "id": "intl.commonMessages.totalPrice",
    defaultMessage: "Prix Total"
  },
  checkout: {
    "id": "intl.commonMessages.checkout",
    defaultMessage: "Commander"
  },
  eMail: {
    "id": "intl.commonMessages.eMail",
    defaultMessage: "Adresse e-mail"
  },
  shortEmail: {
    "id": "intl.commonMessages.shortEmail",
    defaultMessage: "E-mail"
  },
  loading: {
    "id": "intl.commonMessages.loading",
    defaultMessage: "Chargement"
  },
  products: {
    "id": "intl.commonMessages.products",
    defaultMessage: "Produits"
  },
  price: {
    "id": "intl.commonMessages.price",
    defaultMessage: "Prix"
  },
  priceUnit: {
    "id": "intl.commonMessages.priceUnit",
    defaultMessage: "Prix unitaire"
  },
  variant: {
    "id": "intl.commonMessages.variant",
    defaultMessage: "Variante"
  },
  phone: {
    "id": "intl.commonMessages.phone",
    defaultMessage: "Téléphone"
  },
  phoneNumber: {
    "id": "intl.commonMessages.phoneNumber",
    defaultMessage: "No de téléphone: {phone}"
  },
  showEmail: {
    "id": "intl.commonMessages.showEmail",
    defaultMessage: "Email: {email}"
  },
  save: {
    "id": "intl.commonMessages.save",
    defaultMessage: "Enregister"
  },
  add: {
    "id": "intl.commonMessages.add",
    defaultMessage: "Ajouter"
  },
  filterHeader: {
    "id": "intl.commonMessages.filterHeader",
    defaultMessage: "Filtres"
  },
  clearFilterHeader: {
    "id": "intl.commonMessages.clearFilterHeader",
    defaultMessage: "Supprimer les filtres"
  },
  status: {
    "id": "intl.commonMessages.status",
    defaultMessage: "Status"
  },
  cancel: {
    "id": "intl.commonMessages.cancel",
    defaultMessage: "Annuler"
  },
  delete: {
    "id": "intl.commonMessages.delete",
    defaultMessage: "Supprimer"
  },
  home: {
    "id": "intl.commonMessages.home",
    defaultMessage: "Acceuil"
  },
  rate: {
    "id": "intl.commonMessages.rate",
    defaultMessage: "Évaluer le produit"
  },
  review: {
    "id": "intl.commonMessages.review",
    defaultMessage: "Votre avis est important"
  },
  requests: {
    "id": "intl.commonMessages.requests",
    defaultMessage: "Demandes"
  },
  newRequest: {
    "id": "intl.commonMessages.newRequest",
    defaultMessage: "Nouvelle demande"
  },
  requestDetails: {
    "id": "intl.commonMessages.requestDetails",
    defaultMessage: "Demande"
  },
  delivery: {
    "id": "intl.commonMessages.delivery",
    defaultMessage: "Livraison",
    fr: "Livraison"
  },
  suggested: {
    "id": "intl.commonMessages.suggested",
    defaultMessage: "Recommendations"
  },
  featured: {
    "id": "intl.commonMessages.featured",
    defaultMessage: "À la une"
  },
  newArrivals: {
    "id": "intl.commonMessages.newArrivals",
    defaultMessage: "Nouveautés"
  },
  bestSellers: {
    "id": "intl.commonMessages.bestSellers",
    defaultMessage: "Meilleures Ventes"
  },
  goodMorning: {
    "id": "intl.commonMessages.goodMorning",
    defaultMessage: "Bonjour "
  },
  goodEvening: {
    "id": "intl.commonMessages.goodEvening",
    defaultMessage: "Bonsoir "
  },
  login: {
    "id": "intl.commonMessages.login",
    defaultMessage: "Se connecter"
  },
  register: {
    "id": "intl.commonMessages.register",
    defaultMessage: "Créer un compte"
  },
  registerForm: {
    "id": "intl.commonMessages.registerForm",
    defaultMessage: "Créer mon compte"
  },
  emptyCart: {
    "id": "intl.commonMessages.emptyCart",
    defaultMessage: "Votre panier est vide"
  },
  discountSave: {
    "id": "intl.commonMessages.discountSave",
    defaultMessage: "Vous économisez "
  },
  applyFilters: {
    "id": "intl.commonMessages.applyFilters",
    defaultMessage: "Filtrer"
  },
  reorder: {
    "id": "intl.commonMessages.reorder",
    defaultMessage: "Commander à nouveau"
  },
  createReturn: {
    "id": "intl.commonMessages.createReturn",
    defaultMessage: "Demander un retour"
  },
  createRequest: {
    "id": "intl.commonMessages.createRequest",
    defaultMessage: "Créer une réclamation"
  },
  discount: {
    "id": "intl.commonMessages.discount",
    defaultMessage: "Remise"
  },
  paymentMethod: {
    "id": "intl.commonMessages.paymentMethod",
    defaultMessage: "Paiement"
  },
  returns: {
    "id": "intl.commonMessages.returns",
    defaultMessage: "Demandes de retour"
  },
  createdAt: {
    "id": "intl.commonMessages.createdAt",
    defaultMessage: "Crée"
  },
  requestReason: {
    "id": "intl.commonMessages.requestReason",
    defaultMessage: "Raison"
  },
  myOrders: {
    "id": "intl.commonMessages.myOrders",
    defaultMessage: "Mes commandes"
  },
  forgotPassword: {
    "id": "intl.commonMessages.forgotPassword",
    defaultMessage: "Mot de passe oublié ?"
  },
  validate: {
    "id": "intl.commonMessages.validate",
    defaultMessage: "Valider"
  },
  addNewAddress: {
    "id": "intl.commonMessages.addNewAddress",
    defaultMessage: "Ajouter une nouvelle adresse"
  },
  state: {
    "id": "intl.commonMessages.state",
    defaultMessage: "Région"
  },
  city: {
    "id": "intl.commonMessages.city",
    defaultMessage: "Ville"
  },
  userInfo: {
    "id": "intl.commonMessages.userInfo",
    defaultMessage: "Informations personnelles"
  },
  edit: {
    "id": "intl.commonMessages.edit",
    defaultMessage: "Modifier"
  }
});
export var checkoutMessages = defineMessages({
  stepNameAddress: {
    "id": "intl.checkoutMessages.stepNameAddress",
    defaultMessage: "Livraison"
  },
  stepNameShipping: {
    "id": "intl.checkoutMessages.stepNameShipping",
    defaultMessage: "Livraison"
  },
  stepNamePayment: {
    "id": "intl.checkoutMessages.stepNamePayment",
    defaultMessage: "Paiement"
  },
  stepNameReview: {
    "id": "intl.checkoutMessages.stepNameReview",
    defaultMessage: "Résumé"
  },
  addressNextActionName: {
    "id": "intl.checkoutMessages.addressNextActionName",
    defaultMessage: "Continuer"
  },
  shippingNextActionName: {
    "id": "intl.checkoutMessages.shippingNextActionName",
    defaultMessage: "Continuer"
  },
  paymentNextActionName: {
    "id": "intl.checkoutMessages.paymentNextActionName",
    defaultMessage: "Continuer "
  },
  reviewNextActionName: {
    "id": "intl.checkoutMessages.reviewNextActionName",
    defaultMessage: "Commander"
  },
  addNewAddress: {
    "id": "intl.checkoutMessages.addNewAddress",
    defaultMessage: "Ajouter une nouvelle adresse"
  },
  shippingMethod: {
    "id": "intl.checkoutMessages.shippingMethod",
    defaultMessage: "Méthode de livraison"
  },
  billingAddress: {
    "id": "intl.checkoutMessages.billingAddress",
    defaultMessage: "Adresse de facturation"
  },
  billingAsShipping: {
    "id": "intl.checkoutMessages.billingAsShipping",
    defaultMessage: "Envoyer ma facture avec la commande"
  },
  paymentMethod: {
    "id": "intl.checkoutMessages.paymentMethod",
    defaultMessage: "Méthode de paiement "
  },
  reviewOrder: {
    "id": "intl.checkoutMessages.reviewOrder",
    defaultMessage: "Contrôlez votre commande"
  },
  shippingAddress: {
    "id": "intl.checkoutMessages.shippingAddress",
    defaultMessage: "Adresse de livraison"
  },
  continueShopping: {
    "id": "intl.checkoutMessages.continueShopping",
    defaultMessage: "Continuer mes achats"
  },
  noShippingAddressError: {
    "id": "intl.checkoutMessages.noShippingAddressError",
    defaultMessage: "Veuillez sélectionner une adresse de livraison"
  },
  noBillingAddressError: {
    "id": "intl.checkoutMessages.noBillingAddressError",
    defaultMessage: "Veuillez sélectionner une adresse de facturation"
  },
  DiscountFormHeadline: {
    "id": "intl.checkoutMessages.DiscountFormHeadline",
    defaultMessage: "Avez-vous un coupon de réduction ?"
  },
  reviewNotifTitle: {
    "id": "intl.checkoutMessages.reviewNotifTitle",
    defaultMessage: "Vérifiez vos informations"
  },
  reviewNotifExpl: {
    "id": "intl.checkoutMessages.reviewNotifExpl",
    defaultMessage: "Assurez-vous de l'adresse de livraison, de la méthode de payment choisie, ainsi que le total de la commande."
  },
  paymentAtDelivery: {
    "id": "intl.checkoutMessages.paymentAtDelivery",
    defaultMessage: "Paiement à la livraison"
  },
  paymentWithCard: {
    "id": "intl.checkoutMessages.paymentWithCard",
    defaultMessage: "Paiement par carte bancaire"
  },
  conditions: {
    "id": "intl.checkoutMessages.conditions",
    defaultMessage: "*En cliquant sur commander, vous acceptez les <Link>conditions générales de vente </Link>."
  },
  cartSummary: {
    "id": "intl.checkoutMessages.cartSummary",
    defaultMessage: "Panier"
  }
});
export var prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsClear",
    defaultMessage: "Par défaut"
  },
  sortOptionsPrice: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPrice",
    defaultMessage: "Les moins chers"
  },
  sortOptionsPriceDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPriceDsc",
    defaultMessage: "Les plus chers"
  },
  sortOptionsName: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsName",
    defaultMessage: "Name Increasing"
  },
  sortOptionsNameDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsNameDsc",
    defaultMessage: "Name Decreasing"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAt",
    defaultMessage: "Les nouveaux"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc",
    defaultMessage: "Last updated Descending"
  },
  sortOptionsRatingDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsRatingDsc",
    defaultMessage: "Les mieux notés"
  }
});
export var paymentStatusMessages = defineMessages({
  notCharged: {
    "id": "intl.paymentStatusMessages.notCharged",
    defaultMessage: "Not charged"
  },
  partiallyCharged: {
    "id": "intl.paymentStatusMessages.partiallyCharged",
    defaultMessage: "Partially charged"
  },
  fullyCharged: {
    "id": "intl.paymentStatusMessages.fullyCharged",
    defaultMessage: "Fully charged"
  },
  partiallyRefunded: {
    "id": "intl.paymentStatusMessages.partiallyRefunded",
    defaultMessage: "Partially refunded"
  },
  fullyRefunded: {
    "id": "intl.paymentStatusMessages.fullyRefunded",
    defaultMessage: "Fully refunded"
  }
});
export var orderStatusMessages = defineMessages({
  draft: {
    "id": "intl.orderStatusMessages.draft",
    defaultMessage: "Draft"
  },
  unfulfilled: {
    "id": "intl.orderStatusMessages.unfulfilled",
    defaultMessage: "En attente"
  },
  partiallyFulfilled: {
    "id": "intl.orderStatusMessages.partiallyFulfilled",
    defaultMessage: ""
  },
  fulfilled: {
    "id": "intl.orderStatusMessages.fulfilled",
    defaultMessage: "En route"
  },
  delivered: {
    "id": "intl.orderStatusMessages.delivered",
    defaultMessage: "Livrée"
  },
  canceled: {
    "id": "intl.orderStatusMessages.canceled",
    defaultMessage: "Annulée"
  }
});
export function translateOrderStatus(status, intl) {
  switch (status) {
    case "Draft":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Fulfilled":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "Delivered":
      return intl.formatMessage(orderStatusMessages.delivered);

    case "Canceled":
      return intl.formatMessage(orderStatusMessages.canceled);

    default:
      return status;
  }
}
export function translatePaymentStatus(status, intl) {
  switch (status) {
    case "Not charged":
      return intl.formatMessage(paymentStatusMessages.notCharged);

    case "Partially charged":
      return intl.formatMessage(paymentStatusMessages.partiallyCharged);

    case "Fully charged":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);

    case "Partially refunded":
      return intl.formatMessage(paymentStatusMessages.partiallyRefunded);

    case "Fully refunded":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);

    default:
      return status;
  }
}
export var requestReasonMsg = defineMessages({
  accessoriesMissing: {
    "id": "intl.requestReasonMsg.accessoriesMissing",
    defaultMessage: "Partie(s) manquante(s)"
  },
  didNotLike: {
    "id": "intl.requestReasonMsg.didNotLike",
    defaultMessage: "J'ai pas aimé"
  },
  differentProduct: {
    "id": "intl.requestReasonMsg.differentProduct",
    defaultMessage: "Produit different"
  },
  invoiceNotReceived: {
    "id": "intl.requestReasonMsg.invoiceNotReceived",
    defaultMessage: "Facture non reçue"
  },
  invoiceErroneous: {
    "id": "intl.requestReasonMsg.invoiceErroneous",
    defaultMessage: "Facture erronée"
  },
  damagedProducts: {
    "id": "intl.requestReasonMsg.damagedProducts",
    defaultMessage: "Produit endomagé"
  },
  smallerSize: {
    "id": "intl.requestReasonMsg.smallerSize",
    defaultMessage: "Taille petite"
  },
  biggerSize: {
    "id": "intl.requestReasonMsg.biggerSize",
    defaultMessage: "Taille grande"
  },
  productNotSameAsAd: {
    "id": "intl.requestReasonMsg.productNotSameAsAd",
    defaultMessage: "Pas comme sur le site"
  },
  badQuality: {
    "id": "intl.requestReasonMsg.badQuality",
    defaultMessage: "Mauvaise qualité"
  }
});
export function translateRequestReason(reason, intl) {
  switch (reason) {
    case RequestReasonsEnum.ACCESSORIES_MISSING:
      return requestReasonMsg.accessoriesMissing.defaultMessage;

    case RequestReasonsEnum.DID_NOT_LIKE:
      return requestReasonMsg.didNotLike.defaultMessage;

    case RequestReasonsEnum.DIFFERENT_PRODUCT:
      return requestReasonMsg.differentProduct.defaultMessage;

    case RequestReasonsEnum.INVOICE_NOT_RECEIVED:
      return requestReasonMsg.invoiceNotReceived.defaultMessage;

    case RequestReasonsEnum.INVOICE_ERRONEOUS:
      return requestReasonMsg.invoiceErroneous.defaultMessage;

    case RequestReasonsEnum.DAMAGED_PRODUCTS:
      return requestReasonMsg.damagedProducts.defaultMessage;

    case RequestReasonsEnum.SMALLER_SIZE:
      return requestReasonMsg.smallerSize.defaultMessage;

    case RequestReasonsEnum.BIGGER_SIZE:
      return requestReasonMsg.biggerSize.defaultMessage;

    case RequestReasonsEnum.PRODUCT_NOT_SAME_AS_ADVERTISED:
      return requestReasonMsg.productNotSameAsAd.defaultMessage;

    case RequestReasonsEnum.BAD_QUALITY:
      return requestReasonMsg.badQuality.defaultMessage;
  }
}
export var requestStatusMsg = defineMessages({
  accepted: {
    "id": "intl.requestStatusMsg.accepted",
    defaultMessage: "Acceptée"
  },
  rejected: {
    "id": "intl.requestStatusMsg.rejected",
    defaultMessage: "Rejetée"
  },
  closed: {
    "id": "intl.requestStatusMsg.closed",
    defaultMessage: "Terminée"
  },
  pending: {
    "id": "intl.requestStatusMsg.pending",
    defaultMessage: "En attente"
  },
  processing: {
    "id": "intl.requestStatusMsg.processing",
    defaultMessage: "En cours d'évaluation"
  },
  refunded: {
    "id": "intl.requestStatusMsg.refunded",
    defaultMessage: "Remboursée"
  }
});
export function translateRequestStatus(status, intl) {
  switch (status) {
    case RequestStatus.ACCEPTED:
      return requestStatusMsg.accepted.defaultMessage;

    case RequestStatus.REJECTED:
      return requestStatusMsg.rejected.defaultMessage;

    case RequestStatus.CLOSED:
      return requestStatusMsg.closed.defaultMessage;

    case RequestStatus.PENDING:
      return requestStatusMsg.pending.defaultMessage;

    case RequestStatus.PROCESSING:
      return requestStatusMsg.processing.defaultMessage;

    case RequestStatus.REFUNDED:
      return requestStatusMsg.refunded.defaultMessage;
  }
}
export var requestReplacementMsg = defineMessages({
  voucher: {
    "id": "intl.requestReplacementMsg.voucher",
    defaultMessage: "Je voudrais recevoir un coupon"
  },
  refund: {
    "id": "intl.requestReplacementMsg.refund",
    defaultMessage: "Je voudrais un remboursement"
  },
  exchange: {
    "id": "intl.requestReplacementMsg.exchange",
    defaultMessage: "Je voudrais faire un échange"
  }
});
export function translateRequestReplacementMsg(replacement, intl) {
  switch (replacement) {
    case RequestReplacement.REFUND:
      return requestReplacementMsg.refund.defaultMessage;

    case RequestReplacement.VOUCHER:
      return requestReplacementMsg.voucher.defaultMessage;

    case RequestReplacement.EXCHANGE:
      return requestReplacementMsg.exchange.defaultMessage;
  }
}
export var reviewHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.reviewHeaderCommonMsg.sortOptionsClear",
    defaultMessage: "Par défaut"
  },
  sortOptionsCreatedDsc: {
    "id": "intl.reviewHeaderCommonMsg.sortOptionsCreatedDsc",
    defaultMessage: "Les plus récents"
  },
  sortOptionsCreated: {
    "id": "intl.reviewHeaderCommonMsg.sortOptionsCreated",
    defaultMessage: "Les plus anciens"
  },
  sortOptionsRatingDsc: {
    "id": "intl.reviewHeaderCommonMsg.sortOptionsRatingDsc",
    defaultMessage: "Les mieux notés"
  },
  sortOptionsRating: {
    "id": "intl.reviewHeaderCommonMsg.sortOptionsRating",
    defaultMessage: "Note croissante"
  }
});
export var FooterPreCommonMsg = defineMessages({
  delivery: {
    "id": "intl.FooterPreCommonMsg.delivery",
    defaultMessage: "Livraison gratuite"
  },
  deliveryBold: {
    "id": "intl.FooterPreCommonMsg.deliveryBold",
    defaultMessage: "A partir de 200Dt"
  },
  return: {
    "id": "intl.FooterPreCommonMsg.return",
    defaultMessage: "Retour gratuit"
  },
  returnBold: {
    "id": "intl.FooterPreCommonMsg.returnBold",
    defaultMessage: "jusqu'à 7 jours"
  },
  payment: {
    "id": "intl.FooterPreCommonMsg.payment",
    defaultMessage: "Payment en ligne"
  },
  paymentBold: {
    "id": "intl.FooterPreCommonMsg.paymentBold",
    defaultMessage: "100% sécurisé"
  },
  customerService: {
    "id": "intl.FooterPreCommonMsg.customerService",
    defaultMessage: "Service Client"
  },
  customerServiceBold: {
    "id": "intl.FooterPreCommonMsg.customerServiceBold",
    defaultMessage: "de 9h à 22h"
  }
});
export var authMessages = defineMessages({
  requestPasswordResetTitle: {
    "id": "intl.authMessages.requestPasswordResetTitle",
    defaultMessage: "Changez votre mot de passe"
  },
  requestPasswordResetExpl: {
    "id": "intl.authMessages.requestPasswordResetExpl",
    defaultMessage: "Entrez votre addresse e-mail. Nous allonz vous envoyer un mail qui vous permettera de changez votre mot de passe."
  },
  invalidCreds: {
    "id": "intl.authMessages.invalidCreds",
    defaultMessage: "Addresse e-mail ou mot de passe éroné(s). Essayez à nouveau.",
    title: "Informations invalides"
  },
  genericError: {
    "id": "intl.authMessages.genericError",
    defaultMessage: "Une erreur inattendue est sûrvenue",
    title: "Erreur"
  },
  requestPasswordChangeSuccess: {
    "id": "intl.authMessages.requestPasswordChangeSuccess",
    defaultMessage: "Votre demande est approuvée. Vous receverez dans quelques instants un e-mail avec les instructions pour changer votre mot de passe. Si vous n'avez rien reçu, vérifiez votre courrier indésirable (spam), ou réessayez ultérieurement.",
    title: "Consultez votre boîte e-mail"
  },
  emailTaken: {
    "id": "intl.authMessages.emailTaken",
    title: "Adresse e-mail déjà utilisée.",
    defaultMessage: "Un compte utilisant cette adresse e-mail existe déjà. Si c'est votre compte, connectez-vous. Sinon, utilisez une autre adresse e-mail."
  },
  invalidEmail: {
    "id": "intl.authMessages.invalidEmail",
    title: "Adresse e-mail invalide.",
    defaultMessage: "Vérifiez l'adresse e-mail que vous avez saisie."
  },
  registerSuccess: {
    "id": "intl.authMessages.registerSuccess",
    title: "Bienvenue avec nous",
    defaultMessage: "Votre compte a été créé. Pour l'activer, veuillez vérifier votre boîte e-mail. A très bientôt!"
  }
});
export function translateAuthMessages(message, //fixme
intl) {
  switch (message) {
    case AccountErrorCode.INVALID_CREDENTIALS:
      return {
        title: authMessages.invalidCreds.title,
        expl: authMessages.invalidCreds.defaultMessage
      };

    case "User with this Email already exists.":
      return {
        title: authMessages.emailTaken.title,
        expl: authMessages.emailTaken.defaultMessage
      };

    case "Enter a valid email address.":
      return {
        title: authMessages.invalidEmail.title,
        expl: authMessages.invalidEmail.defaultMessage
      };

    default:
      return {
        title: authMessages.genericError.title,
        expl: authMessages.genericError.defaultMessage
      };
  }
}
export var accountMessages = defineMessages({
  unfulfilled: {
    "id": "intl.accountMessages.unfulfilled",
    defaultMessage: "On est entrain de préparer votre colis. Si vous avez commandé des articles de plusieurs vendeurs, vous pouvez recevoir plusieurs colis."
  },
  fulfilled: {
    "id": "intl.accountMessages.fulfilled",
    defaultMessage: "Votre colis est en route."
  },
  cancelOrder: {
    "id": "intl.accountMessages.cancelOrder",
    defaultMessage: "Annuler la commande"
  },
  canceled: {
    "id": "intl.accountMessages.canceled",
    defaultMessage: "Votre commande a été annulé."
  }
});