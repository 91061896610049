import React from "react";
import { useLocation } from "react-router";
import Media from "react-media";
import { SvgEasyReturn, SvgSecuredPayment, SvgCustomerService, SvgDelivery } from "images/index";
import * as S from "./styles";
import { FormattedMessage } from "react-intl";
import { FooterPreCommonMsg } from "@temp/intl";
import { largeScreen } from "@styles/constants";
var EXCLUDED_PATHS_MOBILE = ["checkout"];
export var FooterPre = function FooterPre() {
  var location = useLocation();
  var showMobile = EXCLUDED_PATHS_MOBILE.reduce(function (p, c) {
    return p && !location.pathname.includes(c);
  }, true);
  return React.createElement(Media, {
    query: {
      maxWidth: largeScreen
    }
  }, function (matches) {
    return matches ? showMobile && React.createElement("div", {
      className: "container"
    }, React.createElement(S.Wrapper, null, React.createElement(S.Item, null, React.createElement(SvgDelivery, null), React.createElement("div", {
      className: "text"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.delivery), React.createElement("span", {
      className: "highlight"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.deliveryBold)))), React.createElement(S.Item, {
      className: "reversed"
    }, React.createElement(SvgEasyReturn, null), React.createElement("div", {
      className: "text"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.return), React.createElement("span", {
      className: "highlight"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.returnBold)))), React.createElement(S.Item, null, React.createElement(SvgSecuredPayment, null), React.createElement("div", {
      className: "text"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.payment), React.createElement("span", {
      className: "highlight"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.paymentBold)))), React.createElement(S.Item, {
      className: "reversed"
    }, React.createElement(SvgCustomerService, null), React.createElement("div", {
      className: "text"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.customerService), React.createElement("span", {
      className: "highlight"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.customerServiceBold)))))) : React.createElement("div", {
      className: "container"
    }, React.createElement(S.Wrapper, null, React.createElement(S.Item, null, React.createElement(SvgDelivery, null), React.createElement("div", {
      className: "text"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.delivery), React.createElement("span", {
      className: "highlight"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.deliveryBold)))), React.createElement(S.Item, {
      className: "reversed"
    }, React.createElement(SvgEasyReturn, null), React.createElement("div", {
      className: "text"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.return), React.createElement("span", {
      className: "highlight"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.returnBold)))), React.createElement(S.Item, null, React.createElement(SvgSecuredPayment, null), React.createElement("div", {
      className: "text"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.payment), React.createElement("span", {
      className: "highlight"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.paymentBold)))), React.createElement(S.Item, {
      className: "reversed"
    }, React.createElement(SvgCustomerService, null), React.createElement("div", {
      className: "text"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.customerService), React.createElement("span", {
      className: "highlight"
    }, React.createElement(FormattedMessage, FooterPreCommonMsg.customerServiceBold))))));
  });
};
FooterPre.displayName = "FooterPre";
export default FooterPre;