import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./scss/index.scss";
import { AddressDeleteModal, AddressFormModal, AddressGrid } from "@components/organisms";
import { checkoutMessages, commonMessages } from "@temp/intl";
import { useDeleteUserAddresss } from "@lastmaj/saleor-sdk";
import { AccountErrorCode } from "gqlTypes/globalTypes";
import { getUserDetailsQuery } from "@lastmaj/saleor-sdk/lib/queries/user";
import { ShopContext } from "../../components/ShopProvider/context";
import { Button } from "@components/atoms";
import { useAlert } from "react-alert";

var AddressBook = function AddressBook(_ref) {
  var user = _ref.user;

  var _React$useContext = React.useContext(ShopContext),
      defaultCountry = _React$useContext.defaultCountry;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      displayNewModal = _React$useState2[0],
      setDisplayNewModal = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      displayEditModal = _React$useState4[0],
      setDisplayEditModal = _React$useState4[1];

  var _React$useState5 = React.useState(""),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      deletingAddressId = _React$useState6[0],
      setDeletingAddressId = _React$useState6[1];

  var _React$useState7 = React.useState(null),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      addressData = _React$useState8[0],
      setAddressData = _React$useState8[1];

  var intl = useIntl();
  var alert = useAlert();

  var _useDeleteUserAddress = useDeleteUserAddresss(undefined, {
    refetchQueries: function refetchQueries(result) {
      if (result.data.accountAddressDelete.errors.length > 0) {
        if (result.data.accountAddressDelete.errors.find(function (err) {
          return err.code === AccountErrorCode.NOT_FOUND;
        })) {
          return [{
            query: getUserDetailsQuery
          }];
        } else if (result.data.accountAddressDelete.errors.find( // @ts-ignore: next-line
        function (err) {
          return err.code === AccountErrorCode.ADDRESS_USED_IN_CHECKOUT;
        })) {
          alert.show({
            title: intl.formatMessage({
              defaultMessage: "Adresse en cours d'utilisation.",
              "id": "account.AddressBook.AddressBook.3958473569"
            })
          }, {
            type: "error",
            timeout: 4000
          });
        }
      } else {
        alert.show({
          title: intl.formatMessage({
            defaultMessage: "Adresse supprimée",
            "id": "account.AddressBook.AddressBook.1249454031"
          })
        }, {
          type: "success",
          timeout: 3000
        });
      }
    }
  }),
      _useDeleteUserAddress2 = _slicedToArray(_useDeleteUserAddress, 1),
      setDeleteUserAddress = _useDeleteUserAddress2[0];

  var userAddresses = user.addresses.map(function (address) {
    var addressToDisplay = {
      address: _objectSpread({}, address)
    };

    addressToDisplay.onEdit = function () {
      setDisplayEditModal(true);
      setAddressData({
        address: address,
        id: address.id
      });
    };

    addressToDisplay.onRemove = function (id) {
      setDeletingAddressId(id);
    };

    return addressToDisplay;
  });
  return React.createElement("div", {
    className: "address-book-container"
  }, React.createElement("div", {
    className: "address-book-container__header"
  }, React.createElement("h2", null, React.createElement(FormattedMessage, commonMessages.addressBook)), React.createElement(Button, {
    size: "sm",
    onClick: function onClick() {
      return setDisplayNewModal(true);
    },
    className: "address-book__add-new"
  }, React.createElement(FormattedMessage, checkoutMessages.addNewAddress))), React.createElement(AddressGrid, {
    addresses: userAddresses
  }), displayNewModal && React.createElement(AddressFormModal, _extends({
    hideModal: function hideModal() {
      setDisplayNewModal(false);
    },
    userId: user.id
  }, {
    defaultValue: defaultCountry || {}
  }, {
    submitBtnText: intl.formatMessage(commonMessages.add),
    title: intl.formatMessage(checkoutMessages.addNewAddress),
    formId: "address-form"
  })), displayEditModal && React.createElement(AddressFormModal, {
    hideModal: function hideModal() {
      setDisplayEditModal(false);
    },
    address: addressData,
    submitBtnText: intl.formatMessage(commonMessages.save),
    title: intl.formatMessage(_defineProperty({
      defaultMessage: "Edit address",
      "id": "account.AddressBook.AddressBook.2776756156"
    }, "id", "account.AddressBook.AddressBook.2776756156")),
    formId: "address-form"
  }), deletingAddressId && React.createElement(AddressDeleteModal, {
    hideModal: function hideModal() {
      return setDeletingAddressId("");
    },
    title: "Supprimer cette addresse ?",
    onSubmit: function onSubmit() {
      setDeleteUserAddress({
        addressId: deletingAddressId
      });
      setDeletingAddressId("");
    },
    addressId: deletingAddressId
  }));
};

export default AddressBook;