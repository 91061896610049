// colors
export var autofillColor = "rgb(250, 255, 189)";
export var autofillColorSelected = "rgb(232, 240, 254)";
export var baseFontColor = "#323232";
export var baseFontColorSemiTransparent = "rgba(50,50,50,0.6)";
export var baseFontColorTransparent = "rgba(50,50,50,0.1)";
export var black = "#323232"; // export const blue = "rgb(33,18,94)";

export var blueDark = "#190c4e";
export var blueLight = "#513CA3";
export var blueOverlay = "rgba(33,18,94,0.1)";
export var blueOverlayDark = "rgba(33,18,94,0.2)";
export var gray = "#7d7d7d";
export var grayMedium = "#c4c4c4";
export var grayDark = "#323232";
export var grayLight = "#f1f5f5";
export var greenSaleor = "#3ed256";
export var overlayColor = "rgba(199, 207, 207, 0.8)";
export var rose = "#c22d74";
export var turquoise = "#13bebb";
export var turquoiseDark = "#06a09e";
export var turquoiseLight = "rgba(6, 132, 123, 0.25)";
export var turquoiseTransparent = "rgba(6, 132, 123, 0.1)";
export var white = "#fff";
export var tabelGray = "#eaeaea";
export var darkGreen = "#06847B";
export var secondaryGrey = "#EBF1F6";
export var secondaryGreyDark = "#9D9FB1";
export var secondaryBlue = "#036DFF";
export var green = "#97cbc7";
export var blue = "#9cd8f5";
export var greenPastel = "#daedde";
export var purple = "#babadd";
export var pink = "#f394b8";
export var greenBackground = "#e9ffe9";
export var pinkBackground = "#ffc2d9";
export var primaryAccent = "#7bcac4"; // theme colors

export var theme = {
  activeMenuOption: darkGreen,
  autofill: autofillColor,
  autofillSelected: autofillColorSelected,
  bannerBackground: secondaryGrey,
  bannerEdge: secondaryGreyDark,
  bannerLink: secondaryBlue,
  baseFont: baseFontColor,
  baseFontColorSemiTransparent: baseFontColorSemiTransparent,
  baseFontColorTransparent: baseFontColorTransparent,
  dark: black,
  disabled: gray,
  divider: grayLight,
  dividerDark: grayMedium,
  hoverLightBackground: turquoiseLight,
  light: grayLight,
  lightFont: gray,
  listAttributeName: baseFontColorSemiTransparent,
  listBullet: darkGreen,
  overlay: overlayColor,
  primary: turquoise,
  primaryDark: turquoiseDark,
  primaryLight: turquoiseLight,
  primaryTransparent: turquoiseTransparent,
  secondary: blue,
  secondaryDark: blueDark,
  secondaryLight: blueLight,
  secondaryOverlay: blueOverlay,
  secondaryOverlayDark: blueOverlayDark,
  success: green,
  greenSaleor: greenSaleor,
  tabTitle: darkGreen,
  tableDivider: tabelGray,
  tabsBorder: baseFontColorTransparent,
  thumbnailBorder: darkGreen,
  white: white,
  greenBackground: greenBackground,
  pinkBackground: pinkBackground,
  darkGreen: darkGreen,
  greenDelivery: "rgba(101,201,101,10%)",
  grayUnfulfilled: "rgba(136,136,136, 10%)",
  redOrderDetailsRequest: "rgb(255,87,34, 10%)",
  redOrderDetailsRequestBorder: "rgb(255, 0, 0, 15%)",
  yellowLightBackground: "rgb(255, 229, 0, 35%)",
  grayLightBackground: "rgb(212, 212, 212, 15%)",
  yellowText: "#40401b",
  gray: "#7d7d7d",
  redPrice: "#e93a3a",
  redNotification: "#980000",
  error: "#ff1717",
  redDiscount: "red",
  logoGreen: "#96cbc7",
  hoverMenuGray: "#f1f5f5",
  buttonBackgroundBlack: "#323232",
  blackGrayText: "#585858",
  grayBorder: "#aeaeae38",
  labelGray: "#595959",
  logoPurple: "#3f51b5"
}; // typography

export var baseFontFamily = "'Montserrat', sans-serif";
export var baseFontSize = "1rem"; // 16px

export var baseLineHeight = "1.25rem"; // 20px

export var boldFontWeight = 600;
export var extraBoldFontWeight = 800;
export var h1FontSize = "4rem"; // 64px

export var h2FontSize = "3rem"; // 48px

export var h1LineHeight = 1;
export var h3FontSize = "1.5rem"; // 24px

export var h4FontSize = "1.125rem"; // 18px

export var labelFontSize = "0.75rem"; // 12px

export var smallFontSize = "0.875rem"; // 14px

export var ultraBigFont = "6rem"; // 96px
// spacing

export var spacer = "1"; // rem

export var fieldSpacer = "1rem"; // breakpoints

export var xxxLargeScreen = 1920;
export var xxLargeScreen = 1600;
export var xLargeScreen = 1280;
export var largeScreen = 992;
export var mediumScreen = 720;
export var smallScreen = 540;
export var xSmallScreen = 420; // dropdown menus

export var dropdownMenuBoxShadow = "0px 0 10px 0px rgba(0, 0, 0, 0.15)";