import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n\n  background-color: white;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  grid-area: button;\n  margin: 3rem auto 0;\n  width: 50%;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  padding: 2rem 0 0 0;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  a {\n    -webkit-tap-highlight-color: transparent;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  border-bottom: 1px solid\n    ", ";\n\n  background-color: #fafafa;\n  width: 100vw;\n  margin-left: -17px;\n  margin-top: -17px;\n  padding: 1rem 1rem 0;\n\n  display: flex;\n  align-items: flex-start;\n\n  > div:first-of-type {\n    margin-right: 1.5rem;\n\n    svg {\n      width: 1.5rem;\n      height: auto;\n      transform: rotate(180deg);\n\n      margin-top: 2px;\n    }\n  }\n\n  > div:last-of-type {\n    flex: 1;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  height: 80px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n  border: 1px solid ", ";\n  border-radius: 10px;\n  height: fit-content;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  flex: 2;\n  padding: 0;\n  margin: 0;\n  height: fit-content;\n  border-radius: 10px;\n\n  @media (min-width: ", ") {\n    padding: 0;\n    margin-right: 30px;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    flex-direction: column;\n    margin: 1rem 0 8rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin: 2rem 0;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 10;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Loader = styled.div(_templateObject());
export var Wrapper = styled.div(_templateObject2(), media.largeScreen(_templateObject3()));
export var Main = styled.div(_templateObject4(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var CartSummary = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.grayBorder;
});
export var Navigation = styled.div(_templateObject6());
export var NavigationContainer = styled.div(_templateObject7(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var GoBack = styled.div(_templateObject8());
export var Checkout = styled.div(_templateObject9());
export var Button = styled.div(_templateObject10());
export var CartFooterWrapper = styled.div(_templateObject11());