import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
import SvgMyOrders from "images/SvgMyOrders";
import SvgUserInfo from "images/SvgUserInfo";
import SvgLocation from "images/SvgLocation";
export var AccountMenu = function AccountMenu(_ref) {
  var links = _ref.links,
      active = _ref.active;
  var intl = useIntl();
  return React.createElement(S.Wrapper, null, React.createElement(S.MenuHeader, null, React.createElement(FormattedMessage, commonMessages.myAccount)), React.createElement(S.MenuItems, null, links.map(function (link) {
    var menuItem = link.replace(/\//g, "").replace("-", " ").split(" ").map(function (s) {
      return s.charAt(0).toUpperCase() + s.substring(1);
    }).join(" ");
    var menuItemTrans = menuItem;
    var Svg = React.createElement(React.Fragment, null);
    /* eslint-disable default-case */

    switch (menuItem) {
      case "My Orders":
        menuItemTrans = intl.formatMessage(commonMessages.orderHistory);
        Svg = React.createElement(SvgMyOrders, {
          id: "svgmyorders"
        });
        break;

      case "Account":
        menuItemTrans = intl.formatMessage(commonMessages.account);
        Svg = React.createElement(SvgUserInfo, {
          id: "svguserinfo"
        });
        break;

      case "Address Book":
        menuItemTrans = intl.formatMessage(commonMessages.addressBook);
        Svg = React.createElement(SvgLocation, {
          id: "svglocation"
        });
        break;
    }

    return React.createElement(S.MenuItem, {
      active: active === link
    }, React.createElement(Link, {
      to: link,
      key: link,
      "data-test": "accountMenuLink",
      "data-test-id": link
    }, Svg, menuItemTrans));
  })));
};