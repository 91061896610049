import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth, useCart } from "@lastmaj/saleor-sdk";
import Media from "react-media";
import ReactSVG from "react-svg";
import { MenuDropdown, Offline, Online, OverlayContext, OverlayTheme, OverlayType } from "..";
import * as appPaths from "../../app/routes";
import { maybe } from "../../core/utils";
import NavDropdown from "./NavDropdown";
import { TypedMainMenuQuery } from "./queries";
import hamburgerHoverImg from "../../images/hamburger-hover.svg";
import hamburgerImg from "../../images/hamburger.svg";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";
import { Cart } from "@components/organisms";
import { CartDropdown } from "@components/containers";
import { largeScreen } from "@styles/constants";
import { SvgWonder, SvgSearch } from "@temp/images";
import { searchUrl } from "../../app/routes";
var EXCLUDED_PATHS_MOBILE = ["checkout"];

var MainMenu = function MainMenu() {
  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      search = _React$useState2[0],
      setSearch = _React$useState2[1];

  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var _useCart = useCart(),
      items = _useCart.items;

  var history = useHistory();

  var handleSignOut = function handleSignOut() {
    signOut();
    history.push("/");
  };

  var overlayContext = React.useContext(OverlayContext);

  var _React$useState3 = React.useState(undefined),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      activeDropdown = _React$useState4[0],
      setActiveDropdown = _React$useState4[1];

  var cartItemsQuantity = items && items.reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0) || 0;
  React.useEffect(function () {
    if (activeDropdown) {
      overlayContext.show(OverlayType.mainMenuNav, OverlayTheme.modal);
    } else {
      overlayContext.hide();
    }
  }, [activeDropdown]);

  var showDropdownHandler = function showDropdownHandler(itemId, hasSubNavigation) {
    if (hasSubNavigation) {
      setActiveDropdown(itemId);
    }
  };

  var hideDropdownHandler = function hideDropdownHandler() {
    if (activeDropdown) {
      setActiveDropdown(undefined);
    }
  };

  var location = useLocation();
  var showMobile = EXCLUDED_PATHS_MOBILE.reduce(function (p, c) {
    return p && !location.pathname.includes(c);
  }, true);
  return React.createElement(OverlayContext.Consumer, null, function (overlayContext) {
    return React.createElement(TypedMainMenuQuery, {
      renderOnError: true,
      displayLoader: false
    }, function (_ref) {
      var data = _ref.data;
      var items = maybe(function () {
        return data.shop.navigation.main.items;
      }, []);
      return React.createElement(Media, {
        query: {
          minWidth: largeScreen
        }
      }, function (matches) {
        return matches ? React.createElement(React.Fragment, null, React.createElement("nav", {
          className: "container top-navbar",
          id: "header"
        }, React.createElement("div", {
          className: "top-navbar__left"
        }, React.createElement(Link, {
          to: appPaths.baseUrl
        }, React.createElement(SvgWonder, null))), React.createElement("div", {
          className: "top-navbar__center__search"
        }, React.createElement("form", {
          onSubmit: function onSubmit(e) {
            if (search.length > 2) history.push("".concat(searchUrl, "?q=").concat(search));
            e.preventDefault();
          }
        }, React.createElement("input", {
          "data-test": "menuSearchOverlayLink",
          className: "top-navbar__search",
          placeholder: commonMessages.search.defaultMessage,
          onChange: function onChange(e) {
            return setSearch(e.target.value);
          },
          value: search
        }), React.createElement("button", null, React.createElement(SvgSearch, null)))), React.createElement("div", {
          className: "top-navbar__right"
        }, React.createElement("ul", null, React.createElement(Online, null, React.createElement(Media, {
          query: {
            minWidth: largeScreen
          },
          render: function render() {
            return React.createElement(React.Fragment, null, React.createElement(MenuDropdown, _extends({
              isLoggedIn: !!user,
              userName: (user === null || user === void 0 ? void 0 : user.firstName) || ""
            }, {
              handleSignOut: handleSignOut
            })), React.createElement(CartDropdown, _extends({
              cartItemsQuantity: cartItemsQuantity
            }, {
              content: React.createElement(Cart, null)
            })));
          }
        })), React.createElement(Offline, null, React.createElement("li", {
          className: "top-navbar__offline"
        }, React.createElement(Media, {
          query: {
            minWidth: mediumScreen
          },
          render: function render() {
            return React.createElement("span", null, React.createElement(FormattedMessage, {
              id: "components.MainMenu.MainMenu.4144939532",
              defaultMessage: "Offline"
            }));
          }
        })))))), React.createElement("div", {
          className: "main-menu-container"
        }, React.createElement("div", {
          className: "container"
        }, React.createElement("div", {
          className: "main-menu"
        }, React.createElement("ul", null, items.map(function (item) {
          var _item$children;

          var hasSubNavigation = !!(item === null || item === void 0 ? void 0 : (_item$children = item.children) === null || _item$children === void 0 ? void 0 : _item$children.length);
          return React.createElement("li", {
            "data-test": "mainMenuItem",
            className: "main-menu__item",
            key: item.id
          }, React.createElement(NavDropdown, _extends({
            overlay: overlayContext,
            showDropdown: activeDropdown === item.id && hasSubNavigation,
            onShowDropdown: function onShowDropdown() {
              return showDropdownHandler(item.id, hasSubNavigation);
            },
            onHideDropdown: hideDropdownHandler
          }, item)));
        })))))) : showMobile && React.createElement("nav", {
          className: "top-navbar",
          id: "header"
        }, React.createElement("div", {
          className: "top-navbar__left"
        }, React.createElement("li", {
          "data-test": "toggleSideMenuLink",
          className: "top-navbar__hamburger",
          onClick: function onClick() {
            return overlayContext.show(OverlayType.sideNav, OverlayTheme.left, {
              data: items
            });
          }
        }, React.createElement(ReactSVG, {
          path: hamburgerImg,
          className: "main-menu__hamburger--icon"
        }), React.createElement(ReactSVG, {
          path: hamburgerHoverImg,
          className: "main-menu__hamburger--hover"
        }))), React.createElement("div", {
          className: "top-navbar__center__logo"
        }, React.createElement(Link, {
          to: appPaths.baseUrl
        }, React.createElement(SvgWonder, null))), React.createElement("div", {
          className: "top-navbar__right"
        }, React.createElement("div", {
          "data-test": "menuSearchOverlayLink",
          className: "top-navbar__right__search",
          onClick: function onClick() {
            return overlayContext.show(OverlayType.search, OverlayTheme.right);
          }
        }, React.createElement(SvgSearch, null))));
      });
    });
  });
};

export default MainMenu;