import React from "react";
import * as S from "./styles";
export var CollectionsBannerCarousel = function CollectionsBannerCarousel(_ref) {
  var collections = _ref.collections,
      onCollectionClick = _ref.onCollectionClick,
      activeCollection = _ref.activeCollection;
  return React.createElement(S.Wrapper, null, React.createElement(S.Carousel, null, React.createElement("div", {
    className: "slider",
    style: {
      height: "".concat(100 + (collections.length - 4) * 25, "%")
    }
  }, collections && collections.length > 0 ? collections.map(function (collection) {
    return React.createElement(S.Thumb, {
      source: {
        thumbnail: collection.backgroundImage
      },
      onClick: function onClick() {
        return onCollectionClick(collection);
      },
      active: collection.id === activeCollection
    });
  }) : {})));
};
CollectionsBannerCarousel.displayName = "CollectionsBannerCarousel";
export default CollectionsBannerCarousel;