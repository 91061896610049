import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n\n  a {\n    -webkit-tap-highlight-color: transparent;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  &:not(:last-child) {\n    width: 100%;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  z-index: 1;\n  width: 100%;\n  height: 4px;\n  background-color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  transform: none;\n  right: 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  transform: none;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  white-space: pre;\n  display: block;\n  position: absolute;\n  transform: translateX(-25%);\n  letter-spacing: 2%;\n  color: ", ";\n  font-size: 0.75rem;\n\n  @media (min-width: ", ") {\n    font-size: 0.875rem;\n    top: 35px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  border-radius: 50%;\n\n  span {\n    font-size: 0.875rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  border-radius: 50%;\n  background-color: ", ";\n  width: 25px;\n  height: 25px;\n  z-index: 2;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  span {\n    font-size: 0.875rem;\n    font-weight: 500;\n    color: white;\n  }\n\n  svg {\n    width: 25px;\n    height: 25px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Dot = styled.div(_templateObject(), function (props) {
  return props.done ? "#06847B" : "#c2c2c2";
});
export var ActiveDot = styled.div(_templateObject2());
export var Label = styled.span(_templateObject3(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var LeftLabel = styled(Label)(_templateObject4());
export var RightLabel = styled(Label)(_templateObject5());
export var ProgressBar = styled.div(_templateObject6(), function (props) {
  return props.done ? "#06847B" : "#c2c2c2";
});
export var Step = styled.div(_templateObject7());
export var Wrapper = styled.div(_templateObject8());