import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { Link } from "react-router-dom";
import * as appPaths from "@temp/app/routes";
import * as S from "./styles";
export var MenuProfile = function MenuProfile(_ref) {
  var handleSignOut = _ref.handleSignOut,
      handleDropdownHide = _ref.handleDropdownHide,
      userName = _ref.userName;

  var greeting = function greeting() {
    var today = new Date();
    var curHr = today.getHours();
    var greet = "";
    if (curHr < 18 && curHr > 5) greet = commonMessages.goodMorning.defaultMessage;else greet = commonMessages.goodEvening.defaultMessage;
    return "".concat(greet, " ").concat(userName, "!");
  };

  var onSignOut = function onSignOut() {
    handleDropdownHide();
    handleSignOut();
  };

  return React.createElement(S.Body, null, React.createElement(S.Greeting, null, greeting()), React.createElement(S.Links, null, React.createElement("li", {
    "data-test": "desktopMenuMyAccountLink"
  }, React.createElement(Link, {
    to: appPaths.accountUrl,
    onClick: handleDropdownHide
  }, React.createElement(FormattedMessage, commonMessages.myAccount))), React.createElement("li", {
    "data-test": "desktopMenuOrderHistoryLink"
  }, React.createElement(Link, {
    to: appPaths.orderHistoryUrl,
    onClick: handleDropdownHide
  }, React.createElement(FormattedMessage, commonMessages.orderHistory))), React.createElement("li", {
    "data-test": "desktopMenuAddressBookLink"
  }, React.createElement(Link, {
    to: appPaths.addressBookUrl,
    onClick: handleDropdownHide
  }, React.createElement(FormattedMessage, commonMessages.addressBook))), React.createElement("li", {
    onClick: onSignOut,
    "data-test": "desktopMenuLogoutLink"
  }, React.createElement(FormattedMessage, commonMessages.logOut))));
};
MenuProfile.displayName = "MenuProfile";
export default MenuProfile;