import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { ProductListHeader } from "@components/molecules";
import { FilterSidebar, FilterSidebarMobile, ProductList } from "@components/organisms";
import { largeScreen } from "@styles/constants";
import { Breadcrumbs, ProductsFeatured } from "@temp/components";
import { commonMessages } from "@temp/intl";
import { maybe } from "@utils/misc";
import React from "react";
import { useIntl } from "react-intl";
import Media from "react-responsive";
import * as S from "./styles";

var Page = function Page(_ref) {
  var vendorName = _ref.vendorName,
      products = _ref.products,
      attributes = _ref.attributes,
      filters = _ref.filters,
      displayLoader = _ref.displayLoader,
      loading = _ref.loading,
      hasNextPage = _ref.hasNextPage,
      sortOptions = _ref.sortOptions,
      activeFilters = _ref.activeFilters,
      activePriceFilter = _ref.activePriceFilter,
      activeSortOption = _ref.activeSortOption,
      clearFilters = _ref.clearFilters,
      clearPriceFilter = _ref.clearPriceFilter,
      onLoadMore = _ref.onLoadMore,
      onSubmitPriceFilter = _ref.onSubmitPriceFilter,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange,
      onOrder = _ref.onOrder,
      setAttributeFilters = _ref.setAttributeFilters;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var intl = useIntl();
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });
  var hasProducts = canDisplayProducts && !!products.totalCount;
  React.useEffect(function () {
    document.body.style.overflow = showFilters ? "hidden" : "";
  }, [showFilters]);

  var getAttribute = function getAttribute(attributeSlug, valueSlug) {
    return {
      attributeSlug: attributeSlug,
      valueName: attributes.find(function (_ref2) {
        var slug = _ref2.slug;
        return attributeSlug === slug;
      }).values.find(function (_ref3) {
        var slug = _ref3.slug;
        return valueSlug === slug;
      }).name,
      valueSlug: valueSlug
    };
  };

  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  return React.createElement(S.Wrapper, {
    className: "vendor"
  }, React.createElement(S.Container, null, React.createElement(Breadcrumbs, {
    breadcrumbs: [{
      value: vendorName,
      link: location.pathname
    }]
  })), React.createElement(S.LoadingContainer, null, React.createElement(S.Loading, {
    loading: loading
  }), React.createElement(S.Container, null, React.createElement(S.MainContainer, null, React.createElement(Media, {
    maxWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(FilterSidebarMobile, _extends({
      show: showFilters,
      hide: function hide() {
        return setShowFilters(false);
      },
      onAttributeFiltersChange: onAttributeFiltersChange,
      onAttributeFiltersSubmit: setAttributeFilters
    }, {
      attributes: attributes,
      filters: filters,
      onSubmitPriceFilter: onSubmitPriceFilter,
      activePriceFilter: activePriceFilter,
      setAttributeFilters: setAttributeFilters
    })) : React.createElement(FilterSidebar, _extends({
      onAttributeFiltersChange: onAttributeFiltersChange,
      attributes: attributes,
      filters: filters
    }, {
      onSubmitPriceFilter: onSubmitPriceFilter,
      activePriceFilter: activePriceFilter
    }));
  }), React.createElement(S.Right, null, React.createElement(ProductListHeader, {
    onShowFilters: function onShowFilters() {
      return setShowFilters(true);
    },
    activeSortOption: activeSortOption,
    numberOfProducts: products.totalCount,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange,
    priceFilter: activePriceFilter,
    clearPriceFilter: clearPriceFilter
  }), canDisplayProducts && React.createElement(ProductList, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  }))))), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;