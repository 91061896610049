import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
import { Container } from "../Container";
/**
 * Template for empty cart page.
 */

var CartEmpty = function CartEmpty(_ref) {
  var button = _ref.button;
  return React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement(S.TitleFirstLine, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.2128974847",
    defaultMessage: "Votre panier"
  })), React.createElement(S.TitleSecondLine, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.1442601897",
    defaultMessage: "est vide"
  })), React.createElement(S.HR, null), React.createElement(S.Subtitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.2692798714",
    defaultMessage: "Explorez nos produits \xE0 travers les cat\xE9gories et les collections"
  })), React.createElement(S.ContinueButton, null, button)));
};

export { CartEmpty };