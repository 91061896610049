import React from "react";
import * as S from "./styles";
import { transformPhoneNumber } from "@temp/misc";
export var Address = function Address(_ref) {
  var firstName = _ref.firstName,
      lastName = _ref.lastName,
      companyName = _ref.companyName,
      streetAddress1 = _ref.streetAddress1,
      streetAddress2 = _ref.streetAddress2,
      city = _ref.city,
      postalCode = _ref.postalCode,
      countryArea = _ref.countryArea,
      phone = _ref.phone;
  return React.createElement(S.Wrapper, null, React.createElement(S.Name, null, "".concat(firstName, " ").concat(lastName)), React.createElement("p", null, streetAddress1, " ", streetAddress2 && React.createElement(React.Fragment, null, streetAddress2), React.createElement("br", null), city, " - ", " ", countryArea && React.createElement(React.Fragment, null, countryArea, " "), React.createElement("br", null), phone && React.createElement(React.Fragment, null, transformPhoneNumber(phone.substring(4)), " ", React.createElement("br", null))));
};