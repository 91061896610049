import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { authMessages, commonMessages } from "@temp/intl";
import { Button } from "@components/atoms";
import { Form, TextField } from "..";
import { TypedPasswordResetRequestMutation } from "./queries";
import { passwordResetUrl } from "../../app/routes";
import * as S from "./styles";
import { Notification } from "@components/molecules";
import SvgGreenTick from "images/SvgGreenTick";

var PasswordResetRequestForm = function PasswordResetRequestForm() {
  var intl = useIntl();

  var disableSubmit = function disableSubmit(loading, data) {
    return loading || (data === null || data === void 0 ? void 0 : data.requestPasswordReset.errors.length) === 0;
  };

  return React.createElement(S.Wrapper, null, React.createElement(TypedPasswordResetRequestMutation, null, function (passwordReset, _ref) {
    var loading = _ref.loading,
        data = _ref.data;

    if ((data === null || data === void 0 ? void 0 : data.requestPasswordReset.errors.length) === 0) {
      return React.createElement(React.Fragment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, authMessages.requestPasswordResetTitle)), React.createElement(Notification, {
        title: authMessages.requestPasswordChangeSuccess.title,
        expl: authMessages.requestPasswordChangeSuccess.defaultMessage,
        Svg: SvgGreenTick,
        color: "green"
      }), React.createElement(Link, {
        to: "/"
      }));
    }

    return React.createElement(React.Fragment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, authMessages.requestPasswordResetTitle)), React.createElement(S.Expl, null, React.createElement(FormattedMessage, authMessages.requestPasswordResetExpl)), React.createElement(S.Form, null, React.createElement(Form, {
      errors: (data === null || data === void 0 ? void 0 : data.requestPasswordReset.errors) || [],
      onSubmit: function onSubmit(event, _ref2) {
        var email = _ref2.email;
        event.preventDefault();
        passwordReset({
          variables: {
            email: email,
            redirectUrl: "".concat(window.location.origin).concat(passwordResetUrl)
          }
        });
      }
    }, React.createElement(TextField, {
      name: "email",
      autoComplete: "email",
      label: intl.formatMessage(commonMessages.eMail),
      type: "email",
      required: true
    }), React.createElement("div", null, React.createElement(Button, _extends({
      testingContext: "submit",
      type: "submit"
    }, disableSubmit(loading, data) && {
      disabled: true
    }), React.createElement(FormattedMessage, commonMessages.validate))))));
  }));
};

export default PasswordResetRequestForm;