import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  color: rgba(125, 125, 125, 0.8);\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  width: 120px;\n\n  > div {\n    margin-bottom: 0;\n    margin: 10px 0;\n  }\n\n  @media (min-width: ", ") {\n    width: 120px;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin: 0 0 0 0.5rem;\n\n  @media (min-width: ", ") {\n    margin: auto 1rem auto auto;\n  }\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  color: rgba(125, 125, 125, 0.8);\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  span:nth-of-type(2) {\n    color: #008400;\n    font-size: 1rem;\n    font-weight: 500;\n    @media (min-length: ", ") {\n      margin-right: 20px;\n    }\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.875rem;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  display: flex;\n  justify-content: flex-start;\n  white-space: nowrap;\n  background-color: white;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    flex-flow: column;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n\n  ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-align: left;\n  font-size: 0.8rem;\n\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n\n  @media (min-width: ", ") {\n    margin-bottom: 10px;\n    font-size: 0.875rem;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: description;\n  padding: 10px 0;\n  height: 100%;\n  margin-left: 0.5rem;\n\n  @media (min-length: ", ") {\n    margin-left: 1rem;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: trash;\n  width: fit-content;\n  margin-top: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start\n  cursor: pointer;\n  -webkit-tap-highlight-color: transparent;\n\n  @media (min-width: ", ") {\n    align-items: center;\n    margin-top: unset;\n  }\n\n  span {\n    font-size: 0.8rem;\n    transition: 0.3s;\n  }\n\n  &:hover {\n    > div {\n      background-color: initial;\n\n      path {\n        fill: initial;\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  grid-area: photo;\n  display: flex;\n  align-items: flex-start;\n  align-self: top;\n\n  img {\n    width: 80px;\n    object-fit: contain;\n    border-radius: 10px;\n    border: 1px solid ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border: 1px solid rgba(50, 50, 50, 0.1);\n  padding: 0.8rem 0.5rem;\n  border-radius: 10px;\n\n  display: grid;\n  grid-template-rows: 120px auto auto;\n  grid-template-columns: 80px auto 36px;\n  grid-template-areas:\n    \"photo description trash\"\n    \"photo quantity .\"\n    \"photo totalPrice . \";\n  grid-row-gap: 10px;\n\n  font-size: 0.875rem;\n\n  &:not(:first-of-type) {\n    margin-top: 2rem;\n  }\n\n  @media (min-width: ", ") {\n    grid-template-columns: 80px auto 195px;\n    grid-template-areas:\n      \"photo description quantity\"\n      \"photo . .\"\n      \"trash . totalPrice\";\n    grid-row-gap: unset;\n\n    padding: 1rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Photo = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.grayBorder;
});
export var Trash = styled.div(_templateObject3(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Description = styled.div(_templateObject4(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Name = styled.p(_templateObject5(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Attributes = styled.div(_templateObject6(), media.mediumScreen(_templateObject7()));
export var SingleAttribute = styled.p(_templateObject8());
export var UnitPrice = styled.div(_templateObject9());
export var Price = styled.div(_templateObject10());
export var TotalPrice = styled.div(_templateObject11(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var PriceLabel = styled.span(_templateObject12());
export var Quantity = styled.div(_templateObject13(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var QuantityContainer = styled.div(_templateObject14(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var QuantityButtons = styled.div(_templateObject15());
export var LightFont = styled.span(_templateObject16());