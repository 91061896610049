import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { Link, useHistory, useLocation, Redirect } from "react-router-dom";
import { LoginForm } from "@temp/components";
import { commonMessages } from "@temp/intl";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
import { Button } from "@components/atoms";
import { passwordForgottenPath, registerPath } from "@temp/app/routes";
import { FooterAuth, HeaderAuth } from "@components/organisms";
import { useAuth } from "@lastmaj/saleor-sdk";
export var Login = function Login(_ref) {
  _objectDestructuringEmpty(_ref);

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var history = useHistory();

  var useQuery = function useQuery() {
    return new URLSearchParams(useLocation().search);
  };

  var query = useQuery();

  if (user) {
    if (query.get("redirect")) history.push(query.get("redirect"));else return React.createElement(Redirect, {
      to: "/"
    });
  }

  return React.createElement(React.Fragment, null, React.createElement(HeaderAuth, null), React.createElement(S.Container, null, React.createElement(S.Form, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, commonMessages.login)), React.createElement(LoginForm, null), React.createElement(S.Password, null, React.createElement(Link, {
    to: passwordForgottenPath
  }, React.createElement(FormattedMessage, commonMessages.forgotPassword)))), React.createElement(S.SignUp, null, React.createElement(S.Title, null, "Nouvel utilisateur ?"), React.createElement(Link, {
    to: registerPath
  }, React.createElement(Button, null, React.createElement(FormattedMessage, commonMessages.register))))), React.createElement(FooterAuth, null));
};