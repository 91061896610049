import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { TileGrid } from "@components/atoms";
import { AddressTile } from "@components/molecules";

/**
 * Addresses tiles with add new address tile opening address form addition modal.
 */
export var AddressGrid = function AddressGrid(_ref) {
  var addresses = _ref.addresses;
  var addressTiles = addresses.map(function (address) {
    return React.createElement(AddressTile, _extends({
      key: address.id
    }, address));
  });
  return React.createElement(TileGrid, {
    columns: 2,
    elements: addressTiles
  });
};