import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { TypedRequestDetailsQuery } from "./queries";
import { Button } from "@components/atoms";
import HorizontalStepper from "@components/organisms/HorizontalStepper";
import { RequestDetailsPage } from "@pages";
var steps = ["Label 1", "Label 2", "Label 3"];

var HorizontalLinearStepper = function HorizontalLinearStepper() {
  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeStep = _React$useState2[0],
      setActiveStep = _React$useState2[1];

  var _React$useState3 = React.useState(0),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      progress = _React$useState4[0],
      setProgress = _React$useState4[1];

  var handleNext = function handleNext() {
    progress < 90 ? setProgress(function (prev) {
      return prev + 10;
    }) : nextStep();
  };

  var nextStep = function nextStep() {
    setProgress(0);
    setActiveStep(function (prev) {
      return prev + 1;
    });
  };

  return React.createElement("div", null, React.createElement(HorizontalStepper, {
    steps: steps,
    current: activeStep,
    progress: progress
  }), React.createElement("div", null, React.createElement(Button, {
    color: "primary",
    onClick: handleNext
  }, activeStep === steps.length - 1 ? "Finish" : "Next")));
};

var RequestDetails = function RequestDetails(_ref) {
  var id = _ref.id;
  return React.createElement(TypedRequestDetailsQuery, {
    variables: {
      id: id
    }
  }, function (_ref2) {
    var data = _ref2.data,
        loading = _ref2.loading;
    if (loading) return React.createElement("p", null, "loading ...");
    return React.createElement(React.Fragment, null, React.createElement(RequestDetailsPage, {
      request: data.request
    }));
  });
};

export default RequestDetails;