import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgRate = function SvgRate(props) {
  return React.createElement("svg", _extends({
    "data-name": "Groupe 543",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 39.342 33.799"
  }, props), React.createElement("path", {
    "data-name": "Trac\\xE9 213",
    d: "M25.271 22.463a2.816 2.816 0 0 0 2.808-2.808V2.808A2.816 2.816 0 0 0 25.271 0H2.808A2.816 2.816 0 0 0 0 2.808v25.271l5.616-5.616ZM1.4 24.69V2.808a1.405 1.405 0 0 1 1.4-1.4h22.471a1.405 1.405 0 0 1 1.4 1.4v16.847a1.405 1.405 0 0 1-1.4 1.4H5.035Z",
    fill: "#231f20"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 215",
    d: "m29.448 15.668-3.96-8.438-3.96 8.438-8.857 1.353 6.408 6.568-1.508 9.274 7.921-4.379 7.921 4.379-1.513-9.274 6.408-6.568Z",
    fill: "#fff",
    stroke: "#fff"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 214",
    d: "m27.731 17.567-2.244-4.777-2.242 4.777-5.014.767 3.628 3.719-.857 5.251 4.485-2.479 4.485 2.479-.857-5.251 3.628-3.719Z",
    fill: "#fff",
    stroke: "#231f20"
  }));
};

export default SvgRate;