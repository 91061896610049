export * from "./SvgCart";
export * from "./SvgWonder";
export * from "./SvgSearch";
export * from "./SvgTick";
export * from "./SvgChevron";
export * from "./SvgCustomerService";
export * from "./SvgSecuredPayment";
export * from "./SvgDelivery";
export * from "./SvgEasyReturn";
export * from "./SvgSearch";
export * from "./SvgUser";