import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "../Modal";
import * as S from "./styles";
export var AddressDeleteModal = function AddressDeleteModal(_ref) {
  var hideModal = _ref.hideModal,
      title = _ref.title,
      _onSubmit = _ref.onSubmit,
      addressId = _ref.addressId;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      show = _React$useState2[0],
      setShow = _React$useState2[1];

  return React.createElement(Modal, {
    hide: function hide() {
      document.body.style.overflow = "";
      hideModal();
      setShow(false);
    },
    show: show,
    submitBtnText: "Supprimer",
    testingContext: "delete-address-confirm-testing-context",
    title: title,
    onSubmit: function onSubmit() {
      return _onSubmit(addressId);
    }
  }, React.createElement(S.Content, null, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.AddressDeleteModal.AddressDeleteModal.2201036541",
    defaultMessage: "Vous ne pourrez plus utiliser cette adresse pour vos prochaines commandes!"
  }))));
};
AddressDeleteModal.displayName = "AddressDeleteModal";
export default AddressDeleteModal;