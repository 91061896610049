import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { RelatedProducts } from "@components/organisms";
export var HomepageNewArrivals = function HomepageNewArrivals(_ref) {
  var products = _ref.products,
      title = _ref.title;
  return React.createElement(RelatedProducts, _extends({
    products: products,
    title: title
  }, {
    withLogoDots: true
  }));
};
HomepageNewArrivals.displayName = "HomepageNewArrivals";
export default HomepageNewArrivals;