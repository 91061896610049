import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgCopy = function SvgCopy(props) {
  return React.createElement("svg", _extends({
    viewBox: " 0 0 18 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), React.createElement("path", {
    d: "M8.438 5.331 6.694 7.078c-.642.643-1.288 1.286-1.927 1.937a1.2 1.2 0 0 0-.308.562 492.274 492.274 0 0 0-.68 3.188l-.124.588a.839.839 0 0 0 .213.768c.15.151.36.236.58.236.062 0 .125-.008.187-.019l.646-.136c1.028-.217 2.06-.437 3.087-.65.239-.052.44-.159.613-.331 2.886-2.894 5.771-5.783 8.656-8.669.213-.213.33-.467.36-.78a.516.516 0 0 0-.01-.15c-.02-.074-.034-.15-.052-.224-.04-.188-.085-.401-.173-.607-.54-1.235-1.435-2.117-2.661-2.614-.246-.099-.507-.132-.734-.162l-.063-.007c-.33-.04-.631.07-.892.335A3186.54 3186.54 0 0 1 8.438 5.33ZM14.183.894h.018l.063.007c.19.023.367.045.507.103.998.405 1.732 1.125 2.176 2.14.052.118.085.268.118.426.01.06.025.118.037.177a.28.28 0 0 1-.092.158c-2.89 2.886-5.775 5.78-8.66 8.669a.29.29 0 0 1-.158.084l-3.09.65-.544.115.103-.482c.224-1.059.448-2.121.675-3.18a.32.32 0 0 1 .07-.121c.639-.647 1.277-1.29 1.92-1.93L9.07 5.964c1.662-1.666 3.325-3.331 4.985-5 .07-.059.102-.07.128-.07Z",
    fill: "#323232"
  }), React.createElement("path", {
    d: "M1.428 4.008h6.41a.452.452 0 0 0 0-.904h-6.41A1.43 1.43 0 0 0 0 4.534V16.57C0 17.36.642 18 1.428 18h12.015a1.43 1.43 0 0 0 1.428-1.43v-6.158a.451.451 0 1 0-.903 0v6.158c0 .29-.239.53-.529.53H1.428a.532.532 0 0 1-.529-.53V4.538c0-.29.239-.53.529-.53Z",
    fill: "#323232"
  }));
};

export default SvgCopy;