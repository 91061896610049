import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin: 0.5rem 0;\n  align-items: center;\n\n  width: 50%;\n\n  // for extremely small screens (less than 320px)\n  @media (max-width: 320px) {\n    width: 100%;\n    justify-content: space-evenly;\n  }\n\n  @media (min-width: ", ") {\n    justify-content: center;\n  }\n\n  @media (min-width: ", ") {\n    width: 40%;\n  }\n\n  @media (min-width: ", ") {\n    width: fit-content;\n    flex-direction: row;\n  }\n\n  &.reversed {\n    flex-direction: row-reverse;\n\n    // for extremely small screens (less than 320px)\n    @media (max-width: 320px) {\n      flex-direction: row;\n    }\n\n    @media (min-width: ", ") {\n      flex-direction: row;\n    }\n  }\n\n  svg {\n    height: auto;\n    width: 2rem;\n\n    @media (min-width: ", ") {\n      width: 3rem;\n    }\n  }\n\n  .text {\n    font-size: 0.75rem;\n    margin: 0 0.25rem;\n\n    // for extremely small screens (less than 320px)\n    @media (max-width: 320px) {\n      width: 40%;\n    }\n\n    @media (min-width: ", ") {\n      margin: 0 0.5rem;\n    }\n\n    .highlight {\n      display: block;\n      font-weight: 500;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  margin: 8rem 0 1rem;\n\n  flex-direction: row;\n  flex-wrap: wrap;\n\n  @media (min-width: ", ") {\n    justify-content: space-around;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Item = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.xSmallScreen;
}, function (props) {
  return props.theme.breakpoints.smallScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});