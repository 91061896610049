import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import Media from "react-responsive";
import { FormattedMessage } from "react-intl";
import { ErrorMessage } from "@components/atoms";
import { AddressSummary, Notification } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";
import { Link as _Link } from "react-router-dom";
import { largeScreen } from "@styles/constants";
import { generatePageUrl } from "@utils/core";
/**
 * Review order view showed in checkout.
 */

var CheckoutReview = function CheckoutReview(_ref) {
  var shippingAddress = _ref.shippingAddress,
      billingAddress = _ref.billingAddress,
      shippingMethodName = _ref.shippingMethodName,
      paymentMethodName = _ref.paymentMethodName,
      email = _ref.email,
      errors = _ref.errors,
      accept = _ref.accept,
      setAccept = _ref.setAccept;
  return React.createElement(S.Wrapper, {
    "data-test": "sectionTitle"
  }, React.createElement(Media, {
    minWidth: largeScreen
  }, React.createElement(S.LargeDivider, null)), React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.reviewOrder)), React.createElement(S.Notification, null, React.createElement(Notification, {
    color: "gray",
    title: checkoutMessages.reviewNotifTitle.defaultMessage,
    expl: checkoutMessages.reviewNotifExpl.defaultMessage
  })), React.createElement(S.Grid, null, React.createElement("section", {
    "data-test": "shippingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: shippingAddress,
    email: email
  })), React.createElement("section", {
    "data-test": "billingAddressSection"
  }, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.billingAddress)), React.createElement(S.Divider, null), React.createElement(AddressSummary, {
    address: billingAddress,
    email: email
  })), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingMethod)), React.createElement(S.Divider, null), React.createElement(S.TextSummary, {
    "data-test": "shippingMethodName"
  }, shippingMethodName)), React.createElement("section", null, React.createElement(S.SubTitle, null, React.createElement(FormattedMessage, checkoutMessages.paymentMethod)), React.createElement(S.Divider, null), React.createElement(S.TextSummary, {
    "data-test": "paymentMethodName"
  }, paymentMethodName))), React.createElement(S.Conditions, null, React.createElement("p", null, React.createElement(FormattedMessage, _extends({}, checkoutMessages.conditions, {
    values: {
      Link: function Link(chunks) {
        return React.createElement(_Link, {
          target: "_blank",
          to: generatePageUrl("conditions-generales-de-vente")
        }, chunks);
      }
    }
  })))), errors && errors.length !== 0 && React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  })));
};

export { CheckoutReview };