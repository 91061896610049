import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  height: 1.5rem;\n  width: 1.5rem;\n\n  > svg {\n    height: 100%;\n    width: auto;\n  }\n\n  > span {\n    position: absolute;\n    top: 0;\n    right: -5px;\n    background-color: #b36985cc;\n    border-radius: 1rem;\n    padding: 0 4px;\n    min-width: 1rem;\n    height: 1rem;\n    color: white;\n    font-size: 0.75rem;\n    text-align: center;\n    line-height: 1rem;\n    transition: 0.3s;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  -webkit-tap-highlight-color: transparent;\n\n  border-left: 1px solid #e5e5e5;\n\n  padding: 0.5rem;\n  font-size: 0.75rem;\n  position: relative;\n\n  p {\n    line-height: normal;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border-top: 1px solid #e5e5e5;\n  height: 3.5rem;\n  width: 100%;\n  z-index: 250;\n  background-color: white;\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  justify-content: space-around;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { Link } from "react-router-dom";
export var Wrapper = styled.div(_templateObject());
export var Item = styled(Link)(_templateObject2());
export var SvgContainer = styled.div(_templateObject3());