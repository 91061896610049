import _objectSpread from "@babel/runtime/helpers/objectSpread";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { translateOrderStatus } from "@temp/intl";
import { NotFound } from "../../../components";
import { orderHistoryUrl } from "../../../app/routes";
import { maybe } from "@utils/misc";
import OrderUnfulfilledItems from "@temp/components/OrderUnfulfilledItems";
import OrderFulfillments from "@temp/components/OrderFulfillments/OrderFulfillments";

var extractOrderLines = function extractOrderLines(lines) {
  return lines.map(function (line) {
    return _objectSpread({
      quantity: line.quantity,
      totalPrice: line.totalPrice
    }, line.variant, {
      name: line.productName
    });
  }).sort(function (a, b) {
    return b.id.toLowerCase().localeCompare(a.id.toLowerCase());
  });
};

var Page = function Page(_ref) {
  var guest = _ref.guest,
      order = _ref.order,
      downloadInvoice = _ref.downloadInvoice;
  var intl = useIntl();
  var unfulfilled = maybe(function () {
    return order.lines;
  }, []).filter(function (line) {
    return line.quantityFulfilled < line.quantity;
  });
  return order ? React.createElement("div", null, !guest && React.createElement(Link, {
    className: "order-details__link",
    to: orderHistoryUrl
  }, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.Page.624688748",
    defaultMessage: "Go back to Order History"
  })), React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "userAccount.views.OrderDetails.Page.592732447",
    defaultMessage: "Your order no: {orderNum}",
    values: {
      orderNum: order.number
    }
  })), React.createElement("p", {
    className: "order-details__status"
  }, translateOrderStatus(order.statusDisplay, intl)), unfulfilled.length > 0 && React.createElement(OrderUnfulfilledItems, {
    lines: unfulfilled
  }), order.fulfillments.length > 0 && React.createElement(OrderFulfillments, {
    fulfillments: order.fulfillments
  })) : // <>
  //   {!guest && (
  //     <Link className="order-details__link" to={orderHistoryUrl}>
  //       <FormattedMessage defaultMessage="Go back to Order History" />
  //     </Link>
  //   )}
  //   <div className="order-details__header">
  //     <div>
  //       <h3>
  //         <FormattedMessage
  //           defaultMessage="Your order no.: {orderNum}"
  //           values={{ orderNum: order.id }}
  //         />
  //       </h3>
  //       <p className="order-details__status">
  //         {translatePaymentStatus(order.paymentStatusDisplay, intl)} /{" "}
  //         {translateOrderStatus(order.statusDisplay, intl)}
  //       </p>
  //     </div>
  //     {"invoices" in order && order.invoices?.length > 0 && (
  //       <div className="order-details__header-menu">
  //         <DropdownMenu
  //           type="clickable"
  //           header={
  //             <IconButton
  //               testingContext="expandButton"
  //               name="expand"
  //               size={28}
  //             />
  //           }
  //           items={[
  //             {
  //               onClick: downloadInvoice,
  //               content: (
  //                 <span>
  //                   <FormattedMessage
  //                     defaultMessage="Download invoice"
  //                     description="action in popup menu in order view"
  //                   />
  //                 </span>
  //               ),
  //             },
  //           ]}
  //         />
  //       </div>
  //     )}
  //   </div>
  //   <CartTable
  //     lines={extractOrderLines(order.lines)}
  //     totalCost={<TaxedMoney taxedMoney={order.total} />}
  //     deliveryCost={<TaxedMoney taxedMoney={order.shippingPrice} />}
  //     subtotal={<TaxedMoney taxedMoney={order.subtotal} />}
  //   />
  //   <div className="order-details__summary">
  //     <div>
  //       <h4>
  //         <FormattedMessage {...checkoutMessages.shippingAddress} />
  //       </h4>
  //       <AddressSummary
  //         address={order.shippingAddress}
  //         email={order.userEmail}
  //         paragraphRef={this.shippingAddressRef}
  //       />
  //     </div>
  //   </div>
  // </>
  React.createElement(NotFound, null);
};

export default Page;