import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react"; // import "photoswipe/dist/photoswipe.css";
// import "photoswipe/dist/default-skin/default-skin.css";
// import { Gallery, Item } from "react-photoswipe-gallery";

import { Modal } from "./Modal";
export var ProductImagesModal = function ProductImagesModal(_ref) {
  var hideModal = _ref.hideModal,
      images = _ref.images;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      show = _React$useState2[0],
      setShow = _React$useState2[1];

  return React.createElement(Modal, {
    show: show,
    testingContext: "productImagesModal",
    disabled: false,
    hide: function hide() {
      document.body.style.overflow = "";
      hideModal();
      setShow(false);
    }
  });
};
ProductImagesModal.displayName = "ProductImagesModal";
export default ProductImagesModal;