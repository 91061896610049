import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { sanitize } from "dompurify";
import draftToHtml from "draftjs-to-html";
import * as S from "./styles";
import { formatDeliveryDate } from "@temp/misc";
import { useIntl } from "react-intl";
export var ProductBoxes = function ProductBoxes(_ref) {
  var highlight = _ref.highlight;
  var parsed = JSON.parse(highlight);

  var penultimateLine = _objectSpread({}, parsed, {
    blocks: [parsed.blocks[parsed.blocks.length - 2]] || "Produit 100% tunisien"
  });

  var lastLine = _objectSpread({}, parsed, {
    blocks: [parsed.blocks[parsed.blocks.length - 1]] || "Lisez la description pour les conseils d'entretien"
  });

  var intl = useIntl();
  var today = new Date();
  var firstDate = new Date(today.getTime() + 2 * 86400000);
  var lastDate = new Date(today.getTime() + 5 * 86400000);
  return React.createElement(S.Wrapper, null, React.createElement(S.Box, null, React.createElement("p", null, "Estimation de livraison :"), React.createElement("p", null, formatDeliveryDate(firstDate, intl), " -", " ", formatDeliveryDate(lastDate, intl))), React.createElement(S.Box, null, React.createElement("div", {
    dangerouslySetInnerHTML: {
      __html: sanitize(draftToHtml(penultimateLine))
    }
  })), React.createElement(S.Box, null, React.createElement("div", {
    dangerouslySetInnerHTML: {
      __html: sanitize(draftToHtml(lastLine))
    }
  })));
};
ProductBoxes.displayName = "ProductBoxes";
export default ProductBoxes;