import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect, useRef } from "react";
import { CollectionsBannerCarousel } from "@components/molecules";
import * as S from "./styles";
import { Link } from "react-router-dom";
import { getCollectionPath } from "@temp/app/routes";
import { TIMEOUT_HOMEPAGE_BANNER } from "@temp/core/config";
export var CollectionsBannerWithCarousel = function CollectionsBannerWithCarousel(_ref) {
  var collections = _ref.collections;

  var _useState = useState(collections[0].id),
      _useState2 = _slicedToArray(_useState, 2),
      activeCollection = _useState2[0],
      setActiveCollection = _useState2[1];

  var _useState3 = useState(true),
      _useState4 = _slicedToArray(_useState3, 2),
      isPlaying = _useState4[0],
      setIsPlaying = _useState4[1];

  var onCollectionClick = function onCollectionClick(c) {
    if (c.id === activeCollection) return;
    setActiveCollection(c.id);
  };

  var timeoutRef = useRef(null);
  useEffect(function () {
    if (isPlaying) {
      //@ts-expect-error
      timeoutRef.current = setTimeout(function () {
        var i = collections.findIndex(function (c) {
          return c.id === activeCollection;
        });
        var next = collections[(i + 1) % collections.length];
        setActiveCollection(next.id);
      }, TIMEOUT_HOMEPAGE_BANNER);
    } //@ts-expect-error


    return function () {
      return clearTimeout(timeoutRef.current);
    };
  }, [activeCollection, isPlaying]);
  return React.createElement("div", {
    className: "container"
  }, collections ? React.createElement(S.Wrapper, null, React.createElement(S.Background, {
    onMouseEnter: function onMouseEnter() {
      return setIsPlaying(false);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsPlaying(true);
    }
  }, collections.map(function (collection) {
    var _collection$backgroun;

    return React.createElement(Link, {
      to: getCollectionPath(collection.slug)
    }, React.createElement(S.Image, {
      active: collection.id === activeCollection,
      url: (_collection$backgroun = collection.backgroundImage) === null || _collection$backgroun === void 0 ? void 0 : _collection$backgroun.url
    }));
  })), React.createElement(CollectionsBannerCarousel, {
    collections: collections,
    onCollectionClick: onCollectionClick,
    activeCollection: activeCollection
  })) : React.createElement(React.Fragment, null));
};
CollectionsBannerWithCarousel.displayName = "CollectionsBannerWithCarousel";
export default CollectionsBannerWithCarousel;