import React from "react";
import * as S from "./styles";
import { Button } from "@components/atoms";
import { FormattedMessage, useIntl } from "react-intl";
import { FulfillmentStatus } from "gqlTypes/globalTypes";
import { formatDateTime } from "@temp/misc";
import { Link } from "react-router-dom";
import { accountMessages, commonMessages } from "@temp/intl";
import { requestCreateUrl } from "@temp/app/routes";
import { OverlayContext, OverlayTheme, OverlayType } from "@temp/components/Overlay";
import SvgAddToCart from "images/SvgAddToCart";
import SvgRate from "images/SvgRate";
import SvgTruckCircle from "images/SvgTruckCircle";
import SvgRequestCreate from "images/SvgRequestCreate";
import SvgReturn from "images/SvgReturn";
export var OrderFulfillment = function OrderFulfillment(_ref) {
  var _fulfillment$lines, _fulfillment$delivery, _fulfillment$delivery2;

  var fulfillment = _ref.fulfillment;
  var intl = useIntl();
  var requestCreateLink = requestCreateUrl(fulfillment.id);
  var overlayContext = React.useContext(OverlayContext);
  return React.createElement(S.Wrapper, null, React.createElement(S.Lines, null, (_fulfillment$lines = fulfillment.lines) === null || _fulfillment$lines === void 0 ? void 0 : _fulfillment$lines.map(function (line) {
    var _line$orderLine, _line$orderLine2, _line$orderLine2$vari, _line$orderLine3, _line$orderLine4, _line$orderLine5;

    return React.createElement(S.Line, {
      key: line === null || line === void 0 ? void 0 : line.id
    }, React.createElement(S.LineThumbnail, {
      source: line === null || line === void 0 ? void 0 : line.orderLine
    }), React.createElement(S.LineContent, null, React.createElement("p", null, line === null || line === void 0 ? void 0 : (_line$orderLine = line.orderLine) === null || _line$orderLine === void 0 ? void 0 : _line$orderLine.productName, " -", " ", line === null || line === void 0 ? void 0 : (_line$orderLine2 = line.orderLine) === null || _line$orderLine2 === void 0 ? void 0 : (_line$orderLine2$vari = _line$orderLine2.variant) === null || _line$orderLine2$vari === void 0 ? void 0 : _line$orderLine2$vari.name), (line === null || line === void 0 ? void 0 : (_line$orderLine3 = line.orderLine) === null || _line$orderLine3 === void 0 ? void 0 : _line$orderLine3.quantity) > 1 ? React.createElement("p", {
      className: "quantity"
    }, React.createElement("span", null, "Quantit\xE9 : "), " ", line === null || line === void 0 ? void 0 : (_line$orderLine4 = line.orderLine) === null || _line$orderLine4 === void 0 ? void 0 : _line$orderLine4.quantity) : null, React.createElement(S.LinePrice, {
      taxedMoney: line === null || line === void 0 ? void 0 : (_line$orderLine5 = line.orderLine) === null || _line$orderLine5 === void 0 ? void 0 : _line$orderLine5.totalPrice
    }), fulfillment.status === FulfillmentStatus.DELIVERED && React.createElement(React.Fragment, null, React.createElement(Button, {
      testingContext: "past-orders-reorder",
      size: "sm",
      color: "outline"
    }, React.createElement(SvgAddToCart, null), React.createElement(FormattedMessage, commonMessages.reorder)), React.createElement("button", {
      onClick: function onClick() {
        var _line$orderLine6;

        return overlayContext.show(OverlayType.review, OverlayTheme.right, {
          data: line === null || line === void 0 ? void 0 : (_line$orderLine6 = line.orderLine) === null || _line$orderLine6 === void 0 ? void 0 : _line$orderLine6.id
        });
      }
    }, React.createElement(SvgRate, null), React.createElement(FormattedMessage, commonMessages.rate)))));
  })), React.createElement(S.Status, {
    status: fulfillment.status
  }, fulfillment.status === FulfillmentStatus.DELIVERED ? React.createElement(React.Fragment, null, React.createElement(S.StatusInfo, null, React.createElement(SvgTruckCircle, null), React.createElement("div", {
    className: "text"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderFulfillment.OrderFulfillment.3359740211",
    defaultMessage: "Livr\xE9e \xE0 : "
  }), React.createElement("span", null, (_fulfillment$delivery = fulfillment.delivery) === null || _fulfillment$delivery === void 0 ? void 0 : _fulfillment$delivery.to)), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderFulfillment.OrderFulfillment.3975002734",
    defaultMessage: "Date : "
  }), React.createElement("span", null, formatDateTime(new Date(fulfillment === null || fulfillment === void 0 ? void 0 : (_fulfillment$delivery2 = fulfillment.delivery) === null || _fulfillment$delivery2 === void 0 ? void 0 : _fulfillment$delivery2.at), intl))))), React.createElement(S.StatusActions, null, React.createElement(Link, {
    to: requestCreateLink
  }, React.createElement("button", null, React.createElement(SvgRequestCreate, null), React.createElement(FormattedMessage, commonMessages.createRequest))), React.createElement(Link, {
    to: requestCreateLink
  }, React.createElement("button", null, React.createElement(SvgReturn, null), React.createElement(FormattedMessage, commonMessages.createReturn))))) : fulfillment.status === FulfillmentStatus.FULFILLED ? React.createElement("p", null, React.createElement(FormattedMessage, accountMessages.fulfilled)) : React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderFulfillment.OrderFulfillment.1256928629",
    defaultMessage: "A fulfillment has been cancelled. Do we really wanna show this to the user ?"
  }))));
};
OrderFulfillment.displayName = "OrderFulfillment";
export default OrderFulfillment;