import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgMyOrders = function SvgMyOrders(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 30 28.079"
  }, props), React.createElement("path", {
    "data-name": "Trac\\xE9 201",
    d: "M14.04 14.742a4.92 4.92 0 0 0 4.914-4.914v-3.51h-1.4v3.51a3.51 3.51 0 0 1-7.02 0v-3.51H9.126v3.51a4.92 4.92 0 0 0 4.914 4.914Z",
    fill: "#231f20"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 202",
    d: "M0 25.271a2.816 2.816 0 0 0 2.808 2.808h22.463a2.816 2.816 0 0 0 2.808-2.808V0H0ZM1.4 1.4h25.275v23.871a1.405 1.405 0 0 1-1.4 1.4H2.808a1.405 1.405 0 0 1-1.4-1.4Z",
    fill: "#231f20"
  }));
};

export default SvgMyOrders;