import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query FeaturedProducts {\n    shop {\n      homepageCollection {\n        id\n        products(first: 20) {\n          edges {\n            node {\n              id\n              slug\n              name\n              rating\n              ratingsCount\n              thumbnail(size: 500) {\n                alt\n                url\n              }\n              ...ProductPricingField\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productPricingFragment } from "../../views/Product/queries";
export var featuredProducts = gql(_templateObject(), productPricingFragment);
export var TypedFeaturedProductsQuery = TypedQuery(featuredProducts);