import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  width: 90%;\n\n  margin: auto;\n  margin-top: 10px;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  width: 90%;\n  margin: auto;\n  margin-top: 10px;\n\n  a {\n    width: 40%;\n  }\n\n  button span {\n    font-size: 0.75rem;\n    font-weight: 500;\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  span {\n    font-size: 0.875rem;\n    font-weight: 600;\n\n    color: black;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 5px;\n  padding: 10px 2rem;\n  padding-bottom: 0;\n  font-size: 14px;\n  position: relative;\n\n  box-shadow: white 0px -7px 12px 3px;\n  z-index: 2;\n\n  &::before {\n    border-top: 1px solid #cfcfcf;\n    content: \"\";\n    position: absolute;\n    left: 0;\n    right: 0;\n    width: calc(100% - 10px);\n    top: 0;\n    margin: 0 auto;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  margin-left: 10px;\n  margin-top: 5px;\n\n  font-size: 0.875rem;\n  font-weight: 600;\n\n  color: black;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  font-weight: 500;\n  width: 150%;\n  text-decoration: underline;\n\n  @media (min-width: ", ") {\n    width: unset;\n    text-decoration: unset;\n  }\n\n  &:hover {\n    text-decoration: underline;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  p {\n    line-height: normal;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  padding-bottom: 0;\n  font-weight: 600;\n  line-height: normal;\n\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-left: 10px;\n  justify-content: space-around;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  position: relative;  \n  height: 120px;\n  display: flex;\n  padding: 5px\n\n  font-size: 0.75rem;\n\n  > a {\n    img {\n      border-radius: 7px;\n      width: 60px;\n      height: 90px;\n    }\n  }\n\n  &:not(:last-of-type) {\n    &::after {\n      border-bottom: 1px solid #cfcfcf;\n      content: \"\";\n      position: absolute;\n      left: 0;\n      right: 0;\n      width: calc(100% - 10px);\n      bottom: 0;\n      margin: 0 auto;\n    }\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  max-height: 63vh;\n  overflow-y: scroll;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  z-index: 1;\n\n  &::-webkit-scrollbar {\n    width: 0;\n    height: 0;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 1 rem;\n  text-align: center;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-left: auto;\n  margin-right: 1rem;\n  div {\n    display: flex;\n  }\n\n  svg * {\n    fill: #51e9d2;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin-right: 1rem;\n\n  > div {\n    display: flex;\n  }\n\n  svg * {\n    fill: #51e9d2;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  padding-bottom: 4px;\n  position: relative;\n\n  &::after {\n    border-bottom: 1px solid #f1f1f1;\n    content: \"\";\n    position: absolute;\n    left: 0;\n    right: 0;\n    width: calc(100% - 100px);\n    bottom: 0;\n    margin: 0 auto;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: ", ";\n  position: ", ";\n  width: ", ";\n  padding: ", ";\n  border-radius: 10px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import ReactSVG from "react-svg";
export var Cart = styled.div(_templateObject(), function (props) {
  return props.overlay ? "100%" : "auto";
}, function (props) {
  return props.overlay ? "relative" : "initial";
}, function (props) {
  return props.overlay ? "100%" : "380px";
}, function (props) {
  return props.overlay ? "0.5rem" : "1rem 1rem 8px";
});
export var Header = styled.div(_templateObject2());
export var CartIcon = styled(ReactSVG)(_templateObject3());
export var CloseIcon = styled(ReactSVG)(_templateObject4());
export var HeaderText = styled.div(_templateObject5());
export var RowList = styled.div(_templateObject6());
export var Row = styled.div(_templateObject7());
export var Middle = styled.div(_templateObject8());
export var Name = styled.p(_templateObject9());
export var Details = styled.div(_templateObject10());
export var Delete = styled.p(_templateObject11(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Price = styled.div(_templateObject12());
export var Footer = styled.div(_templateObject13());
export var FooterPrice = styled.div(_templateObject14());
export var FooterPriceRow = styled.div(_templateObject15());
export var Buttons = styled.div(_templateObject16());
export var ButtonContinue = styled.div(_templateObject17());