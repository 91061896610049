import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgOrders = function SvgOrders(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48"
  }, props), React.createElement("defs", null, React.createElement("style", null, ".orders_svg__cls-2{fill:#231f20}")), React.createElement("g", {
    id: "orders_svg__Layer_2",
    "data-name": "Layer 2"
  }, React.createElement("g", {
    id: "orders_svg__Layer_1-2",
    "data-name": "Layer 1"
  }, React.createElement("path", {
    style: {
      fill: "none"
    },
    d: "M0 0h48v48H0z"
  }), React.createElement("g", {
    id: "orders_svg__Shopicon"
  }, React.createElement("path", {
    id: "orders_svg__Path_201",
    "data-name": "Path 201",
    className: "orders_svg__cls-2",
    d: "M24 24.7a4.91 4.91 0 0 0 4.91-4.91v-3.51h-1.4v3.51a3.51 3.51 0 0 1-7 0v-3.51h-1.4v3.51A4.91 4.91 0 0 0 24 24.7Z"
  }), React.createElement("path", {
    id: "orders_svg__Path_202",
    "data-name": "Path 202",
    className: "orders_svg__cls-2",
    d: "M10 35.23A2.81 2.81 0 0 0 12.77 38h22.46A2.81 2.81 0 0 0 38 35.23V10H10Zm1.4-23.87h25.24v23.87a1.41 1.41 0 0 1-1.4 1.4H12.77a1.4 1.4 0 0 1-1.4-1.4Z"
  })))));
};

export default SvgOrders;