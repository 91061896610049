import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "../Modal";
import * as S from "./styles";
export var OrderCancelModal = function OrderCancelModal(_ref) {
  var hideModal = _ref.hideModal,
      onSubmit = _ref.onSubmit;

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      show = _React$useState2[0],
      setShow = _React$useState2[1];

  return React.createElement(Modal, {
    hide: function hide() {
      document.body.style.overflow = "";
      hideModal();
      setShow(false);
    },
    show: show,
    submitBtnText: "Annuler la commande",
    testingContext: "cancel-order-testing-context",
    title: "\xCAtes-vous sur ? ",
    onSubmit: onSubmit
  }, React.createElement(S.Content, null, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.OrderCancelModal.OrderCancelModal.1361569836",
    defaultMessage: "Cette action est irr\xE9versible."
  }))));
};
OrderCancelModal.displayName = "OrderCancelModal";
export default OrderCancelModal;