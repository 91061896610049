import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  margin-bottom: auto;\n  width: 5rem;\n\n  @media (min-width: ", ") {\n    width: 12rem;\n  }\n  @media (min:width ", "){\n    margin-bottom: unset;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  height: 13rem;\n  justify-content: space-between;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    height: auto;\n\n    > div:not(:last-child) {\n      margin-right: 1rem;\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.75rem;\n  color: gray;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Bar = styled.div(_templateObject());
export var Wrapper = styled.div(_templateObject2());
export var Title = styled.h6(_templateObject3());
export var Text = styled.p(_templateObject4());
export var Stars = styled.div(_templateObject5(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Sort = styled.div(_templateObject6(), function (props) {
  return props.theme.breakpoints.smallScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});