import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useCheckout } from "@lastmaj/saleor-sdk";
import { StringParam, useQueryParam } from "use-query-params";
import { useLocalStorage } from "@hooks";
import { useHistory } from "react-router";
import { ClickToPayFail } from "@components/organisms";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { Loader } from "@components/atoms";

var ClickToPayFailView = function ClickToPayFailView(props) {
  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout,
      completeCheckout = _useCheckout.completeCheckout;

  var _useQueryParam = useQueryParam("orderId", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 1),
      orderId = _useQueryParam2[0];

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var paymentData = useLocalStorage("payment").storedValue;
  var history = useHistory();
  useEffect(function () {
    setLoading(true);
    paymentData.orderId = orderId;

    if (checkout && checkout.id) {
      completeCheckout({
        paymentData: paymentData
      }).then(function (_) {
        setLoading(false);
        setTimeout(function () {
          return history.replace(CHECKOUT_STEPS[3].link);
        }, 6000);
      });
    }
  }, [checkout]);
  if (loading) return React.createElement(Loader, null);
  return React.createElement(ClickToPayFail, null);
};

export { ClickToPayFailView };