import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  color: red;\n  font-weight: 500;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  padding: 2px 5px;\n  font-size: 1.25rem;\n  cursor: ", "\n  color: ", ";\n\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding: 2px;\n  align-items: center;\n  margin: auto;\n  display: flex;\n\n  > * {\n    user-select: none;\n    -moz-user-select: none;\n    -khtml-user-select: none;\n    -webkit-user-select: none;\n    -o-user-select: none;\n  }\n\n  > span {\n    border-right: 1px solid black;\n    border-left: 1px solid black;\n    text-align: center;\n    width: 2rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  border-radius: 5px;\n  width: fit-content;\n  font-size: 1rem;\n  border: 1px solid black;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Text = styled.p(_templateObject());
export var Wrapper = styled.div(_templateObject2());
export var Container = styled.div(_templateObject3());
export var Controller = styled.div(_templateObject4(), function (props) {
  return props.disabled ? "not-allowed" : "pointer";
}, function (props) {
  return props.disabled ? "gray" : "black";
});
export var Error = styled.span(_templateObject5());