import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { Loader } from "@components/atoms";
import * as S from "./styles";
import PastOrderRow from "@components/molecules/PastOrderRow";
import { useOrdersByUser } from "@lastmaj/saleor-sdk";
var ORDERS_BY_PAGE = 5;
export var OrdersHistory = function OrdersHistory(props) {
  var _useOrdersByUser = useOrdersByUser({
    perPage: ORDERS_BY_PAGE
  }, {
    fetchPolicy: "network-only"
  }),
      data = _useOrdersByUser.data,
      loading = _useOrdersByUser.loading,
      loadMore = _useOrdersByUser.loadMore;

  return loading && !data ? React.createElement(Loader, null) : React.createElement(S.Wrapper, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, commonMessages.myOrders)), data === null || data === void 0 ? void 0 : data.edges.map(function (edge) {
    return React.createElement(PastOrderRow, {
      order: edge.node,
      key: edge.node.id
    });
  }), (data === null || data === void 0 ? void 0 : data.pageInfo.hasNextPage) && React.createElement(S.LoadMore, {
    testingContext: "loadMoreOrdersButton",
    onClick: function onClick() {
      loadMore({
        after: data.pageInfo.endCursor,
        perPage: ORDERS_BY_PAGE
      });
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.pages.OrdersHistory.OrdersHistory.2222888003",
    defaultMessage: "Voir plus"
  })));
};