import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  cursor: pointer;\n  justify-content: flex-start;\n  align-items: center;\n\n  input[type=\"checkbox\"] {\n    display: none;\n    position: relative;\n    right: -999em;\n  }\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 26px;\n    width: 26px;\n\n    span {\n      border: 1px solid ", ";\n      width: 26px;\n      height: 26px;\n      display: inline-block;\n\n      border-radius: 50%;\n      background-color: ", ";\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n\n  input:checked + div {\n    span {\n      background-clip: content-box;\n      padding: 2px;\n      border: 1px solid\n        ", ";\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: fit-content;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  cursor: pointer;\n  position: relative;\n  margin-bottom: 0.5rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  flex-basis: 20%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Checkbox = styled.div(_templateObject2());
export var Label = styled.label(_templateObject3(), function (props) {
  return props.theme.colors.gray;
}, function (props) {
  return props.color;
}, function (props) {
  return props.color === "rgb(255,255,255)" ? props.theme.colors.gray : props.color;
});