import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as S from "./styles";
import { transformPaymentMethodName } from "@temp/misc";
import { TaxedMoney, Money } from "@components/containers";
import { checkoutMessages, commonMessages } from "@temp/intl";
export var OrderAddressesPayment = function OrderAddressesPayment(_ref) {
  var _shipping$phone, _billing$phone, _transformPaymentMeth;

  var shipping = _ref.shipping,
      billing = _ref.billing,
      payment = _ref.payment,
      shippingPrice = _ref.shippingPrice,
      total = _ref.total,
      discount = _ref.discount;
  var intl = useIntl();
  return React.createElement(S.Wrapper, null, React.createElement(S.Addresses, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderAddressesPayment.OrderAddressesPayment.2584311989",
    defaultMessage: "Adresses"
  })), React.createElement(S.Subtitle, null, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), React.createElement("p", null, shipping.streetAddress1), React.createElement("p", null, shipping.streetAddress2), React.createElement("p", null, shipping.postalCode), React.createElement("p", null, "".concat(shipping.city, ", ").concat(shipping.countryArea)), React.createElement("p", null, "".concat(shipping.firstName, " ").concat(shipping.lastName, " - ").concat((_shipping$phone = shipping.phone) === null || _shipping$phone === void 0 ? void 0 : _shipping$phone.substring(4))), React.createElement(S.Subtitle, null, React.createElement(FormattedMessage, checkoutMessages.billingAddress)), React.createElement("p", null, billing.streetAddress1), React.createElement("p", null, billing.streetAddress2), React.createElement("p", null, billing.postalCode), React.createElement("p", null, "".concat(billing.city, ", ").concat(billing.countryArea)), React.createElement("p", null, "".concat(billing.firstName, " ").concat(billing.lastName, " - ").concat((_billing$phone = billing.phone) === null || _billing$phone === void 0 ? void 0 : _billing$phone.substring(4)))), React.createElement(S.Payment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, checkoutMessages.paymentMethod)), payment && payment.gateway && payment.gateway !== "manual" && React.createElement("div", {
    className: "line"
  }, React.createElement("h6", null, React.createElement(FormattedMessage, commonMessages.paymentMethod)), React.createElement("span", null, (_transformPaymentMeth = transformPaymentMethodName(payment === null || payment === void 0 ? void 0 : payment.gateway, intl)) === null || _transformPaymentMeth === void 0 ? void 0 : _transformPaymentMeth.localized)), React.createElement("div", {
    className: "line"
  }, React.createElement("h6", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderAddressesPayment.OrderAddressesPayment.2230166502",
    defaultMessage: "Livraison"
  })), React.createElement(TaxedMoney, {
    className: "money",
    taxedMoney: shippingPrice
  })), discount.amount !== 0 && React.createElement("div", {
    className: "line"
  }, React.createElement("h6", null, React.createElement(FormattedMessage, commonMessages.discount)), React.createElement(Money, {
    className: "discount",
    money: discount
  })), React.createElement("div", {
    className: "line line__total"
  }, React.createElement("h6", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderAddressesPayment.OrderAddressesPayment.878013594",
    defaultMessage: "Total"
  })), React.createElement(TaxedMoney, {
    taxedMoney: total,
    className: "money"
  }))));
};
OrderAddressesPayment.displayName = "OrderAddressesPayment";
export default OrderAddressesPayment;