import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { pick } from "lodash";
import React from "react";
import { AddressFormContent } from "./AddressFormContent";
var ADDRESS_FIELDS = ["city", "companyName", "countryArea", "firstName", "lastName", "country", "phone", "postalCode", "streetAddress1", "streetAddress2", "email"];
export var AddressForm = function AddressForm(_ref) {
  var address = _ref.address,
      handleSubmit = _ref.handleSubmit,
      formId = _ref.formId,
      defaultValue = _ref.defaultValue,
      countriesOptions = _ref.countriesOptions,
      props = _objectWithoutProperties(_ref, ["address", "handleSubmit", "formId", "defaultValue", "countriesOptions"]);

  var addressWithPickedFields = {};

  if (address) {
    var _addressWithPickedFie;

    addressWithPickedFields = pick(address, ADDRESS_FIELDS);
    addressWithPickedFields.countryArea = {
      label: addressWithPickedFields.countryArea
    };
    addressWithPickedFields.city = {
      label: addressWithPickedFields.city
    };
    addressWithPickedFields.phone = (_addressWithPickedFie = addressWithPickedFields.phone) === null || _addressWithPickedFie === void 0 ? void 0 : _addressWithPickedFie.substring(4);
  }

  if (defaultValue) {
    addressWithPickedFields.country = defaultValue;
  }

  return React.createElement(AddressFormContent, _extends({
    values: addressWithPickedFields
  }, {
    countriesOptions: countriesOptions,
    defaultValue: defaultValue,
    formId: formId
  }, props));
};