import { TaxedMoney } from "@components/containers";
import React from "react";
import { Button } from "@components/atoms";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { OverlayContext, OverlayTheme, OverlayType } from "../Overlay";

var OrderFulfillmentLine = function OrderFulfillmentLine(props) {
  var overlayContext = React.useContext(OverlayContext);
  var line = props.line,
      status = props.status;
  var orderLine = line.orderLine;
  var orderlineID = orderLine.id;
  return React.createElement("div", {
    className: "fulfillment-line-container"
  }, React.createElement("div", {
    className: "products-container"
  }, React.createElement("img", {
    src: orderLine.thumbnail.url
  }), React.createElement("div", {
    className: "product-container"
  }, React.createElement("p", null, orderLine.productName, " - ", orderLine.variant.name), React.createElement("p", null, "Unit price -", React.createElement(TaxedMoney, {
    taxedMoney: orderLine.unitPrice
  })), React.createElement("p", null, " Total price -", React.createElement(TaxedMoney, {
    taxedMoney: orderLine.totalPrice
  })), status === "DELIVERED" && React.createElement("div", null, React.createElement(Button, {
    testingContext: "goToReviewButton",
    onClick: function onClick() {
      return overlayContext.show(OverlayType.review, OverlayTheme.right, {
        data: orderlineID
      });
    }
  }, React.createElement(FormattedMessage, commonMessages.rate))))));
};

export default OrderFulfillmentLine;