import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { Offline, OfflinePlaceholder, Online, Overlay, OverlayTheme, OverlayType } from "../..";
import RegisterForm from "./RegisterForm";
import closeImg from "../../../images/x.svg";
import ForgottenPassword from "./ForgottenPassword";

var Login = function Login(props) {
  var _useState = useState("login"),
      _useState2 = _slicedToArray(_useState, 2),
      active = _useState2[0],
      setActive = _useState2[1];

  var overlay = props.overlay;
  var show = overlay.show,
      hide = overlay.hide;
  var location = useLocation();
  return React.createElement(Overlay, {
    testingContext: "loginOverlay",
    context: overlay
  }, React.createElement("div", {
    className: "login"
  }, React.createElement(Online, null, React.createElement("div", {
    className: "overlay__header"
  }, React.createElement("p", {
    className: "overlay__header-text"
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Login.index.282922692",
    defaultMessage: "Saleor account"
  })), React.createElement(ReactSVG, {
    path: closeImg,
    onClick: hide,
    className: "overlay__header__close-icon"
  })), React.createElement("div", {
    className: "login__tabs"
  }, React.createElement("span", {
    "data-test": "loginTab",
    onClick: function onClick() {
      return setActive("login");
    },
    className: active === "login" ? "active-tab" : ""
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Login.index.3293735236",
    defaultMessage: "Sign in to account"
  })), React.createElement("span", {
    "data-test": "registerTab",
    onClick: function onClick() {
      return setActive("register");
    },
    className: active === "register" ? "active-tab" : ""
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Login.index.3254057623",
    defaultMessage: "Register new account"
  }))), React.createElement("div", {
    className: "login__content"
  }, active === "login" ? React.createElement(React.Fragment, null, React.createElement(Link, {
    className: "login__content__button",
    to: "/login?redirect=".concat(encodeURIComponent(location.pathname))
  }, "go to login"), React.createElement(Link, {
    className: "login__content__button",
    to: "/register?redirect=".concat(encodeURIComponent(location.pathname))
  }, "go to register"), React.createElement(ForgottenPassword, {
    onClick: function onClick() {
      show(OverlayType.password, OverlayTheme.right);
    }
  })) : React.createElement(RegisterForm, {
    hide: hide
  }))), React.createElement(Offline, null, React.createElement(OfflinePlaceholder, null))));
};

export default Login;