import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { usePasswordChange } from "@lastmaj/saleor-sdk";
import { PasswordChangeForm } from "./PasswordChangeForm";
import * as S from "./styles";
export var PasswordTile = function PasswordTile() {
  var _usePasswordChange = usePasswordChange(),
      _usePasswordChange2 = _slicedToArray(_usePasswordChange, 2),
      setPasswordChange = _usePasswordChange2[0],
      _usePasswordChange2$ = _usePasswordChange2[1],
      data = _usePasswordChange2$.data,
      error = _usePasswordChange2$.error;

  var intl = useIntl();
  return React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AccountTabTiles.PasswordTile.1194069762",
    defaultMessage: "Modifier votre mot de passe"
  })), React.createElement(S.Content, null, React.createElement(PasswordChangeForm, {
    handleSubmit: function handleSubmit(data) {
      setPasswordChange(data);
    },
    error: error ? error.extraInfo.userInputErrors : []
  })));
};