import React from "react";
import * as S from "./styles";
export var HighlightedCollections = function HighlightedCollections(_ref) {
  var collections = _ref.collections;
  return React.createElement("div", {
    className: "container"
  }, React.createElement(S.Wrapper, null, collections && collections.map(function (c) {
    var _c$backgroundImage;

    return React.createElement(S.Card, {
      background: (_c$backgroundImage = c.backgroundImage) === null || _c$backgroundImage === void 0 ? void 0 : _c$backgroundImage.url
    });
  })));
};
HighlightedCollections.displayName = "HighlightedCollections";
export default HighlightedCollections;