import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import SvgTick from "images/SvgTick";
import React from "react";
import convertColor from "./colorMap";
import * as S from "./styles";
var ENTER_KEY = 13;
var SPACE_KEY = 32;

var getTickColor = function getTickColor(color) {
  var _color$substring$spli = color.substring(color.indexOf("(") + 1, color.lastIndexOf(")")).split(/,\s*/),
      _color$substring$spli2 = _slicedToArray(_color$substring$spli, 3),
      red = _color$substring$spli2[0],
      green = _color$substring$spli2[1],
      blue = _color$substring$spli2[2];

  var brightness = (parseInt(red) * 299 + parseInt(green) * 587 + parseInt(blue) * 114) / 1000;
  return brightness > 123 ? "black" : "white";
};

export var CheckboxColor = function CheckboxColor(_ref) {
  var name = _ref.name,
      color = _ref.color,
      checked = _ref.checked,
      _ref$onChange = _ref.onChange,
      onChange = _ref$onChange === void 0 ? function () {
    return null;
  } : _ref$onChange,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ["name", "color", "checked", "onChange", "children"]);

  var ref = React.useRef(null);
  var colorValue = convertColor(color);
  var tickColor = getTickColor(colorValue);
  return React.createElement(S.Wrapper, null, React.createElement(S.Checkbox, {
    ref: ref,
    title: color,
    onClick: function onClick(evt) {
      evt.preventDefault();
      onChange(evt);

      if (ref.current) {
        ref.current.blur();
      }
    }
  }, React.createElement(S.Label, {
    color: colorValue
  }, React.createElement("input", _extends({}, props, {
    tabIndex: -1,
    type: "checkbox",
    name: name,
    checked: checked,
    readOnly: true
  })), React.createElement("div", {
    ref: ref,
    tabIndex: 0,
    onKeyDown: function onKeyDown(evt) {
      if (evt.which === SPACE_KEY || evt.which === ENTER_KEY) {
        evt.preventDefault();
        onChange(evt);
      }
    }
  }, React.createElement("span", null, checked && React.createElement(SvgTick, {
    fill: tickColor
  })))), children));
};
CheckboxColor.displayName = "CheckboxColor";
export default CheckboxColor;