import "./scss/index.scss";
import * as React from "react";
import { Loader } from "@components/atoms";
import { useAuth } from "@lastmaj/saleor-sdk";
import Page from "./Page";
import { TypedOrderDetailsQuery } from "./queries";
import { maybe } from "@utils/misc";

var View = function View(_ref) {
  var token = _ref.match.params.token;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var guest = !user;

  var handleDownloadInvoice = function handleDownloadInvoice(order) {
    var _order$invoices;

    if (order && "invoices" in order && ((_order$invoices = order.invoices) === null || _order$invoices === void 0 ? void 0 : _order$invoices.length) > 0) {
      // Always download latest invoice
      var invoice = order.invoices.reduce(function (a, b) {
        return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
      });

      if (invoice) {
        window.open(invoice.url, "_blank");
      }
    }
  };

  return React.createElement(TypedOrderDetailsQuery, {
    variables: {
      token: token
    }
  }, function (_ref2) {
    var data = _ref2.data,
        loading = _ref2.loading;
    if (loading) return React.createElement(Loader, null);
    return React.createElement("div", {
      className: "order-details container"
    }, React.createElement(Page, {
      order: maybe(function () {
        return data.orderByToken;
      }),
      guest: guest,
      downloadInvoice: function downloadInvoice() {
        return handleDownloadInvoice(data.orderByToken);
      }
    }));
  });
};

export default View;