import React from "react";
import * as S from "./styles";
export var Stars = function Stars(_ref) {
  var rating = _ref.rating,
      size = _ref.size;

  var fill = function fill(i) {
    var diff = rating - i;
    if (diff <= 0) return "0";
    if (diff >= 1) return "100";
    return diff.toFixed(2).slice(-2);
  };

  return React.createElement(S.Container, null, [0, 1, 2, 3, 4].map(function (x) {
    return React.createElement(S.Wrapper, {
      key: x,
      size: size
    }, React.createElement(S.Star, {
      size: size
    }, React.createElement("div", null)), React.createElement(S.FilledStar, {
      fill: fill(x),
      size: size
    }, React.createElement("div", null)));
  }));
};
Stars.displayName = "Stars";
export default Stars;