import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation RegisterAccount(\n    $email: String!\n    $firstName: String!\n    $lastName: String!\n    $password: String!\n    $redirectUrl: String!\n  ) {\n    accountRegister(\n      input: {\n        email: $email\n        firstName: $firstName\n        lastName: $lastName\n        password: $password\n        redirectUrl: $redirectUrl\n      }\n    ) {\n      user {\n        firstName\n      }\n      accountErrors {\n        field\n        message\n        code\n      }\n      requiresConfirmation\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "../../core/mutations";
var accountRegisterMutation = gql(_templateObject());
export var TypedAccountRegisterMutation = TypedMutation(accountRegisterMutation);