import React from "react";
import { Formik } from "formik";
import { ErrorMessage, Radio } from "@components/atoms";
import { PROVIDERS } from "@temp/core/config";
import * as S from "./styles";
import { FormattedMessage } from "react-intl";
import { checkoutMessages } from "@temp/intl";
/**
 * Payment Gateways list
 */

var PaymentGatewaysList = function PaymentGatewaysList(_ref) {
  var paymentGateways = _ref.paymentGateways,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      formRef = _ref.formRef,
      formId = _ref.formId,
      processPayment = _ref.processPayment,
      submitPayment = _ref.submitPayment,
      submitPaymentSuccess = _ref.submitPaymentSuccess,
      errors = _ref.errors,
      onError = _ref.onError,
      total = _ref.total;
  return React.createElement(S.Wrapper, null, paymentGateways.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name,
        config = _ref2.config;
    var checked = selectedPaymentGateway === id;

    switch (name) {
      case PROVIDERS.DELIVERY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDeliveryInput",
          name: "payment-method",
          value: "delivery",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDeliveryName"
        }, React.createElement(FormattedMessage, checkoutMessages.paymentAtDelivery)))), checked && React.createElement(Formik, {
          initialValues: {
            status: "not-charged"
          },
          onSubmit: function onSubmit(_, _ref3) {
            var setSubmitting = _ref3.setSubmitting;
            processPayment(id, "not-charged", total);
            setSubmitting(false);
          }
        }, function (_ref4) {
          var handleSubmit = _ref4.handleSubmit;
          return React.createElement("form", {
            id: formId,
            ref: formRef,
            onSubmit: handleSubmit
          });
        }));
        break;

      case PROVIDERS.SMT.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDeliveryInput",
          name: "payment-method",
          value: "delivery",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDeliveryName"
        }, React.createElement(FormattedMessage, checkoutMessages.paymentWithCard)))), checked && React.createElement(Formik, {
          initialValues: {
            status: "not-charged"
          },
          onSubmit: function onSubmit(_, _ref5) {
            var setSubmitting = _ref5.setSubmitting;
            processPayment(id, "not-charged", total);
            setSubmitting(false);
          }
        }, function (_ref6) {
          var handleSubmit = _ref6.handleSubmit;
          return React.createElement("form", {
            id: formId,
            ref: formRef,
            onSubmit: handleSubmit
          });
        }));

      default:
        return null;
    }
  }), !selectedPaymentGateway && errors && React.createElement(ErrorMessage, {
    errors: errors
  }));
};

export { PaymentGatewaysList };