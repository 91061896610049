import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  height: 379px;\n  display; flex;\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n  div {\n    margin-bottom: 20px;\n    &:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  span {\n    display: inline-block;\n  }\n  font-weight: ", ";\n\n  ", "\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px 20px;\n  margin: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  button {\n    font-size: 0.75rem;\n    padding: 5px;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid ", ";\n  margin: 0;\n  padding: 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 30px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: 0 20px 30px 20px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Content = styled.div(_templateObject2());
export var ProductLine = styled.div(_templateObject3());
export var CartSummaryProductList = styled.div(_templateObject4());
export var HR = styled.hr(_templateObject5(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Header = styled.div(_templateObject6());
export var Title = styled.h3(_templateObject7(), function (props) {
  return props.theme.typography.h3FontSize;
});
export var CostLine = styled.div(_templateObject8(), function (props) {
  return props.last ? props.theme.typography.boldFontWeight : "normal";
}, function (props) {
  return props.negative && "\n    span:last-of-type {\n      color: ".concat(props.theme.colors.darkGreen, ";\n      font-weight: ").concat(props.theme.typography.boldFontWeight, ";\n    }\n  ");
}, function (props) {
  return props.last && "\n    margin-top: 20px;\n  ";
});
export var Costs = styled.div(_templateObject9());
export var Loading = styled.div(_templateObject10());