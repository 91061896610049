import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import * as ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import * as S from "./styles";
var modalRoot = document.getElementById("modal-root");

var Overlay = function Overlay(_ref) {
  var children = _ref.children,
      _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? 600 : _ref$duration,
      hide = _ref.hide,
      _ref$position = _ref.position,
      position = _ref$position === void 0 ? "center" : _ref$position,
      show = _ref.show,
      _ref$transparent = _ref.transparent,
      transparent = _ref$transparent === void 0 ? false : _ref$transparent,
      _ref$target = _ref.target,
      target = _ref$target === void 0 ? modalRoot : _ref$target,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId;
  var animationProps = {
    open: show,
    position: position
  };
  return target && ReactDOM.createPortal(React.createElement(Transition, {
    in: show,
    timeout: duration,
    unmountOnExit: true
  }, function (state) {
    return React.createElement(S.Overlay, _extends({}, animationProps, {
      state: state,
      onClick: hide,
      transparent: transparent,
      "data-test": testingContext,
      "data-test-id": testingContextId
    }), React.createElement(S.Lightbox, _extends({}, animationProps, {
      state: state,
      onClick: function onClick(e) {
        return e.stopPropagation();
      }
    }), children));
  }), target);
};

export default Overlay;