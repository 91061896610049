import React from "react";
import * as S from "./styles";
import { Stars } from "..";
export var StarsBreakdown = function StarsBreakdown(_ref) {
  var ratingsSummary = _ref.ratingsSummary,
      totalRatings = _ref.totalRatings;
  return React.createElement("div", null, ratingsSummary === null || ratingsSummary === void 0 ? void 0 : ratingsSummary.map(function (r) {
    return React.createElement(S.SummaryItem, null, React.createElement(S.Stars, null, r.stars), React.createElement(Stars, {
      rating: r.stars,
      size: "sm"
    }), React.createElement(S.Gauge, null, React.createElement(S.PaintedGauge, {
      percentage: 100 * r.count / totalRatings
    })), React.createElement(S.CountSpan, null, r === null || r === void 0 ? void 0 : r.count));
  }));
};
StarsBreakdown.displayName = "StarsBreakdown";
export default StarsBreakdown;