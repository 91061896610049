import React from "react";
import { FormattedMessage, FormattedDate, useIntl } from "react-intl";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { TaxedMoney } from "@components/containers";
import { commonMessages, translateOrderStatus } from "@temp/intl";
import { Thumbnail } from "..";
import * as S from "./styles";

var header = function header(matches) {
  return React.createElement(S.HeaderRow, null, React.createElement(S.IndexNumber, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.2387666633",
    defaultMessage: "Index Number"
  })), matches && React.createElement(React.Fragment, null, React.createElement(S.ProductsOrdered, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.724329054",
    defaultMessage: "Products Ordered"
  })), React.createElement(S.DateOfOrder, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.1633831221",
    defaultMessage: "Date of Order"
  })), React.createElement(S.Value, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.OrderTabel.OrderTabel.1148029984",
    defaultMessage: "Value"
  }))), React.createElement(S.Status, null, React.createElement(FormattedMessage, commonMessages.status)));
};

export var OrderTabel = function OrderTabel(_ref) {
  var orders = _ref.orders,
      history = _ref.history;
  var theme = React.useContext(ThemeContext);
  var intl = useIntl();
  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    query: {
      minWidth: theme.breakpoints.largeScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, React.createElement(S.Row, null, header(matches)), orders && orders.map(function (order) {
      var date = new Date(order.node.created);
      return React.createElement(S.Row, {
        "data-test": "orderEntry",
        "data-test-id": order.node.number,
        key: order.node.number,
        onClick: function onClick(evt) {
          evt.stopPropagation();
          history.push("/order-history/".concat(order.node.token));
        }
      }, React.createElement(S.IndexNumber, null, order.node.number), matches ? React.createElement(React.Fragment, null, React.createElement(S.ProductsOrdered, null, order.node.lines.slice(0, 5).map(function (product) {
        return React.createElement("span", {
          key: product.variant.id,
          onClick: function onClick(evt) {
            evt.stopPropagation();
            history.push("/product/".concat(slug, "/"));
          }
        }, React.createElement(Thumbnail, {
          source: product
        }));
      })), React.createElement(S.DateOfOrder, null, React.createElement(FormattedDate, {
        value: date
      })), React.createElement(S.Value, null, React.createElement(TaxedMoney, {
        taxedMoney: order.node.total
      }))) : "", React.createElement(S.Status, null, translateOrderStatus(order.node.statusDisplay, intl)));
    }));
  }));
};