import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  grid-area: chevron;\n  line-height: 0;\n\n  margin: 0.5rem auto;\n\n  svg {\n    width: 10px;\n    transform: ", ";\n  }\n\n  @media (min-width: ", ") {\n    padding: 1rem;\n    margin: auto;\n\n    svg {\n      width: 1rem;\n    }\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: money;\n\n  color: ", ";\n  text-align: right;\n  font-weight: 500;\n\n  @media (min-width: ", ") {\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  grid-area: status;\n\n  @media (min-width: ", ") {\n    text-align: center;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: info;\n  color: ", ";\n  text-align: right;\n\n  p {\n    line-height: 2rem;\n    margin-right: 0.5rem;\n  }\n\n  @media (min-width: ", ") {\n    padding: 0 1rem;\n    text-align: left;\n    margin-right: unset;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 5px;\n  width: 30px;\n  display: flex;\n  border-radius: 10%;\n  align-items: center;\n  justify-content: flex-start;\n  height: auto;\n\n  p {\n    font-weight: 700;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: no-wrap;\n  justify-content: flex-start;\n\n  grid-area: products;\n\n  img {\n    width: 40px;\n    height: 60px;\n    border-radius: 10%;\n    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);\n    -webkit-box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 75%);\n    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);\n  }\n\n  @media (min-width: ", ") {\n    justify-content: center;\n    img {\n      max-width: 50px;\n    }\n  }\n\n  img:not(:first-child) {\n    margin-left: -20px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  cursor: pointer;\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n  border-radius: 7px;\n  z-index: 2;\n\n  background-color: ", ";\n  box-shadow: ", ";\n\n  display: grid;\n\n  grid-template-columns: 90px auto auto 1rem;\n  grid-template-rows: auto auto;\n\n  grid-template-areas:\n    \"products info info chevron\"\n    \"status . money .\";\n\n  row-gap: 1rem;\n\n  font-size: 0.8rem;\n\n  @media (min-width: ", ") {\n    grid-template-columns: 110px auto 2fr auto auto;\n    grid-template-areas: \"products info status money chevron\";\n\n    row-gap: unset;\n    column-gap: 1rem;\n\n    font-size: 0.875rem;\n\n    > * {\n      margin: auto;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border: 1px solid ", ";\n  border-radius: 10px;\n\n  padding: 0.5rem;\n\n  box-shadow: ", ";\n\n  margin-bottom: 4rem;\n\n  @media (min-width: ", ") {\n    padding: 1rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { TaxedMoney } from "@components/containers";
import ReactSVG from "react-svg";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.grayBorder;
}, function (props) {
  return props.expanded ? props.theme.boxShadow.pastOrderRow : "none";
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Row = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.expanded ? "hsl(0deg 0% 100%) 0px 7px 20px 1px" : "none";
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Products = styled.div(_templateObject3(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var ProductsPlus = styled.div(_templateObject4());
export var OrderNumberAndDate = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.lightFont;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var OrderNumber = styled.span(_templateObject6(), function (props) {
  return props.theme.colors.dark;
});
export var OrderStatus = styled.span(_templateObject7(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Money = styled(TaxedMoney)(_templateObject8(), function (props) {
  return props.theme.colors.darkGreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var DownIcon = styled(ReactSVG)(_templateObject9(), function (props) {
  return props.expanded ? "rotate(180deg)" : "unset";
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});