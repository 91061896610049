import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect } from "react";
import { useProductVariantsAttributes, useProductVariantsAttributesValuesSelection } from "@hooks";
import * as S from "./styles";

var ProductVariantPicker = function ProductVariantPicker(_ref) {
  var _ref$productVariants = _ref.productVariants,
      productVariants = _ref$productVariants === void 0 ? [] : _ref$productVariants,
      _ref$queryAttributes = _ref.queryAttributes,
      queryAttributes = _ref$queryAttributes === void 0 ? {} : _ref$queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      onChange = _ref.onChange,
      variantId = _ref.variantId,
      _ref$animateError = _ref.animateError,
      animateError = _ref$animateError === void 0 ? false : _ref$animateError,
      _ref$textError = _ref.textError,
      textError = _ref$textError === void 0 ? false : _ref$textError;
  var productVariantsAttributes = useProductVariantsAttributes(productVariants);

  var _useProductVariantsAt = useProductVariantsAttributesValuesSelection(productVariantsAttributes),
      _useProductVariantsAt2 = _slicedToArray(_useProductVariantsAt, 2),
      productVariantsAttributesSelectedValues = _useProductVariantsAt2[0],
      selectProductVariantsAttributesValue = _useProductVariantsAt2[1];

  useEffect(function () {
    var selectedVariant = productVariants.find(function (productVariant) {
      return productVariant.attributes.every(function (productVariantAttribute) {
        var productVariantAttributeId = productVariantAttribute.attribute.id;

        if (productVariantAttribute.values[0] && productVariantsAttributesSelectedValues[productVariantAttributeId] && productVariantAttribute.values[0].id === productVariantsAttributesSelectedValues[productVariantAttributeId].id) {
          return true;
        }

        return false;
      });
    });

    if (onChange) {
      onChange(productVariantsAttributesSelectedValues, selectedVariant);
    }
  }, [productVariantsAttributesSelectedValues]);

  var onAttributeChange = function onAttributeChange(id, value, slug) {
    selectProductVariantsAttributesValue(id, value);
    onAttributeChangeHandler(slug, value);
  };

  return React.createElement(S.Wrapper, null, Object.keys(productVariantsAttributes).map(function (productVariantsAttributeId) {
    var productVariantsAttribute = productVariantsAttributes[productVariantsAttributeId];
    var slug = productVariantsAttribute.attribute.slug;
    return React.createElement(React.Fragment, null, React.createElement("p", null, productVariantsAttribute.attribute.name, " :", textError && React.createElement("span", {
      style: {
        color: "red"
      }
    }, " Selectionnez une option")), React.createElement(S.Variants, null, productVariants && productVariants.length !== 0 && productVariants.map(function (variant) {
      return React.createElement(S.Variant, {
        key: variant.id,
        selected: variant.id === variantId,
        available: variant.quantityAvailable !== 0,
        animate: animateError,
        className: variant.quantityAvailable === 0 ? "strikethrough" : "",
        onClick: function onClick() {
          return onAttributeChange(productVariantsAttributeId, variant.name, slug);
        }
      }, variant.name);
    })));
  }));
};

ProductVariantPicker.displayName = "ProductVariantPicker";
export default ProductVariantPicker;