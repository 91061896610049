import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: seemore;\n  padding: 0.25rem 0.5rem;\n  font-size: 0.8rem;\n  width: fit-content;\n  margin-left: auto;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  grid-area: reason;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  grid-area: status;\n\n  @media (min-width: ", ") {\n    text-align: center;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: info;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: thumb;\n  width: 50px;\n  height: auto !important;\n  border-radius: 10px;\n  border: 1px solid #00000038;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n  border-radius: 10px;\n  padding: 0.5rem;\n  font-size: 0.8rem;\n  border: 1px solid ", ";\n\n  display: grid;\n\n  grid-template-rows: auto auto auto;\n  grid-template-columns: 50px auto auto;\n  grid-template-areas:\n    \"thumb  info .\"\n    \"thumb  reason .\"\n    \"status  status seemore\";\n\n  column-gap: 0.5rem;\n  row-gap: 0.5rem;\n\n  @media (min-width: ", ") {\n    padding: 1rem;\n    font-size: 0.875rem;\n\n    grid-template-rows: 1fr 1fr;\n    grid-template-columns: 50px auto 1.5fr auto;\n    grid-template-areas:\n      \"thumb info status seemore\"\n      \"thumb reason . .\";\n\n    column-gap: 1rem;\n    row-gap: 1rem;\n  }\n\n  span {\n    color: ", ";\n    font-weight: 300;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 500;\n  letter-spacing: -0.5px;\n  font-size: 1.375rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  box-shadow: ", ";\n  padding: 0.5rem;\n  border-radius: 10px;\n  background: ", ";\n\n  @media (min-width: ", ") {\n    padding: 1rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { Button } from "@components/atoms";
import { styled } from "@styles";
import { Thumbnail } from "..";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.boxShadow.orderFulfillment;
}, function (props) {
  return props.theme.colors.redOrderDetailsRequest;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Title = styled.h3(_templateObject2());
export var Item = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.redOrderDetailsRequest;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.colors.labelGray;
});
export var Thumb = styled(Thumbnail)(_templateObject4());
export var Info = styled.p(_templateObject5());
export var Status = styled.p(_templateObject6(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Reason = styled.p(_templateObject7());
export var Seemore = styled(Button)(_templateObject8());