import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import { NavLink } from "..";

var NavItem = function NavItem(_ref) {
  var handleClick = _ref.handleClick,
      children = _ref.children,
      item = _objectWithoutProperties(_ref, ["handleClick", "children"]);

  var content = children && children.length ? React.createElement("ul", {
    onClick: handleClick
  }, children.map(function (subItem, i) {
    return React.createElement(NavItem, _extends({
      key: i
    }, subItem));
  })) : null;
  return React.createElement("li", null, React.createElement(NavLink, {
    item: item,
    onClick: handleClick
  }), content);
};

export default NavItem;