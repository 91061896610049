import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.8rem 1rem;\n  margin: 0;\n  border: none;\n  width: 100%;\n  outline: none;\n  font-size: 0.875rem;\n  background-color: transparent;\n  &:-webkit-autofill {\n    & + label {\n      ", ";\n    }\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  border: 1px solid ", ";\n  border-radius: 15px;\n\n  color: ", ";\n  transition: all 0.3s ease;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  top: 100%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: ", "\n  \n  position: relative;\n  input:-webkit-autofill {\n    -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */\n    -webkit-border-radius: 15px;\n}\n\ninput:-webkit-autofill:focus {\n    -webkit-box-shadow: /*your box-shadow*/,0 0 0 50px white inset;\n}  \n\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { activeLabelStyles } from "../../atoms/InputLabel";
export var PhoneField = styled.div(_templateObject(), function (props) {
  return props.errors ? "0.5rem" : "2rem";
});
PhoneField.displayName = "S.PhoneField";
export var HelpText = styled.span(_templateObject2(), function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.input.labelFontSize;
});
export var ErrorMessages = styled.div(_templateObject3());

var getEdgeColor = function getEdgeColor(_ref) {
  var active = _ref.active,
      error = _ref.error,
      disabled = _ref.disabled,
      theme = _ref.theme;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (disabled) {
    return theme.colors.disabled;
  }

  if (error) {
    return theme.colors.error;
  }

  if (active) return theme.colors.dark;
  return theme.colors.grayBorder;
};

var getColor = function getColor(_ref2) {
  var error = _ref2.error,
      disabled = _ref2.disabled,
      theme = _ref2.theme;
  if (error) return theme.colors.error;
  return theme.colors.dark;
};

export var Wrapper = styled.div(_templateObject4(), function (props) {
  return getEdgeColor(props);
}, function (props) {
  return getColor(props);
});
export var Content = styled.span(_templateObject5());
export var InputWrapper = styled.div(_templateObject6());
export var Input = styled.input(_templateObject7(), function (props) {
  return activeLabelStyles(props.theme, props.labelBackground);
});