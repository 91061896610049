import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { ProductTileCircles } from "@components/molecules";
import { ReactCarousel } from "@components/organisms";
import { Countdown } from "@components/atoms";
import flashSale from "@temp/images/flashSale.svg";
import * as S from "./styles";
var responsive = {
  mobile: {
    breakpoint: {
      max: 720,
      min: 0
    },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
export var HomepageFlashSale = function HomepageFlashSale(_ref) {
  var products = _ref.products,
      expiresAt = _ref.expiresAt;
  var theme = React.useContext(ThemeContext);
  return React.createElement("div", {
    className: "container"
  }, React.createElement(S.Wrapper, null, React.createElement(S.Banner, null, React.createElement(S.Inner, null, React.createElement("img", {
    src: flashSale
  }), React.createElement("h1", null, "FLASH sales")), React.createElement(Countdown, {
    difference: Date.parse(expiresAt) - new Date().getTime()
  })), React.createElement(S.ProductsContainer, null, products.length && React.createElement(Media, {
    query: {
      maxWidth: theme.breakpoints.mediumScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, matches ? React.createElement(ReactCarousel, _extends({
      containerClass: "carousel"
    }, {
      responsive: responsive
    }), products.map(function (product) {
      return React.createElement(ProductTileCircles, _extends({
        product: product
      }, {
        variant: "flash-sale"
      }));
    })) : products.map(function (product) {
      return React.createElement(ProductTileCircles, _extends({
        product: product
      }, {
        variant: "flash-sale"
      }));
    }));
  }))));
};
HomepageFlashSale.displayName = "HomepageFlashSale";
export default HomepageFlashSale;