import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { useCheckout } from "@lastmaj/saleor-sdk";
import { useLocalStorage } from "@hooks";
import ClickToPaySuccess from "@components/organisms/ClickToPaySuccess";
import { Loader } from "@components/atoms";
import { orderFinalizedUrl } from "@temp/app/routes";

var ClickToPaySuccessView = function ClickToPaySuccessView(props) {
  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout,
      completeCheckout = _useCheckout.completeCheckout;

  var _useQueryParam = useQueryParam("orderId", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 1),
      orderId = _useQueryParam2[0];

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var paymentData = useLocalStorage("payment").storedValue;
  var history = useHistory();
  useEffect(function () {
    paymentData.orderId = orderId;
    setLoading(true);

    if (checkout && checkout.id) {
      completeCheckout({
        paymentData: paymentData
      }).then(function (_) {
        setLoading(false);
        setTimeout(function () {
          return history.replace(orderFinalizedUrl);
        }, 4000);
      });
    }
  }, [checkout]);
  if (loading) return React.createElement(Loader, null);
  return React.createElement(ClickToPaySuccess, null);
};

export { ClickToPaySuccessView };