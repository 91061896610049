import React from "react";
import { HomepageBestSellers } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { maybe } from "@utils/misc";
import { TypedBestSellersCollectionProductsQuery } from "../queries";
import { getEvenArray } from "@temp/core/utils";
export var BestSellers = function BestSellers() {
  return React.createElement(TypedBestSellersCollectionProductsQuery, {
    displayError: false
  }, function (_ref) {
    var data = _ref.data;
    var products = maybe(function () {
      return data.collection.products.edges;
    }, []);

    if (products.length) {
      return React.createElement(HomepageBestSellers, {
        products: getEvenArray(products).map(function (p) {
          return p.node;
        }),
        title: commonMessages.bestSellers.defaultMessage
      });
    }

    return React.createElement(React.Fragment, null);
  });
};