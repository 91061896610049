import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment AddressFragment on Address {\n    city\n    countryArea\n    companyName\n    firstName\n    id\n    lastName\n    phone\n    postalCode\n    streetAddress1\n    streetAddress2\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
export var fragmentAddress = gql(_templateObject());