import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 1rem 0 0;\n  cursor: pointer;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  border-width: 1px;\n  border-style: solid;\n  border-color: black;\n  color: black;\n  padding: 5px 10px;\n  font-size: 0.8rem;\n  border-radius: 6px;\n  cursor: pointer;\n  font-weight: 500;\n\n  @keyframes error {\n    50% {\n      color: white;\n      border-color: transparent;\n      background-color: #ff0000b5;\n    }\n  }\n  ", "\n\n  ", "\n\n  ", ";\n\n  &:not(:last-of-type) {\n    margin-right: 1rem;\n  }\n\n  pointer-events: ", "\n    &.strikethrough {\n    position: relative;\n\n    &:before {\n      color: orange;\n      position: absolute;\n      content: \"\";\n      left: 0;\n      top: 45%;\n      right: 0;\n      border-top: 1px solid;\n      border-color: inherit;\n      -webkit-transform: skewY(328deg);\n      -moz-transform: skewY(328deg);\n      transform: skewY(328deg);\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  > p {\n    font-size: 0.8rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Variants = styled.div(_templateObject2());
export var Variant = styled.div(_templateObject3(), function (props) {
  return props.animate && "\n      animation-name: error;\n      animation-duration: 1s;\n      animation-iteration-count: 2;\n  ";
}, function (props) {
  return props.available === false && "\n    color: gray;\n    border-color: gray;\n  ";
}, function (props) {
  return props.selected && "\n    color: ".concat(props.theme.colors.logoGreen, ";\n    border-color: ").concat(props.theme.colors.logoGreen, ";\n    ");
}, function (props) {
  return props.available ? "initial" : "none";
});
export var SelectIndicator = styled.div(_templateObject4());