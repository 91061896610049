import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  margin: 2rem 0 0.5rem;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: 0.5rem;\n  height: 100%;\n  margin-right: 5px;\n\n  path {\n    fill: ", ";\n  }\n\n  transform: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 1rem;\n  background: white;\n  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 10%);\n  border-bottom: 1px solid #e6e6e6;\n  opacity: 0;\n  transition: all 0.2s cubic-bezier(0.5, 0.46, 0.5, 0.49);\n  transition: all 0.2s cubic-bezier(0.51, 0.79, 0.66, 0.82);\n  z-index: -100;\n\n  position: fixed;\n  bottom: ", ";\n\n  font-size: 0.8rem;\n  line-height: unset;\n  pointer-events: none;\n\n  &.active {\n    transform: translateY(-11px);\n    opacity: 1;\n    pointer-events: inherit;\n    z-index: 250;\n  }\n\n  .item {\n    display: flex;\n    justify-content: space-between;\n\n    span:first-of-type {\n      color: ", ";\n    }\n\n    span:last-of-type {\n      font-weight: 500;\n    }\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  padding: 0.5rem 1rem;\n\n  font-size: 0.875rem;\n\n  > button {\n    flex: 1.5;\n  }\n\n  > div {\n    flex: 1;\n  }\n\n  span {\n    display: block;\n\n    &:last-of-type {\n      font-weight: 500;\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: #a0a0a0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n  > div {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    &:not(:last-child) {\n      margin-bottom: 10px;\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1.25rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  box-shadow: ", ";\n  position: sticky;\n  top: 1rem;\n\n  @media (min-width: ", ") {\n    padding: 2rem 20px 1rem;\n    border-radius: 10px;\n  }\n\n  .discount {\n    color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import SvgChevronVertical from "images/SvgChevronVertical";
export var Container = styled.div(_templateObject(), function (props) {
  return props.theme.boxShadow.border;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.colors.darkGreen;
});
export var Title = styled.h2(_templateObject2());
export var Wrapper = styled.div(_templateObject3());
export var Label = styled.span(_templateObject4());
export var MobileWrapper = styled.div(_templateObject5());
export var MobileContent = styled.div(_templateObject6());
export var MobileExpanded = styled.div(_templateObject7(), function (props) {
  return props.type === "withoutNav" ? "45px" : "100px";
}, function (props) {
  return props.theme.colors.labelGray;
});
export var Chevron = styled(SvgChevronVertical)(_templateObject8(), function (props) {
  return props.theme.colors.labelGray;
}, function (props) {
  return props.expanded ? "rotate(180deg)" : "none";
});
export var Button = styled.div(_templateObject9());