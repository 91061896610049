import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var QuantityInput = function QuantityInput(_ref) {
  var disabled = _ref.disabled,
      quantity = _ref.quantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      isLowStock = _ref.isLowStock,
      hideErrors = _ref.hideErrors,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isTooMuch = _useState2[0],
      setIsTooMuch = _useState2[1];

  var intl = useIntl();
  useEffect(function () {
    setIsTooMuch(!isNaN(quantity) && quantity > maxQuantity);
  }, [quantity, maxQuantity]);

  var handleQuantityChange = function handleQuantityChange(direction) {
    if (disabled) return;
    if (quantity === 1 && direction === -1) return;
    if (quantity === maxQuantity && direction === 1) return;
    var newQuantity = direction === 1 ? quantity + 1 : quantity - 1;
    onQuantityChange(newQuantity);
    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  var quantityErrors = !hideErrors && isTooMuch ? [{
    message: intl.formatMessage(commonMessages.maxQtyIs, {
      maxQuantity: maxQuantity
    })
  }] : undefined;
  return React.createElement(React.Fragment, null, React.createElement(S.Text, null, "Quantit\xE9:", " ", isLowStock && React.createElement(S.Error, null, intl.formatMessage(commonMessages.lowStock, {
    left: maxQuantity
  }))), React.createElement(S.Wrapper, null, React.createElement(S.Container, null, React.createElement(S.Controller, {
    onClick: function onClick() {
      return handleQuantityChange(-1);
    },
    disabled: disabled || quantity === 1
  }, "-"), React.createElement("span", null, quantity), React.createElement(S.Controller, {
    onClick: function onClick() {
      return handleQuantityChange(1);
    },
    disabled: disabled || quantity >= maxQuantity
  }, "+"))));
};
QuantityInput.displayName = "QuantityInput";
export default QuantityInput;