import { createBrowserHistory } from "history";
var history = createBrowserHistory();
history.listen(function (_location, action) {
  if (["PUSH"].includes(action)) {
    if (_location.state && _location.state.scroll) {
      window.scroll({
        top: _location.state.scroll
      });
    } else window.scroll({
      behavior: "smooth",
      top: 0
    });
  }
});
export { history };