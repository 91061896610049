import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem;\n\n  @media (min-width: 1220px) {\n    padding: 2rem 1rem;\n  }\n\n  background-color: #f1f8f2;\n  border-radius: 10px;\n\n  margin-bottom: 2rem;\n\n  width: 100%;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  font-size: 0.8rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Box = styled.div(_templateObject2());