import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { FooterAuth, HeaderAuth } from "@components/organisms";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
import RegisterForm from "@temp/components/Register";
export var Register = function Register(_ref) {
  _objectDestructuringEmpty(_ref);

  return React.createElement(S.Wrapper, null, React.createElement(HeaderAuth, null), React.createElement(S.Container, null, React.createElement(S.Form, null, React.createElement(S.Title, null, "Rejoignez-nous!"), React.createElement(RegisterForm, null)), React.createElement(S.Login, null, React.createElement(S.Title, null, "D\xE9j\xE0 avec nous ?"), React.createElement(Link, {
    to: "/login"
  }, React.createElement(Button, null, React.createElement(FormattedMessage, commonMessages.login))))), React.createElement(FooterAuth, null));
};