import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgLocation = function SvgLocation(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48"
  }, props), React.createElement("g", {
    "data-name": "Layer 2"
  }, React.createElement("g", {
    "data-name": "Layer 1"
  }, React.createElement("path", {
    d: "M0 0h48v48H0Z",
    style: {
      fill: "none"
    }
  }), React.createElement("path", {
    d: "M24 6.74a12.08 12.08 0 0 0-12.08 12.08C11.92 30.9 24 41.26 24 41.26S36.08 30.9 36.08 18.82A12.08 12.08 0 0 0 24 6.74Zm0 32.15c-2.89-2.84-10.35-11-10.35-20.07a10.35 10.35 0 0 1 20.7 0c0 9.02-7.46 17.23-10.35 20.07Z"
  }), React.createElement("path", {
    d: "M24 24.44a5.62 5.62 0 1 1 5.62-5.62A5.62 5.62 0 0 1 24 24.44Zm0-9.51a3.9 3.9 0 1 0 3.89 3.89A3.89 3.89 0 0 0 24 14.93Z"
  }))));
};

export default SvgLocation;