import * as React from "react";
import { isEqual } from "lodash";
import { TaxedMoney } from "../../containers";
import * as S from "./styles";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
/**
 * Renders formatted price for chosen variant or product.
 * Price ranges and discounts are additionally formatted available.
 */

export var getProductPrice = function getProductPrice(productPricingRange, variantPricing) {
  if (variantPricing) {
    var _productPricingRange$;

    if (isEqual(variantPricing.priceUndiscounted, variantPricing.price)) {
      return React.createElement(S.Price, null, React.createElement(TaxedMoney, {
        taxedMoney: variantPricing.price
      }));
    }

    return React.createElement(S.Price, null, React.createElement(S.ProductPricing, null, React.createElement(TaxedMoney, {
      taxedMoney: productPricingRange.priceRange.start
    })), React.createElement(S.Baseline, null, React.createElement(TaxedMoney, {
      className: "undiscounted",
      taxedMoney: (_productPricingRange$ = productPricingRange.priceRangeUndiscounted) === null || _productPricingRange$ === void 0 ? void 0 : _productPricingRange$.start
    }), React.createElement(S.Saving, null, React.createElement(FormattedMessage, commonMessages.discountSave), React.createElement(TaxedMoney, {
      taxedMoney: productPricingRange.discount
    }))), React.createElement(S.Percentage, null, React.createElement("span", null, "Sale"), React.createElement("span", null, React.createElement("span", null, "".concat(Math.round(productPricingRange.discount.gross.amount * 100 / productPricingRange.priceRangeUndiscounted.start.gross.amount), " %")))));
  }

  if (!productPricingRange.priceRange) {
    return React.createElement(React.Fragment, null);
  }

  var _productPricingRange$2 = productPricingRange.priceRange,
      start = _productPricingRange$2.start,
      stop = _productPricingRange$2.stop;

  if (isEqual(start, stop)) {
    var _productPricingRange$3;

    if (isEqual(productPricingRange.priceRange, productPricingRange.priceRangeUndiscounted)) return React.createElement(S.Price, null, React.createElement(TaxedMoney, {
      taxedMoney: start
    }));
    return React.createElement(S.Price, null, React.createElement(S.ProductPricing, null, React.createElement(TaxedMoney, {
      taxedMoney: productPricingRange.priceRange.start
    })), React.createElement(S.Baseline, null, React.createElement(TaxedMoney, {
      className: "undiscounted",
      taxedMoney: (_productPricingRange$3 = productPricingRange.priceRangeUndiscounted) === null || _productPricingRange$3 === void 0 ? void 0 : _productPricingRange$3.start
    }), React.createElement(S.Saving, null, React.createElement(FormattedMessage, commonMessages.discountSave), React.createElement(TaxedMoney, {
      taxedMoney: productPricingRange.discount
    }))), React.createElement(S.Percentage, null, React.createElement("span", null, "Sale"), React.createElement("span", null, "".concat(Math.round(productPricingRange.discount.gross.amount * 100 / productPricingRange.priceRangeUndiscounted.start.gross.amount), " %"))));
  }

  return React.createElement(React.Fragment, null, React.createElement(TaxedMoney, {
    taxedMoney: start
  }), " - ", React.createElement(TaxedMoney, {
    taxedMoney: stop
  }));
};
export var canAddToCart = function canAddToCart(items, isAvailableForPurchase, variantId, variantStock, quantity) {
  var cartItem = items === null || items === void 0 ? void 0 : items.find(function (item) {
    return item.variant.id === variantId;
  });
  var syncedQuantityWithCart = cartItem ? quantity + ((cartItem === null || cartItem === void 0 ? void 0 : cartItem.quantity) || 0) : quantity;
  return isAvailableForPurchase && quantity > 0 && !!variantId && variantStock >= syncedQuantityWithCart;
};
/**
 * Returns how many items you can add to the cart. Takes in account quantity already in cart.
 */

export var getAvailableQuantity = function getAvailableQuantity(items, variantId, variantStock) {
  var cartItem = items === null || items === void 0 ? void 0 : items.find(function (item) {
    return item.variant.id === variantId;
  });
  var quantityInCart = (cartItem === null || cartItem === void 0 ? void 0 : cartItem.quantity) || 0;
  return variantStock - quantityInCart;
};