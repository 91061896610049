import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  display: block;\n  font-size: ", ";\n\n  border-width: 3px;\n  border-style: solid;\n  border-color : ", "\n  cursor: pointer;\n  -webkit-tap-highlight-color: transparent;\n\n  padding: 0.5rem;\n\n  border-radius: 10px;\n\n\n  @media (min-width: ", ") {\n    padding: 1rem;\n  }\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Label = styled.label(_templateObject(), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.checked ? "#21125E" : props.theme.colors.grayBorder;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Input = styled.input(_templateObject2());