import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useIntl } from "react-intl";
import { StringParam, useQueryParam } from "use-query-params";
import { REVIEWS_PER_PAGE } from "@temp/core/config";
import { TypedProductReviewsQuery, TypedReviewProductQuery } from "../queries";
import Page from "./Page";
import { reviewHeaderCommonMsg } from "@temp/intl";
import { convertSortByFromString } from "@temp/core/utils";
export var FilterQuerySet = {
  encode: function encode(valueArr) {
    return valueArr.join("_");
  },
  decode: function decode(strValue) {
    return strValue.split("_");
  }
};

var View = function View(_ref) {
  var match = _ref.match;
  var intl = useIntl();

  var _useQueryParam = useQueryParam("sortBy", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      sort = _useQueryParam2[0],
      setSort = _useQueryParam2[1];

  var _useQueryParam3 = useQueryParam("starsFilter", FilterQuerySet),
      _useQueryParam4 = _slicedToArray(_useQueryParam3, 2),
      starsFilter = _useQueryParam4[0],
      setStarsFilter = _useQueryParam4[1];

  var onFilterChange = function onFilterChange(value) {
    if (value < 1 || value > 5) return;

    if (starsFilter && starsFilter.length > 0) {
      if (starsFilter.includes(value)) setStarsFilter(starsFilter.filter(function (x) {
        return x !== value;
      }));else setStarsFilter([].concat(_toConsumableArray(starsFilter), [value]));
    } else setStarsFilter([value]);
  };

  var filters = {
    perPage: REVIEWS_PER_PAGE,
    ratingGte: null,
    ratingLte: null,
    sortBy: sort || null
  };
  var sortOptions = [{
    label: intl.formatMessage(reviewHeaderCommonMsg.sortOptionsClear),
    value: null
  }, {
    label: intl.formatMessage(reviewHeaderCommonMsg.sortOptionsCreatedDsc),
    value: "-created"
  }, {
    label: intl.formatMessage(reviewHeaderCommonMsg.sortOptionsCreated),
    value: "created"
  }, {
    label: intl.formatMessage(reviewHeaderCommonMsg.sortOptionsRatingDsc),
    value: "-rating"
  }, {
    label: intl.formatMessage(reviewHeaderCommonMsg.sortOptionsRating),
    value: "rating"
  }];
  return React.createElement(TypedReviewProductQuery, {
    variables: {
      slug: match.params.slug
    } // fetchPolicy="cache-and-network"

  }, function (product) {
    return React.createElement(TypedProductReviewsQuery, {
      variables: {
        slug: match.params.slug,
        perPage: filters.perPage,
        sortBy: convertSortByFromString(filters.sortBy),
        values: starsFilter || []
      } // fetchPolicy="cache-and-network"

    }, function (reviews) {
      var _reviews$data, _reviews$data$product, _product$data, _reviews$data2, _reviews$data2$produc, _reviews$data3, _reviews$data3$produc;

      return React.createElement(Page, _extends({
        hasNextPage: (_reviews$data = reviews.data) === null || _reviews$data === void 0 ? void 0 : (_reviews$data$product = _reviews$data.product.reviews) === null || _reviews$data$product === void 0 ? void 0 : _reviews$data$product.pageInfo.hasNextPage
      }, {
        sortOptions: sortOptions
      }, {
        onSort: function onSort(value) {
          return setSort(value.value);
        },
        onFilter: function onFilter(value) {
          return onFilterChange(value);
        },
        activeFilters: starsFilter,
        activeSortOption: filters.sortBy,
        product: (_product$data = product.data) === null || _product$data === void 0 ? void 0 : _product$data.product,
        reviews: (_reviews$data2 = reviews.data) === null || _reviews$data2 === void 0 ? void 0 : (_reviews$data2$produc = _reviews$data2.product.reviews) === null || _reviews$data2$produc === void 0 ? void 0 : _reviews$data2$produc.edges.map(function (e) {
          return e.node;
        }),
        foundComments: reviews === null || reviews === void 0 ? void 0 : (_reviews$data3 = reviews.data) === null || _reviews$data3 === void 0 ? void 0 : (_reviews$data3$produc = _reviews$data3.product.reviews) === null || _reviews$data3$produc === void 0 ? void 0 : _reviews$data3$produc.totalCount
      }));
    });
  });
};

export default View;