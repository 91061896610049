import { ChipPush, DropdownSelect, Stars } from "@components/atoms";
import React from "react";
import * as S from "./Styles";
export var ReviewListHeader = function ReviewListHeader(_ref) {
  var onSort = _ref.onSort,
      handleClick = _ref.handleClick,
      sortOptions = _ref.sortOptions,
      activeSortOption = _ref.activeSortOption,
      activeFilters = _ref.activeFilters;

  var pressed = function pressed(x) {
    return activeFilters ? activeFilters.includes(x) : false;
  };

  return React.createElement(S.Wrapper, null, React.createElement(S.Text, null, "Filtrer par note: "), React.createElement(S.Bar, null, React.createElement(S.Stars, null, ["1", "2", "3", "4", "5"].map(function (x) {
    return React.createElement(ChipPush, {
      size: "md",
      pressed: pressed(x),
      onClick: function onClick() {
        return handleClick(x);
      }
    }, React.createElement(Stars, {
      rating: x,
      key: x,
      size: "sm"
    }));
  })), React.createElement(S.Sort, null, React.createElement(DropdownSelect, {
    onChange: onSort,
    options: sortOptions,
    value: sortOptions.find(function (option) {
      return option.value === activeSortOption;
    })
  }))));
};
ReviewListHeader.displayName = "ReviewListHeader";
export default ReviewListHeader;