import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  border: 1px solid gray;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  box-shadow: inset 0px 0px 0px 3px\n    ", ";\n  border-left: 1px solid ", ";\n  border-right: 1px solid ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n\n  border: none;\n  transition: background-color 0.3s;\n  outline: none;\n  cursor: pointer;\n  color: ", ";\n\n  border-radius: 5px;\n  padding: ", "\n\n  width: 100%\n\n  padding: 0.5rem;\n  font-size: 0.875rem;\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n    box-shadow: -3px 3px 14px 0px rgba(129, 67, 67, 0.2);\n  }\n\n  &:disabled {\n    background-color: ", ";\n\n    &,\n    &:hover {\n      cursor: default;\n    }\n  }\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
var padding = {
  md: "0.5rem 1rem",
  sm: "0.5rem"
};
export var Primary = styled.button(_templateObject(), function (props) {
  return props.theme.button.colors[props.color].background;
}, function (props) {
  return props.theme.button.colors[props.color].color;
}, function (props) {
  return padding[props.size];
}, function (props) {
  return props.theme.button.colors[props.color].hoverBackground;
}, function (props) {
  return props.theme.button.colors[props.color].hoverColor;
}, function (props) {
  return props.theme.button.colors[props.color].activeBackground;
}, function (props) {
  return props.theme.colors.disabled;
});
export var Secondary = styled(Primary)(_templateObject2(), function (props) {
  return props.theme.button.colors.secondary.color;
}, function (props) {
  return props.theme.button.colors.secondary.color;
}, function (props) {
  return props.theme.button.colors.secondary.color;
});
export var Outline = styled(Primary)(_templateObject3());