import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";
import SvgGreenTick from "images/SvgGreenTick";

var generateDot = function generateDot(stepIndex, currentActiveStep) {
  if (stepIndex < currentActiveStep) {
    // done dot
    return React.createElement(S.Dot, {
      done: true
    }, React.createElement(SvgGreenTick, null));
  }

  if (stepIndex === currentActiveStep) {
    // active dot
    return React.createElement(S.ActiveDot, null, React.createElement(S.Dot, {
      done: true
    }, React.createElement("span", null, stepIndex + 1)));
  }

  if (stepIndex > currentActiveStep) {
    // inactive dot
    return React.createElement(S.Dot, null, React.createElement("span", null, stepIndex + 1));
  }
};

var generateLabel = function generateLabel(stepIndex, name, numberOfSteps) {
  if (stepIndex === 0) {
    return React.createElement(S.LeftLabel, null, name);
  }

  if (stepIndex === numberOfSteps - 1) {
    return React.createElement(S.RightLabel, null, name);
  }

  return React.createElement(S.Label, null, name);
};

var generateProgressBar = function generateProgressBar(index, currentActive, numberOfSteps) {
  if (index !== numberOfSteps - 1) {
    return React.createElement(S.ProgressBar, {
      done: currentActive > index
    });
  }
};

var generateSteps = function generateSteps(steps, currentActive, intl) {
  return steps === null || steps === void 0 ? void 0 : steps.map(function (step, index) {
    var name = step.name;
    /* eslint-disable default-case */

    switch (step.name) {
      case "Address":
        name = intl.formatMessage(checkoutMessages.stepNameAddress);
        break;

      case "Shipping":
        name = intl.formatMessage(checkoutMessages.stepNameShipping);
        break;

      case "Payment":
        name = intl.formatMessage(checkoutMessages.stepNamePayment);
        break;

      case "Review":
        name = intl.formatMessage(checkoutMessages.stepNameReview);
        break;
    }

    return React.createElement(S.Step, {
      key: step.index
    }, React.createElement(Link, {
      to: step.link
    }, generateDot(index, currentActive), generateLabel(index, name, steps.length)), generateProgressBar(index, currentActive, steps.length));
  });
};
/**
 * Progress bar showing current step of checkout process.
 */


var CheckoutProgressBar = function CheckoutProgressBar(_ref) {
  var steps = _ref.steps,
      activeStep = _ref.activeStep;
  var intl = useIntl();
  return React.createElement(S.Wrapper, null, generateSteps(steps, activeStep, intl));
};

export { CheckoutProgressBar };