import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import Overlay from "@temp/components/Overlay/Overlay";
import React, { useState } from "react";
import { Online } from "../..";
import ReactSVG from "react-svg";
import closeImg from "../../../images/x.svg";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import Stars from "./Stars";
import { Formik, Form } from "formik";
import { TypedReviewCreationMutation } from "./queries";
import { useAlert } from "react-alert";
import { useIntl } from "react-intl";
import { maybe } from "../../../core/utils";
import { useAuth } from "@lastmaj/saleor-sdk";
import { Button } from "@components/atoms";

var showSuccessNotification = function showSuccessNotification(data, hide, alert, intl) {
  var successful = maybe(function () {
    return !data.reviewCreate.errors.length;
  });

  if (successful) {
    hide();
    alert.show({
      title: intl.formatMessage({
        defaultMessage: "Merci pour votre évaluation 🙏🏽",
        "id": "components.OverlayManager.Review.Review.3991843411"
      })
    }, {
      type: "success",
      timeout: 5000
    });
  }
};

var Review = function Review(_ref) {
  var overlay = _ref.overlay;
  var alert = useAlert();
  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      rating = _useState2[0],
      setRating = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      hoverRating = _useState4[0],
      setHoverRating = _useState4[1];

  var handleStarClick = function handleStarClick(index) {
    setRating(index);
  };

  var handleStarEnter = function handleStarEnter(index) {
    setHoverRating(index);
  };

  var handleStarLeave = function handleStarLeave() {
    setHoverRating(0);
  };

  return React.createElement(Overlay, {
    testingContext: "goToReviewButton",
    context: overlay
  }, React.createElement(Online, null, React.createElement("div", {
    className: "review"
  }, React.createElement("div", {
    className: "overlay__header"
  }, React.createElement("div", {
    className: "overlay__header-text"
  }, React.createElement(FormattedMessage, commonMessages.review)), React.createElement(ReactSVG, {
    path: closeImg,
    onClick: overlay.hide,
    className: "overlay__header__close-icon"
  })), React.createElement("div", {
    className: "overlay__body"
  }, React.createElement(TypedReviewCreationMutation, {
    onCompleted: function onCompleted(data) {
      return showSuccessNotification(data, overlay.hide, alert, intl);
    }
  }, function (createReview, _ref2) {
    var loading = _ref2.loading,
        data = _ref2.data;
    return user && React.createElement(Formik, {
      initialValues: {
        comment: "",
        rating: 0
      },
      onSubmit: function onSubmit(values, _ref3) {
        var setSubmitting = _ref3.setSubmitting;
        createReview({
          variables: {
            orderline: overlay.context.data,
            rating: rating,
            userName: user.firstName,
            comment: values.comment
          }
        });
        setSubmitting(false);
      }
    }, function (_ref4) {
      var isSubmitting = _ref4.isSubmitting,
          values = _ref4.values,
          handleChange = _ref4.handleChange;
      return React.createElement(Form, null, React.createElement("div", {
        className: "overlay__body__stars-container"
      }, React.createElement("label", {
        htmlFor: "stars"
      }, React.createElement(FormattedMessage, {
        id: "components.OverlayManager.Review.Review.3393192285",
        defaultMessage: "Votre note :"
      })), React.createElement("div", {
        className: "overlay__body__stars"
      }, React.createElement(Stars, {
        rating: rating,
        hoverRating: hoverRating,
        onStarClick: handleStarClick,
        onStarEnter: handleStarEnter,
        onStarLeave: handleStarLeave,
        value: values.rating,
        onChange: handleChange
      }))), React.createElement("div", {
        className: "overlay__body__comment"
      }, React.createElement("label", {
        htmlFor: "comment"
      }, React.createElement(FormattedMessage, {
        id: "components.OverlayManager.Review.Review.4076795182",
        defaultMessage: "Votre commentaire (optionnel) :"
      })), React.createElement("textarea", {
        name: "comment",
        id: "comment",
        maxLength: 500,
        value: values.comment,
        onChange: handleChange
      })), React.createElement(Button, {
        type: "submit"
      }, "Confirmer"));
    });
  })))));
};

export default Review;