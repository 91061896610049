import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgChevronVertical = function SvgChevronVertical(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 1000 1000",
    xmlSpace: "preserve"
  }, props), React.createElement("path", {
    d: "M978 715.3 531.1 225.9c-8.6-8.6-19.9-12.3-31.1-11.4-11.2-.8-22.5 2.9-31 11.4L22 715.3c-16 16.3-16 42.1 0 58.4 16.1 15.9 42 15.9 58 0l420-459.9 420 459.9c16 15.9 41.9 15.9 58 0 16-16.4 16-42.1 0-58.4"
  }));
};

export default SvgChevronVertical;