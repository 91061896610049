import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Media from "react-responsive";
import { Icon, IconButton } from "@components/atoms";
import { CachedImage, InputSelect } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
import { largeScreen } from "@styles/constants";

var QuantityButtons = function QuantityButtons(add, subtract, index) {
  return React.createElement(S.QuantityButtons, {
    "data-test": "quantityControls"
  }, React.createElement("div", {
    onClick: subtract,
    "data-test": "subtractButton"
  }, React.createElement(Icon, {
    size: 16,
    name: "horizontal_line"
  })), React.createElement("div", {
    onClick: add,
    "data-test": "increaseButton"
  }, React.createElement(Icon, {
    size: 16,
    name: "plus"
  })));
};
/**
 * Product row displayed on cart page
 */


export var CartRow = function CartRow(_ref) {
  var index = _ref.index,
      totalPrice = _ref.totalPrice,
      unitPrice = _ref.unitPrice,
      name = _ref.name,
      sku = _ref.sku,
      quantity = _ref.quantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      thumbnail = _ref.thumbnail,
      _ref$attributes = _ref.attributes,
      attributes = _ref$attributes === void 0 ? [] : _ref$attributes,
      onRemove = _ref.onRemove,
      id = _ref.id,
      slug = _ref.slug;

  var _useState = useState(quantity),
      _useState2 = _slicedToArray(_useState, 2),
      tempQuantity = _useState2[0],
      setTempQuantity = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isTooMuch = _useState4[0],
      setIsTooMuch = _useState4[1];

  var intl = useIntl();

  var handleBlurQuantityInput = function handleBlurQuantityInput() {
    var newQuantity = tempQuantity;

    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = quantity;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    var newTempQuantity = newQuantity;

    if (tempQuantity !== newTempQuantity) {
      setTempQuantity(newTempQuantity);
    }

    setIsTooMuch(false);
  };

  useEffect(function () {
    setTempQuantity(quantity);
  }, [quantity]);

  var handleQuantityChange = function handleQuantityChange(option) {
    var newQuantity = option.value;
    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);

    if (quantity !== newQuantity) {
      setTempQuantity(newQuantity);
      onQuantityChange(newQuantity);
    }
  };

  var quantityErrors = isTooMuch ? [{
    message: intl.formatMessage(commonMessages.maxQtyIs, {
      maxQuantity: maxQuantity
    })
  }] : undefined;
  var productUrl = "/product/".concat(slug, "/");
  var options = Array.from(Array(maxQuantity), function (e, i) {
    return {
      label: "".concat(i + 1),
      value: i + 1
    };
  });
  return React.createElement(S.Wrapper, {
    "data-test": "cartRow",
    "data-test-id": sku
  }, React.createElement(S.Photo, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(CachedImage, _extends({
    "data-test": "itemImage"
  }, thumbnail)))), React.createElement(S.Description, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(S.Name, {
    "data-test": "itemName"
  }, name)), React.createElement(S.Attributes, {
    "data-test": "itemAttributes"
  }, attributes.map(function (_ref2, attributeIndex) {
    var attribute = _ref2.attribute,
        values = _ref2.values;
    return React.createElement(S.SingleAttribute, {
      key: attribute.id
    }, React.createElement("span", {
      "data-test": "itemSingleAttribute",
      "data-test-id": attributeIndex
    }, React.createElement(S.LightFont, null, attribute.name, ":"), " ", values.map(function (value) {
      return value.name;
    }).join(", ")));
  })), React.createElement(S.UnitPrice, null, React.createElement(S.PriceLabel, null, React.createElement(S.LightFont, null, React.createElement(FormattedMessage, commonMessages.priceUnit), ":")), React.createElement("span", {
    "data-test": "unitPrice"
  }, " ", unitPrice))), React.createElement(S.Trash, {
    onClick: onRemove
  }, React.createElement(IconButton, {
    testingContext: "removeButton",
    testingContextId: sku,
    size: 16,
    name: "trash"
  }), React.createElement(Media, {
    minWidth: largeScreen
  }, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.delete)))), React.createElement(S.Quantity, null, React.createElement(S.QuantityContainer, null, React.createElement(InputSelect, {
    name: "quantity",
    label: intl.formatMessage(commonMessages.qty),
    options: options,
    value: options.find(function (o) {
      return o.value === tempQuantity;
    }),
    errors: quantityErrors,
    onChange: handleQuantityChange,
    isSearchable: false
  }))), React.createElement(S.TotalPrice, null, React.createElement(S.PriceLabel, null, React.createElement(FormattedMessage, commonMessages.totalPrice), ":"), " ", totalPrice));
};