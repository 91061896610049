import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgChevronHorizontal = function SvgChevronHorizontal(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg" // width={1706.667}
    // height={1706.667}
    ,
    viewBox: "0 0 1280 1280"
  }, props), React.createElement("path", {
    d: "M316 13.6c-3.6.7-9.6 2.9-13.5 4.8-31.1 15.4-38.1 58.2-13.7 83.5 3.1 3.3 136.7 125.6 296.7 271.7 160.1 146.1 291 266 291 266.4 0 .4-130.9 120.3-291 266.4-160 146.1-293.6 268.4-296.7 271.7-20.2 20.9-19.5 54.6 1.4 74.9 15.7 15.2 39.8 18.8 60.3 9 7.7-3.7 21.4-16.1 326.1-294.3 226.8-207.1 318.9-291.9 321.3-295.5 6.3-9.9 7.5-15 7.6-31.7 0-13.4-.3-15.7-2.4-21.5-1.3-3.6-3.8-8.7-5.5-11.3-2.1-3.3-103.4-96.5-321.1-295.4C371.1 33.4 358.2 21.7 350.5 18c-7.8-3.7-17.6-6.1-24.6-5.9-1.9 0-6.3.7-9.9 1.5z"
  }));
};

export default SvgChevronHorizontal;