import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgGarbage = function SvgGarbage(props) {
  return React.createElement("svg", _extends({
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 10 12"
  }, props), React.createElement("path", {
    d: "M9.524 2.202h-2.22V1.84c0-.647-.526-1.173-1.172-1.173h-2.11c-.647 0-1.173.526-1.173 1.173v.362H.629a.295.295 0 0 0-.296.296c0 .164.132.296.296.296h.535V9.75c0 .873.711 1.583 1.584 1.583h4.658c.872 0 1.583-.71 1.583-1.583V2.794h.535a.295.295 0 0 0 .296-.296.295.295 0 0 0-.296-.296ZM3.441 1.84c0-.32.26-.581.58-.581h2.11c.32 0 .582.26.582.58v.363H3.44V1.84Zm4.956 7.91a.993.993 0 0 1-.991.991H2.748a.993.993 0 0 1-.991-.991V2.794h6.642V9.75h-.002Z",
    fill: "#000"
  }), React.createElement("path", {
    d: "M5.077 9.68a.295.295 0 0 0 .296-.296V4.152a.295.295 0 0 0-.296-.297.295.295 0 0 0-.296.297v5.23c0 .164.131.298.296.298ZM3.145 9.353a.295.295 0 0 0 .296-.296v-4.58a.295.295 0 0 0-.296-.297.295.295 0 0 0-.296.296v4.581c0 .165.133.296.296.296ZM7.009 9.353a.295.295 0 0 0 .296-.296v-4.58a.295.295 0 0 0-.296-.297.295.295 0 0 0-.296.296v4.581c0 .165.131.296.296.296Z",
    fill: "#000"
  }));
};

export default SvgGarbage;