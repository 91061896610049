import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import SvgScrollToTop from "images/SvgScrollToTop";
import * as S from "./styles";
import { useLocation } from "react-router";
var excludedPaths = ["cart", "checkout", "page"];
export var ScrollToTopButton = function ScrollToTopButton() {
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      show = _React$useState2[0],
      setShow = _React$useState2[1];

  var location = useLocation();
  var isValidLocation = excludedPaths.reduce(function (p, c) {
    return p && !location.pathname.includes(c);
  }, true);
  React.useEffect(function () {
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 300) setShow(true);else setShow(false);
    });
  }, []);

  var scrollToTop = function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return React.createElement(React.Fragment, null, isValidLocation && show && React.createElement(S.Wrapper, null, React.createElement(SvgScrollToTop, {
    onClick: scrollToTop
  })));
};
ScrollToTopButton.displayName = "ScrollToTopButton";
export default ScrollToTopButton;