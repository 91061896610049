import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
export var SvgEasyReturn = function SvgEasyReturn(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 80.296 75.068"
  }, props), React.createElement("g", {
    "data-name": "Groupe 138",
    transform: "translate(0 -.442)"
  }, React.createElement("g", {
    transform: "translate(0 13.672)"
  }, React.createElement("circle", {
    "data-name": "Ellipse 19",
    cx: 3.5,
    cy: 3.5,
    r: 3.5,
    transform: "translate(20.296 16.77)"
  }), React.createElement("circle", {
    "data-name": "Ellipse 20",
    cx: 3.5,
    cy: 3.5,
    r: 3.5,
    transform: "translate(34.296 16.77)"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 83",
    d: "M30.919 42.943a12.039 12.039 0 0 0 12.025-12.024h-3.436a8.589 8.589 0 0 1-17.178 0h-3.435a12.039 12.039 0 0 0 12.024 12.024Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 84",
    d: "M30.92 61.839A30.92 30.92 0 1 0 0 30.92a30.92 30.92 0 0 0 30.92 30.919Zm0-58.4A27.484 27.484 0 1 1 3.436 30.92 27.516 27.516 0 0 1 30.92 3.436Z"
  })), React.createElement("ellipse", {
    "data-name": "Ellipse 22",
    cx: 19,
    cy: 18.5,
    rx: 19,
    ry: 18.5,
    transform: "translate(42.296 .442)",
    fill: "#f394b8"
  })));
};
export default SvgEasyReturn;