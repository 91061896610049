import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  height: ", ";\n  width: ", "%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  overflow: hidden;\n\n  div {\n    background: url(", ") no-repeat center;\n    height: ", ";\n    width: ", ";\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: ", ";\n  height: ", ";\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  div {\n    background: url(", ") no-repeat center;\n    height: ", ";\n    width: ", ";\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: ", ";\n  height: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import star from "@temp/images/star.svg";
import starFilled from "@temp/images/star_filled.svg";
var size = {
  sm: "1rem",
  md: "2rem"
};
export var Container = styled.div(_templateObject());
export var Wrapper = styled.div(_templateObject2(), function (props) {
  return size[props.size];
}, function (props) {
  return size[props.size];
});
export var Star = styled.div(_templateObject3(), function (props) {
  return size[props.size];
}, function (props) {
  return size[props.size];
}, star, function (props) {
  return size[props.size];
}, function (props) {
  return size[props.size];
});
export var FilledStar = styled.div(_templateObject4(), function (props) {
  return size[props.size];
}, function (props) {
  return props.fill;
}, starFilled, function (props) {
  return size[props.size];
}, function (props) {
  return size[props.size];
});