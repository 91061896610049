import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TaxedMoney } from "@components/containers";
import { commonMessages } from "@temp/intl";
import { CachedImage } from "../CachedImage";
import * as S from "./styles";

/**
 * Row with product to display in cart summary.
 */
var CartSummaryRow = function CartSummaryRow(_ref) {
  var index = _ref.index,
      sku = _ref.sku,
      name = _ref.name,
      variantName = _ref.variantName,
      price = _ref.price,
      quantity = _ref.quantity,
      thumbnail = _ref.thumbnail;
  return React.createElement(S.Wrapper, {
    "data-test": "cartSummary",
    "data-test-id": sku
  }, React.createElement(S.Name, {
    "data-test": "name"
  }, name), React.createElement(S.Sku, null, React.createElement("span", {
    "data-test": "sku"
  }, variantName)), React.createElement(S.Quantity, null, React.createElement(FormattedMessage, commonMessages.quantity), ": ", React.createElement("span", {
    "data-test": "quantity"
  }, quantity)), React.createElement(S.Price, {
    "data-test": "price"
  }, React.createElement(TaxedMoney, {
    taxedMoney: price
  })), React.createElement(S.Photo, null, React.createElement(CachedImage, _extends({
    "data-test": "image"
  }, thumbnail))));
};

export { CartSummaryRow };