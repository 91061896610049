import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n  font-weight: 400;\n  color: #3f3f3f;\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: #ecb801;\n  height: 100%;\n  border-radius: 7px;\n  width: ", "%;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  margin: auto 1rem;\n  height: 0.5rem;\n  width: 10rem;\n  background: #e6e6e6;\n  position: relative;\n  border-radius: 7px;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  width: 0.5rem;\n  font-weight: 700;\n  margin-right: 0.5rem;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin: 0.5rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  @media (min-width: ", ") {\n    width: 50%;\n\n    > div {\n      margin-left: 5rem;\n    }\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  color: #ecb801;\n  font-size: 1rem;\n\n  @media (min-width: ", ") {\n    font-size: 2rem;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 1.5rem;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin-right: auto;\n  justify-content: space-around;\n  align-items: center;\n  flex-direction: column;\n\n  @media (min-width: ", ") {\n    margin-left: auto;\n    margin-right: auto;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  height: 100px;\n  width: auto;\n  border-radius: 10%;\n  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);\n  -webkit-box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 75%);\n  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);\n  margin-right: auto;\n\n  @media (min-width: ", ") {\n    height: 170px;\n    margin-right: unset;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin: 1rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  margin-bottom: 1rem;\n\n  @media (min-width: ", ") {\n    width: 50%;\n    margin-bottom: unset;\n    justify-content: flex-start;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 2rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n  margin-bottom: 5rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { Thumbnail } from "..";
export var Wrapper = styled.div(_templateObject());
export var Name = styled.p(_templateObject2());
export var Inner = styled.div(_templateObject3(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Rating = styled.div(_templateObject4(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Ratings = styled.div(_templateObject5());
export var Thumb = styled(Thumbnail)(_templateObject6(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var RatingInner = styled.div(_templateObject7(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Title = styled.h4(_templateObject8());
export var RatingText = styled.p(_templateObject9(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var RatingTotal = styled.p(_templateObject10());
export var Summary = styled.div(_templateObject11(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var SummaryItem = styled.div(_templateObject12());
export var Stars = styled.div(_templateObject13());
export var Gauge = styled.div(_templateObject14());
export var paintedGauge = styled.div(_templateObject15(), function (props) {
  return props.percentage;
});
export var CountSpan = styled.span(_templateObject16());