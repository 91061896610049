import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  button {\n    width: 100%;\n    visibility: hidden;\n  }\n  ", ":hover & {\n    button {\n      visibility: unset;\n    }\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n  font-size: 0.75rem;\n\n  @media (min-width: ", ") {\n    width: 220px;\n    font-size: unset;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  text-decoration: line-through;\n  color: ", ";\n  font-size: 0.75rem;\n  font-weight: regular;\n  margin-right: 5px;\n  color: ", ";\n\n  @media (min-width: ", ") {\n    font-size: unset;\n    margin-right: 10px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: fit-content;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n\n  span {\n    font-size: 0.8rem;\n    color: gray;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  height: 2rem;\n  font-size: 0.8rem;\n  line-height: normal;\n  letter-spacing: -0.2px;\n  overflow: hidden;\n\n  @media (min-width: ", ") {\n    margin-bottom: ", ";\n    height: 2.2rem;\n    font-size: 0.875rem;\n  }\n\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding-bottom: 8px;\n  @media (min-width: ", ") {\n    padding: 10px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  img {\n    height: 55vw;\n    width: auto;\n  }\n\n  @media (min-width: ", ") {\n    width: 232px;\n    height: 347px;\n\n    img {\n      width: 232px;\n      height: 347px;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 36.6666666667vw;\n  margin: 0 10px 0 0;\n\n  @media (min-width: ", ") {\n    height: 480px;\n    width: 232px;\n  }\n\n  @media (min-width: ", ") {\n    margin: auto;\n\n    &:hover {\n      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
var customBreakpoint = "620px";
export var Wrapper = styled.div(_templateObject(), customBreakpoint, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Thumb = styled.div(_templateObject2(), customBreakpoint);
export var Content = styled.div(_templateObject3(), customBreakpoint);
export var Name = styled.h6(_templateObject4(), customBreakpoint, function (props) {
  return props.rating ? "2px" : "22px";
});
export var Rating = styled.div(_templateObject5());
export var StarsContainer = styled.div(_templateObject6());
export var Undiscounted = styled.span(_templateObject7(), function (props) {
  return props.theme.colors.gray;
}, function (props) {
  return props.theme.colors.redDiscount;
}, customBreakpoint);
export var Price = styled.p(_templateObject8(), customBreakpoint);
export var ButtonContainer = styled.div(_templateObject9(), Wrapper);