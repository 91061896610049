import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { authMessages, commonMessages, translateAuthMessages } from "@temp/intl";
import { Button } from "@components/atoms";
import { Notification, TextField } from "@components/molecules";
import { accountConfirmUrl } from "../../app/routes";
import { TypedAccountRegisterMutation } from "./mutations";
import { maybe } from "../../core/utils";
import SvgRedX from "images/SvgRedX";
import SvgGreenTick from "images/SvgGreenTick";

var showSuccessNotification = function showSuccessNotification(data, alert, intl) {
  var successful = maybe(function () {
    return !data.accountRegister.accountErrors.length;
  });

  if (successful) {
    alert.show({
      title: data.accountRegister.requiresConfirmation ? intl.formatMessage({
        defaultMessage: "Please check your e-mail for further instructions",
        "id": "components.Register.index.2622394113"
      }) : intl.formatMessage({
        defaultMessage: "Account successfully created",
        "id": "components.Register.index.1273821880"
      })
    }, {
      type: "success",
      timeout: 5000
    });
  }
};

var RegisterForm = function RegisterForm() {
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      loading = _React$useState2[0],
      setLoading = _React$useState2[1];

  var _React$useState3 = React.useState(null),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      errors = _React$useState4[0],
      setErrors = _React$useState4[1];

  var _useForm = useForm(),
      handleSubmit = _useForm.handleSubmit,
      control = _useForm.control;

  var intl = useIntl();
  var alert = useAlert();
  return React.createElement(TypedAccountRegisterMutation, {
    onCompleted: function onCompleted(data) {
      if (data.accountRegister.accountErrors.length) setErrors(data.accountRegister.accountErrors);
    }
  }, function (registerCustomer, _ref) {
    var _data$accountRegister, _data$accountRegister2;

    var loading = _ref.loading,
        data = _ref.data;

    if ((data === null || data === void 0 ? void 0 : (_data$accountRegister = data.accountRegister) === null || _data$accountRegister === void 0 ? void 0 : (_data$accountRegister2 = _data$accountRegister.accountErrors) === null || _data$accountRegister2 === void 0 ? void 0 : _data$accountRegister2.length) === 0) {
      return React.createElement(Notification, {
        Svg: SvgGreenTick,
        title: "".concat(authMessages.registerSuccess.title, ", ").concat(data.accountRegister.user.firstName, "!"),
        expl: authMessages.registerSuccess.defaultMessage,
        color: "green"
      });
    }

    return React.createElement("form", {
      onSubmit: handleSubmit(function (formData) {
        var redirectUrl = "".concat(window.location.origin).concat(accountConfirmUrl);
        registerCustomer({
          variables: _objectSpread({}, formData, {
            redirectUrl: redirectUrl
          })
        });
      })
    }, errors && React.createElement(Notification, {
      Svg: SvgRedX,
      title: translateAuthMessages(errors[0].message, intl).title,
      expl: translateAuthMessages(errors[0].message, intl).expl,
      color: "red"
    }), React.createElement(Controller, {
      control: control,
      name: "email",
      defaultValue: "",
      rules: {
        required: {
          value: true,
          message: "Ce champ est obligatoire"
        }
      },
      render: function render(_ref2) {
        var _ref2$field = _ref2.field,
            value = _ref2$field.value,
            onChange = _ref2$field.onChange,
            error = _ref2.fieldState.error;
        return React.createElement(TextField, {
          name: "email",
          onChange: onChange,
          value: value,
          autoComplete: "email",
          label: intl.formatMessage(commonMessages.eMail),
          type: "email",
          errors: error && [error]
        });
      }
    }), React.createElement(Controller, {
      control: control,
      name: "firstName",
      rules: {
        required: {
          value: true,
          message: "Ce champ est obligatoire"
        }
      },
      defaultValue: "",
      render: function render(_ref3) {
        var _ref3$field = _ref3.field,
            value = _ref3$field.value,
            onChange = _ref3$field.onChange,
            error = _ref3.fieldState.error;
        return React.createElement(TextField, {
          name: "firstName",
          onChange: onChange,
          autoComplete: "first-name",
          value: value,
          label: intl.formatMessage(commonMessages.firstName),
          errors: error && [error]
        });
      }
    }), React.createElement(Controller, {
      control: control,
      name: "lastName",
      rules: {
        required: {
          value: true,
          message: "Ce champ est obligatoire"
        }
      },
      defaultValue: "",
      render: function render(_ref4) {
        var _ref4$field = _ref4.field,
            value = _ref4$field.value,
            onChange = _ref4$field.onChange,
            error = _ref4.fieldState.error;
        return React.createElement(TextField, {
          name: "lastName",
          autoComplete: "family-name",
          onChange: onChange,
          value: value,
          label: intl.formatMessage(commonMessages.lastName),
          errors: error && [error]
        });
      }
    }), React.createElement(Controller, {
      control: control,
      name: "password",
      defaultValue: "",
      rules: {
        required: {
          value: true,
          message: "Ce champ est obligatoire"
        },
        minLength: {
          value: 8,
          message: "Utilisez au moins 8 charactères"
        }
      },
      render: function render(_ref5) {
        var _ref5$field = _ref5.field,
            value = _ref5$field.value,
            onChange = _ref5$field.onChange,
            error = _ref5.fieldState.error;
        return React.createElement(TextField, {
          name: "password",
          value: value,
          onChange: onChange,
          autoComplete: "password",
          label: intl.formatMessage(commonMessages.password),
          type: "password",
          errors: error && [error]
        });
      }
    }), React.createElement("div", null, React.createElement(Button, _extends({
      testingContext: "submit",
      type: "submit"
    }, loading && {
      disabled: true
    }), React.createElement(FormattedMessage, commonMessages.registerForm))));
  });
};

export default RegisterForm;