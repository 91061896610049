import { defineMessages } from "react-intl";
export var formatDateTime = function formatDateTime(date, intl) {
  var formattedDate = intl.formatDate(date, {
    day: "numeric",
    weekday: "long",
    year: "numeric",
    month: "long"
  }).replaceAll(",", "");
  var formattedTime = intl.formatTime(date, {
    hour12: false
  });
  return "".concat(formattedDate, ", ").concat(formattedTime);
};
export var formatDeliveryDate = function formatDeliveryDate(date, intl) {
  var formattedDate = intl.formatDate(date, {
    day: "numeric",
    weekday: "long",
    month: "long"
  }).replaceAll(",", "");
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};
var paymentMethodNames = defineMessages({
  atDelivery: {
    "id": "misc.atDelivery",
    defaultMessage: "Paiement à la livraison",
    description: "payment method name"
  },
  online: {
    "id": "misc.online",
    defaultMessage: "Paiement par carte bancaire",
    description: "payment method name"
  }
});
export var transformPaymentMethodName = function transformPaymentMethodName(name, intl) {
  switch (name) {
    case "wonder.payments.delivery":
      {
        return {
          localized: intl.formatMessage(paymentMethodNames.atDelivery)
        };
      }

    case "wonder.payments.smt":
      {
        return {
          localized: intl.formatMessage(paymentMethodNames.online)
        };
      }
  }
};
export var transformFormHelperText = function transformFormHelperText(errorCode) {
  switch (errorCode) {
    case "required":
      return "This field is required";

    default:
      return "Invalid input";
  }
};
export var transformPhoneNumber = function transformPhoneNumber(phone) {
  return phone.substring(0, 2) + " " + phone.substring(2, 5) + " " + phone.substring(5) || "";
};