import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useInView } from "react-intersection-observer";
import ReactImageMagnify from "react-image-magnify";
import { Icon } from "@components/atoms";
import { CachedImage } from "@components/molecules";
import * as S from "./styles";
var MINIMAL_NUMBER_OF_IMAGES_FOR_BUTTONS = 4;
export var ProductGallery = function ProductGallery(_ref) {
  var images = _ref.images,
      thumbnails = _ref.thumbnails,
      hoveredGroupImage = _ref.hoveredGroupImage,
      rimProps = _ref.rimProps;

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      imageIndex = _React$useState2[0],
      setImageIndex = _React$useState2[1];

  var displayButtons = images.length > MINIMAL_NUMBER_OF_IMAGES_FOR_BUTTONS;
  React.useEffect(function () {
    if (imageIndex >= images.length) {
      setImageIndex(0);
    }
  }, [images]);
  var bottomImageRef = React.useRef(null);
  var topImageRef = React.useRef(null);

  var _useInView = useInView({
    threshold: 0.5
  }),
      _useInView2 = _slicedToArray(_useInView, 2),
      topImageIntersectionObserver = _useInView2[0],
      topImageInView = _useInView2[1];

  var _useInView3 = useInView({
    threshold: 0.5
  }),
      _useInView4 = _slicedToArray(_useInView3, 2),
      bottomImageIntersectionObserver = _useInView4[0],
      bottomImageInView = _useInView4[1];

  var setBottomRef = React.useCallback(function (node) {
    bottomImageRef.current = node;
    bottomImageIntersectionObserver(node);
  }, [bottomImageIntersectionObserver]);
  var setTopRef = React.useCallback(function (node) {
    topImageRef.current = node;
    topImageIntersectionObserver(node);
  }, [topImageIntersectionObserver]);

  var setIntersectionObserver = function setIntersectionObserver(index, lengthOfArray) {
    if (lengthOfArray > MINIMAL_NUMBER_OF_IMAGES_FOR_BUTTONS) {
      if (index === 0) {
        return setTopRef;
      }

      if (index === lengthOfArray - 1) {
        return setBottomRef;
      }
    }
  };

  return React.createElement(S.Wrapper, {
    "data-test": "productPhotosGallery"
  }, React.createElement(S.ThumbnailsContainer, null, !topImageInView && displayButtons && React.createElement(S.TopButton, {
    onClick: function onClick() {
      if (topImageRef.current) {
        topImageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest"
        });
      }
    }
  }, React.createElement(Icon, {
    name: "select_arrow",
    size: 10
  })), !bottomImageInView && displayButtons && React.createElement(S.BottomButton, {
    onClick: function onClick() {
      if (bottomImageRef.current) {
        bottomImageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest"
        });
      }
    }
  }, React.createElement(Icon, {
    name: "select_arrow",
    size: 10
  })), React.createElement(S.ThumbnailList, null, React.createElement("ul", null, thumbnails && thumbnails.length > 0 && thumbnails.map(function (image, index) {
    return React.createElement("li", {
      key: index,
      "data-test": "galleryThumbnail",
      "data-test-id": index
    }, React.createElement(S.Thumbnail, {
      onClick: function onClick() {
        return setImageIndex(index);
      },
      onMouseEnter: function onMouseEnter() {
        return setImageIndex(index);
      },
      activeThumbnail: Boolean(index === imageIndex),
      ref: setIntersectionObserver(index, images.length)
    }, React.createElement(CachedImage, {
      alt: image.alt,
      url: image.url
    })));
  })))), React.createElement(S.Preview, {
    "data-test": "imagePreview"
  }, images && images.length > 0 && imageIndex < images.length && (hoveredGroupImage === "" ? React.createElement(ReactImageMagnify, _extends({
    smallImage: {
      alt: images[imageIndex].alt,
      isFluidWidth: true,
      src: images[imageIndex].url
    },
    largeImage: {
      src: images[imageIndex].url,
      width: 1200,
      height: 1800
    },
    lensStyle: {
      backgroundColor: "rgba(0,0,0,.5)"
    }
  }, rimProps)) : React.createElement(ReactImageMagnify, _extends({
    smallImage: {
      alt: "autre couleur",
      isFluidWidth: true,
      src: hoveredGroupImage
    },
    largeImage: {
      src: "foo",
      width: 1200,
      height: 1800
    },
    lensStyle: {
      backgroundColor: "rgba(0,0,0,.5)"
    }
  }, rimProps))), images.length === 0 && React.createElement(CachedImage, null)));
};