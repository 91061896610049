import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";
import { Link } from "react-router-dom";
import { orderHistoryUrl } from "@temp/app/routes";
import { Notification } from "@components/molecules";
import SvgGreenTick from "images/SvgGreenTick";
/**
 * Thank you page after completing the checkout.
 */

var ThankYou = function ThankYou(_ref) {
  var orderNumber = _ref.orderNumber,
      orderDetails = _ref.orderDetails;
  return React.createElement(Container, {
    "data-test": "thankYouView"
  }, React.createElement(S.Wrapper, null, React.createElement(Notification, {
    title: "Commande prise avec succ\xE9s",
    color: "green",
    Svg: SvgGreenTick,
    expl: "Wonder vous remercie pour votre fid\xE9lit\xE9 \u2764\uFE0F. Vous pouvez suivre votre commande sur notre site. Vous serez aussi notifi\xE9 par email lorsque vos colis seront exp\xE9di\xE9s. "
  }), React.createElement(S.Buttons, null, React.createElement(Link, {
    to: "/"
  }, React.createElement(Button, {
    testingContext: "continueShoppingButton",
    color: "outline",
    fullWidth: true
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping))), React.createElement(Link, {
    to: orderHistoryUrl
  }, React.createElement(Button, {
    testingContext: "gotoOrderDetailsButton",
    fullWidth: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.283239519",
    defaultMessage: "D\xE9tails de la commande"
  }))))));
};

export { ThankYou };