import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  button {\n    height: 100%;\n    margin: 0;\n    padding: 0.5rem;\n    background-color: ", ";\n    border-radius: 10px;\n  }\n\n  > div {\n    width: ", ";\n    margin-bottom: 0;\n\n    ", "\n  }\n\n  svg {\n    width: 1rem;\n    height: 1rem;\n\n    path {\n      fill: black;\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.875rem;\n  font-weight: 600;\n  padding-bottom: 0.5rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Title = styled.h6(_templateObject2());
export var Form = styled.form(_templateObject3(), function (props) {
  return props.theme.colors.logoGreen;
}, function (props) {
  return props.isMobile ? "45%" : "35%";
}, function (props) {
  return !props.isMobile && "input {\n      padding: 0.75rem 0.5rem;\n    }\n      label {\n        font-size: 0.75rem;\n      }\n    ";
});