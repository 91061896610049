import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
export var SvgSecuredPayment = function SvgSecuredPayment(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 87.774 78.504"
  }, props), React.createElement("g", {
    "data-name": "Groupe 137",
    transform: "translate(0 -.442)"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 80",
    d: "M34.355 78.947s34.355-6.871 34.355-58.4l-34.355-10.31L0 20.544c0 54.968 34.355 58.403 34.355 58.403Zm0-65.123 30.89 9.267c-.989 42.846-26.915 51.292-31.051 52.364-4.147-.689-29.808-6.8-30.734-52.364Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 81",
    d: "m47.091 34.286-2.429-2.429L30.92 45.599l-6.871-6.871-2.429 2.429 9.3 9.3Z"
  }), React.createElement("ellipse", {
    "data-name": "Ellipse 23",
    cx: 19,
    cy: 18.5,
    rx: 19,
    ry: 18.5,
    transform: "translate(49.774 .442)",
    fill: "#9bd7f5"
  })));
};
export default SvgSecuredPayment;