import React from "react";
import { FormattedMessage } from "react-intl";
import { Stars } from "..";
import * as S from "./styles";
export var ProductRating = function ProductRating(_ref) {
  var rating = _ref.rating,
      totalRatings = _ref.totalRatings;
  return React.createElement(S.Wrapper, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.ProductRating.ProductRating.3846824194",
    defaultMessage: "Avis des clients"
  })), React.createElement(S.RatingText, null, "".concat(rating, " / 5")), React.createElement(Stars, {
    rating: rating,
    size: "md"
  }), React.createElement(S.RatingTotal, null, "".concat(totalRatings, " avis")));
};
ProductRating.displayName = "ProductRating";
export default ProductRating;