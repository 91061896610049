import React from "react";
import { FormattedMessage } from "react-intl";
import { useAuth, useCart } from "@lastmaj/saleor-sdk";
import { commonMessages } from "@temp/intl";
import { generatePath, Link } from "react-router-dom";
import { Online } from "@temp/components";
import cartImg from "@temp/images/cart.svg";
import xImg from "@temp/images/x.svg";
import { Button, Loader } from "@components/atoms";
import { Thumbnail } from "@components/molecules";
import { Money, TaxedMoney } from "@components/containers";
import { EmptyCart } from "..";
import { cartUrl, checkoutUrl, loginRedirectPath } from "@temp/app/routes";
import * as S from "./styles";

var CartRows = function CartRows(_ref) {
  var lines = _ref.lines,
      remove = _ref.remove;
  return lines.map(function (line, index) {
    var _line$variant$attribu;

    var productUrl = "/product/".concat(line.variant.product.slug, "/");
    var key = line.id ? "id-".concat(line.id) : "idx-".concat(index);
    return React.createElement(S.Row, {
      key: key,
      "data-test": "cartRow",
      "data-test-id": line.variant.sku
    }, React.createElement(Link, {
      to: productUrl
    }, React.createElement(Thumbnail, {
      source: line.variant.product
    })), React.createElement(S.Middle, null, React.createElement(Link, {
      to: productUrl
    }, React.createElement(S.Name, {
      "data-test": "name"
    }, line.variant.product.name)), React.createElement(S.Details, null, line.variant.attributes.length > 0 && React.createElement("p", null, (_line$variant$attribu = line.variant.attributes[0]) === null || _line$variant$attribu === void 0 ? void 0 : _line$variant$attribu.attribute.name, " :", " ", line.variant.name), React.createElement("p", null, "Quantit\xE9 : ", line.quantity)), React.createElement(S.Delete, {
      onClick: function onClick() {
        return remove(line.variant.id);
      }
    }, "Supprimer du panier")), React.createElement(S.Price, {
      "data-test": "price"
    }, React.createElement(TaxedMoney, {
      taxedMoney: line.variant.pricing.price
    })));
  });
};

export var Cart = function Cart(_ref2) {
  var overlay = _ref2.overlay,
      hide = _ref2.hide;

  var _useCart = useCart(),
      items = _useCart.items,
      removeItem = _useCart.removeItem,
      discount = _useCart.discount,
      subtotalPrice = _useCart.subtotalPrice;

  var _useAuth = useAuth(),
      authenticated = _useAuth.authenticated;

  var itemsQuantity = (items === null || items === void 0 ? void 0 : items.reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0)) || 0;

  var missingVariants = function missingVariants() {
    return items.find(function (item) {
      return !item.variant || !item.totalPrice;
    });
  }; // ASSUMPTION : DELIVERY FREE FROM 200TND


  var shippingPrice = subtotalPrice && subtotalPrice.gross.amount >= 200 ? 0 : 7;
  var totalPrice = subtotalPrice && subtotalPrice.gross.amount + shippingPrice;
  return React.createElement(Online, null, React.createElement(S.Cart, {
    overlay: overlay
  }, React.createElement(S.Header, null, React.createElement(S.CartIcon, {
    path: cartImg
  }), React.createElement(S.HeaderText, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.Cart.Cart.464484266",
    defaultMessage: "Panier"
  }), " ", React.createElement("span", {
    className: "overlay__header-text-items"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.Cart.Cart.3100571513",
    defaultMessage: " | {itemsQuantity,plural,one{{itemsQuantity} article} other{{itemsQuantity} articles}}",
    description: "items quantity in cart",
    values: {
      itemsQuantity: itemsQuantity
    }
  }))), overlay && React.createElement(S.CloseIcon, {
    path: xImg,
    onClick: hide
  })), !!items && itemsQuantity !== 0 ? React.createElement(React.Fragment, null, missingVariants() ? React.createElement(Loader, {
    fullScreen: true
  }) : React.createElement(React.Fragment, null, React.createElement(S.RowList, null, React.createElement(CartRows, {
    lines: items,
    remove: removeItem
  })), React.createElement(S.Footer, null, React.createElement(S.FooterPrice, null, React.createElement(S.FooterPriceRow, null, React.createElement("p", null, React.createElement(FormattedMessage, commonMessages.delivery)), React.createElement(Money, {
    "data-test": "shippingPrice",
    money: {
      amount: shippingPrice,
      currency: "TND"
    }
  })), React.createElement(S.FooterPriceRow, null, React.createElement("p", null, "Total TTC"), React.createElement(Money, {
    "data-test": "totalPrice",
    money: {
      amount: totalPrice,
      currency: "TND"
    }
  }))), React.createElement(S.Buttons, null, React.createElement(Link, {
    to: generatePath(cartUrl, {
      token: null
    })
  }, React.createElement(Button, null, "Mon panier")), React.createElement(Link, {
    to: authenticated ? checkoutUrl : loginRedirectPath(checkoutUrl)
  }, React.createElement(Button, {
    testingContext: "gotoCheckoutButton"
  }, "J'ach\xE8te!"))), overlay && React.createElement(S.ButtonContinue, null, React.createElement(Button, {
    onClick: hide,
    testingContext: "continueBrowsingButton"
  }, "Continuer mes achats"))))) : React.createElement(EmptyCart, null)));
};
Cart.displayName = "Cart";
export default Cart;