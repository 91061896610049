import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n  text-align: center;\n  height: 10rem;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n\n  @media (min-width: ", ") {\n    width: 50%;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  margin: auto;\n  width: 100%;\n  margin-top: 1rem;\n\n  @media (min-width: ", ") {\n    width: fit-content;\n  }\n\n  @media (min-width: ", ") {\n    margin-top: unset;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  height: fit-content;\n  width: 100%;\n  margin: auto;\n  padding: 1rem;\n  padding-top: 1.5rem;\n  position: sticky;\n  top: 10px;\n\n  border-radius: 5px;\n  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;\n\n  @media (min-width: ", ") {\n    position: sticky;\n    width: fit-content;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 50%;\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1rem;\n  width: fit-content;\n  margin: 0 auto 1rem;\n  position: relative;\n\n  &::before {\n    border-bottom: 1px solid #000;\n    content: \"\";\n    position: absolute;\n    bottom: 0;\n    right: 25%;\n    width: 50%;\n    margin: 0 auto;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    flex-direction: column;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 2rem;\n\n  position: relative;\n  padding-top: 2rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled, media } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Container = styled.div(_templateObject2(), media.largeScreen(_templateObject3()));
export var Title = styled.h3(_templateObject4());
export var Left = styled.div(_templateObject5(), media.largeScreen(_templateObject6()));
export var Card = styled.div(_templateObject7(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Rating = styled.div(_templateObject8());
export var Breakdown = styled.div(_templateObject9(), function (props) {
  return props.theme.breakpoints.xSmallScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Reviews = styled.div(_templateObject10(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var NoReviews = styled.p(_templateObject11());