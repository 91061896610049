import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 2rem 0;\n\n  @media (min-width: ", ") {\n    padding: 2rem 1rem;\n  } \n\n  @media (min-width: ", ") {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n  }\n\n  @media (min-width: ", ") {\n    width: 65%;\n  }\n\n  .carousel {\n    padding-bottom: 0.5rem;\n\n    > ul > li > div {\n      margin: auto;\n      padding-top: 0.1px;\n    }\n\n    .react-multiple-carousel__arrow--right {\n      right: 0;\n    }\n  \n    .react-multiple-carousel__arrow--left {\n      left: 0;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 2rem 0;\n  background-color: #202020;\n  color: #daedde;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  @media (min-width: ", ") {\n    width: 35%;\n    height: 100%;\n    padding: unset;\n  }\n\n  img {\n    display: inline-block;\n\n    @media (max-width: ", ") {\n      height: 2.5rem;\n    }\n  }\n\n  h1 {\n    font-size: 2.5rem;\n    margin: 0 0.8rem;\n    font-weight: 500;\n\n    @media (max-width: ", ") {\n      font-size: 1.5rem;\n      font-weight: 600;\n    }\n  }\n\n  span {\n    margin-top: 0.5rem;\n    font-size: 0.8rem;\n    font-weight: 300;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  flex-direction: column;\n  width: 100%;\n\n  display: flex;\n  align-items: center;\n  background-color: #daedde;\n  margin-bottom: 6rem;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    height: 20rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Banner = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.smallScreen;
}, function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Inner = styled.div(_templateObject3());
export var ProductsContainer = styled.div(_templateObject4(), function (props) {
  return props.theme.breakpoints.smallScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});