import { HomepageFlashSale } from "@components/organisms";
import { maybe } from "@utils/misc";
import React from "react";
import { TypedFlashSaleCollectionQuery, TypedFlashSaleEndDate } from "../queries";
export var FlashSale = function FlashSale() {
  return React.createElement(TypedFlashSaleEndDate, null, function (_ref) {
    var flashSaleEndData = _ref.data,
        error = _ref.error;
    var endDate = flashSaleEndData.flashSaleEndDate;
    if (error || !endDate || endDate === null) return React.createElement(React.Fragment, null);
    return React.createElement(TypedFlashSaleCollectionQuery, null, function (_ref2) {
      var data = _ref2.data,
          collectionError = _ref2.error;
      var products = maybe(function () {
        return data.collection.products.edges;
      }, []);
      if (collectionError || !data) return React.createElement(React.Fragment, null);

      if (products.length) {
        return React.createElement(HomepageFlashSale, {
          products: products.map(function (edge) {
            return edge.node;
          }),
          expiresAt: flashSaleEndData.flashSaleEndDate
        });
      }
    });
  });
};