import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-row-gap: 1rem;\n    width: 100%;\n    margin-top: 1rem;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 2rem;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: 30px;\n  button {\n    padding-left: 0;\n    padding-right: 0;\n  }\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 2rem auto;\n\n  max-width: 500px;\n  width: 95%;\n  font-size: 3rem;\n  padding-bottom: 2rem;\n  border-bottom: 1px solid\n    ", ";\n  margin-bottom: 40px;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, media.smallScreen(_templateObject2(), function (props) {
  return props.theme.typography.h1FontSize;
}));
export var ThankYouHeader = styled.p(_templateObject3());
export var Buttons = styled.div(_templateObject4(), media.smallScreen(_templateObject5()));