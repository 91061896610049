import React from "react";
import Media from "react-responsive";
import { FormattedMessage } from "react-intl";
import { Button, Chip, DropdownSelect } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
import { largeScreen } from "@styles/constants";
import { convertPriceFilterToChips } from "@temp/core/utils";
export var ProductListHeader = function ProductListHeader(_ref) {
  var _ref$numberOfProducts = _ref.numberOfProducts,
      numberOfProducts = _ref$numberOfProducts === void 0 ? 0 : _ref$numberOfProducts,
      activeSortOption = _ref.activeSortOption,
      _ref$activeFilters = _ref.activeFilters,
      activeFilters = _ref$activeFilters === void 0 ? 0 : _ref$activeFilters,
      _ref$activeFiltersAtt = _ref.activeFiltersAttributes,
      activeFiltersAttributes = _ref$activeFiltersAtt === void 0 ? [] : _ref$activeFiltersAtt,
      sortOptions = _ref.sortOptions,
      onChange = _ref.onChange,
      onCloseFilterAttribute = _ref.onCloseFilterAttribute,
      onShowFilters = _ref.onShowFilters,
      priceFilter = _ref.priceFilter,
      clearPriceFilter = _ref.clearPriceFilter,
      clearFilters = _ref.clearFilters;

  var canClearFilters = function canClearFilters() {
    return priceFilter || activeFiltersAttributes.length !== 0;
  };

  return React.createElement(Media, {
    maxWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(S.Wrapper, null, React.createElement(S.Products, null, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.ProductListHeader.ProductListHeader.969492747",
      defaultMessage: "{numberOfProducts,plural,one{{numberOfProducts} produit} other{{numberOfProducts} produits}}",
      description: "products count in product list",
      values: {
        numberOfProducts: numberOfProducts
      }
    })), React.createElement(S.Bar, null, React.createElement(S.Element, null, React.createElement(Button, {
      onClick: onShowFilters,
      size: "sm",
      color: "outline"
    }, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.ProductListHeader.ProductListHeader.2289615021",
      defaultMessage: "Filtrer"
    }))), React.createElement(S.Element, null, React.createElement(S.Sort, null, React.createElement(DropdownSelect, {
      onChange: onChange,
      options: sortOptions,
      value: sortOptions.find(function (option) {
        return option.value === activeSortOption;
      }),
      noErrors: true
    })))), React.createElement(S.FiltersChipsWrapper, null, activeFiltersAttributes.map(function (_ref2) {
      var attributeSlug = _ref2.attributeSlug,
          valueName = _ref2.valueName,
          valueSlug = _ref2.valueSlug;
      return React.createElement(Chip, {
        onClose: function onClose() {
          return onCloseFilterAttribute(attributeSlug, valueSlug);
        }
      }, valueName);
    }), priceFilter && React.createElement(Chip, {
      onClose: clearPriceFilter
    }, convertPriceFilterToChips(priceFilter)), canClearFilters() && React.createElement(S.ClearButton, {
      onClick: clearFilters,
      color: "outline"
    }, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.ProductListHeader.ProductListHeader.2287626915",
      defaultMessage: "Effacer les filtres"
    })))) : React.createElement(S.Wrapper, null, React.createElement(S.BarDesktop, null, React.createElement(S.ProductsDesktop, null, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.ProductListHeader.ProductListHeader.969492747",
      defaultMessage: "{numberOfProducts,plural,one{{numberOfProducts} produit} other{{numberOfProducts} produits}}",
      description: "products count in product list",
      values: {
        numberOfProducts: numberOfProducts
      }
    })), React.createElement(S.SortDesktop, null, React.createElement(DropdownSelect, {
      onChange: onChange,
      options: sortOptions,
      value: sortOptions.find(function (option) {
        return option.value === activeSortOption;
      }),
      noErrors: true
    }))), React.createElement(S.FiltersChipsWrapper, null, activeFiltersAttributes.map(function (_ref3) {
      var attributeSlug = _ref3.attributeSlug,
          valueName = _ref3.valueName,
          valueSlug = _ref3.valueSlug;
      return React.createElement(Chip, {
        onClose: function onClose() {
          return onCloseFilterAttribute(attributeSlug, valueSlug);
        }
      }, valueName);
    }), priceFilter && React.createElement(Chip, {
      onClose: clearPriceFilter
    }, convertPriceFilterToChips(priceFilter)), canClearFilters() && React.createElement(S.ClearButton, {
      onClick: clearFilters,
      color: "outline"
    }, React.createElement(FormattedMessage, commonMessages.clearFilterHeader))));
  });
};