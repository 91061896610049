import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { components } from "react-select";
import { ThemeContext } from "styled-components";
import { Icon, InputLabel, Select } from "@components/atoms";
import * as S from "./styles";
export var InputSelect = React.forwardRef(function (props, ref) {
  var label = props.label,
      inputProps = props.inputProps,
      errors = props.errors;
  var hasError = errors && errors.length > 0;
  var customTheme = React.useContext(ThemeContext);
  var secondaryColor = customTheme.colors.dark;
  var borderColor = customTheme.colors.grayBorder;
  var errorColor = customTheme.colors.error;
  var customStyles = {
    control: function control(provided, state) {
      return _objectSpread({}, provided, {
        ":hover": {
          border: state.menuIsOpen ? "1px solid ".concat(secondaryColor) : hasError ? "1px solid ".concat(errorColor) : "1px solid ".concat(borderColor)
        },
        border: state.menuIsOpen ? "1px solid ".concat(secondaryColor) : hasError ? "1px solid ".concat(errorColor) : "1px solid ".concat(borderColor),
        borderRadius: 15,
        boxShadow: 0,
        boxSizing: "border-box",
        outline: "",
        padding: "0.55rem 1rem",
        transition: "all 0.3s ease"
      });
    },
    valueContainer: function valueContainer(provided) {
      return _objectSpread({}, provided, {
        padding: 0
      });
    }
  };
  var customComponents = {
    Control: function Control(props) {
      var customTheme = React.useContext(ThemeContext);
      return React.createElement(React.Fragment, null, React.createElement(components.Control, _extends({
        "data-test": "inputSelect"
      }, _objectSpread({
        customTheme: customTheme
      }, props))), React.createElement(InputLabel, {
        labelBackground: "white",
        active: props.selectProps.menuIsOpen || props.hasValue,
        hasError: hasError
      }, label));
    },
    IndicatorSeparator: function IndicatorSeparator() {
      return null;
    },
    IndicatorsContainer: function IndicatorsContainer(_ref) {
      var selectProps = _ref.selectProps,
          hasValue = _ref.hasValue,
          clearValue = _ref.clearValue;
      var showClearIndicator = selectProps.isClearable || selectProps.isMulti && selectProps.isClearable === undefined;

      if (showClearIndicator && hasValue) {
        return React.createElement(S.ClearIndicator, {
          onClick: clearValue
        }, React.createElement(Icon, {
          name: "select_x",
          size: 10
        }));
      } // Boolean to string conversion done due to
      // https://github.com/styled-components/styled-components/issues/1198


      return React.createElement(S.DropdownIndicator, {
        rotate: String(selectProps.menuIsOpen)
      }, React.createElement(Icon, {
        name: "select_arrow",
        size: 10
      }));
    },
    Input: function Input(props) {
      return React.createElement(components.Input, _objectSpread({}, props, inputProps));
    },
    Option: function Option(props) {
      var customTheme = React.useContext(ThemeContext);
      return React.createElement(components.Option, _objectSpread({
        customTheme: customTheme
      }, props));
    }
  };
  return React.createElement(Select, _extends({
    customComponents: customComponents
  }, props, {
    ref: ref,
    customStyles: customStyles
  }));
});