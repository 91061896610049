import "./scss/index.scss";
import * as React from "react";
import { MetaWrapper } from "../../components";
import Page from "./Page";
import { TypedHomepageCollectionsQuery, TypedHomePageQuery } from "./queries";
import { Loader } from "@components/atoms";

var View = function View() {
  return React.createElement("div", {
    className: "home-page"
  }, React.createElement(TypedHomePageQuery, {
    alwaysRender: true,
    errorPolicy: "all"
  }, function (_ref) {
    var shopData = _ref.data,
        loading = _ref.loading;
    if (loading) return React.createElement(Loader, {
      fullScreen: true
    });
    return React.createElement(TypedHomepageCollectionsQuery, null, function (data) {
      return React.createElement(MetaWrapper, {
        meta: {
          description: shopData.shop ? shopData.shop.description : "",
          title: shopData.shop ? shopData.shop.name : ""
        }
      }, React.createElement(Page, {
        loading: loading,
        backgroundImage: shopData.shop && shopData.shop.homepageCollection && shopData.shop.homepageCollection.backgroundImage,
        categories: shopData.categories,
        collections: data.data.collections.edges.map(function (e) {
          return e.node;
        }),
        shop: shopData.shop
      }));
    });
  }));
};

export default View;