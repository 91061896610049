import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { SvgCart } from "@temp/images";
import * as S from "./styles";
var EXCLUDED_PATHS = ["cart", "checkout"];
export var CartDropdown = function CartDropdown(_ref) {
  var cartItemsQuantity = _ref.cartItemsQuantity,
      content = _ref.content,
      _ref$debug = _ref.debug,
      debug = _ref$debug === void 0 ? false : _ref$debug;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      active = _React$useState2[0],
      setActive = _React$useState2[1];

  var location = useLocation();
  React.useEffect(function () {
    if (debug) setActive(true);
  }, [active]);
  var isValidLocation = EXCLUDED_PATHS.reduce(function (p, c) {
    return p && !location.pathname.includes(c);
  }, true);
  var wrapperClass = classNames({
    visible: isValidLocation && active
  });
  var iconClass = classNames({
    "top-navbar__icon__active": isValidLocation && active,
    "top-navbar__icon": true,
    "top-navbar__cart": true
  });
  var menuBodyClass = classNames({
    visible: isValidLocation && active
  });
  return React.createElement(S.MenuDropdown, {
    "data-test": "userButton",
    className: wrapperClass,
    onMouseOver: function onMouseOver() {
      return setActive(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setActive(false);
    }
  }, React.createElement("li", {
    className: iconClass
  }, React.createElement(SvgCart, null), cartItemsQuantity > 0 ? React.createElement("span", {
    className: "top-navbar__cart__quantity"
  }, cartItemsQuantity) : null), React.createElement(S.MenuBody, {
    id: "cart-dropdown-body",
    className: menuBodyClass
  }, content));
};
CartDropdown.displayName = "CartDropdown";
export default CartDropdown;