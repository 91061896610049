import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { Link } from "react-router-dom";
import { CartFooter, Loader } from "@components/atoms";
import { Container } from "../Container";
import * as S from "./styles";
import Media from "react-responsive";
import { largeScreen } from "@styles/constants";
import SvgChevronHorizontal from "images/SvgChevronHorizontal";
import { CartSummary } from "@components/organisms";
/**
 * Template for checkout page.
 */

var Checkout = function Checkout(_ref) {
  var loading = _ref.loading,
      navigation = _ref.navigation,
      checkout = _ref.checkout,
      paymentGateways = _ref.paymentGateways,
      _ref$hidePaymentGatew = _ref.hidePaymentGateways,
      hidePaymentGateways = _ref$hidePaymentGatew === void 0 ? false : _ref$hidePaymentGatew,
      button = _ref.button,
      onButtonClick = _ref.onButtonClick,
      buttonText = _ref.buttonText,
      goBackLink = _ref.goBackLink,
      discountPrice = _ref.discountPrice,
      shippingPrice = _ref.shippingPrice,
      subtotalPrice = _ref.subtotalPrice,
      totalPrice = _ref.totalPrice,
      products = _ref.products;
  return React.createElement(Container, null, loading && React.createElement(S.Loader, null, React.createElement(Loader, {
    fullScreen: true
  })), React.createElement(S.Wrapper, null, React.createElement(S.Main, null, React.createElement(Media, {
    minWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(S.Navigation, null, navigation) : React.createElement(S.NavigationContainer, null, React.createElement(S.GoBack, null, React.createElement(Link, {
      to: goBackLink
    }, React.createElement(SvgChevronHorizontal, null))), React.createElement(S.Navigation, null, navigation));
  }), React.createElement(S.Checkout, null, checkout), React.createElement(Media, {
    minWidth: largeScreen
  }, React.createElement(S.Button, null, button))), React.createElement(Media, {
    minWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(S.CartSummary, null, React.createElement(CartSummary, {
      shipping: shippingPrice,
      subtotal: subtotalPrice,
      promoCode: discountPrice,
      total: totalPrice,
      products: products
    })) : React.createElement(S.CartFooterWrapper, null, React.createElement(CartFooter, _extends({
      subtotalPrice: subtotalPrice,
      shippingPrice: shippingPrice,
      discountPrice: discountPrice,
      totalPrice: totalPrice
    }, {
      withoutNav: true
    }, {
      onButtonClick: onButtonClick,
      buttonText: buttonText
    })));
  })));
};

export { Checkout };