import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  p {\n    margin-top: 0.5rem;\n    font-size: 0.8rem;\n    font-weight: 300;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 5rem;\n  height: 5rem;\n  font-size: 0.75rem;\n\n  > span:first-child {\n    font-size: 1.8rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 90%;\n  justify-content: space-between;\n  margin-top: 1rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Box = styled.div(_templateObject2());
export var WrapperExpired = styled.div(_templateObject3());