import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { Redirect } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";
import { useCheckoutStepFromPath, useCheckoutStepState } from "@hooks";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { checkIfShippingRequiredForProducts } from "@utils/core";

var CheckoutRouter = function CheckoutRouter(_ref) {
  var items = _ref.items,
      checkout = _ref.checkout,
      payment = _ref.payment,
      totalPrice = _ref.totalPrice,
      renderAddress = _ref.renderAddress,
      renderPayment = _ref.renderPayment,
      renderReview = _ref.renderReview;

  var _useLocation = useLocation(),
      pathname = _useLocation.pathname;

  var localPayment = JSON.parse(localStorage.getItem("payment"));

  var _useCheckoutStepState = useCheckoutStepState(items, checkout, localPayment, totalPrice),
      recommendedStep = _useCheckoutStepState.recommendedStep,
      maxPossibleStep = _useCheckoutStepState.maxPossibleStep;

  var stepFromPath = useCheckoutStepFromPath(pathname);
  var isShippingRequiredForProducts = checkIfShippingRequiredForProducts(items);

  var getStepLink = function getStepLink() {
    var _CHECKOUT_STEPS$find;

    return ((_CHECKOUT_STEPS$find = CHECKOUT_STEPS.find(function (stepObj) {
      return stepObj.step === recommendedStep;
    })) === null || _CHECKOUT_STEPS$find === void 0 ? void 0 : _CHECKOUT_STEPS$find.link) || CHECKOUT_STEPS[0].link;
  };

  if (pathname !== CHECKOUT_STEPS[3].link && (!stepFromPath || stepFromPath && maxPossibleStep < stepFromPath) || pathname === CHECKOUT_STEPS[1].link && !isShippingRequiredForProducts) {
    return React.createElement(Redirect, {
      to: getStepLink()
    });
  }

  return React.createElement(Switch, null, React.createElement(Route, {
    path: CHECKOUT_STEPS[3].link,
    render: renderReview
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[0].link,
    render: renderAddress
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[1].link,
    render: renderPayment
  }), React.createElement(Route, {
    path: CHECKOUT_STEPS[2].link,
    render: renderReview
  }), React.createElement(Route, {
    render: function render(props) {
      return React.createElement(Redirect, _extends({}, props, {
        to: getStepLink()
      }));
    }
  }));
};

export { CheckoutRouter };