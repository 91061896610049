import _defineProperty from "@babel/runtime/helpers/defineProperty";
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
var customStepperStyle = {
  wrapper: {
    zIndex: -1,
    marginTop: "2rem",
    fontSize: "0.8rem"
  },
  stepper: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
    padding: 0,
    width: "100%"
  },
  stepper__step: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
    textAlign: "center",
    width: "100%"
  },
  stepper__step__index: {
    width: "15px",
    height: "15px",
    lineHeight: "15px",
    borderRadius: "50%",
    background: "#d2e5e4",
    textAlign: "center",
    marginBottom: "5px"
  },
  stepper__step__label: {
    margin: 0
  },
  labelContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center"
  },
  linearProgress: {
    flex: "2 1 auto",
    position: "absolute",
    top: 5.5,
    left: "calc(-50% + 15px)",
    right: "calc(50% + 15px)",
    backgroundColor: "#40918940"
  },
  bar: {
    backgroundColor: "#06847B"
  },
  currentStep: {
    backgroundColor: "#06847B !important",
    color: "#fff",
    fontWeight: "500",
    transform: "scale(1)",
    boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
    animation: "pulse 2s infinite"
  },
  done: {
    backgroundColor: "#06847B !important",
    color: "#fff",
    fontWeight: "500"
  }
};

function HorizontalStepper(props) {
  var steps = props.steps,
      current = props.current,
      progress = props.progress,
      classes = props.classes;

  function StepContent(_ref) {
    var done = _ref.done,
        index = _ref.index;
    return done ? "✓" : index + 1;
  }

  var ProgressBar = function ProgressBar(_ref2) {
    var current = _ref2.current,
        step = _ref2.step,
        progress = _ref2.progress;
    var value = 0;

    if (current + 1 === step) {
      value = progress;
    } else if (current >= step) {
      value = 100;
    }

    return React.createElement(LinearProgress, {
      variant: "determinate",
      value: value,
      classes: {
        root: classes.linearProgress,
        bar: classes.bar
      }
    });
  };

  function renderStep(label, key) {
    var _classNames;

    var current = this.current,
        progress = this.progress;
    var done = key < current;
    var currentStep = key === current;
    var stepClasses = classNames((_classNames = {}, _defineProperty(_classNames, classes.stepper__step__index, true), _defineProperty(_classNames, classes.currentStep, currentStep), _defineProperty(_classNames, classes.done, done), _classNames));
    return React.createElement("li", {
      className: classes.stepper__step,
      key: key
    }, React.createElement("div", {
      className: classes.labelContainer
    }, React.createElement("span", {
      className: stepClasses
    }), React.createElement("p", {
      className: classes.stepper__step__label
    }, label)), !!key && React.createElement(ProgressBar, {
      current: current,
      step: key,
      progress: progress
    }));
  }

  return React.createElement("div", {
    className: classes.wrapper
  }, React.createElement("ul", {
    className: classes.stepper
  }, steps.map(renderStep, {
    current: current,
    progress: progress
  })));
}

export default withStyles(customStepperStyle)(HorizontalStepper);