import React from "react";
import * as S from "./styles";
export var Notification = function Notification(_ref) {
  var color = _ref.color,
      title = _ref.title,
      expl = _ref.expl,
      Svg = _ref.Svg;
  return React.createElement(S.Wrapper, {
    color: color
  }, React.createElement(S.Header, null, Svg && React.createElement(Svg, null), React.createElement(S.Title, null, title)), expl && React.createElement(S.Expl, null, expl));
};
Notification.displayName = "Notification";
export default Notification;