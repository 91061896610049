import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  query ProductReviews(\n    $slug: String!\n    $perPage: Int\n    $after: String\n    $sortBy: ReviewSortingInput\n    $values: [Int]\n  ) {\n    product(slug: $slug) {\n      id\n      reviews(\n        first: $perPage\n        after: $after\n        sortBy: $sortBy\n        filter: { rating: { values: $values } }\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n        totalCount\n        edges {\n          node {\n            id\n            userName\n            created\n            rating\n            comment\n            purchasedAt\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  query ReviewProduct($slug: String!) {\n    product(slug: $slug) {\n      id\n      name\n      slug\n      rating\n      thumbnail {\n        alt\n        url\n      }\n      reviewsSummary {\n        ratingsSummary {\n          stars\n          count\n        }\n        totalRatings\n        totalComments\n      }\n    }\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query VariantList($ids: [ID!], $countryCode: CountryCode) {\n    productVariants(ids: $ids, first: 100) {\n      edges {\n        node {\n          ...ProductVariantFields\n          product {\n            ...BasicProductFields\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  query ProductDetails($slug: String!, $countryCode: CountryCode) {\n    product(slug: $slug) {\n      ...BasicProductFields\n      ...ProductPricingField\n      descriptionJson\n      category {\n        id\n        name\n        slug\n        products(first: 3) {\n          edges {\n            node {\n              ...BasicProductFields\n              ...ProductPricingField\n            }\n          }\n        }\n      }\n      images {\n        id\n        alt\n        url\n      }\n      attributes {\n        ...SelectedAttributeFields\n      }\n      variants {\n        ...ProductVariantFields\n      }\n      seoDescription\n      seoTitle\n      isAvailable\n      isAvailableForPurchase\n      availableForPurchase\n      thumbnails(size: 200) {\n        alt\n        url\n      }\n      mobileThumbnails: thumbnails(size: 500) {\n        alt\n        url\n      }\n      ratingsCount\n      productGroupDisplay\n      productGroup {\n        displayName\n        products {\n          id\n          slug\n          name\n          productGroupDisplay\n          firstImage {\n            alt\n            url\n          }\n          thumbnail(size: 120) {\n            url\n            alt\n          }\n        }\n      }\n      relatedProducts {\n        ...BasicProductFields\n        ...ProductPricingField\n      }\n      reviewsSummary {\n        totalComments\n        ratingsSummary {\n          stars\n          count\n        }\n      }\n      previewReviews {\n        id\n        userName\n        rating\n        comment\n        created\n        purchasedAt\n      }\n      vendor {\n        id\n        name\n        slug\n      }\n    }\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductVariantFields on ProductVariant {\n    id\n    sku\n    name\n    isAvailable\n    quantityAvailable(countryCode: $countryCode)\n    images {\n      id\n      url\n      alt\n    }\n    pricing {\n      onSale\n      priceUndiscounted {\n        ...Price\n      }\n      price {\n        ...Price\n      }\n    }\n    attributes {\n      attribute {\n        id\n        name\n        slug\n      }\n      values {\n        id\n        name\n        value: name\n      }\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  fragment SelectedAttributeFields on SelectedAttribute {\n    attribute {\n      id\n      name\n    }\n    values {\n      id\n      name\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductTileFields on Product {\n    id\n    slug\n    name\n    rating\n    ratingsCount\n    isAvailable\n    thumbnail(size: 400) {\n      url\n      alt\n    }\n    ...ProductPricingField\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductPricingField on Product {\n    pricing {\n      onSale\n      discount {\n        ...Price\n      }\n      priceRangeUndiscounted {\n        start {\n          ...Price\n        }\n        stop {\n          ...Price\n        }\n      }\n      priceRange {\n        start {\n          ...Price\n        }\n        stop {\n          ...Price\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  fragment BasicProductFields on Product {\n    id\n    slug\n    name\n    rating\n    ratingsCount\n    isAvailable\n    thumbnail(size: 500) {\n      url\n      alt\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment Price on TaxedMoney {\n    gross {\n      amount\n      currency\n    }\n    net {\n      amount\n      currency\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var priceFragment = gql(_templateObject());
export var basicProductFragment = gql(_templateObject2());
export var productPricingFragment = gql(_templateObject3(), priceFragment);
export var productTileFragment = gql(_templateObject4(), productPricingFragment);
export var selectedAttributeFragment = gql(_templateObject5());
export var productVariantFragment = gql(_templateObject6(), priceFragment);
export var productDetailsQuery = gql(_templateObject7(), basicProductFragment, selectedAttributeFragment, productVariantFragment, productPricingFragment); // FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.

export var productVariantsQuery = gql(_templateObject8(), basicProductFragment, productVariantFragment);
export var TypedProductDetailsQuery = TypedQuery(productDetailsQuery);
export var TypedProductVariantsQuery = TypedQuery(productVariantsQuery);
export var reviewProductQuery = gql(_templateObject9());
export var TypedReviewProductQuery = TypedQuery(reviewProductQuery);
export var productReviewsQuery = gql(_templateObject10());
export var TypedProductReviewsQuery = TypedQuery(productReviewsQuery);