import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n  li {\n    padding: 0 1rem;\n    line-height: 2.5rem;\n    text-align: left;\n    width: 100%;\n    cursor: pointer;\n\n    :hover {\n      a {\n        color: ", ";\n      }\n    }\n\n    &:last-of-type {\n      border-bottom-left-radius: 10px;\n      border-bottom-right-radius: 10px;\n      &:hover {\n        color: ", ";\n      }\n    }\n\n    a {\n      display: block;\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 1rem 1rem 7px;\n  font-weight: 500;\n  position: relative;\n\n  &::after {\n    border-bottom: 1px solid #f1f1f1;\n    content: \"\";\n    position: absolute;\n    left: 0;\n    right: 0;\n    width: 100%;\n    bottom: -5px;\n    margin: 0 auto;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 230px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Body = styled.div(_templateObject());
export var Greeting = styled.p(_templateObject2());
export var Links = styled.ul(_templateObject3(), function (props) {
  return props.theme.colors.logoGreen;
}, function (props) {
  return props.theme.colors.logoGreen;
});