import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgAddToCart = function SvgAddToCart(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 22.459 22.89"
  }, props), React.createElement("path", {
    "data-name": "Trac\\xE9 129",
    d: "m19.2 14.674 3.254-9.762-1.114-.372-2.986 8.96H7.466L3.358 1.174H0v1.174h2.511l3.9 11.708-2.622 5.9h16.167v-1.174H5.6l1.825-4.108Z",
    fill: "#fff"
  }), React.createElement("circle", {
    "data-name": "Ellipse 37",
    cx: 0.984,
    cy: 0.984,
    r: 0.984,
    transform: "translate(7.868 20.923)",
    fill: "#fff"
  }), React.createElement("circle", {
    "data-name": "Ellipse 38",
    cx: 0.984,
    cy: 0.984,
    r: 0.984,
    transform: "translate(14.753 20.923)",
    fill: "#fff"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 130",
    d: "M12.326 0v3.522H8.804V4.7h3.522v3.522h1.178V4.7h3.522V3.522h-3.522V0Z",
    fill: "#fff"
  }));
};

export default SvgAddToCart;