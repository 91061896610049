import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation ReviewCreate(\n      $userName: String!\n      $rating: Int!\n      $comment: String\n      $orderline: ID!\n  ) {\n    reviewCreate(\n      input: {userName: $userName, rating: $rating, comment: $comment} \n      orderline: $orderline\n    ) {\n      errors {\n        field\n        message\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "../../../core/mutations";
var reviewCreationMutation = gql(_templateObject());
export var TypedReviewCreationMutation = TypedMutation(reviewCreationMutation);