import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: 600;\n  display: block;\n  margin-top: 0.5rem;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.2rem 1rem;\n  max-width: 360px;\n\n  .quantity {\n    font-size: 0.8rem;\n    span {\n      color: rgb(125, 125, 125);\n    }\n  }\n\n  button {\n    display: block;\n    margin-top: 1rem;\n    display: flex;\n    align-items: center;\n\n    font-size: 0.8rem;\n\n    @media (max-width: ", ") {\n      font-size: 0.8rem;\n    }\n\n    &:first-of-type {\n      padding: 0.25rem 0.5rem;\n      path {\n        fill: black;\n      }\n    }\n\n    svg {\n      height: 1.5rem;\n      width: auto;\n      margin-right: 0.5rem;\n    }\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  height: 150px;\n  width: auto;\n\n  border-radius: 10px;\n  border: 1px solid #00000038;\n\n  @media (max-width: ", ") {\n    height: 110px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  margin-bottom: 3rem;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: fit-content;\n  margin: auto;\n  height: 56px;\n  justify-content: space-between;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: baseline;\n    width: unset;\n    height: unset;\n    margin: unset;\n  }\n\n  button {\n    display: flex;\n    align-items: center;\n    font-size: 0.8rem;\n\n    @media (max-width: ", ") {\n      font-size: 0.8rem;\n    }\n\n    svg {\n      width: 1.2rem;\n      height: 1.2rem;\n      margin-right: 0.5rem;\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  margin-bottom: 3rem;\n\n  svg {\n    height: 48px;\n    width: auto;\n    margin-right: 1rem;\n  }\n\n  .text {\n    color: ", ";\n\n    span {\n      font-weight: 500;\n    }\n  }\n\n  &::after {\n    border-bottom: 1px solid ", ";\n    content: \"\";\n    position: absolute;\n    width: 80%;\n    left: 10%;\n    bottom: -1.5rem;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  width: 100%;\n  padding: 1rem;\n\n  @media (min-width: ", ") {\n    width: 50%;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n  width: 100%;\n\n  @media (min-width: ", ") {\n    width: 50%;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  box-shadow: ", ";\n  font-size: 0.8rem;\n  margin: 4rem 0;\n  border-radius: 10px;\n  overflow: hidden;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    justify-content: space-between;\n    font-size: 0.875rem;\n  }\n\n  @media (max-width: ", ") {\n    p {\n      line-height: 1.2rem;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TaxedMoney } from "@components/containers";
import { styled } from "@styles";
import { Thumbnail } from "../Thumbnail";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.dark;
}, function (props) {
  return props.theme.boxShadow.orderFulfillment;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Lines = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Status = styled.div(_templateObject3(), function (props) {
  return props.status === "DELIVERED" ? props.theme.colors.greenBackground : props.theme.colors.autofill;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var StatusInfo = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.blackGrayText;
}, function (props) {
  return props.theme.colors.grayBorder;
});
export var StatusActions = styled.div(_templateObject5(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Line = styled.div(_templateObject6());
export var LineThumbnail = styled(Thumbnail)(_templateObject7(), function (props) {
  return props.theme.breakpoints.xSmallScreen;
});
export var LineContent = styled.div(_templateObject8(), function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var LinePrice = styled(TaxedMoney)(_templateObject9(), function (props) {
  return props.theme.colors.listBullet;
});