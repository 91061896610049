import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  text-align: center;\n  vertical-align: center;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  -webkit-tap-highlight-color: transparent;\n\n  p {\n    margin: 0;\n    font-size: 0.8rem;\n\n    @media (min-width: ", ") {\n      font-size: 1rem;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Content = styled.div(_templateObject(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});