import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  display: flex !important;\n  align-items: center;\n  width: fit-content;\n\n  svg {\n    height: 18px;\n    width: 18px;\n    margin-right: 5px;\n\n    path {\n      transition: fill 0.3s;\n      fill: #3e3e3e;\n    }\n  }\n\n  span {\n    font-size: 0.875rem;\n    line-height: normal;\n    margin-top: 2px;\n    transition: color 0.3s;\n  }\n\n  &:hover {\n    path {\n      fill: black;\n    }\n\n    span {\n      color: black;\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  font-size: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var FooterContent = styled.div(_templateObject2());
export var Item = styled.div(_templateObject3());