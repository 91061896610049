import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgScrollToTop = function SvgScrollToTop(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 90 90"
  }, props), React.createElement("g", {
    "data-name": "UP Button",
    transform: "translate(-1754.969 -906.969)"
  }, React.createElement("circle", {
    "data-name": "Ellipse 25",
    cx: 45,
    cy: 45,
    r: 45,
    transform: "translate(1754.969 906.969)",
    fill: "#231f20"
  }), React.createElement("g", {
    "data-name": "Groupe 184"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 93",
    d: "M1805.465 936.032a5.019 5.019 0 1 1-5.015-4.942 4.981 4.981 0 0 1 5.015 4.942Z",
    fill: "#9bd7f5"
  }), React.createElement("g", {
    "data-name": "Groupe 185"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 94",
    d: "M1784.31 959.403a5.015 5.015 0 1 1-1.47-3.552 5.019 5.019 0 0 1 1.47 3.552Z",
    fill: "#96cbc7"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 92",
    d: "M1826.156 959.403a5.019 5.019 0 1 1-5.019-5.019 5.019 5.019 0 0 1 5.019 5.019Z",
    fill: "#daeddd"
  })), React.createElement("g", {
    "data-name": "Groupe 186"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 91",
    d: "M1816.886 947.913a5.019 5.019 0 1 1-5.019-5.015 5.019 5.019 0 0 1 5.019 5.015Z",
    fill: "#f394b8"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 95",
    d: "M1793.592 947.913a5.023 5.023 0 1 1-1.482-3.554 5.019 5.019 0 0 1 1.482 3.554Z",
    fill: "#babadd"
  })))));
};

export default SvgScrollToTop;