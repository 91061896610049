import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
export var SvgChevron = function SvgChevron(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 26.907 49.615"
  }, props), "0 0 26.907 49.615", React.createElement("path", {
    "data-name": "Path 64",
    d: "M24.807 0 0 24.808l24.807 24.807 2.1-2.1L4.199 24.808 26.907 2.1Z",
    fill: "rgba(33,33,33,0.55)"
  }));
};