import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react";
import * as S from "./styles";
import { useFormContext } from "react-hook-form";
export var FormCheckbox = function FormCheckbox(_ref) {
  var checked = _ref.checked,
      props = _objectWithoutProperties(_ref, ["checked"]);

  var _useFormContext = useFormContext(),
      register = _useFormContext.register;

  return React.createElement(S.CheckboxContainer, null, React.createElement(S.HiddenCheckbox, _extends({
    checked: checked
  }, register("orderline"), props)), React.createElement(S.StyledCheckbox, {
    checked: checked
  }));
};