import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  vertical-align: middle;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  box-shadow: inset 0px 0px 0px 3px\n    ", ";\n  border-left: 1px solid ", ";\n  border-right: 1px solid ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  line-height: inherit;\n  border: 1px solid ", ";\n  box-shadow: ", ";\n\n  padding: ", ";\n  transition: 0.3s;\n  outline: none;\n  border-radius: 2rem;\n  color: ", ";\n  width: ", "\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: default;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
var padding = {
  md: "0.4rem",
  sm: "0.1rem"
};
var paddingCloseButton = {
  md: "0.4rem",
  sm: "0.1rem"
};

var fontSize = function fontSize(_fontSize, smallFontSize) {
  return {
    md: _fontSize,
    sm: smallFontSize
  };
};

export var Primary = styled.div(_templateObject(), function (props) {
  return props.theme.chip.colors[props.color].background;
}, function (props) {
  return props.pressed ? "0 0 0 2px #38003c" : "none";
}, function (props) {
  return padding[props.size];
}, function (props) {
  return props.theme.chip.colors[props.color].color;
}, function (props) {
  return props.fullWidth ? "100%" : "auto";
});
export var Secondary = styled(Primary)(_templateObject2(), function (props) {
  return props.theme.chip.colors.secondary.color;
}, function (props) {
  return props.theme.chip.colors.secondary.color;
}, function (props) {
  return props.theme.chip.colors.secondary.color;
});
export var Text = styled.span(_templateObject3(), function (_ref) {
  var size = _ref.size,
      typography = _ref.theme.chip.typography;
  return fontSize(typography.fontSize, typography.smallFontSize)[size];
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.baseLineHeight;
});