import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  transition: opacity 0.6s ease-in;\n  object-position: cover;\n  position: absolute;\n  top: 0;\n  left: 0;\n  opacity: ", ";\n  z-index: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  aspect-ratio: 2.62/1;\n\n  position: relative;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n  border-radius: 10px;\n  overflow: hidden;\n\n  @media (min-width: ", ") {\n    width: 90%;\n    height: 100%;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  margin: 2rem auto 4rem;\n\n  cursor: pointer;\n  padding: 2px;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    height: 400px;\n    margin: 2rem auto 6rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { CachedImage } from "@components/molecules";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Background = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Image = styled(CachedImage)(_templateObject3(), function (props) {
  return props.active ? 1 : 0;
}, function (props) {
  return props.active ? 1 : 0;
});