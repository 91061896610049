import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { transformPhoneNumber } from "@temp/misc";
import * as S from "./styles";

/**
 * Address summary
 */
var AddressSummary = function AddressSummary(_ref) {
  var address = _ref.address,
      email = _ref.email;

  if (address) {
    return React.createElement(S.Wrapper, {
      "data-test": "addressTile"
    }, React.createElement("strong", null, "".concat(address.firstName, " ").concat(address.lastName)), React.createElement("br", null), address.companyName && React.createElement(React.Fragment, null, address.companyName, " ", React.createElement("br", null)), address.streetAddress1, address.streetAddress2 && React.createElement(React.Fragment, null, ", ", address.streetAddress2), address.postalCode && React.createElement(React.Fragment, null, address.postalCode), React.createElement("br", null), address.city, ", ", address.countryArea, React.createElement("br", null), address.phone && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, _extends({}, commonMessages.phoneNumber, {
      values: {
        phone: transformPhoneNumber(address.phone.substring(4))
      }
    })), " ", React.createElement("br", null)));
  }

  if (email) {
    return React.createElement(S.Wrapper, {
      "data-test": "emailTile"
    }, email);
  }

  return null;
};

export { AddressSummary };