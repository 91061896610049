import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  top: 100%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: ", "\n  \n  position: relative;\n  input:-webkit-autofill {\n    -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */\n    -webkit-border-radius: 15px;\n}\n\ninput:-webkit-autofill:focus {\n    -webkit-box-shadow: /*your box-shadow*/,0 0 0 50px white inset;\n}  \n\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var TextField = styled.div(_templateObject(), function (props) {
  return props.errors ? "0.5rem" : "2rem";
});
TextField.displayName = "S.TextField";
export var HelpText = styled.span(_templateObject2(), function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.input.labelFontSize;
});
export var ErrorMessages = styled.div(_templateObject3());