import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  font-size: 0.8rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ErrorMessage = styled.div(_templateObject(), function (props) {
  return props.theme.colors.error;
});
export var ErrorParagraph = styled.p(_templateObject2());
ErrorMessage.displayName = "S.ErrorMessage";