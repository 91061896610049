import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  fragment OrderDetailsFragment on Order {\n    id\n    number\n    shippingAddress {\n      ...AddressFragment\n    }\n    billingAddress {\n      ...AddressFragment\n    }\n    created\n    customerNote\n    fulfillments {\n      ...FulfillmentFragment\n    }\n    lines {\n      ...OrderLineFragment\n    }\n    shippingMethodName\n    shippingPrice {\n      gross {\n        amount\n        currency\n      }\n      net {\n        amount\n        currency\n      }\n    }\n    status\n    statusDisplay\n    paymentStatusDisplay\n    subtotal {\n      gross {\n        amount\n        currency\n      }\n    }\n    total {\n      gross {\n        amount\n        currency\n      }\n      net {\n        amount\n        currency\n      }\n      tax {\n        amount\n        currency\n      }\n    }\n    totalCaptured {\n      currency\n      amount\n    }\n    discount {\n      amount\n      currency\n    }\n    payments {\n      gateway\n      total {\n        amount\n        currency\n      }\n      capturedAmount {\n        amount\n        currency\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  fragment VariantFragment on ProductVariant {\n    id\n    name\n    sku\n    quantityAvailable\n    isAvailable\n    pricing {\n      onSale\n      priceUndiscounted {\n        gross {\n          amount\n          currency\n        }\n      }\n      price {\n        gross {\n          amount\n          currency\n        }\n      }\n    }\n    attributes {\n      attribute {\n        name\n        values {\n          id\n          name\n        }\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  fragment FulfillmentFragment on Fulfillment {\n    id\n    lines {\n      id\n      quantity\n      orderLine {\n        ...OrderLineFragment\n      }\n    }\n    fulfillmentOrder\n    status\n    created\n    trackingNumber\n    delivery {\n      to\n      at\n      created\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment OrderLineFragment on OrderLine {\n    id\n    variant {\n      id\n      name\n    }\n    productName\n    productSku\n    quantity\n    quantityFulfilled\n    totalPrice {\n      gross {\n        amount\n        currency\n      }\n      net {\n        amount\n        currency\n      }\n    }\n    unitPrice {\n      gross {\n        amount\n        currency\n      }\n      net {\n        amount\n        currency\n      }\n    }\n    thumbnail {\n      url\n      alt\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { fragmentAddress } from "./address";
export var fragmentOrderLine = gql(_templateObject());
export var fragmentFulfillment = gql(_templateObject2(), fragmentOrderLine);
export var fragmentVariant = gql(_templateObject3());
export var fragmentOrderDetails = gql(_templateObject4(), fragmentAddress, fragmentOrderLine, fragmentFulfillment);