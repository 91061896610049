import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button } from "@components/atoms";
import * as S from "./styles";
import { Container } from "@components/templates";

var NotFound = function NotFound() {
  return (// <div className="not-found-page">
    //   <h2 className="not-found-page__header">
    //     <FormattedMessage defaultMessage="404" />
    //   </h2>
    //   <div className="not-found-page__message">
    //   </div>
    //   <div className="not-found-page__button">
    //     <Link to={BASE_URL}>
    //       <Button testingContext="404pageGotoHomeButton" fullWidth>
    //         <FormattedMessage defaultMessage="Back to home" />
    //       </Button>
    //     </Link>
    //   </div>
    // </div>
    React.createElement(Container, {
      "data-test": "notFoundView"
    }, React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, {
      id: "components.NotFound.index.3478767873",
      defaultMessage: "404"
    })), React.createElement(S.Text, null, React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.NotFound.index.3789726474",
      defaultMessage: "F\xE9licitations, vous avez retrouv\xE9 Ammar 404."
    })), React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "components.NotFound.index.168309611",
      defaultMessage: "En ce qui concerne la page que vous demandez, elle n'existe pas, ou son URL a chang\xE9. "
    }), " ")), React.createElement(Link, {
      to: "/"
    }, React.createElement(Button, {
      testingContext: "gotohomeButton"
    }, React.createElement(FormattedMessage, {
      id: "components.NotFound.index.4263057205",
      defaultMessage: "Retour \xE0 l'accueil"
    })))))
  );
};

export default NotFound;