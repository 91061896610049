import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n\tfragment BulkRequestErrorFragment on BulkRequestError {\n    field\n    code\n    index\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
export var bulkRequestErrorFragment = gql(_templateObject());