import { HomepageHotDeals } from "@components/organisms";
import { maybe } from "@utils/misc";
import React from "react";
import { TypedHotDealsCollectionQuery } from "../queries";
export var HotDeals = function HotDeals() {
  return React.createElement(TypedHotDealsCollectionQuery, null, function (_ref) {
    var data = _ref.data;
    var products = maybe(function () {
      return data.collection.products.edges;
    }, []);

    if (products.length) {
      return React.createElement(HomepageHotDeals, {
        products: products.map(function (edge) {
          return edge.node;
        })
      });
    } else return React.createElement(React.Fragment, null);
  });
};