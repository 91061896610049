import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { Button } from "@components/atoms";
import { AttributeValuesChecklist, PriceFilter } from "@components/molecules";
import { useHandlerWhenClickedOutside } from "@hooks";
import { commonMessages } from "@temp/intl";
import ReactSVG from "react-svg";
import closeImg from "images/x.svg";
import { Overlay } from "..";
import * as S from "./styles";

var checkIfAttributeIsChecked = function checkIfAttributeIsChecked(filterAttributes, value, slug) {
  if (filterAttributes && filterAttributes.hasOwnProperty(slug)) {
    if (filterAttributes[slug].find(function (filter) {
      return filter === value.slug;
    })) {
      return true;
    }

    return false;
  }

  return false;
};

export var FilterSidebarMobile = function FilterSidebarMobile(_ref) {
  var hide = _ref.hide,
      filters = _ref.filters,
      show = _ref.show,
      attributes = _ref.attributes,
      target = _ref.target,
      activePriceFilter = _ref.activePriceFilter,
      onAttributeFiltersSubmit = _ref.onAttributeFiltersSubmit,
      onSubmitPriceFilter = _ref.onSubmitPriceFilter;

  var _useForm = useForm(),
      control = _useForm.control,
      setValue = _useForm.setValue,
      getValues = _useForm.getValues,
      handleSubmit = _useForm.handleSubmit;

  var _React$useState = React.useState(filters.attributes),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      attributeFilters = _React$useState2[0],
      setAttributeFilters = _React$useState2[1];

  React.useEffect(function () {
    setAttributeFilters(filters.attributes);
  }, [filters.attributes]);

  var onFiltersChange = function onFiltersChange(name, value) {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (attributeFilters["".concat(name)].length === 1) {
          var att = _objectSpread({}, attributeFilters);

          delete att["".concat(name)];
          setAttributeFilters(_objectSpread({}, att));
        } else {
          setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), attributeFilters["".concat(name)].filter(function (item) {
            return item !== value;
          }))));
        }
      } else {
        setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [].concat(_toConsumableArray(attributeFilters["".concat(name)]), [value]))));
      }
    } else {
      setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [value])));
    }
  };

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    hide();
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  var submitFilters = function submitFilters(filterAttributes) {
    if (filterAttributes) {
      onAttributeFiltersSubmit(filterAttributes);
    }

    handleSubmit(onSubmitPriceFilter)();
    hide();
  };

  return React.createElement(Overlay, {
    duration: 0,
    position: "left",
    show: show,
    hide: hide,
    target: target
  }, React.createElement(S.Wrapper, {
    ref: setElementRef(),
    "data-test": "filterSidebar"
  }, React.createElement("div", {
    className: "overlay__header"
  }, React.createElement("div", {
    className: "overlay__header-text"
  }, React.createElement(FormattedMessage, commonMessages.filterHeader)), React.createElement(ReactSVG, {
    path: closeImg,
    onClick: hide,
    className: "overlay__header__close-icon"
  })), React.createElement("div", {
    className: "overlay__body"
  }, attributes.map(function (_ref2) {
    var id = _ref2.id,
        name = _ref2.name,
        slug = _ref2.slug,
        values = _ref2.values;
    return React.createElement(AttributeValuesChecklist, {
      key: id,
      title: name,
      name: slug,
      values: values.map(function (value) {
        return _objectSpread({}, value, {
          selected: checkIfAttributeIsChecked(attributeFilters, value, slug)
        });
      }),
      valuesShowLimit: true,
      onValueClick: function onValueClick(value) {
        return onFiltersChange(slug, value.slug);
      }
    });
  }), attributes && attributes.length > 0 && React.createElement(PriceFilter, _extends({
    onSubmitPriceFilter: onSubmitPriceFilter,
    activePriceFilter: activePriceFilter,
    control: control,
    getValues: getValues,
    setValue: setValue,
    handleSubmit: handleSubmit
  }, {
    isMobile: true
  }))), React.createElement("div", {
    className: "overlay__footer"
  }, React.createElement(Button, {
    onClick: function onClick() {
      return submitFilters(attributeFilters);
    }
  }, React.createElement(FormattedMessage, commonMessages.applyFilters)))));
};
FilterSidebarMobile.displayName = "FilterSidebarMobile";
export default FilterSidebarMobile;