import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import "./scss/index.scss";
import React from "react";

var Stars = function Stars(props) {
  var rating = props.rating,
      hoverRating = props.hoverRating,
      onStarClick = props.onStarClick,
      onStarEnter = props.onStarEnter,
      onStarLeave = props.onStarLeave;
  return React.createElement(React.Fragment, null, _toConsumableArray(Array(5)).map(function (_, i) {
    var fill = "";

    if (hoverRating != 0) {
      fill = i < hoverRating ? "hovered" : "gray";
    } else {
      fill = i < rating ? "clicked" : "gray";
    }

    return React.createElement("label", {
      className: "overlay__body__stars__label",
      onClick: function onClick() {
        return onStarClick(i + 1);
      },
      onMouseEnter: function onMouseEnter() {
        return onStarEnter(i + 1);
      },
      onMouseLeave: onStarLeave,
      key: i,
      htmlFor: "stars-".concat(i)
    }, React.createElement("input", {
      type: "radio",
      name: "rating",
      value: i + 1,
      id: "stars-".concat(i),
      onChange: props.onChange
    }), React.createElement("svg", {
      width: "30",
      className: fill,
      viewBox: "0 -10 511.98685 511",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
      d: "m510.652344 185.902344c-3.351563-10.367188-12.546875-17.730469-23.425782-18.710938l-147.773437-13.417968-58.433594-136.769532c-4.308593-10.023437-14.121093-16.511718-25.023437-16.511718s-20.714844 6.488281-25.023438 16.535156l-58.433594 136.746094-147.796874 13.417968c-10.859376 1.003906-20.03125 8.34375-23.402344 18.710938-3.371094 10.367187-.257813 21.738281 7.957031 28.90625l111.699219 97.960937-32.9375 145.089844c-2.410156 10.667969 1.730468 21.695313 10.582031 28.09375 4.757813 3.4375 10.324219 5.1875 15.9375 5.1875 4.839844 0 9.640625-1.304687 13.949219-3.882813l127.46875-76.183593 127.421875 76.183593c9.324219 5.609376 21.078125 5.097657 29.910156-1.304687 8.855469-6.417969 12.992187-17.449219 10.582031-28.09375l-32.9375-145.089844 111.699219-97.941406c8.214844-7.1875 11.351563-18.539063 7.980469-28.925781zm0 0"
    })));
  }));
};

export default Stars;