import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation OrderCancel($id: ID!) {\n    orderCancel(id: $id) {\n      order {\n        status\n      }\n      orderErrors {\n        field\n        message\n        code\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedMutation } from "@temp/core/mutations";
import gql from "graphql-tag";
var orderCancelMutation = gql(_templateObject());
export var TypedOrderCancelMutation = TypedMutation(orderCancelMutation);