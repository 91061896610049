import _defineProperty from "@babel/runtime/helpers/defineProperty";
import React from "react";
import { useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { TextField } from "..";
import SvgSearch from "images/SvgSearch";
import * as S from "./styles";
export var PriceFilter = function PriceFilter(_ref) {
  var onSubmitPriceFilter = _ref.onSubmitPriceFilter,
      activePriceFilter = _ref.activePriceFilter,
      _ref$isMobile = _ref.isMobile,
      isMobile = _ref$isMobile === void 0 ? false : _ref$isMobile,
      control = _ref.control,
      getValues = _ref.getValues,
      setValue = _ref.setValue,
      handleSubmit = _ref.handleSubmit;
  var intl = useIntl();
  React.useEffect(function () {
    if (activePriceFilter) {
      setValue("min", activePriceFilter[0] || "");
      setValue("max", activePriceFilter[1] || "");
    } else {
      setValue("min", "");
      setValue("max", "");
    }
  }, [activePriceFilter, getValues()]);
  return React.createElement(S.Wrapper, null, React.createElement(S.Title, null, "Prix"), React.createElement(S.Form, {
    onSubmit: handleSubmit(onSubmitPriceFilter),
    isMobile: isMobile
  }, React.createElement(Controller, {
    control: control,
    name: "min",
    render: function render(_ref2) {
      var _ref2$field = _ref2.field,
          _onChange = _ref2$field.onChange,
          value = _ref2$field.value;
      return React.createElement(TextField, {
        label: intl.formatMessage(_defineProperty({
          defaultMessage: "Min",
          "id": "@next.components.molecules.PriceFilter.PriceFilter.2612472964"
        }, "id", "@next.components.molecules.PriceFilter.PriceFilter.2612472964")),
        type: "tel",
        value: value,
        onChange: function onChange(e) {
          if (e.nativeEvent.inputType === "deleteContentBackward") return _onChange(e);
          if (e.nativeEvent.data.charCodeAt(0) < 48 || e.nativeEvent.data.charCodeAt(0) > 57) return;else _onChange(e);
          return;
        }
      });
    }
  }), React.createElement(Controller, {
    control: control,
    name: "max",
    render: function render(_ref3) {
      var _ref3$field = _ref3.field,
          _onChange2 = _ref3$field.onChange,
          value = _ref3$field.value;
      return React.createElement(TextField, {
        label: intl.formatMessage(_defineProperty({
          defaultMessage: "Max",
          "id": "@next.components.molecules.PriceFilter.PriceFilter.1153577553"
        }, "id", "@next.components.molecules.PriceFilter.PriceFilter.1153577553")),
        type: "tel",
        value: value,
        onChange: function onChange(e) {
          if (e.nativeEvent.inputType === "deleteContentBackward") return _onChange2(e);
          if (e.nativeEvent.data.charCodeAt(0) < 48 || e.nativeEvent.data.charCodeAt(0) > 57) return;else _onChange2(e);
          return;
        }
      });
    }
  }), !isMobile && React.createElement("button", {
    type: "submit"
  }, React.createElement(SvgSearch, null))));
};
PriceFilter.displayName = "PriceFilter";
export default PriceFilter;