import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n\n  @media (min-width: ", ") {\n    font-size: 0.875rem;\n    line-height: 1.5rem;\n  }\n\n  strong {\n    font-weight: ", ";\n    display: inline-block;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});