import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  padding: 4px 10px;\n  width: unset;\n  margin-left: 0.5rem;\n\n  margin-top: 10px;\n\n  @media (min-width: ", ") {\n    margin-top: unset;\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n  padding: 0 0.5rem;\n\n  @media (min-width: ", ") {\n    padding: unset;\n    margin-top: unset;\n  }\n\n  > div {\n    margin-top: 10px;\n\n    @media (min-width: ", ") {\n      margin-top: unset;\n    }\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 88%;\n  @media (min-width: ", ") {\n    width: 12rem;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: 90%;\n  @media (min-width: ", ") {\n    width: 12rem;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 50%;\n  display: flex;\n  justify-content: center;\n\n  > button {\n    width: 90%;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 2rem;\n  cursor: pointer;\n  font-size: ", ";\n  color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  margin-bottom: 0.5rem;\n  font-size: 0.8rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 1rem;\n  position: relative;\n\n  @media (max-width: ", ") {\n    padding: 1rem 0;\n\n    &::after {\n      border-bottom: 1px solid #000;\n      content: \"\";\n      position: absolute;\n      left: 0;\n      right: 0;\n      width: calc(100% - 6rem);\n      margin: 1rem auto 0;\n    }\n  }\n\n  button > span {\n    font-weight: normal;\n    font-size: 0.8rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { Button } from "@components/atoms";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Products = styled.p(_templateObject2());
export var ProductsDesktop = styled.p(_templateObject3());
export var Bar = styled.div(_templateObject4(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var BarDesktop = styled.div(_templateObject5());
export var Clear = styled.button(_templateObject6(), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.lightFont;
});
export var Element = styled.div(_templateObject7());
export var Label = styled.span(_templateObject8(), function (props) {
  return props.theme.colors.lightFont;
});
export var Sort = styled.div(_templateObject9(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var SortDesktop = styled.div(_templateObject10(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var FiltersChipsWrapper = styled.div(_templateObject11(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var ClearButton = styled(Button)(_templateObject12(), function (props) {
  return props.theme.breakpoints.largeScreen;
});