import React from "react";
import { FormattedMessage } from "react-intl";
import Media from "react-responsive";
import { mediumScreen } from "@styles/constants";
import LogoSmall from "../../../../images/logo-small.svg";
import * as S from "./styles";
export var DemoBanner = function DemoBanner() {
  return React.createElement(S.Wrapper, null, React.createElement(S.BorderedWrapper, null, React.createElement("a", {
    target: "_blank",
    href: ""
  }, React.createElement(S.LogoWrapper, {
    path: LogoSmall
  })), React.createElement(S.LinkList, null, React.createElement(Media, {
    maxWidth: mediumScreen
  }, React.createElement(S.Link, {
    target: "_blank",
    href: ""
  }, React.createElement(S.TextEmphasis, null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.DemoBanner.DemoBanner.2367386033",
    defaultMessage: "API"
  }))), React.createElement(S.Divider, null), React.createElement(S.Link, {
    target: "_blank",
    href: ""
  }, React.createElement(S.TextEmphasis, null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.DemoBanner.DemoBanner.3662390044",
    defaultMessage: "Dashboard"
  })))), React.createElement(Media, {
    minWidth: mediumScreen
  }, React.createElement(S.Link, {
    target: "_blank",
    href: ""
  }, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.DemoBanner.DemoBanner.2844777114",
    defaultMessage: "Explore <emphasis>Store\u2019s dashboard</emphasis>",
    values: {
      emphasis: function emphasis(children) {
        return React.createElement(S.TextEmphasis, null, children);
      }
    }
  })), React.createElement(S.Divider, null), React.createElement(S.Link, {
    target: "_blank",
    href: ""
  }, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.DemoBanner.DemoBanner.214739616",
    defaultMessage: "Play with <emphasis>GraphQL API</emphasis>",
    values: {
      emphasis: function emphasis(children) {
        return React.createElement(S.TextEmphasis, null, children);
      }
    }
  }))))));
};