import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
export var Money = function Money(_ref) {
  var _money$amount, _money$amount2;

  var money = _ref.money,
      defaultValue = _ref.defaultValue,
      negative = _ref.negative,
      props = _objectWithoutProperties(_ref, ["money", "defaultValue", "negative"]);

  if (!money) {
    return React.createElement("span", props, defaultValue);
  }

  return React.createElement("span", props, negative && "- ", money.currency && money.currency !== "" ? ((_money$amount = money.amount) === null || _money$amount === void 0 ? void 0 : _money$amount.toLocaleString("FR", {
    currency: money.currency,
    style: "currency",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })) || "" : ((_money$amount2 = money.amount) === null || _money$amount2 === void 0 ? void 0 : _money$amount2.toLocaleString("FR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })) || "");
};
Money.displayName = "Money";
export default Money;