import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  overflow: hidden\n  border-radius: 50%;\n  object-fit: cover;\n  width: 3rem;\n  height: 3rem;\n  margin: -2px;\n  padding: 2px;\n\n  transition: background 0.7s ease-out;\n\n  @media (min-width: ", ") {\n    width: 5rem;\n    height: 5rem;\n    margin: -3px;\n    padding: 3px;\n  }\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  align-self: center;\n  display: flex;\n  justify-content: center;\n  position: relative;\n  align-items: center;\n\n  @media (min-width: ", ") {\n    flex-direction: column;\n    height: 100%;\n  }\n\n  > div {\n    padding: 0.5rem 0;\n    overflow: hidden;\n    width: 100%;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    flex-shrink: 0;\n    transition: all 0.5s;\n\n    @media (min-width: ", ") {\n      flex-direction: column;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 25px;\n  margin-top: 1rem;\n  padding: 1rem 0;\n\n  @media (min-width: ", ") {\n    margin-top: unset;\n    flex-direction: column;\n    height: 100%;\n    width: 8%;\n    padding: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { Thumbnail } from "..";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Carousel = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Thumb = styled(Thumbnail)(_templateObject3(), function (props) {
  return props.theme.breakpoints.smallScreen;
}, function (props) {
  return props.active && "\n      background-color: ".concat(props.theme.colors.logoGreen, "\n    ");
});