import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  overflow: hidden;\n  flex-grow: 1;\n  width: 80%;\n\n  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,\n    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;\n\n  border-radius: 10px;\n\n  @media (min-width: 1220px) {\n    width: 400px;\n    flex-grow: unset;\n  }\n\n  img {\n    width: 100%;\n    object-fit: contain;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  bottom: 0%;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  top: 0%;\n\n  transform: rotate(180deg);\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 60px;\n  height: 35px;\n\n  @media (min-width: 1220px) {\n    width: 80px;\n    height: 50px;\n  }\n\n  position: absolute;\n  z-index: 1;\n  background-color: rgba(50, 50, 50, 0.3);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 60px;\n  height: 90px;\n\n  @media (min-width: 1220px) {\n    width: 80px;\n    height: 120px;\n  }\n\n  border-radius: 5px;\n\n  display: flex;\n  border-style: solid;\n  border-width: 2px;\n  border-color: ", ";\n  border-color: ", ";\n\n  justify-content: center;\n  overflow: hidden;\n  img {\n    width: 100%;\n    object-fit: cover;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  height: 100%;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  scrollbar-width: none;\n  ::-webkit-scrollbar {\n    width: 0px;\n  }\n\n  ul {\n    position: absolute;\n    display: block;\n    padding: 0;\n    margin: 0;\n\n    li:not(:last-of-type) {\n      margin-bottom: 1rem;\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  margin-right: 5px;\n  width: 60px;\n\n  @media (min-width: 1220px) {\n    width: 80px;\n    margin-right: 10px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  display: flex;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var ThumbnailsContainer = styled.div(_templateObject2());
export var ThumbnailList = styled.div(_templateObject3());
export var Thumbnail = styled.div(_templateObject4(), function (props) {
  return props.activeThumbnail === true ? props.theme.colors.thumbnailBorder : "transparent";
}, function (props) {
  return props.activeThumbnail === true ? "#000000bd" : "transparent";
});
export var Button = styled.div(_templateObject5());
export var TopButton = styled(Button)(_templateObject6());
export var BottomButton = styled(Button)(_templateObject7());
export var Preview = styled.div(_templateObject8());