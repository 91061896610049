import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n", "\n\tmutation RequestBulkCreate(\n\t\t$requests: [RequestCreateInput!]!\n\t) {\n\t\trequestBulkCreate(requests: $requests) {\n\t\t\terrors: bulkRequestErrors {\n\t\t\t\t...BulkRequestErrorFragment\n\t\t\t}\n\t\t\tcount\n\t\t\trequests{\n\t\t\t\tid\n\t\t\t\tcreated\n\t\t\t}\n\t\t}\n\t}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "@temp/core/mutations";
import { bulkRequestErrorFragment } from "@temp/fragments/errors";
var requestBulkCreateMutation = gql(_templateObject(), bulkRequestErrorFragment);
export var TypedRequestBulkCreateMutation = TypedMutation(requestBulkCreateMutation);