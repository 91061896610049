import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { ReviewListHeader, ProductPreview } from "@components/molecules";
import { ReviewList } from "@components/organisms";

var Page = function Page(_ref) {
  var reviews = _ref.reviews,
      sortOptions = _ref.sortOptions,
      onSort = _ref.onSort,
      onFilter = _ref.onFilter,
      activeSortOption = _ref.activeSortOption,
      product = _ref.product,
      activeFilters = _ref.activeFilters,
      foundComments = _ref.foundComments;
  return React.createElement("div", {
    className: "reviews"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(ProductPreview, {
    product: product
  }), React.createElement(ReviewListHeader, _extends({
    totalComments: product === null || product === void 0 ? void 0 : product.reviewsSummary.totalComments,
    foundComments: foundComments,
    handleClick: onFilter
  }, {
    sortOptions: sortOptions,
    onSort: onSort,
    activeSortOption: activeSortOption,
    activeFilters: activeFilters
  })), React.createElement(ReviewList, {
    reviews: reviews,
    foundComments: foundComments
  })));
};

export default Page;