import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n\n  @media (min-width: ", ") {\n    width: 50%;\n    padding-left: 1rem;\n  }\n\n  .line {\n    display: flex;\n    margin: 0.5rem auto 0;\n\n    justify-content: space-between;\n    align-items: baseline;\n\n    @media (min-width: ", ") {\n      margin: 0.5rem 0 auto;\n      width: 85%;\n    }\n\n    &__total {\n      margin: 0.5rem auto 0;\n\n      @media (min-width: ", ") {\n        margin: 0.5rem 0 auto;\n      }\n\n      padding-top: 0.5rem;\n      border-top: 1px solid #cecece;\n    }\n\n    .money {\n      color: ", ";\n      font-weight: 600;\n      margin-top: 2px;\n    }\n\n    .discount {\n      color: ", ";\n      font-weight: 600;\n    }\n  }\n\n  h6 {\n    font-weight: 500;\n    @media (min-width: ", ") {\n      font-size: 1rem;\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 500;\n  letter-spacing: -0.5px;\n  margin: 1rem 0 0.25rem;\n  font-size: 1.125rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin: 2rem 0 1rem;\n  font-weight: 500;\n  letter-spacing: -0.5px;\n  font-size: 1.375rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n\n  @media (min-width: ", ") {\n    width: 50%;\n    padding: 0 2.5rem 1rem 1rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n  width: 100%;\n  display: flex;\n  flex-direction: column\n  color: ", ";\n  font-weight: normal;\n  font-size: 0.8rem;\n\n  p {\n    line-height: 1.25rem;\n  }\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    font-size: 0.875rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.dark;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Addresses = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Title = styled.h3(_templateObject3());
export var Subtitle = styled.h5(_templateObject4());
export var Payment = styled.div(_templateObject5(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.colors.darkGreen;
}, function (props) {
  return props.theme.colors.redPrice;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});