import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n  min-height: 80vh;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  width: 100%\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  pointer-events: none;\n  ", "\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n  position: relative;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 1200px;\n  max-width: 100vw;\n  margin: 0 auto;\n  padding: 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Container = styled.div(_templateObject2());
export var LoadingContainer = styled.div(_templateObject3());
export var MainContainer = styled.div(_templateObject4());
export var Loading = styled.div(_templateObject5(), function (props) {
  return props.loading && "background-color: rgba(255,255,255,0.7);";
}, function (props) {
  return props.loading === false && "{\n      transition: all 0.8s ease-out;\n    }";
});
export var Right = styled.div(_templateObject6());