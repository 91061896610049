import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 10px;\n  display: flex;\n  overflow-x: scroll;\n  overflow-y: hidden;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1rem;\n  width: fit-content;\n  margin: auto;\n\n  position: relative;\n\n  &::before {\n    border-bottom: 1px solid #000;\n    content: \"\";\n    position: absolute;\n    bottom: 0;\n    right: 25%;\n    width: 50%;\n    margin: 0 auto;\n  }\n\n  margin-bottom: 3rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  border: 1px solid transparent;\n  // &::before {\n  //   border-bottom: 1px solid #000;\n  //   content: \"\";\n  //   position: absolute;\n  //   left: 0;\n  //   right: 0;\n  //   width: calc(100% - 6rem);\n  //   top: 0;\n  //   margin: 0 auto;\n  // }\n  margin-bottom: 2rem;\n\n  .dots {\n    overflow: hidden;\n    position: absolute;\n    opacity: 0.6;\n  }\n\n  .dots:first-of-type {\n    height: 3rem;\n    left: -1rem;\n    top: -3rem;\n\n    @media (min-width: ", ") {\n      height: 8rem;\n      left: -5rem;\n      top: -4rem;\n    }\n  }\n\n  .dots:nth-of-type(2) {\n    height: 3rem;\n    right: -1rem;\n    top: -3rem;\n\n    @media (min-width: ", ") {\n      height: 8rem;\n      right: -5rem;\n      top: -4rem;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Title = styled.h3(_templateObject2());
export var ProductList = styled.div(_templateObject3());