import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import Media from "react-responsive";
import { commonMessages } from "@temp/intl";
import { ProductDescription } from "@components/molecules";
import { ProductGallery, ProductReviewSummary, RelatedProducts } from "@components/organisms";
import AddToCartSection from "@components/organisms/AddToCartSection";
import { Breadcrumbs, OverlayContext, OverlayTheme, OverlayType } from "../../components";
import { generateCategoryUrl } from "../../core/utils";
import * as S from "./styles";
import ProductBoxes from "@components/molecules/ProductBoxes";
import GalleryCarousel from "./GalleryCarousel";
import { largeScreen } from "@styles/constants";

var populateBreadcrumbs = function populateBreadcrumbs(product) {
  return [{
    link: generateCategoryUrl(product.category.slug),
    value: product.category.name
  }];
};

var Page = function Page(_ref) {
  var add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      hoveredGroupImage = _React$useState2[0],
      setHoveredGroupImage = _React$useState2[1];

  var _React$useState3 = React.useState(""),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      variantId = _React$useState4[0],
      setVariantId = _React$useState4[1];

  var overlayContext = React.useContext(OverlayContext);
  var productGallery = React.useRef();
  var descriptionRef = React.useRef();

  var getImages = function getImages() {
    if (product.variants && variantId) {
      var variant = product.variants.find(function (variant) {
        return variant.id === variantId;
      });

      if (variant.images.length > 0) {
        return variant.images;
      }
    }

    return product.images;
  };

  var handleAddToCart = function handleAddToCart(variantId, quantity, price, isOverlay) {
    add(variantId, quantity); // fbAddToCart(product!.slug!, price);

    if (isOverlay) {
      overlayContext.show(OverlayType.cart, OverlayTheme.right);
    } else {
      setTimeout(function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        document.querySelector("#cart-dropdown-body").classList.add("visible");
      }, 1000);
    }
  };

  var handleScrollToDesc = function handleScrollToDesc() {
    var y = descriptionRef.current.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      behavior: "smooth",
      top: y
    });
  };

  var addToCartSection = React.createElement(AddToCartSection, {
    items: items,
    productId: product.id,
    name: product.name,
    ratingsCount: product.ratingsCount,
    discount: product.pricing.discount,
    productRating: product.rating,
    productVariants: product.variants,
    productPricing: product.pricing,
    queryAttributes: queryAttributes,
    setVariantId: setVariantId,
    variantId: variantId,
    onAddToCart: handleAddToCart,
    onAttributeChangeHandler: onAttributeChangeHandler,
    isAvailableForPurchase: product.isAvailableForPurchase,
    availableForPurchase: product.availableForPurchase,
    productGroup: product.productGroup,
    onGroupHover: setHoveredGroupImage,
    description: product.descriptionJson,
    onScrollToDesc: handleScrollToDesc,
    vendor: product.vendor
  });
  return React.createElement(React.Fragment, null, React.createElement(S.Breadcrumbs, null, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(product)
  }), React.createElement(Media, {
    maxWidth: largeScreen
  }, React.createElement(S.Hint, null, React.createElement("p", null, "Tapez sur l'image pour une meilleure qualit\xE9e")))), React.createElement(S.Container, null, React.createElement(S.Wrapper, null, React.createElement("div", {
    className: "gallery",
    ref: productGallery
  }, React.createElement(Media, {
    minWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(ProductGallery, _extends({
      images: getImages(),
      thumbnails: product.thumbnails
    }, {
      hoveredGroupImage: hoveredGroupImage,
      rimProps: {
        enlargedImagePortalId: "portal",
        enlargedImageContainerDimensions: {
          width: "123%",
          height: "100%"
        }
      }
    })) : React.createElement(GalleryCarousel, {
      images: getImages(),
      thumbnails: product.mobileThumbnails
    });
  })), React.createElement("div", {
    className: "info"
  }, addToCartSection, React.createElement("div", {
    id: "portal",
    className: "portal"
  })), React.createElement("div", {
    className: "right"
  }, React.createElement(ProductBoxes, {
    highlight: product.descriptionJson
  })))), React.createElement("div", {
    className: "container"
  }, React.createElement(RelatedProducts, {
    products: product.relatedProducts,
    title: commonMessages.suggested.defaultMessage
  })), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    ref: descriptionRef
  }, React.createElement(ProductDescription, {
    productName: product.name,
    imageUrl: product.images[0].url,
    imageAlt: product.images[0].alt,
    descriptionJson: product.descriptionJson,
    attributes: product.attributes
  }))), React.createElement("div", {
    className: "container"
  }, React.createElement(ProductReviewSummary, {
    rating: product.rating,
    totalRatings: product.ratingsCount,
    ratingsSummary: product.reviewsSummary.ratingsSummary,
    previewReviews: product.previewReviews
  })));
};

export default Page;