import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SvgChevronHorizontal from "images/SvgChevronHorizontal";
import { ProductTile } from "..";
import * as S from "./styles";

var CustomPrevArrow = function CustomPrevArrow(props) {
  var onClick = props.onClick;
  return React.createElement(S.ChevronContainerPrevious, {
    onClick: onClick
  }, React.createElement(SvgChevronHorizontal, null));
};

var CustomNextArrow = function CustomNextArrow(props) {
  var onClick = props.onClick;
  return React.createElement(S.ChevronContainerNext, {
    onClick: onClick
  }, React.createElement(SvgChevronHorizontal, null));
};

var settings = {
  slidesToShow: 4,
  slidesToScroll: 2,
  prevArrow: React.createElement(CustomPrevArrow, null),
  nextArrow: React.createElement(CustomNextArrow, null),
  responsive: [{
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: true
    }
  }]
};
export var CarouselProducts = function CarouselProducts(_ref) {
  var products = _ref.products;
  return React.createElement(S.Wrapper, null, React.createElement(Slider, settings, products.map(function (product) {
    return React.createElement(S.TileContainer, null, React.createElement(ProductTile, {
      product: product
    }));
  })));
};
CarouselProducts.displayName = "CarouselProducts";
export default CarouselProducts;