import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { formatDateTime } from "@temp/misc";
import { requestDetailsUrl } from "@temp/app/routes";
import * as S from "./styles";
import { commonMessages, translateRequestReason, translateRequestStatus } from "@temp/intl";
export var OrderRequests = function OrderRequests(_ref) {
  var lines = _ref.lines;
  var intl = useIntl();
  return React.createElement(S.Wrapper, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, commonMessages.returns)), lines.map(function (line) {
    var request = line.request;
    return React.createElement(Link, {
      to: requestDetailsUrl(request.id)
    }, React.createElement(S.Item, null, React.createElement(S.Thumb, {
      source: line
    }), React.createElement(S.Info, null, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.createdAt), " ", ": "), formatDateTime(new Date(request.created), intl)), React.createElement(S.Status, null, translateRequestStatus(request === null || request === void 0 ? void 0 : request.status, intl)), React.createElement(S.Reason, null, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.requestReason), " ", ": "), translateRequestReason(request === null || request === void 0 ? void 0 : request.reason, intl)), React.createElement(S.Seemore, {
      color: "outline"
    }, "Voir plus")));
  }));
};
OrderRequests.displayName = "OrderRequests";
export default OrderRequests;