import { HomepageNewArrivals } from "@components/molecules";
import { getEvenArray } from "@temp/core/utils";
import { commonMessages } from "@temp/intl";
import { maybe } from "@utils/misc";
import React from "react";
import { TypedNewArrivalsCollectionProductsQuery } from "../queries";
export var NewArrivals = function NewArrivals() {
  return React.createElement(TypedNewArrivalsCollectionProductsQuery, null, function (_ref) {
    var data = _ref.data;
    var products = maybe(function () {
      return data.collection.products.edges;
    }, []);

    if (products.length) {
      return React.createElement(HomepageNewArrivals, {
        products: getEvenArray(products).map(function (p) {
          return p.node;
        }),
        title: commonMessages.newArrivals.defaultMessage
      });
    }

    return React.createElement(React.Fragment, null);
  });
};