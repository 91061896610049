import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import * as S from "./styles";
export var ChipPush = function ChipPush(_ref) {
  var children = _ref.children,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "primary" : _ref$color,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      handleClick = _ref.handleClick,
      props = _objectWithoutProperties(_ref, ["children", "color", "size", "handleClick"]);

  var ChipWithTheme = color === "primary" ? S.Primary : S.Secondary;
  return React.createElement(ChipWithTheme, _extends({
    color: color,
    size: size
  }, props), React.createElement(S.Text, {
    size: size
  }, children));
};
ChipPush.displayName = "ChipPush";
export default ChipPush;