import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
export var SvgSearch = function SvgSearch(props) {
  return React.createElement("svg", _extends({
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24"
  }, props), React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.494 18.433a11.076 11.076 0 0 0 2.746-7.313C22.24 4.983 17.257 0 11.12 0S0 4.983 0 11.12s4.983 11.12 11.12 11.12c2.799 0 5.357-1.036 7.313-2.745l4.284 4.282c.14.14.332.221.53.221a.753.753 0 0 0 .531-1.28l-4.284-4.285Zm-1.41-.678a9.712 9.712 0 0 0 1.9-2.892 9.644 9.644 0 0 0 .756-3.742v-.002a9.635 9.635 0 0 0-.755-3.74A9.646 9.646 0 0 0 17.92 4.32a9.576 9.576 0 0 0-6.8-2.82 9.575 9.575 0 0 0-6.802 2.82 9.623 9.623 0 0 0-2.062 3.056 9.62 9.62 0 0 0 2.062 10.545 9.573 9.573 0 0 0 6.799 2.818h.004a9.566 9.566 0 0 0 3.74-.755 9.67 9.67 0 0 0 2.892-1.899.742.742 0 0 1 .33-.33Z",
    fill: "#ffffff"
  }));
};
export default SvgSearch;