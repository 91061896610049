import urljoin from "url-join";
var slugUrl = ":slug([a-zà-ú-0-9]+)/:id([0-9]+)/";
var onlySlugUrl = ":slug([a-zà-ú-0-9]+)/";
export var baseUrl = "/";
export var searchUrl = "".concat(baseUrl, "search/");
export var categoryUrl = "".concat(baseUrl, "category/").concat(onlySlugUrl);
export var collectionUrl = "".concat(baseUrl, "collection/").concat(onlySlugUrl);
export var vendorPath = "".concat(baseUrl, "boutique/").concat(onlySlugUrl);
export var productUrl = "".concat(baseUrl, "product/").concat(onlySlugUrl);
export var cartUrl = "".concat(baseUrl, "cart/:token?/"); // export const checkoutLoginUrl = `${baseUrl}login/`;

export var pageUrl = "".concat(baseUrl, "page/:slug/");
export var guestOrderDetailsUrl = "/order-history/:token/";
export var accountUrl = "".concat(baseUrl, "account/");
export var accountConfirmUrl = "".concat(baseUrl, "account-confirm/");
export var orderHistoryUrl = "".concat(baseUrl, "my-orders/");
export var addressBookUrl = "".concat(baseUrl, "address-book/");
export var requestsUrl = "".concat(baseUrl, "requests/");
export var requestCreatePath = function requestCreatePath(id) {
  return urljoin(baseUrl, "new-request", id);
};
export var requestCreateUrl = function requestCreateUrl(id) {
  return requestCreatePath(encodeURIComponent(id));
};
export var requestDetailsPath = function requestDetailsPath(id) {
  return urljoin(baseUrl, "request", id);
};
export var requestDetailsUrl = function requestDetailsUrl(id) {
  return requestDetailsPath(encodeURIComponent(id));
};
export var passwordResetUrl = "".concat(baseUrl, "reset-password/");
export var passwordForgottenPath = "".concat(baseUrl, "password-forgotten");
export var checkoutUrl = "".concat(baseUrl, "checkout/");
export var orderFinalizedUrl = "".concat(baseUrl, "order-finalized/");
export var clickToPaySuccessPath = "".concat(baseUrl, "clictopaysuccess/");
export var clickToPayFailPath = "".concat(baseUrl, "clictopayfail/");
export var loginPath = "".concat(baseUrl, "login");
export var registerPath = "".concat(baseUrl, "register");
export var reviewsUrl = "".concat(productUrl, "reviews/");
export var loginRedirectPath = function loginRedirectPath(redirect) {
  return "".concat(loginPath, "?redirect=").concat(encodeURIComponent(redirect));
};
export var getVendorPath = function getVendorPath(slug) {
  return "/boutique/".concat(slug);
};
export var getCollectionPath = function getCollectionPath(slug) {
  return "/collection/".concat(slug);
};