import React from "react";
import { Address, Tile } from "@components/atoms";
import SvgTrash from "images/SvgTrash";
import SvgEdit from "images/SvgEdit";
import * as S from "./styles";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
export var AddressTile = function AddressTile(_ref) {
  var onEdit = _ref.onEdit,
      onRemove = _ref.onRemove,
      address = _ref.address;
  var footer = React.createElement(S.FooterContent, null, React.createElement("div", null, React.createElement(S.Item, {
    onClick: onEdit
  }, React.createElement(SvgEdit, null), React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.edit)))), React.createElement("div", null, React.createElement(S.Item, {
    onClick: function onClick() {
      return onRemove(address.id);
    }
  }, React.createElement(SvgTrash, null), React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.delete)))));
  var content = React.createElement(Address, address);
  return React.createElement(S.Wrapper, {
    "data-test-billing-default": address.isDefaultBillingAddress,
    "data-test-shipping-default": address.isDefaultShippingAddress
  }, React.createElement(Tile, {
    footer: footer
  }, content));
};