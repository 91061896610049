import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgTick = function SvgTick(props) {
  var _props$fill = props.fill,
      fill = _props$fill === void 0 ? "black" : _props$fill;
  return React.createElement("svg", _extends({
    width: 16,
    height: 16,
    xmlns: "http://www.w3.org/2000/svg"
  }, props), React.createElement("path", {
    fill: "none",
    d: "M0 0h16v16H0z"
  }), React.createElement("path", {
    fill: fill,
    d: "M0 9.014 1.414 7.6l3.59 3.589L14.593 1.6l1.414 1.414L5.003 14.017 0 9.014Z"
  }));
};

export default SvgTick;