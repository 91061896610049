import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { useIntl } from "react-intl";
import { prodListHeaderCommonMsg } from "@temp/intl";
import { StringParam, useQueryParam } from "use-query-params";
import { Loader } from "@components/atoms";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { PRODUCTS_PER_PAGE } from "../../core/config";
import { convertSortByFromString, convertToAttributeScalar, getPriceGteFromFilter, getPriceLteFromFilter, maybe } from "../../core/utils";
import Page from "./Page";
import { TypedCollectionProductsDataQuery, TypedCollectionProductsQuery } from "./queries";
export var FilterQuerySet = {
  encode: function encode(valueObj) {
    var str = [];
    Object.keys(valueObj).forEach(function (value) {
      str.push("".concat(value, "_").concat(valueObj[value].join("_")));
    });
    return str.join(".");
  },
  decode: function decode(strValue) {
    var obj = {};
    var propsWithValues = strValue.split(".").filter(function (n) {
      return n;
    });
    propsWithValues.map(function (value) {
      var propWithValues = value.split("_").filter(function (n) {
        return n;
      });
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  }
};
export var PriceFilterQuerySet = {
  encode: function encode(valueObj) {
    return valueObj;
  },
  decode: function decode(strValue) {
    return strValue.split("-").map(Number);
  }
};
export var View = function View(_ref) {
  var match = _ref.match;

  var _useQueryParam = useQueryParam("sortBy", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      sort = _useQueryParam2[0],
      setSort = _useQueryParam2[1];

  var _useQueryParam3 = useQueryParam("filters", FilterQuerySet),
      _useQueryParam4 = _slicedToArray(_useQueryParam3, 2),
      attributeFilters = _useQueryParam4[0],
      setAttributeFilters = _useQueryParam4[1];

  var _useQueryParam5 = useQueryParam("price", PriceFilterQuerySet),
      _useQueryParam6 = _slicedToArray(_useQueryParam5, 2),
      priceFilter = _useQueryParam6[0],
      setPriceFilter = _useQueryParam6[1];

  var intl = useIntl();

  var clearFilters = function clearFilters() {
    setAttributeFilters({});
    setPriceFilter("");
  };

  var onFiltersChange = function onFiltersChange(name, value) {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes["".concat(name)].length === 1) {
          var att = _objectSpread({}, attributeFilters);

          delete att["".concat(name)];
          setAttributeFilters(_objectSpread({}, att));
        } else {
          setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), attributeFilters["".concat(name)].filter(function (item) {
            return item !== value;
          }))));
        }
      } else {
        setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [].concat(_toConsumableArray(attributeFilters["".concat(name)]), [value]))));
      }
    } else {
      setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [value])));
    }

    window.scrollTo({
      top: 160
    });
  };

  var filters = {
    attributes: attributeFilters,
    pageSize: PRODUCTS_PER_PAGE,
    priceGte: getPriceGteFromFilter(priceFilter),
    priceLte: getPriceLteFromFilter(priceFilter),
    sortBy: sort || null
  };

  var variables = _objectSpread({}, filters, {
    attributes: filters.attributes ? convertToAttributeScalar(filters.attributes) : {},
    slug: match.params.slug,
    sortBy: convertSortByFromString(filters.sortBy),
    priceGte: getPriceGteFromFilter(priceFilter),
    priceLte: getPriceLteFromFilter(priceFilter)
  });

  var sortOptions = [{
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsClear),
    value: null
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPrice),
    value: "price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPriceDsc),
    value: "-price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsName),
    value: "name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsNameDsc),
    value: "-name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc),
    value: "-updated_at"
  }];

  var onSubmitPriceFilter = function onSubmitPriceFilter(data) {
    var min = data.min,
        max = data.max;
    if (!!min && !max) setPriceFilter("".concat(min, "-0"));
    if (!min && !!max) setPriceFilter("0-".concat(max));
    if (!!min && !!max) setPriceFilter("".concat(Math.min(min, max), "-").concat(Math.max(min, max)));
    if (!min && !max) return;
    window.scrollTo({
      top: 160
    });
  };

  return React.createElement("div", {
    className: "ContainerFullScreen"
  }, React.createElement(NetworkStatus, null, function (isOnline) {
    return React.createElement(TypedCollectionProductsDataQuery, {
      variables: variables,
      errorPolicy: "all"
    }, function (collectionData) {
      var _collectionData$data, _collectionData$data$, _collectionData$data$2, _collectionData$data2, _collectionData$data3, _collectionData$data4;

      if (collectionData.data && collectionData.data.collection === null) {
        return React.createElement(NotFound, null);
      }

      if (!isOnline) {
        return React.createElement(OfflinePlaceholder, null);
      }

      var canDisplayFilters = !!((_collectionData$data = collectionData.data) === null || _collectionData$data === void 0 ? void 0 : (_collectionData$data$ = _collectionData$data.collection) === null || _collectionData$data$ === void 0 ? void 0 : (_collectionData$data$2 = _collectionData$data$.products) === null || _collectionData$data$2 === void 0 ? void 0 : _collectionData$data$2.attributes) && !!((_collectionData$data2 = collectionData.data) === null || _collectionData$data2 === void 0 ? void 0 : (_collectionData$data3 = _collectionData$data2.collection) === null || _collectionData$data3 === void 0 ? void 0 : _collectionData$data3.name);
      return React.createElement(TypedCollectionProductsQuery, {
        variables: _objectSpread({}, variables, {
          id: (_collectionData$data4 = collectionData.data) === null || _collectionData$data4 === void 0 ? void 0 : _collectionData$data4.collection.id
        })
      }, function (collectionProductsData) {
        if (!canDisplayFilters && collectionProductsData.loading) {
          return React.createElement(Loader, null);
        }

        if (canDisplayFilters) {
          var handleLoadMore = function handleLoadMore() {
            return collectionProductsData.loadMore(function (prev, next) {
              return {
                collection: _objectSpread({}, prev.collection, {
                  products: _objectSpread({}, prev.collection.products, {
                    edges: [].concat(_toConsumableArray(prev.collection.products.edges), _toConsumableArray(next.collection.products.edges)),
                    pageInfo: next.collection.products.pageInfo
                  })
                })
              };
            }, {
              after: collectionProductsData.data.collection.products.pageInfo.endCursor
            });
          };

          return React.createElement(MetaWrapper, {
            meta: {
              description: collectionData.data.collection.seoDescription,
              title: collectionData.data.collection.seoTitle,
              type: "product.collection"
            }
          }, React.createElement(Page, {
            clearPriceFilter: function clearPriceFilter() {
              return setPriceFilter("");
            },
            clearFilters: clearFilters,
            attributes: collectionData.data.collection.products.attributes,
            collection: collectionData.data.collection,
            displayLoader: collectionData.loading,
            hasNextPage: maybe(function () {
              return collectionProductsData.data.collection.products.pageInfo.hasNextPage;
            }, false),
            sortOptions: sortOptions,
            activeSortOption: filters.sortBy,
            filters: filters,
            products: collectionProductsData.data.collection.products,
            onAttributeFiltersChange: onFiltersChange,
            onLoadMore: handleLoadMore,
            activeFilters: filters.attributes ? Object.keys(filters.attributes).length : 0,
            onOrder: function onOrder(value) {
              setSort(value.value);
            },
            activePriceFilter: priceFilter,
            setAttributeFilters: setAttributeFilters,
            onSubmitPriceFilter: onSubmitPriceFilter,
            loading: collectionData.loading
          }));
        }

        return null;
      });
    });
  }));
};
export default View;