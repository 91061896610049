import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  margin: auto;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  border-bottom: 1px solid #b4b4b4;\n\n  > div {\n    display: flex;\n    width: 80%;\n    justify-content: space-between;\n    align-items: center;\n    margin: 1.5rem 0 0.5rem;\n    padding: 0;\n\n    font-weight: ", ";\n    font-size: ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n  .overlay__header {\n    padding: 2rem 0;\n  }\n\n  .overlay__body {\n    overflow: auto;\n    padding: 0 0 2rem;\n    flex: 1;\n  }\n\n  .overlay__footer {\n    padding: 1rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Header = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h3FontSize;
});
export var Content = styled.div(_templateObject3());