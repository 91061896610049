import React from "react";
import * as S from "./styles";
export var Loader = function Loader(_ref) {
  var fullScreen = _ref.fullScreen;
  return React.createElement("div", null, React.createElement(S.Wrapper, {
    fullScreen: !!fullScreen
  }, React.createElement("svg", {
    "data-name": "Groupe 51",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 279.777 172.306",
    fill: "#96cbc7",
    className: "dots"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 62",
    d: "M79.113 39.56a39.523 39.523 0 11-11.588-28 39.556 39.556 0 0111.588 28z",
    className: "path1"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 61",
    d: "M179.392 39.56A39.556 39.556 0 11139.872 0a39.556 39.556 0 0139.52 39.56z",
    className: "path2"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 60",
    d: "M279.774 39.56A39.556 39.556 0 11240.22 0a39.556 39.556 0 0139.554 39.56z",
    className: "path3"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 59",
    d: "M226.837 132.716a39.556 39.556 0 11-39.557-39.523 39.556 39.556 0 0139.556 39.523z",
    className: "path4"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 58",
    d: "M132.12 132.717a39.589 39.589 0 11-11.677-28.01 39.556 39.556 0 0111.677 28.01z",
    className: "path5"
  }))));
};