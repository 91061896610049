import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid ", ";\n  margin: 0;\n  padding: 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 60px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 2rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n\n  p {\n    font-size: 0.8rem;\n    max-width: 60%;\n    margin-left: 1rem;\n    line-height: 1.25rem;\n  }\n\n  @media (min-width: ", ") {\n    p {\n      font-size: inherit;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { Thumbnail } from "@components/molecules";
export var Lines = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Line = styled.div(_templateObject2());
export var Thumb = styled(Thumbnail)(_templateObject3());
export var HR = styled.hr(_templateObject4(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});