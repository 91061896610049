import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 1rem 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 500;\n  font-size: 1.125rem;\n  margin-bottom: 0.5rem;\n\n  @media (min-width: ", ") {\n    font-size: 1.25rem;\n    margin-bottom: 1rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border: 1px solid ", ";\n  margin-top: -3rem;\n  background-color: white;\n  padding: 1rem;\n  border-radius: 10px;\n\n  @media (min-width: ", ") {\n    border: none;\n    padding: unset;\n  }\n\n  // checkbox selector\n  section:last-of-type {\n    > div:first-of-type {\n      font-size: 0.8rem;\n      position: relative;\n\n      @media (min-width: ", ") {\n        font-size: 1rem;\n      }\n    }\n  }\n\n  .errors {\n    margin: 0.5rem 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.grayBorder;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Title = styled.h3(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Divider = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});