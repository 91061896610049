import * as React from "react";
import "./scss/index.scss";
import SvgLogoFooter from "images/SvgLogoFooter";
import { useLocation } from "react-router";
import Media from "react-media";
import { largeScreen } from "@styles/constants";
import { Link } from "react-router-dom";
import { generatePageUrl } from "@utils/core";
var EXCLUDED_PATHS_MOBILE = ["checkout"];

var Footer = function Footer() {
  var location = useLocation();
  var showMobile = EXCLUDED_PATHS_MOBILE.reduce(function (p, c) {
    return p && !location.pathname.includes(c);
  }, true);

  var Component = function Component() {
    return React.createElement("div", {
      className: "footer",
      id: "footer"
    }, React.createElement("div", {
      className: "container"
    }, React.createElement(SvgLogoFooter, null), React.createElement("div", {
      className: "footer__content"
    }, React.createElement("h2", null, "Wonder"), React.createElement("div", {
      className: "footer__columns"
    }, React.createElement("div", {
      className: "footer__columns__column__text"
    }, React.createElement("p", null, "Appelez le 53 115 680 ou envoyez-nous un mail \xE0 contact@wonder.tn")), React.createElement("div", {
      className: "footer__columns__column"
    }, React.createElement("h3", {
      className: "title"
    }, "Partenariat"), React.createElement("div", {
      className: "items"
    }, React.createElement(Link, {
      to: generatePageUrl("vendez-sur-wonder")
    }, "Vendez sur Wonder"))), React.createElement("div", {
      className: "footer__columns__column"
    }, React.createElement("h3", {
      className: "title"
    }, "A Propos"), React.createElement("div", {
      className: "items"
    }, React.createElement(Link, {
      to: generatePageUrl("a-propos")
    }, "Qui sommes nous"), React.createElement(Link, {
      to: "/page/conditions-generales-de-vente/"
    }, "Conditions G\xE9n\xE9rales de Vente"), React.createElement(Link, {
      to: generatePageUrl("politique-de-confidentialite")
    }, "Politique de Confidentialit\xE9"), React.createElement(Link, {
      to: generatePageUrl("protection-de-donnees")
    }, "Protection de Donn\xE9es"), React.createElement(Link, {
      to: generatePageUrl("mentions-legales")
    }, "Mentions L\xE9gales"))), React.createElement("div", {
      className: "footer__columns__column"
    }, React.createElement("h3", {
      className: "title"
    }, "Aide"), React.createElement("div", {
      className: "items"
    }, React.createElement(Link, {
      to: generatePageUrl("service-client")
    }, "Service client"), React.createElement(Link, {
      to: generatePageUrl("conditions-de-retour")
    }, "Retourner un produit"), React.createElement(Link, {
      to: generatePageUrl("conditions-dechange")
    }, "\xC9changer un produit")))))));
  };

  return React.createElement(Media, {
    query: {
      maxWidth: largeScreen
    }
  }, function (matches) {
    return matches ? showMobile && React.createElement(Component, null) : React.createElement(Component, null);
  });
};

export default Footer;
/*
{SOCIAL_MEDIA.map(medium => (
  <SocialMediaIcon medium={medium} key={medium.ariaLabel} />
))}
*/