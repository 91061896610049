import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
import { accountMessages } from "@temp/intl";
import { Button } from "@components/atoms";
export var OrderUnfulfilledItems = function OrderUnfulfilledItems(_ref) {
  var lines = _ref.lines,
      isCanceled = _ref.isCanceled,
      canCancel = _ref.canCancel,
      onCancel = _ref.onCancel;
  return React.createElement(React.Fragment, null, React.createElement(S.Wrapper, null, React.createElement(S.Lines, null, lines.map(function (line) {
    var _line$variant;

    return React.createElement(S.Line, {
      key: line === null || line === void 0 ? void 0 : line.id
    }, React.createElement(S.LineThumbnail, {
      source: line
    }), React.createElement(S.LineContent, null, React.createElement("p", null, line === null || line === void 0 ? void 0 : line.productName, " - ", line === null || line === void 0 ? void 0 : (_line$variant = line.variant) === null || _line$variant === void 0 ? void 0 : _line$variant.name), (line === null || line === void 0 ? void 0 : line.quantity) > 1 ? React.createElement("p", {
      className: "quantity"
    }, React.createElement("span", null, "Quantit\xE9 : "), " ", line === null || line === void 0 ? void 0 : line.quantity) : null, React.createElement(S.LinePrice, {
      taxedMoney: line === null || line === void 0 ? void 0 : line.totalPrice
    })));
  })), React.createElement(S.Status, null, React.createElement("p", null, isCanceled ? React.createElement(FormattedMessage, accountMessages.canceled) : React.createElement(FormattedMessage, accountMessages.unfulfilled)), canCancel && !isCanceled && React.createElement(S.Cancel, null, React.createElement(Button, {
    color: "outline",
    onClick: onCancel
  }, React.createElement(FormattedMessage, accountMessages.cancelOrder))))));
};
OrderUnfulfilledItems.displayName = "OrderUnfulfilledItems";
export default OrderUnfulfilledItems;