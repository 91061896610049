import React from "react";
import Media from "react-responsive";
import { CarouselProducts, CarouselProductsMobile } from "@components/molecules";
import * as S from "./styles";
import { largeScreen } from "@styles/constants";
import SvgLogodots from "images/SvgLogoDots";
export var RelatedProducts = function RelatedProducts(_ref) {
  var products = _ref.products,
      title = _ref.title,
      _ref$withLogoDots = _ref.withLogoDots,
      withLogoDots = _ref$withLogoDots === void 0 ? false : _ref$withLogoDots;
  if ((products === null || products === void 0 ? void 0 : products.length) % 2 === 1) products.pop();
  return React.createElement(S.Wrapper, null, withLogoDots && React.createElement(React.Fragment, null, React.createElement(SvgLogodots, {
    className: "dots"
  }), React.createElement(SvgLogodots, {
    className: "dots"
  })), title && React.createElement(S.Title, null, title), products && React.createElement(Media, {
    minWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(CarouselProducts, {
      products: products
    }) : React.createElement(CarouselProductsMobile, {
      products: products
    });
  }));
};
RelatedProducts.displayName = "RelatedProducts";
export default RelatedProducts;