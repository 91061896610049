import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

var _this = this;

import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { StringParam, useQueryParams } from "use-query-params";
import { TypedAccountConfirmMutation } from "./queries";
import "./scss/index.scss";
import { HeaderAuth } from "@components/organisms";
import { Notification } from "@components/molecules";
import SvgRedX from "images/SvgRedX";
import SvgGreenTick from "images/SvgGreenTick";
import * as S from "./styles";

var AccountConfirm = function AccountConfirm(_ref) {
  var history = _ref.history;

  var _useQueryParams = useQueryParams({
    email: StringParam,
    token: StringParam
  }),
      _useQueryParams2 = _slicedToArray(_useQueryParams, 1),
      query = _useQueryParams2[0];

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      isSuccess = _React$useState2[0],
      setIsSuccess = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isFailure = _React$useState4[0],
      setIsFailure = _React$useState4[1];

  var k = uuidv4();

  var saveTokens = function saveTokens(data) {
    var csrfToken = data.csrfToken,
        token = data.token;
    localStorage.setItem("token", token);
    localStorage.setItem("csrf_token", csrfToken);
  };

  React.useEffect(function () {
    _this.accountManagerFn({
      variables: {
        email: query.email,
        token: query.token
      }
    }).then(function (result) {
      var possibleErrors = result.data.confirmAccount.accountErrors;
      if (possibleErrors.length === 0) saveTokens(result.data.confirmAccount);
      if (possibleErrors.length > 0) setIsFailure(true);else setIsSuccess(true);
    }).catch(function () {
      setIsFailure(true);
    }).finally(function () {
      setTimeout(function () {
        return window.location.assign(window.location.origin);
      }, 5000);
    });
  }, []);
  return React.createElement(TypedAccountConfirmMutation, null, function (accountConfirm) {
    _this.accountManagerFn = accountConfirm;
    return React.createElement(React.Fragment, null, React.createElement(HeaderAuth, null), React.createElement(S.Wrapper, null, isSuccess && React.createElement(Notification, {
      title: "Votre compte a \xE9t\xE9 confirm\xE9 avec succ\xE8s \uD83D\uDE4C",
      expl: "Redirection vers la page d'accueil dans quelques instants.",
      color: "green",
      Svg: SvgGreenTick
    }), isFailure && React.createElement(Notification, {
      title: "Une erreur est survenue \uD83D\uDE22",
      expl: "Veuillez nous contacter par e-mail sur : contact@wonder.tn ou appelez-nous sur le 20 500 500. Redirection vers la page d'accueil dans quelques instants.",
      color: "red",
      Svg: SvgRedX
    })));
  });
};

export default AccountConfirm;