import React from "react";
import * as S from "./styles";
import { ProductTileAlternative } from "../ProductTileAlternative";
export var CarouselProductsMobile = function CarouselProductsMobile(_ref) {
  var products = _ref.products;
  return React.createElement(React.Fragment, null, React.createElement(S.Wrapper, null, products.map(function (product) {
    return React.createElement(ProductTileAlternative, {
      product: product
    });
  })));
};
export default CarouselProductsMobile;