import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;      \n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: ", ";\n  max-width: 100vw;\n  margin: 0 auto 4rem;\n  padding: 0 ", ";\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Container = styled.div(_templateObject(), function (props) {
  return "".concat(props.theme.container.width, "px");
}, function (props) {
  return props.theme.spacing.spacer;
}, media.largeScreen(_templateObject2()));