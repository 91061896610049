import React from "react";
import { useAuth, useCart } from "@lastmaj/saleor-sdk";
import { accountUrl, cartUrl, orderHistoryUrl, loginRedirectPath } from "@temp/app/routes";
import { SvgCart, SvgUser } from "@temp/images";
import * as S from "./styles";
import { useLocation } from "react-router";
import SvgOrdersBig from "images/SvgOrdersBig";
var excludedPaths = ["checkout", "page"];
export var NavbarBottomMobile = function NavbarBottomMobile() {
  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useCart = useCart(),
      items = _useCart.items;

  var location = useLocation();
  var isValidLocation = excludedPaths.reduce(function (p, c) {
    return p && !location.pathname.includes(c);
  }, true);
  var cartItemsQuantity = items && items.reduce(function (prevVal, currVal) {
    return prevVal + currVal.quantity;
  }, 0) || 0;
  return React.createElement(React.Fragment, null, isValidLocation && React.createElement(S.Wrapper, null, React.createElement(S.Item, {
    to: user ? accountUrl : loginRedirectPath(location.pathname)
  }, React.createElement(S.SvgContainer, null, React.createElement(SvgUser, null)), user ? React.createElement("p", null, "Hi, ", user.firstName) : React.createElement("p", null, "Se connecter")), React.createElement(S.Item, {
    to: cartUrl
  }, React.createElement(S.SvgContainer, null, React.createElement(SvgCart, null), cartItemsQuantity > 0 ? React.createElement("span", null, cartItemsQuantity) : null), React.createElement("p", null, "Panier")), user && React.createElement(S.Item, {
    to: orderHistoryUrl
  }, React.createElement(SvgOrdersBig, null), React.createElement("p", null, "Commandes"))));
};
NavbarBottomMobile.displayName = "NavbarBottomMobile";
export default NavbarBottomMobile;