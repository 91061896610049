import React from "react";
import { Route, Switch } from "react-router-dom";
import Media from "react-responsive";
import { Footer, MainMenu, MetaConsumer, OverlayManager, OverlayProvider } from "../components";
import ShopProvider from "../components/ShopProvider";
import "../globalStyles/scss/index.scss";
import { Routes } from "./routes";
import Notifications from "./Notifications";
import * as paths from "./routes/paths";
import { AccountConfirm } from "@temp/views/Account";
import { largeScreen } from "@styles/constants";
import { FooterPre, NavbarBottomMobile } from "@components/organisms";
import ScrollToTopButton from "../@next/components/atoms/ScrollToTopButton/ScrollToTopButton";
import { Login, Register, RequestPasswordReset } from "@pages";

var App = function App() {
  return React.createElement(ShopProvider, null, React.createElement(Switch, null, React.createElement(Route, {
    path: paths.loginPath,
    component: Login
  }), React.createElement(Route, {
    path: paths.registerPath,
    component: Register
  }), React.createElement(Route, {
    path: paths.accountConfirmUrl,
    component: AccountConfirm
  }), React.createElement(Route, {
    path: paths.passwordForgottenPath,
    component: RequestPasswordReset
  }), React.createElement(OverlayProvider, null, React.createElement(MetaConsumer, null), React.createElement("header", null, React.createElement(MainMenu, null)), React.createElement(Routes, null), React.createElement(FooterPre, null), React.createElement(Footer, null), React.createElement(OverlayManager, null), React.createElement(Notifications, null), React.createElement(ScrollToTopButton, null), React.createElement(Media, {
    maxWidth: largeScreen
  }, React.createElement(NavbarBottomMobile, null)))));
};

export default App;