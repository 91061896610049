import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  background-color: #ffedcd;\n  width: 100%;\n  height: 13rem;\n  position: relative;\n  margin-bottom: 1rem;\n\n  @media (min-width: ", ") {\n    height: unset;\n    grid-column: 9/20;\n    grid-row: 2;\n    margin-bottom: unset;\n  }\n\n  img {\n    position: absolute;\n    width: 40%;\n    right: 0;\n    bottom: 28%;\n\n    @media (min-width: ", ") {\n      width: 50%;\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  background-color: #daf3ed;\n  width: 100%;\n  height: 13rem;\n  position: relative;\n  margin-bottom: 1rem;\n\n  @media (min-width: ", ") {\n    height: unset;\n    grid-column: 1/9;\n    grid-row: 2;\n    margin-bottom: unset;\n  }\n\n  img {\n    position: absolute;\n    width: 30%;\n    bottom: 5%;\n    right: 8%;\n    max-height: 98%;\n\n    @media (min-width: ", ") {\n      width: 40%;\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  background-color: #f9d9da;\n  height: 13rem;\n  position: relative;\n  margin-bottom: 1rem;\n\n  @media (min-width: ", ") {\n    height: unset;\n    grid-column: 20/26;\n    grid-row: 1/3;\n    position: relative;\n    margin-bottom: unset;\n  }\n\n  img {\n    position: absolute;\n    width: 35%;\n    bottom: 0;\n    left: 0;\n\n    @media (min-width: ", ") {\n      width: 28%;\n    }\n\n    @media (min-width: ", ") {\n      width: 100%;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: #e2f0df;\n  width: 100%;\n  height: 13rem;\n  position: relative;\n  margin-bottom: 1rem;\n  br {\n    display: none;\n  }\n\n  @media (min-width: ", ") {\n    height: unset;\n    grid-column: 13/20;\n    grid-row: 1;\n    margin-bottom: unset;\n\n    br {\n      display: unset;\n    }\n  }\n\n  img {\n    width: 50%;\n    position: absolute;\n    bottom: 0;\n    right: -10px;\n\n    @media (min-width: ", ") {\n      width: 70%;\n    }\n  }\n\n  > div {\n    margin-left: 6%;\n    margin-top: 4%;\n\n    > p:first-of-type {\n      border-bottom: 1px solid black;\n      width: fit-content;\n      font-size: 50%;\n    }\n\n    > p:last-of-type {\n      margin-top: 5%;\n      font-size: 100%;\n\n      @media (min-width: ", ") {\n        font-size: 120%;\n      }\n\n      @media (min-width: ", ") {\n        font-size: 200%;\n      }\n\n      span {\n        font-weight: 500;\n        line-height: 1.2;\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: #d8dbec;\n  width: 100%;\n  height: 13rem;\n  position: relative;\n  margin-bottom: 1rem;\n  \n  br {\n    display: none;\n  }\n\n  @media (min-width: ", ") {\n    height: unset;\n    grid-column: 1/13;\n    grid-row: 1;\n    margin-bottom: unset;\n\n    br {\n      display: none;\n    }\n  }\n\n  img {\n    position: absolute;\n    top: 0;\n    height: 100%;\n    right: 2%\n  }\n\n  > div {\n    margin-left: 10%;\n    margin-top: 4%;\n    width: 40%;\n\n    > p:first-of-type {\n      border-bottom:1px solid black;\n      width: fit-content;\n      font-size: 50%;\n    }\n\n    > p:last-of-type {\n      margin-top: 10%;\n      font-size: 100%;\n\n      @media (min-width: ", ") {\n        font-size: 120%;\n      }\n\n      @media (min-width: ", ") {\n        font-size: 200%;\n      }\n\n\n      span {\n        font-weight: 500;\n        line-height: 1.2;\n      }\n    } \n  }\n\n  @media (min-width: ", ") {\n   \n\n    img {\n      right: 10%;\n    }\n\n    > div {\n\n      > p:first-of-type {\n      }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: auto;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 6rem;\n  margin-top: 4rem;\n\n  > div {\n    box-shadow: rgb(0 0 0 / 30%) 0px 3px 6px 1px\n\n    border-radius: 10px;\n\n    overflow: hidden;\n  }\n\n  @media (min-width: ", ") {\n    display: grid;\n    width: 100%;\n    grid-template-columns: repeat(25, 1fr);\n    grid-template-rows: repeat(2, 21vw);\n    grid-row-gap: 1rem;\n    grid-column-gap: 1rem;\n  margin-bottom: 8rem;\n\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Gallery = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Purple = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Green = styled.div(_templateObject3(), function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Pink = styled.div(_templateObject4(), function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.smallScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Teal = styled.div(_templateObject5(), function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Yellow = styled.div(_templateObject6(), function (props) {
  return props.theme.breakpoints.mediumScreen;
}, function (props) {
  return props.theme.breakpoints.mediumScreen;
});