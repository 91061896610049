import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: ", ";\n  padding: ", " 0;\n\n  svg {\n    display: block;\n    width: 100px;\n    margin: 100px auto;\n\n    .path1 {\n      fill: #96cbc7;\n      animation: ", " 1.3s infinite cubic-bezier(0.4, 0, 1, 1);\n    }\n\n    .path2 {\n      fill: #9bd7f5;\n      animation: ", " 1.3s infinite cubic-bezier(0.4, 0, 1, 1);\n      animation-delay: 200ms;\n    }\n\n    .path3 {\n      fill: #daeddd;\n      animation: ", " 1.3s infinite cubic-bezier(0.4, 0, 1, 1);\n      animation-delay: 400ms;\n    }\n    .path4 {\n      fill: #f394b8;\n      animation: ", " 1.3s infinite cubic-bezier(0.4, 0, 1, 1);\n      animation-delay: 600ms;\n    }\n    .path5 {\n      fill: #babadd;\n      animation: ", " 1.3s infinite cubic-bezier(0.4, 0, 1, 1);\n      animation-delay: 800ms;\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n0% {\n  fill: #babadd;\n}\n50% {\n  fill: white;\n}\n100% {\n  fill: #babadd;\n}\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n0% {\n  fill: #f394b8;\n}\n50% {\n  fill: white;\n}\n100% {\n  fill: #f394b8;\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n0% {\n  fill: #daeddd;\n}\n50% {\n  fill: white;\n}\n100% {\n  fill: #daeddd;\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n0% {\n  fill: #9bd7f5;\n}\n50% {\n  fill: white;\n}\n100% {\n  fill: #9bd7f5;\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n0% {\n  fill: #96cbc7;\n}\n50% {\n  fill: white;\n}\n100% {\n  fill: #96cbc7;\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { keyframes } from "styled-components";
var firstAnimation = keyframes(_templateObject());
var secondAnimation = keyframes(_templateObject2());
var thirdAnimation = keyframes(_templateObject3());
var fourthAnimation = keyframes(_templateObject4());
var fifthAnimation = keyframes(_templateObject5());
export var Wrapper = styled.div(_templateObject6(), function (props) {
  return props.fullScreen ? "100vh" : "100%";
}, function (props) {
  return props.theme.spacing.spacer;
}, firstAnimation, secondAnimation, thirdAnimation, fourthAnimation, fifthAnimation);