import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import * as S from "./styles";

/**
 * The basic button
 */
export var Button = function Button(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "primary" : _ref$color,
      btnRef = _ref.btnRef,
      children = _ref.children,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      props = _objectWithoutProperties(_ref, ["color", "btnRef", "children", "testingContext", "testingContextId", "size"]);

  var mapColorToButton = {
    primary: S.Primary,
    secondary: S.Secondary,
    outline: S.Outline
  };
  var ButtonWithTheme = mapColorToButton[color];
  return React.createElement(ButtonWithTheme, _extends({
    "data-test": testingContext,
    "data-test-id": testingContextId,
    color: color,
    size: size,
    ref: btnRef
  }, props), children);
};