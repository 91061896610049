import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 0.5rem;\n  font-size: 0.75rem;\n  color: ", ";\n\n  a {\n    text-decoration: underline;\n    font-size: 0.75rem;\n  }\n\n  @media (min-width: ", ") {\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n\n  @media (min-width: ", ") {\n    font-size: 0.875rem;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: rgba(50, 50, 50, 0.6);\n  font-size: 1rem;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 500;\n  font-size: 1.125rem;\n  margin-bottom: 2rem;\n\n  @media (min-width: ", ") {\n    font-size: 1.25rem;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin: 1rem 0;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n\n  margin: 5px 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: repeat(1, 1fr);\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 30px;\n  grid-template-columns: repeat(2, 1fr);\n  margin-top: 2rem;\n\n  ", "\n\n  @media (min-width: ", ") {\n    border: 1px solid ", ";\n    padding: 1rem;\n    border-radius: 10px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 2rem;\n  margin-bottom: 1rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border: 1px solid ", ";\n  margin-top: -3rem;\n  background-color: white;\n  padding: 1rem;\n  border-radius: 10px;\n\n  @media (min-width: ", ") {\n    border: none;\n    padding: unset;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.grayBorder;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Notification = styled.div(_templateObject2());
export var Grid = styled.div(_templateObject3(), media.smallScreen(_templateObject4()), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.colors.grayBorder;
});
export var Divider = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var LargeDivider = styled(Divider)(_templateObject6());
export var Title = styled.h3(_templateObject7(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var SubTitle = styled.h4(_templateObject8());
export var TextSummary = styled.p(_templateObject9(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var ErrorMessages = styled.div(_templateObject10());
export var Conditions = styled.div(_templateObject11(), function (props) {
  return props.theme.colors.labelGray;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});