var countryAreas = [{
  label: "Tunis"
}, {
  label: "Ariana"
}, {
  label: "Manouba"
}, {
  label: "Ben Arous"
}, {
  label: "Nabeul"
}, {
  label: "Bizerte"
}, {
  label: "Zaghouan"
}, {
  label: "Sousse"
}, {
  label: "Monastir"
}, {
  label: "Mahdia"
}, {
  label: "Sfax"
}, {
  label: "Béja"
}, {
  label: "Jendouba"
}, {
  label: "Kef"
}, {
  label: "Siliana"
}, {
  label: "Kairouan"
}, {
  label: "Sidi Bouzid"
}, {
  label: "Kasserine"
}, {
  label: "Gabès"
}, {
  label: "Medenine"
}, {
  label: "Gafsa"
}, {
  label: "Tozeur"
}, {
  label: "Tataouine"
}, {
  label: "Kébili"
}];
var cities = [[{
  label: "Carthage"
}, {
  label: "La Médina"
}, {
  label: "Bab Bhar"
}, {
  label: "Bab Souika"
}, {
  label: "Omrane"
}, {
  label: "Omrane Supérieur"
}, {
  label: "Attahrir"
}, {
  label: "El Menzah"
}, {
  label: "Cité Alkhadhra"
}, {
  label: "Bardo"
}, {
  label: "Séjoumi"
}, {
  label: "Azzouhour"
}, {
  label: "Alhrairia"
}, {
  label: "Sidi Hsine"
}, {
  label: "Ouardia"
}, {
  label: "Kabaria"
}, {
  label: "Sidi Elbéchir"
}, {
  label: "Jebel Jelloud"
}, {
  label: "La Goulette"
}, {
  label: "Le Kram"
}, {
  label: "La Marsa"
}], [{
  label: "Ariana Ville"
}, {
  label: "Soukra"
}, {
  label: "Raouède"
}, {
  label: "Kalâat Andalous"
}, {
  label: "Sidi Thabet"
}, {
  label: "Cité Attadhamon"
}, {
  label: "M’nihla"
}], [{
  label: "Manouba"
}, {
  label: "Oued Ellil"
}, {
  label: "Tebourba"
}, {
  label: "Battan"
}, {
  label: "Jedaida"
}, {
  label: "Mornaguia"
}, {
  label: "Borj Amri"
}, {
  label: "Douar Hicher"
}], [{
  label: "Ben Arous"
}, {
  label: "Nouvelle Médina"
}, {
  label: "Mourouj"
}, {
  label: "Hammam Lif"
}, {
  label: "Hammam Chatt"
}, {
  label: "Boumhel Bassatine"
}, {
  label: "Ezzahra"
}, {
  label: "Radès"
}, {
  label: "Megrine"
}, {
  label: "M’hamdia"
}, {
  label: "Fouchana"
}, {
  label: "Mornag"
}], [{
  label: "Nabeul"
}, {
  label: "Dar Chaâbane Elfehri"
}, {
  label: "Béni Khiar"
}, {
  label: "Korba"
}, {
  label: "Menzel Temime"
}, {
  label: "Mida"
}, {
  label: "Kelibia"
}, {
  label: "Hammam Ghezaz"
}, {
  label: "Haouaria"
}, {
  label: "Takelsa"
}, {
  label: "Slimane"
}, {
  label: "Menzel Bouzelfa"
}, {
  label: "Béni Khalled"
}, {
  label: "Grombalia"
}, {
  label: "Bouârgoub"
}, {
  label: "Hammamet"
}], [{
  label: "Bizerte Nord"
}, {
  label: "Jarzouna"
}, {
  label: "Bizerte Sud"
}, {
  label: "Sejnane"
}, {
  label: "Joumine"
}, {
  label: "Mateur"
}, {
  label: "Ghezala"
}, {
  label: "Menzel Bourguiba"
}, {
  label: "Tinja"
}, {
  label: "Utique"
}, {
  label: "Ghar El Melh"
}, {
  label: "Menzel Jemil"
}, {
  label: "El Alia"
}, {
  label: "Ras Jebel"
}], [{
  label: "Zaghouan"
}, {
  label: "Zériba"
}, {
  label: "Bir Mecharga"
}, {
  label: "Fahs"
}, {
  label: "Nadhour"
}, {
  label: "Saouaf"
}], [{
  label: "Sousse Ville"
}, {
  label: "Zaouia Ksiba Thrayat"
}, {
  label: "Sousse Ryadh"
}, {
  label: "Sousse Jawhara"
}, {
  label: "Sousse Sidi Abdelhamid"
}, {
  label: "Hammam sousse"
}, {
  label: "Akouda"
}, {
  label: "Kalâa Elkébira"
}, {
  label: "Sidi Bouali"
}, {
  label: "Hergla"
}, {
  label: "Enfidha"
}, {
  label: "Bouficha"
}, {
  label: "Koundar"
}, {
  label: "Sidi Elheni"
}, {
  label: "Msaken"
}, {
  label: "Kalâa Ességhira"
}], [{
  label: "Monastir"
}, {
  label: "Ouerdanine"
}, {
  label: "Sahline"
}, {
  label: "Zéramdine"
}, {
  label: "Béni Hassan"
}, {
  label: "Jammel"
}, {
  label: "Benbla"
}, {
  label: "Moknine"
}, {
  label: "Bekalta"
}, {
  label: "Teboulba"
}, {
  label: "Ksar Helal"
}, {
  label: "Ksibet Medyouni"
}, {
  label: "Sayada Lamta Bouhjar"
}], [{
  label: "Mahdia"
}, {
  label: "Boumerdes"
}, {
  label: "Ouled Chamekh"
}, {
  label: "Chorbane"
}, {
  label: "Hbira"
}, {
  label: "Souassi"
}, {
  label: "Eljem"
}, {
  label: "Chebba"
}, {
  label: "Malloulech"
}, {
  label: "Sidi Alouane"
}, {
  label: "Ksour Essef"
}], [{
  label: "Sfax Ville"
}, {
  label: "Sfax Ouest"
}, {
  label: "Sakiet Ezzit"
}, {
  label: "Sakiet Eddaier"
}, {
  label: "Sfax sud"
}, {
  label: "Tina"
}, {
  label: "Agareb"
}, {
  label: "Jebeniana"
}, {
  label: "El Amra"
}, {
  label: "El Hencha"
}, {
  label: "Menzel chaker"
}, {
  label: "Ghraiba"
}, {
  label: "Bir Ali Ben Khelifa"
}, {
  label: "Sekhira"
}, {
  label: "Mahrès"
}, {
  label: "Kerkennah"
}], [{
  label: "Béja nord"
}, {
  label: "Béja sud"
}, {
  label: "Amdoun"
}, {
  label: "Nefza"
}, {
  label: "Teboursouk"
}, {
  label: "Tibar"
}, {
  label: "Testour"
}, {
  label: "Goubellat"
}, {
  label: "Mejez El Bab"
}], [{
  label: "Jendouba"
}, {
  label: "Jendouba Nord"
}, {
  label: "Boussalem"
}, {
  label: "Tabarka"
}, {
  label: "Ain Drahem"
}, {
  label: "Fernana"
}, {
  label: "Ghardimaou"
}, {
  label: "Oued Mliz"
}, {
  label: "Balta Bouaouene"
}], [{
  label: "Kef Ouest"
}, {
  label: "Kef Est"
}, {
  label: "Nebeur"
}, {
  label: "Sakiet Sidi Youssef"
}, {
  label: "Tejerouine"
}, {
  label: "Kalâat sinane"
}, {
  label: "Kalâa El khasba"
}, {
  label: "Jerissa"
}, {
  label: "Gsour"
}, {
  label: "Dahmani"
}, {
  label: "Le Sers"
}], [{
  label: "Siliana nord"
}, {
  label: "Siliana sud"
}, {
  label: "Bouarada"
}, {
  label: "Gâafour"
}, {
  label: "El Aroussa"
}, {
  label: "Le Krib"
}, {
  label: "Bourouis"
}, {
  label: "Makther"
}, {
  label: "Rouhia"
}, {
  label: "Kesra"
}, {
  label: "Bargou"
}], [{
  label: "Kairouan Nord"
}, {
  label: "Kairouan Sud"
}, {
  label: "Chebika"
}, {
  label: "Sebikha"
}, {
  label: "Oueslatia"
}, {
  label: "Haffouz"
}, {
  label: "El Ala"
}, {
  label: "Hajeb El Ayoun"
}, {
  label: "Nasrallah"
}, {
  label: "Cherarda"
}, {
  label: "Bouhajla"
}], [{
  label: "Sidi Bouzid Ouest"
}, {
  label: "Sidi Bouzid Est"
}, {
  label: "Jelma"
}, {
  label: "Sabbalet Ouled Askar"
}, {
  label: "Bir Hfay"
}, {
  label: "Sidi Ali Benôun"
}, {
  label: "Menzel Bouzayane"
}, {
  label: "Meknassi"
}, {
  label: "Souk Jedid"
}, {
  label: "Mezouna"
}, {
  label: "Regueb"
}, {
  label: "Ouled Haffouz"
}], [{
  label: "Kasserine Nord"
}, {
  label: "Kasserine Sud"
}, {
  label: "Azzouhour"
}, {
  label: "Hassi ferid"
}, {
  label: "Sbitla"
}, {
  label: "Sbiba"
}, {
  label: "Jedliane"
}, {
  label: "El Ayoun"
}, {
  label: "Tela"
}, {
  label: "Hidra"
}, {
  label: "Foussana"
}, {
  label: "Feriana"
}, {
  label: "Mejel Bel Abbes"
}], [{
  label: "Gabès ville"
}, {
  label: "Gabès ouest"
}, {
  label: "Gabès sud"
}, {
  label: "Ghannouch"
}, {
  label: "Metouia"
}, {
  label: "Menzel habib"
}, {
  label: "Hamma"
}, {
  label: "Matmata"
}, {
  label: "Matmata nouvelle"
}, {
  label: "Mareth"
}], [{
  label: "Mednine Nord"
}, {
  label: "Mednine Sud"
}, {
  label: "Béni khedach"
}, {
  label: "Ben Guerdene"
}, {
  label: "Zazis"
}, {
  label: "Jerba Houmet Souk"
}, {
  label: "Jerba Midoun"
}, {
  label: "Jerba Ajim"
}, {
  label: "Sidi Makhlouf"
}], [{
  label: "Gafsa Nord"
}, {
  label: "Sidi Aich"
}, {
  label: "El Ksar"
}, {
  label: "Gafsa Sud"
}, {
  label: "Moulares"
}, {
  label: "Redyef"
}, {
  label: "Métlaoui"
}, {
  label: "El Mdhilla"
}, {
  label: "El Guettar"
}, {
  label: "Belkhir"
}, {
  label: "Sned"
}], [{
  label: "Tozeur"
}, {
  label: "Degueche"
}, {
  label: "Tameghza"
}, {
  label: "Nefta"
}, {
  label: "Hezoua"
}], [{
  label: "Tataouine Nord"
}, {
  label: "Tataouine Sud"
}, {
  label: "Smar"
}, {
  label: "Bir Lahmer"
}, {
  label: "Ghomrassen"
}, {
  label: "Dhehiba"
}, {
  label: "Remada"
}], [{
  label: "Kébili Sud"
}, {
  label: "Kébili Nord"
}, {
  label: "Souk El Ahad"
}, {
  label: "Douz nord"
}, {
  label: "Douz sud"
}, {
  label: "El Faouar"
}]];
export { countryAreas, cities };