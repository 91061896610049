import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, Link } from "react-router-dom";
import Media from "react-responsive";
import { commonMessages } from "@temp/intl";
import QuantityInput from "../../molecules/QuantityInput";
import AddToCartButton from "../../molecules/AddToCartButton";
import ProductVariantPicker from "../ProductVariantPicker";
import * as S from "./styles";
import { largeScreen } from "@styles/constants";
import { getAvailableQuantity, getProductPrice, canAddToCart } from "./stockHelpers";
import { Button, RichTextContent, Stars } from "@components/atoms";
import ProductGroup from "@components/molecules/ProductGroup";
import { LOW_STOCK_QUANTITY } from "@temp/core/config";
import { getVendorPath } from "@temp/app/routes";

var AddToCartSection = function AddToCartSection(_ref) {
  var productId = _ref.productId,
      availableForPurchase = _ref.availableForPurchase,
      isAvailableForPurchase = _ref.isAvailableForPurchase,
      items = _ref.items,
      name = _ref.name,
      productPricing = _ref.productPricing,
      productVariants = _ref.productVariants,
      queryAttributes = _ref.queryAttributes,
      onAddToCart = _ref.onAddToCart,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      setVariantId = _ref.setVariantId,
      variantId = _ref.variantId,
      productGroup = _ref.productGroup,
      onGroupHover = _ref.onGroupHover,
      productRating = _ref.productRating,
      ratingsCount = _ref.ratingsCount,
      description = _ref.description,
      onScrollToDesc = _ref.onScrollToDesc,
      vendor = _ref.vendor;
  var intl = useIntl();
  var location = useLocation();

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      quantity = _useState2[0],
      setQuantity = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      variantStock = _useState4[0],
      setVariantStock = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      variantPricing = _useState6[0],
      setVariantPricing = _useState6[1];

  var availableQuantity = getAvailableQuantity(items, variantId, variantStock);
  var isOutOfStock = !!variantId && variantStock === 0;
  var noPurchaseAvailable = !isAvailableForPurchase && !availableForPurchase;
  var purchaseAvailableDate = !isAvailableForPurchase && availableForPurchase && Date.parse(availableForPurchase);
  var isNoItemsAvailable = !!variantId && !isOutOfStock && !availableQuantity;
  var isLowStock = !!variantId && !isOutOfStock && !isNoItemsAvailable && availableQuantity < LOW_STOCK_QUANTITY;
  var disableAddToCart = !canAddToCart(items, !!isAvailableForPurchase, variantId, variantStock, quantity);

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      animateVariantsError = _useState8[0],
      setAnimateVariantsError = _useState8[1];

  var _useState9 = useState(false),
      _useState10 = _slicedToArray(_useState9, 2),
      selectVariantError = _useState10[0],
      setSelectVariantError = _useState10[1];

  var renderErrorMessage = function renderErrorMessage(message, testingContextId) {
    return React.createElement(S.ErrorMessage, {
      "data-test": "stockErrorMessage",
      "data-testId": testingContextId
    }, message);
  };

  var onVariantPickerChange = function onVariantPickerChange(_selectedAttributesValues, selectedVariant) {
    if (!selectedVariant) {
      setVariantId("");
      setVariantPricing(null);
      setVariantStock(0);
      return;
    }

    setVariantId(selectedVariant.id);
    setVariantPricing(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.pricing);
    setVariantStock(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.quantityAvailable);
    setQuantity(1);
    setSelectVariantError(false);
  };

  return React.createElement(S.Wrapper, null, React.createElement(S.AddToCartSelection, null, React.createElement(S.ProductNameHeader, {
    "data-test": "productName"
  }, name), React.createElement(S.RatingContainer, null, vendor && vendor.slug && vendor.name && React.createElement(S.Vendor, null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.AddToCartSection.AddToCartSection.1659841270",
    defaultMessage: "Vendeur : "
  })), React.createElement(Link, {
    to: getVendorPath(vendor.slug)
  }, vendor.name)), React.createElement(S.Rating, null, productRating ? React.createElement(React.Fragment, null, React.createElement(Stars, {
    size: "sm",
    rating: productRating
  }), React.createElement(Link, {
    to: "".concat(location.pathname, "reviews/")
  }, React.createElement("span", null, "".concat(ratingsCount, " avis")))) : React.createElement("span", null, "Pas d'avis encore"))), isOutOfStock ? renderErrorMessage(intl.formatMessage(commonMessages.outOfStock), "outOfStock") : React.createElement(S.ProductPricing, null, getProductPrice(productPricing, variantPricing)), noPurchaseAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noPurchaseAvailable), "notAvailable"), purchaseAvailableDate && renderErrorMessage(intl.formatMessage(commonMessages.purchaseAvailableOn, {
    date: new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(purchaseAvailableDate),
    time: new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric"
    }).format(purchaseAvailableDate)
  }), "timeRestrictedAvailability"), isNoItemsAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noItemsAvailable), "noItemsAvailable"), React.createElement(S.ProductGroup, null, React.createElement(Media, {
    maxWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(ProductGroup, _extends({
      group: productGroup
    }, {
      onGroupHover: onGroupHover,
      productId: productId
    })) : React.createElement(ProductGroup, _extends({
      group: productGroup,
      isMobile: true
    }, {
      onGroupHover: onGroupHover,
      productId: productId
    }));
  })), React.createElement(S.VariantPicker, null, React.createElement(ProductVariantPicker, {
    productVariants: productVariants,
    onChange: onVariantPickerChange,
    selectSidebar: true,
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: onAttributeChangeHandler,
    variantId: variantId,
    animateError: animateVariantsError,
    textError: selectVariantError
  })), React.createElement(S.QuantityInput, null, React.createElement(QuantityInput, {
    quantity: quantity,
    maxQuantity: availableQuantity,
    disabled: !variantId || isOutOfStock || isNoItemsAvailable,
    onQuantityChange: setQuantity,
    hideErrors: !variantId || isOutOfStock || isNoItemsAvailable,
    testingContext: "addToCartQuantity",
    isLowStock: isLowStock
  })), React.createElement(S.AddToCartButton, null, React.createElement(Media, {
    maxWidth: largeScreen
  }, function (matches) {
    return React.createElement(AddToCartButton, {
      onSubmit: function onSubmit() {
        if (disableAddToCart) {
          setAnimateVariantsError(true);
          setSelectVariantError(true);
          setTimeout(function () {
            return setAnimateVariantsError(false);
          }, 2000);
        } else onAddToCart(variantId, quantity, matches);
      }
    });
  })), React.createElement(S.PurchaseInfo, null), React.createElement(S.About, null, React.createElement("h4", null, "A propos : "), React.createElement(RichTextContent, {
    descriptionJson: description
  }), React.createElement("div", {
    className: "color-overlay"
  }, React.createElement(Button, {
    size: "sm",
    onClick: onScrollToDesc,
    color: "outline"
  }, "Plus d'infos")))));
};

AddToCartSection.displayName = "AddToCartSection";
export default AddToCartSection;