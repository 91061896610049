import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgLogoFooter = function SvgLogoFooter(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 241.602 40.484"
  }, props), React.createElement("g", {
    "data-name": "Groupe 159"
  }, React.createElement("g", {
    "data-name": "Groupe 608",
    fill: "#4a4a4a"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 62",
    d: "M106.014 9.294a9.286 9.286 0 1 0 2.723-6.577 9.294 9.294 0 0 0-2.723 6.577Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 61",
    d: "M82.452 9.294A9.294 9.294 0 1 0 91.74 0a9.294 9.294 0 0 0-9.288 9.294Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 60",
    d: "M58.867 9.294A9.294 9.294 0 1 0 68.161 0a9.294 9.294 0 0 0-9.294 9.294Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 59",
    d: "M71.306 31.184a9.294 9.294 0 1 0 9.294-9.286 9.294 9.294 0 0 0-9.294 9.286Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 58",
    d: "M93.56 31.184a9.3 9.3 0 1 0 2.744-6.581 9.294 9.294 0 0 0-2.744 6.581Z"
  })), React.createElement("g", {
    "data-name": "Groupe 607",
    fill: "#4a4a4a"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 62",
    d: "M164.014 31.19a9.286 9.286 0 1 1 2.723 6.577 9.294 9.294 0 0 1-2.723-6.577Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 61",
    d: "M140.452 31.19a9.294 9.294 0 1 1 9.288 9.294 9.294 9.294 0 0 1-9.288-9.294Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 60",
    d: "M116.867 31.19a9.294 9.294 0 1 1 9.294 9.294 9.294 9.294 0 0 1-9.294-9.294Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 59",
    d: "M129.306 9.302a9.294 9.294 0 1 1 9.294 9.286 9.294 9.294 0 0 1-9.294-9.286Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 58",
    d: "M151.56 9.302a9.3 9.3 0 1 1 2.744 6.581 9.294 9.294 0 0 1-2.744-6.581Z"
  })), React.createElement("g", {
    "data-name": "Groupe 609",
    fill: "#4a4a4a"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 62",
    d: "M47.146 31.19a9.286 9.286 0 1 1 2.723 6.577 9.294 9.294 0 0 1-2.723-6.577Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 61",
    d: "M23.584 31.19a9.294 9.294 0 1 1 9.288 9.294 9.294 9.294 0 0 1-9.288-9.294Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 60",
    d: "M-.001 31.19a9.294 9.294 0 1 1 9.294 9.294A9.294 9.294 0 0 1-.001 31.19Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 59",
    d: "M12.438 9.302a9.294 9.294 0 1 1 9.294 9.286 9.294 9.294 0 0 1-9.294-9.286Z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 58",
    d: "M34.692 9.302a9.3 9.3 0 1 1 2.744 6.581 9.294 9.294 0 0 1-2.744-6.581Z"
  })), React.createElement("g", {
    "data-name": "Groupe 157"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 62",
    d: "M194.455 9.295a9.286 9.286 0 1 1-2.723-6.577 9.294 9.294 0 0 1 2.723 6.577Z",
    fill: "#96cbc7"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 61",
    d: "M218.017 9.294A9.294 9.294 0 1 1 208.731 0a9.294 9.294 0 0 1 9.286 9.294Z",
    fill: "#9bd7f5"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 60",
    d: "M241.602 9.295a9.294 9.294 0 1 1-9.294-9.294 9.294 9.294 0 0 1 9.294 9.294Z",
    fill: "#daeddd"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 59",
    d: "M229.165 31.182a9.294 9.294 0 1 1-9.3-9.286 9.294 9.294 0 0 1 9.3 9.286Z",
    fill: "#f394b8"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 58",
    d: "M206.909 31.183a9.3 9.3 0 1 1-2.744-6.581 9.294 9.294 0 0 1 2.744 6.581Z",
    fill: "#babadd"
  }))));
};

export default SvgLogoFooter;