import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
export var SvgDelivery = function SvgDelivery(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 92.445 78.504"
  }, props), React.createElement("g", {
    "data-name": "Groupe 135",
    transform: "translate(0 -.442)"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 75",
    d: "M68.71 13.672H27.484a6.891 6.891 0 0 0-6.871 6.871v6.871A20.674 20.674 0 0 0 0 48.027V68.64h6.871a10.307 10.307 0 0 0 20.613 0h13.742a10.307 10.307 0 0 0 20.613 0h6.871a6.891 6.891 0 0 0 6.871-6.868V20.543a6.891 6.891 0 0 0-6.871-6.871Zm3.436 48.1a3.439 3.439 0 0 1-3.436 3.436h-7.47a10.292 10.292 0 0 0-19.414 0H27.484a3.378 3.378 0 0 1-.622-.064 10.308 10.308 0 0 0-2.813-4.172V20.543a3.439 3.439 0 0 1 3.436-3.436H68.71a3.439 3.439 0 0 1 3.436 3.436ZM58.4 68.64a6.871 6.871 0 1 1-6.867-6.868A6.878 6.878 0 0 1 58.4 68.64Zm-34.355 0a6.871 6.871 0 1 1-6.867-6.868 6.878 6.878 0 0 1 6.871 6.868Zm-3.432-37.79v15.46H3.523a17.2 17.2 0 0 1 17.09-15.46ZM3.436 65.205v-15.46h17.177v9.188a10.263 10.263 0 0 0-13.142 6.272Z"
  }), React.createElement("path", {
    "data-name": "Rectangle 79",
    d: "M36.445 29.442h20v3h-20z"
  }), React.createElement("path", {
    "data-name": "Rectangle 80",
    d: "M36.445 42.442h14v4h-14z"
  }), React.createElement("ellipse", {
    "data-name": "Ellipse 21",
    cx: 19,
    cy: 18.5,
    rx: 19,
    ry: 18.5,
    transform: "translate(54.445 .442)",
    fill: "#96cbc7"
  })));
};
export default SvgDelivery;