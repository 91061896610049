import { RelatedProducts } from "@components/organisms";
import { commonMessages } from "@temp/intl";
import * as React from "react";
import { maybe } from "../../core/utils";
import { TypedFeaturedProductsQuery } from "./queries";
import "./scss/index.scss";

var ProductsFeatured = function ProductsFeatured(_ref) {
  var title = _ref.title;
  return React.createElement(TypedFeaturedProductsQuery, {
    displayError: false
  }, function (_ref2) {
    var data = _ref2.data;
    var products = maybe(function () {
      return data.shop.homepageCollection.products.edges;
    }, []);

    if (products.length) {
      return React.createElement(RelatedProducts, {
        products: products.map(function (p) {
          return p.node;
        }),
        title: commonMessages.featured.defaultMessage
      });
    }

    return null;
  });
};

ProductsFeatured.defaultProps = {
  title: "Featured"
};
export default ProductsFeatured;