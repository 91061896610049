import { ProductRating, StarsBreakdown } from "@components/atoms";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ReviewList } from "..";
import * as S from "./styles";
import { FormattedMessage } from "react-intl";
export var ProductReviewSummary = function ProductReviewSummary(_ref) {
  var rating = _ref.rating,
      totalRatings = _ref.totalRatings,
      ratingsSummary = _ref.ratingsSummary,
      previewReviews = _ref.previewReviews;
  var location = useLocation();
  return React.createElement(S.Wrapper, null, React.createElement(S.Title, null, "Avis du produit"), rating ? React.createElement(S.Container, null, React.createElement(S.Left, null, React.createElement(S.Card, null, React.createElement(S.Rating, null, React.createElement(ProductRating, {
    rating: rating,
    totalRatings: totalRatings
  })), React.createElement(S.Breakdown, null, React.createElement(StarsBreakdown, {
    totalRatings: totalRatings,
    ratingsSummary: ratingsSummary
  })))), React.createElement(S.Reviews, null, React.createElement(ReviewList, {
    reviews: previewReviews
  }), React.createElement(Link, {
    to: "".concat(location.pathname, "reviews/")
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ProductReviewSummary.ProductReviewSummary.1304743291",
    defaultMessage: "Lire tous les commentaires"
  })))) : React.createElement(S.NoReviews, null, "Pas d'avis encore"));
};
ProductReviewSummary.displayName = "ProductReviewSummary";
export default ProductReviewSummary;