import React from "react";
import { useHistory } from "react-router";
import SvgWonder from "images/SvgWonder";
import * as S from "./styles";
export var HeaderAuth = function HeaderAuth() {
  var history = useHistory();
  return React.createElement(S.Header, null, React.createElement(SvgWonder, {
    onClick: function onClick() {
      return history.push("/");
    }
  }));
};
HeaderAuth.displayName = "HeaderAuth";
export default HeaderAuth;