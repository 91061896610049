import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from "react";
import classNames from "classnames";
import { NavLink } from "..";
import NavItem from "./NavItem";
import "./scss/index.scss";

var NavDropdown = function NavDropdown(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, ["children"]);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      active = _React$useState2[0],
      setActive = _React$useState2[1];

  var hasSubNavigation = children && !!children.length;
  return React.createElement("ul", {
    className: classNames({
      "main-menu__nav-dropdown": true,
      "main-menu__nav-dropdown--active": active
    }),
    onMouseOver: function onMouseOver() {
      if (hasSubNavigation) setActive(true);
    },
    onMouseLeave: function onMouseLeave() {
      if (active) setActive(false);
    }
  }, React.createElement("li", null, React.createElement(NavLink, {
    item: props,
    onClick: function onClick() {
      return setActive(false);
    }
  })), React.createElement("li", {
    className: classNames({
      "main-menu__nav-dropdown__body": true,
      "main-menu__nav-dropdown__body--visible": active
    })
  }, React.createElement("ul", null, children.map(function (subItem, i) {
    return React.createElement(NavItem, _extends({
      key: i
    }, subItem, {
      handleClick: function handleClick() {
        return setActive(false);
      }
    }));
  }))));
};

export default NavDropdown;