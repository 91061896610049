import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  height: 2.5rem;\n  text-align: left;\n  width: 100%;\n\n  cursor: pointer;\n  color: ", ";\n\n  a {\n    padding: 0 1rem;\n    width: 100%;\n    display: flex;\n    align-items: center;\n\n    svg {\n      width: 1.75rem;\n      height: auto;\n      margin-right: 5px;\n\n      path {\n        fill: ", ";\n      }\n    }\n\n    &:hover {\n      color: ", ";\n\n      svg {\n        path {\n          fill: ", ";\n        }\n      }\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  border-right: 1px solid ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-transform: \"uppercase\";\n  padding-bottom: 2rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  padding-top: 2.5rem;\n\n  font-size: 0.875rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var MenuHeader = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var MenuItems = styled.ul(_templateObject3(), function (props) {
  return props.theme.colors.grayBorder;
});
export var MenuItem = styled.li(_templateObject4(), function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "";
}, function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "";
}, function (props) {
  return props.active ? props.theme.colors.activeMenuOption : props.theme.colors.logoGreen;
}, function (props) {
  return props.active ? props.theme.colors.activeMenuOption : props.theme.colors.logoGreen;
});