import "./scss/index.scss";
import * as React from "react";
import { Cart as CartOrganism } from "@components/organisms/Cart";
import { Offline, OfflinePlaceholder, Overlay } from "../..";

var Cart = function Cart(_ref) {
  var overlay = _ref.overlay;
  return React.createElement(Overlay, {
    testingContext: "cartOverlay",
    context: overlay
  }, React.createElement(CartOrganism, {
    overlay: true,
    hide: overlay.hide
  }), React.createElement(Offline, null, React.createElement("div", {
    className: "cart"
  }, React.createElement(OfflinePlaceholder, null))));
};

export default Cart;