import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  img {\n    border-width: 1px;\n    border-style: solid;\n    border-color: ", ";\n    height: 54px;\n    width: auto;\n    margin-right: 10px;\n    border-radius: 7px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  overflow-y: scroll;\n  padding-bottom: 0.5rem;\n\n  @media (min-width: ", ") {\n    flex-wrap: wrap;\n    overflow-y: unset;\n    padding-bottom: unset;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  p {\n    font-size: 0.8rem;\n  }\n\n  img {\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrap = styled.div(_templateObject());
export var Items = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Thumb = styled.div(_templateObject3(), function (props) {
  return props.selected ? "black" : "transparent";
});