import React from "react";
import * as S from "./styles";
import { Stars } from "@components/atoms";
import { FormattedDate, FormattedMessage } from "react-intl";
export var ReviewList = function ReviewList(_ref) {
  var reviews = _ref.reviews,
      foundComments = _ref.foundComments;
  return React.createElement(S.Wrapper, null, foundComments > 0 ? React.createElement(S.Header, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ReviewList.ReviewList.3401450822",
    defaultMessage: "{foundComments,plural,one{{foundComments} commentaire trouv\xE9} other{{foundComments} commentaires trouv\xE9s}}",
    description: "items quantity in cart",
    values: {
      foundComments: foundComments
    }
  })) : React.createElement(S.Header, null, "Pas de commentaires trouv\xE9s"), reviews.length > 0 && reviews.map(function (r) {
    return React.createElement(S.Item, null, React.createElement(S.Author, null, r.userName, React.createElement("span", null, React.createElement(FormattedMessage, {
      id: "@next.components.organisms.ReviewList.ReviewList.472460084",
      defaultMessage: "achat effectu\xE9 le "
    }), React.createElement(FormattedDate, {
      value: r.purchasedAt,
      year: "numeric",
      month: "long",
      day: "2-digit"
    }))), React.createElement(Stars, {
      rating: r.rating,
      size: "sm"
    }), React.createElement(S.Text, null, r.comment), React.createElement(S.Date, null, React.createElement(FormattedDate, {
      value: r.created,
      year: "numeric",
      month: "long",
      day: "2-digit"
    })));
  }));
};
ReviewList.displayName = "ReviewList";
export default ReviewList;