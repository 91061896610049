import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin: 2rem auto 1rem;\n  padding: 1.5rem 4rem;\n  border-radius: 10px;\n\n  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,\n    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;\n\n  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 4px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 1rem;\n  font-size: 1.125rem;\n  font-weight: 500;\n\n  @media (max-width: ", ") {\n    font-size: 1rem;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin: 1.5rem auto 0;\n  padding: 1.5rem 4rem;\n\n  border-radius: 10px;\n  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 4px;\n\n  label {\n    font-size: 0.875rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 420px;\n  margin: auto;\n\n  @media (max-width: ", ") {\n    padding: 0 1rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.875rem;\n\n  button {\n    width: 100%;\n  }\n\n  @media (max-width: ", ") {\n    font-size: 0.75rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Container = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Form = styled.div(_templateObject3());
export var Title = styled.h3(_templateObject4(), function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Login = styled.div(_templateObject5());