import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  bottom: 4rem;\n  right: 1rem;\n  z-index: 280;\n  -webkit-tap-highlight-color: transparent;\n\n  @media (min-width: ", ") {\n    bottom: 2rem;\n  }\n\n  svg {\n    height: 40px;\n    width: auto;\n    cursor: pointer;\n\n    @media (min-width: ", ") {\n      height: 60px;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});