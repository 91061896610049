import React from "react";
export var ReviewSummary = function ReviewSummary(_ref) {
  var reviewsSummary = _ref.reviewsSummary;
  var totalComments = reviewsSummary.totalComments,
      totalRatings = reviewsSummary.totalRatings,
      ratingsSummary = reviewsSummary.ratingsSummary;
  return React.createElement(React.Fragment, null, React.createElement("p", null, "comments: ", totalComments), React.createElement("p", null, "reviews: ", totalRatings), ratingsSummary.map(function (x, i) {
    return React.createElement("p", {
      key: i
    }, "starts: ", x.stars, " - count: ", x.count);
  }));
};
ReviewSummary.displayName = "ReviewSummary";
export default ReviewSummary;