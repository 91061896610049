import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
// TODO: Once used in @next - move utils to @utils
// Use slugs everywhere (they are used partially right now)
// NOTE: This component should only be used for navigation inside application
// For external urls, use different component
import React from "react";
import { generateCategoryUrl, generateCollectionUrl, generatePageUrl } from "@utils/core";
import * as S from "./styles";

var getLinkUrl = function getLinkUrl(_ref) {
  var category = _ref.category,
      collection = _ref.collection,
      page = _ref.page;

  if (category) {
    return generateCategoryUrl(category.slug);
  }

  if (collection) {
    return generateCollectionUrl(collection.id, collection.name);
  }

  if (page) {
    return generatePageUrl(page.slug);
  }
};

export var NavLink = function NavLink(_ref2) {
  var item = _ref2.item,
      _ref2$fullWidth = _ref2.fullWidth,
      fullWidth = _ref2$fullWidth === void 0 ? false : _ref2$fullWidth,
      props = _objectWithoutProperties(_ref2, ["item", "fullWidth"]);

  var name = item.name,
      url = item.url,
      category = item.category,
      collection = item.collection,
      page = item.page;

  if (url) {
    return React.createElement("a", _extends({
      href: url
    }, props), name);
  }

  var linkUrl = getLinkUrl({
    category: category,
    collection: collection,
    page: page
  });
  return linkUrl ? React.createElement(S.Link, _extends({
    to: linkUrl,
    activeClassName: "navlink-active",
    fullWidth: fullWidth
  }, props), name) : null;
};