import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: #c22d74;\n  font-size: 0.75rem;\n\n  p {\n    margin: 0;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin: 0.875rem 0 2rem;\n\n  textarea {\n    display: block;\n    resize: none;\n    height: 10rem;\n    width: 100%;\n    padding: 1rem;\n    overflow: auto;\n    border-color: transparent;\n    background-color: #dfdfdf;\n    transition: background-color 300ms;\n    transition: all 300ms;\n\n    :focus {\n      background-color: transparent;\n      outline: 1px solid ", ";\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 60px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 2rem;\n\n  p {\n    margin-left: 1rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 3rem 0;\n  padding: 1rem 2rem;\n  border: 1px solid #7989895c;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { Thumbnail } from "@components/molecules";
export var Wrapper = styled.div(_templateObject());
export var Inner = styled.div(_templateObject2());
export var Line = styled.div(_templateObject3());
export var Thumb = styled(Thumbnail)(_templateObject4());
export var Comment = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.primary;
});
export var Error = styled.div(_templateObject6());