import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Thumbnail } from "../";
import angleDownImage from "../../../../images/angle-down-solid.svg";
import * as S from "./styles";
import { PastOrderDetails } from "@temp/views/Account";
import { formatDateTime } from "@temp/misc";
import { translateOrderStatus } from "@temp/intl";

var PastOrderRow = function PastOrderRow(_ref) {
  var order = _ref.order;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      expanded = _React$useState2[0],
      setExpanded = _React$useState2[1];

  var scrollRef = React.useRef();
  var intl = useIntl();

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      detailsLoaded = _React$useState4[0],
      setDetailsLoaded = _React$useState4[1];

  var onLoadDetails = function onLoadDetails() {
    setDetailsLoaded(true);
  };

  var toggle = function toggle() {
    setExpanded(!expanded);
  };

  React.useEffect(function () {
    if (detailsLoaded) {
      if (expanded === true) setTimeout(function () {
        return scroll(true);
      }, 300);else scroll(false);
    }
  }, [detailsLoaded, expanded]);

  var scroll = function scroll(isSmooth) {
    var yOffset = -20;
    var y = scrollRef.current.getBoundingClientRect().top + yOffset + window.pageYOffset;
    var behave = isSmooth ? "smooth" : "auto";
    window.scrollTo({
      behavior: behave,
      top: y
    });
  };

  var lines = null;
  if (order.lines.length < 2) lines = order.lines.map(function (line) {
    return React.createElement(Thumbnail, {
      source: line,
      key: line === null || line === void 0 ? void 0 : line.id
    });
  });else if (order.lines.length === 2) lines = React.createElement(React.Fragment, null, order.lines.map(function (line) {
    return React.createElement(Thumbnail, {
      source: line,
      key: line === null || line === void 0 ? void 0 : line.id
    });
  }));else lines = React.createElement(React.Fragment, null, order.lines.slice(0, 2).map(function (line) {
    return React.createElement(Thumbnail, {
      source: line,
      key: line === null || line === void 0 ? void 0 : line.id
    });
  }), React.createElement(S.ProductsPlus, null, "+ ".concat(order.lines.length - 2)));
  return React.createElement(S.Wrapper, {
    expanded: expanded
  }, React.createElement(S.Row, {
    onClick: toggle,
    ref: scrollRef,
    expanded: expanded
  }, React.createElement(S.Products, null, lines), React.createElement(S.OrderNumberAndDate, null, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.PastOrderRow.PastOrderRow.1371456881",
    defaultMessage: "Commande No:  ",
    description: "Order Number in PastOrderList"
  }), React.createElement(S.OrderNumber, null, order.number)), React.createElement("p", null, formatDateTime(new Date(order.created), intl))), React.createElement(S.OrderStatus, null, translateOrderStatus(order.statusDisplay, intl)), React.createElement(S.Money, {
    taxedMoney: order.total
  }), React.createElement(S.DownIcon, {
    path: angleDownImage,
    expanded: expanded
  })), expanded && React.createElement(PastOrderDetails, {
    token: order.token,
    onLoadDetails: onLoadDetails
  }));
};

PastOrderRow.displayName = "PastOrderRow";
export default PastOrderRow;