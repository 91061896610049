import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
export var SvgWonder = function SvgWonder(props) {
  return React.createElement("svg", _extends({
    "data-name": "Calque 1",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 113.263 18.29"
  }, props), React.createElement("g", {
    "data-name": "Groupe 182",
    fill: "#231f20"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 1",
    d: "M45.587.567l-5.393 17.4h-3.877l-2.93-10.412-2.93 10.412h-3.9l-5.37-17.4h4.449l3.006 11.431L31.873.567h3.032l3.211 11.431 3-11.431z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 2",
    d: "M58.489 11.675a6.761 6.761 0 11-6.76-6.61 6.505 6.505 0 016.76 6.61zm-4.05 0a2.7 2.7 0 10-2.709 2.684 2.638 2.638 0 002.709-2.684z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 3",
    d: "M72.58 10.083v7.879h-4.052v-6.785a2.018 2.018 0 10-4.026 0v6.785h-4.024v-12.6h4.024v1.162a4.379 4.379 0 013.454-1.466c3.132.007 4.624 2.496 4.624 5.025z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 4",
    d: "M88.187.567v17.4h-4.026v-1.222a4.371 4.371 0 01-3.554 1.545c-3.927 0-6.038-3.305-6.038-6.61s2.112-6.61 6.038-6.61a4.356 4.356 0 013.554 1.545V.567zm-4.151 11.108a2.582 2.582 0 00-2.708-2.709 2.551 2.551 0 00-2.685 2.709 2.541 2.541 0 002.685 2.684 2.574 2.574 0 002.708-2.684z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 5",
    d: "M103.196 12.893h-9.1a2.975 2.975 0 003.156 2.138 3.4 3.4 0 002.575-1.094l2.843 1.688a6.3 6.3 0 01-5.417 2.66c-4.249 0-7.082-2.684-7.082-6.61a6.507 6.507 0 016.511-6.61c3.731.001 7.019 2.535 6.514 7.828zm-3.652-2.435a2.539 2.539 0 00-2.733-2.138 2.715 2.715 0 00-2.709 2.138z"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 6",
    d: "M113.264 5.065v4.323c-3.578-.546-3.977 1.492-3.977 3.606v4.979h-4.026V5.365h4.026v1.914a4.808 4.808 0 013.977-2.214z"
  })), React.createElement("g", {
    "data-name": "Groupe 181"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 7",
    d: "M17.973 15.057a2.919 2.919 0 01-2.919 2.919H2.919A2.919 2.919 0 010 15.057V2.919A2.919 2.919 0 012.919 0h12.138a2.919 2.919 0 012.919 2.919z",
    fill: "#231f20"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 8",
    d: "M5.838 7.139a2.048 2.048 0 11-.6-1.451 2.05 2.05 0 01.6 1.451z",
    fill: "#96cbc7"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 9",
    d: "M11.035 7.139a2.05 2.05 0 11-2.048-2.05 2.05 2.05 0 012.048 2.05z",
    fill: "#9bd7f5"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 10",
    d: "M16.237 7.139a2.05 2.05 0 11-2.05-2.05 2.05 2.05 0 012.05 2.05z",
    fill: "#daeddd"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 11",
    d: "M13.493 11.967a2.05 2.05 0 11-2.05-2.048 2.05 2.05 0 012.05 2.048z",
    fill: "#f394b8"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 12",
    d: "M8.585 11.967a2.052 2.052 0 11-.605-1.452 2.05 2.05 0 01.605 1.452z",
    fill: "#babadd"
  })));
};
export default SvgWonder;