import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  height: 200px;\n  @media (min-width: ", ") {\n    width: unset;\n  }\n\n  overflow: hidden;\n  font-size: 0.8rem;\n\n\n  h4 {\n  font-size: 0.8rem;\n  margin-bottom: 5px;\n  }\n  \n  ul {\n    list-style: inside;\n    padding: 0 1rem;\n  }\n\n  \n  .color-overlay {\n    width: 100%;\n    padding-bottom: 1rem;\n    border-radius: 10px;\n    background-color: white;\n    box-shadow: 0px -30px 40px 6px white;\n    box-shadow: 0px -10px 20px 5px white;\n\n    position: absolute;\n    bottom: -1px;\n    left: 0;\n    background-color: white;\n\n    display: flex;\n    align-items: flex-end;\n    justify-content: center;\n    \n    button {\n      width: 40%;\n      padding: 5px;\n      font-size: 0.875rem;\n    }\n  }\n  .\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n  color: gray;\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  margin: 1rem 0;\n  width: 100%;\n  > button {\n    border-radius: 12px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    background: linear-gradient(\n          ", ",\n          ", "\n        )\n        padding-box,\n      linear-gradient(\n          90deg,\n          rgba(147, 199, 195, 1) 0%,\n          rgba(152, 211, 241, 1) 25%,\n          rgba(186, 186, 221, 1) 50%,\n          rgba(216, 235, 219, 1) 75%,\n          rgba(243, 148, 184, 1) 100%\n        )\n        border-box;\n\n    border-radius: 30px;\n    border: 4px solid transparent;\n\n    svg {\n      width: 1.5rem;\n      margin-right: 1rem;\n    }\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  color: red;\n  font-weight: 500;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 10px;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 10px;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  right: -10px;\n  width: 4rem;\n  height: 4rem;\n  font-size: 1rem;\n  padding: 1rem 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-left: auto;\n  border-radius: 50%;\n  background-color: red;\n  color: white;\n\n  @media (min-width: ", ") {\n    margin-right: 2rem;\n    right: 0;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: red;\n  font-weight: 500;\n  font-size: 0.8rem;\n\n  @media (min-width: ", ") {\n    margin-left: 0.5rem;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    align-items: center;\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n\n  .undiscounted {\n    color: ", ";\n    font-size: ", ";\n    text-decoration: line-through;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: 1.5rem;\n  margin-bottom: 1rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  height: 22px;\n  display: flex;\n  align-items: center;\n  width: fit-content;\n\n  margin: 1rem 0 22px;\n\n  a > span {\n    display: inline-block;\n    margin-left: 0.5rem;\n    margin-top: 3px;\n  }\n\n  span {\n    font-size: 0.8rem;\n  }\n\n  @media (min-width: ", ") {\n    margin: 0 1rem 1rem auto;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  height: fit-content;\n  font-size: 0.8rem;\n  flex-basis: 100%;\n\n  @media (min-width: ", ") {\n    flex-basis: unset;\n  }\n\n  span {\n    color: ", ";\n  }\n\n  a {\n    color: ", ";\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: 20px;\n  line-height: 1.7rem;\n  min-height: 3.4rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  max-height: 100%;\n\n  padding: 1rem;\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var AddToCartSelection = styled.div(_templateObject2());
export var ProductNameHeader = styled.h3(_templateObject3(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var RatingContainer = styled.div(_templateObject4());
export var Vendor = styled.div(_templateObject5(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.colors.labelGray;
}, function (props) {
  return props.theme.colors.logoPurple;
});
export var Rating = styled.div(_templateObject6(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var ProductPricing = styled.div(_templateObject7(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Price = styled.div(_templateObject8(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var Baseline = styled.div(_templateObject9(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Saving = styled.p(_templateObject10(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Percentage = styled.div(_templateObject11(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var ProductGroup = styled.div(_templateObject12());
export var VariantPicker = styled.div(_templateObject13());
export var QuantityInput = styled.div(_templateObject14());
export var ErrorMessage = styled.span(_templateObject15());
export var AddToCartButton = styled.div(_templateObject16(), function (props) {
  return props.theme.colors.buttonBackgroundBlack;
}, function (props) {
  return props.theme.colors.buttonBackgroundBlack;
});
export var PurchaseInfo = styled.div(_templateObject17());
export var About = styled.div(_templateObject18(), function (props) {
  return props.theme.breakpoints.largeScreen;
});