import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { ErrorMessage } from "@components/atoms";
import React from "react";
import { PhoneInput } from "./PhoneInput";
import * as S from "./styles";
export var PhoneField = function PhoneField(_ref) {
  var errors = _ref.errors,
      helpText = _ref.helpText,
      rest = _objectWithoutProperties(_ref, ["errors", "helpText"]);

  var hasErrors = !!(errors && errors.length);
  return React.createElement(S.PhoneField, {
    errors: hasErrors
  }, React.createElement(PhoneInput, _extends({}, rest, {
    error: hasErrors
  })), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  }), S.HelpText && React.createElement(S.HelpText, null, helpText)));
};
PhoneField.displayName = "PhoneField";
export default PhoneField;