import React from "react";
import { Link } from "react-router-dom";
import { EmptyProductList, Loader } from "@components/atoms";
import { ProductTile } from "@components/molecules";
import * as S from "./styles";
export var ProductList = function ProductList(_ref) {
  var products = _ref.products,
      _ref$canLoadMore = _ref.canLoadMore,
      canLoadMore = _ref$canLoadMore === void 0 ? false : _ref$canLoadMore,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? false : _ref$loading,
      testingContextId = _ref.testingContextId,
      _ref$onLoadMore = _ref.onLoadMore,
      onLoadMore = _ref$onLoadMore === void 0 ? function () {
    return null;
  } : _ref$onLoadMore;
  return React.createElement(S.Wrapper, null, React.createElement("div", {
    "data-test": "productList",
    "data-test-id": testingContextId
  }, products.length !== 0 ? React.createElement(S.Infinite, {
    dataLength: products.length //This is important field to render the next data
    ,
    next: onLoadMore,
    hasMore: canLoadMore,
    loader: React.createElement(Loader, null),
    scrollThreshold: 0.6
  }, products.map(function (product) {
    var id = product.id,
        name = product.name,
        slug = product.slug;
    return id && name && React.createElement(S.Card, null, React.createElement(Link, {
      to: "/product/".concat(slug, "/"),
      key: id
    }, React.createElement(ProductTile, {
      product: product
    })));
  })) : React.createElement(EmptyProductList, null)));
};