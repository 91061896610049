import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { CachedImage } from "@components/molecules";
import noPhotoImg from "../../images/no-photo.svg";
import Chevron from "images/chevron.svg";

var CustomPrevArrow = function CustomPrevArrow(props) {
  var onClick = props.onClick;
  return React.createElement("img", {
    className: "prev-arrow",
    src: Chevron,
    alt: "",
    onClick: onClick
  });
};

var CustomNextArrow = function CustomNextArrow(props) {
  var onClick = props.onClick;
  return React.createElement("img", {
    className: "next-arrow",
    src: Chevron,
    alt: "",
    onClick: onClick
  });
};

var GalleryCarousel = function GalleryCarousel(_ref) {
  var images = _ref.images,
      thumbnails = _ref.thumbnails;

  var _React$useState = React.useState(0),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      lightboxIndex = _React$useState2[0],
      setLightboxIndex = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      isLightboxOpen = _React$useState4[0],
      setIsLightboxOpen = _React$useState4[1];

  var sliderRef = React.useRef();

  var handleLightBoxClose = function handleLightBoxClose() {
    sliderRef.current.slickGoTo(lightboxIndex);
    setIsLightboxOpen(false);
  };

  var settings = {
    dots: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: React.createElement(CustomPrevArrow, null),
    nextArrow: React.createElement(CustomNextArrow, null),
    lazyLoad: true,
    afterChange: function afterChange(current) {
      return setLightboxIndex(current);
    }
  };
  var imgs = images.map(function (i) {
    return i.url;
  });
  return React.createElement(React.Fragment, null, React.createElement(Slider, _extends({}, settings, {
    ref: sliderRef
  }), thumbnails.map(function (image) {
    return React.createElement(CachedImage, {
      url: image.url || noPhotoImg,
      key: image.id,
      onClick: function onClick() {
        return setIsLightboxOpen(true);
      }
    });
  })), isLightboxOpen && React.createElement(Lightbox, {
    mainSrc: imgs[lightboxIndex],
    nextSrc: imgs[(lightboxIndex + 1) % imgs.length],
    prevSrc: imgs[(lightboxIndex + imgs.length - 1) % imgs.length],
    onCloseRequest: handleLightBoxClose,
    onMovePrevRequest: function onMovePrevRequest() {
      return setLightboxIndex((lightboxIndex + imgs.length - 1) % imgs.length);
    },
    onMoveNextRequest: function onMoveNextRequest() {
      return setLightboxIndex((lightboxIndex + 1) % imgs.length);
    }
  }));
};

export default GalleryCarousel;