import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { ThankYou } from "@components/organisms";

var ThankYouPage = function ThankYouPage(_ref) {
  _objectDestructuringEmpty(_ref);

  return React.createElement(ThankYou // continueShopping={() => history.push(BASE_URL)}
  // orderNumber={orderNumber}
  // orderDetails={() => history.push(generateGuestOrderDetailsUrl(token))}
  , null);
};

export { ThankYouPage };