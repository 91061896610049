import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { useAccountUpdate, useAuth } from "@lastmaj/saleor-sdk";
import { AccountUpdateForm } from "./AccountUpdateForm";
import * as S from "./styles";
export var AccountTile = function AccountTile() {
  var _useAccountUpdate = useAccountUpdate(),
      _useAccountUpdate2 = _slicedToArray(_useAccountUpdate, 2),
      setAccountUpdate = _useAccountUpdate2[0],
      _useAccountUpdate2$ = _useAccountUpdate2[1],
      data = _useAccountUpdate2$.data,
      error = _useAccountUpdate2$.error;

  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  return React.createElement(S.Wrapper, null, React.createElement(S.Header, null, React.createElement(FormattedMessage, commonMessages.userInfo)), React.createElement(S.Content, null, React.createElement(AccountUpdateForm, {
    initialValues: {
      firstName: user && user.firstName || "",
      lastName: user && user.lastName || ""
    },
    handleSubmit: function handleSubmit(data) {
      setAccountUpdate({
        input: data
      });
    }
  })));
};