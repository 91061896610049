import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useLocation } from "react-router";
import { MenuProfile } from "@components/organisms";
import SvgUser from "@temp/images/SvgUser";
import classNames from "classnames";
import "./scss/index.scss";
import { Link } from "react-router-dom";
import { loginRedirectPath } from "@temp/app/routes";

var MenuDropdown = function MenuDropdown(_ref) {
  var _ref$suffixClass = _ref.suffixClass,
      suffixClass = _ref$suffixClass === void 0 ? "" : _ref$suffixClass,
      isLoggedIn = _ref.isLoggedIn,
      userName = _ref.userName,
      handleSignOut = _ref.handleSignOut,
      _ref$debug = _ref.debug,
      debug = _ref$debug === void 0 ? false : _ref$debug;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      active = _React$useState2[0],
      setActive = _React$useState2[1];

  var location = useLocation();
  React.useEffect(function () {
    if (debug) setActive(true);
  }, [active]);
  var wrapperClass = classNames({
    "menu-dropdown": true,
    "menu-dropdown--visible": active && isLoggedIn
  });
  var iconClass = classNames({
    "top-navbar__icon__active": active && isLoggedIn,
    "top-navbar__icon": true,
    "top-navbar__user": true,
    "top-navbar__user--active": isLoggedIn
  });
  return React.createElement("div", {
    "data-test": "userButton",
    className: wrapperClass,
    onMouseOver: function onMouseOver() {
      return setActive(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setActive(false);
    }
  }, isLoggedIn ? React.createElement(React.Fragment, null, React.createElement("li", {
    className: iconClass
  }, React.createElement(SvgUser, null), !isLoggedIn && React.createElement("span", null, "Se connecter")), React.createElement("div", {
    className: "menu-dropdown__body".concat(" menu-dropdown__body".concat(suffixClass), active ? " menu-dropdown--visible__body" : "")
  }, React.createElement(MenuProfile, _extends({
    handleDropdownHide: function handleDropdownHide() {
      return setActive(false);
    }
  }, {
    handleSignOut: handleSignOut,
    userName: userName
  })))) : React.createElement(Link, {
    to: loginRedirectPath(location.pathname)
  }, React.createElement("li", {
    className: iconClass
  }, React.createElement(SvgUser, null), !isLoggedIn && React.createElement("span", null, "Se connecter"))));
};

export default MenuDropdown;