import { InputSelect, RequestFormMotive } from "@components/molecules";
import React from "react";
import { Controller } from "react-hook-form";
import * as S from "./styles";
import { FormattedMessage } from "react-intl";
export var RequestPartialForm = function RequestPartialForm(_ref) {
  var id = _ref.id,
      control = _ref.control,
      quantity = _ref.quantity,
      productName = _ref.productName,
      thumbnail = _ref.thumbnail;
  var options = Array.from(Array(quantity), function (_, i) {
    return {
      label: "".concat(i + 1),
      value: i + 1
    };
  });
  return React.createElement(S.Wrapper, null, React.createElement(S.Inner, null, React.createElement(S.Line, null, React.createElement(S.Thumb, {
    source: {
      thumbnail: thumbnail
    }
  }), React.createElement("p", null, productName)), React.createElement(RequestFormMotive, {
    control: control,
    id: id
  }), React.createElement(Controller, {
    control: control,
    name: "comment-".concat(id),
    defaultValue: "",
    rules: {
      required: true
    },
    render: function render(_ref2) {
      var _ref2$field = _ref2.field,
          onChange = _ref2$field.onChange,
          value = _ref2$field.value,
          _ref2$fieldState = _ref2.fieldState,
          error = _ref2$fieldState.error,
          invalid = _ref2$fieldState.invalid;
      return React.createElement(React.Fragment, null, React.createElement("label", {
        htmlFor: "comment"
      }, React.createElement(FormattedMessage, {
        id: "@next.components.organisms.RequestPartialForm.RequestPartialForm.4076795182",
        defaultMessage: "Votre commentaire (optionnel) :"
      })), React.createElement(S.Comment, null, React.createElement("textarea", {
        name: "comment",
        id: "comment",
        maxLength: 500,
        value: value,
        onChange: onChange
      }), error && React.createElement(S.Error, null, React.createElement("p", null, "This field is required"))));
    }
  }), React.createElement(Controller, {
    control: control,
    name: "quantitySelected-".concat(id),
    defaultValue: 1,
    rules: {
      required: true
    },
    render: function render(_ref3) {
      var _ref3$field = _ref3.field,
          onChange = _ref3$field.onChange,
          value = _ref3$field.value;
      return React.createElement(InputSelect, {
        name: "quantity",
        label: "Quantit\xE9 \xE0 retourner",
        onChange: onChange,
        options: options,
        isDisabled: quantity === 1,
        isSearchable: false,
        value: options.find(function (o) {
          return o.value === value;
        })
      });
    }
  })));
};
RequestPartialForm.displayName = "RequestPartialForm";
export default RequestPartialForm;