import React from "react";
import { useIntl } from "react-intl";
import * as S from "./styles";
import { Thumbnail } from "@components/molecules";
import { formatDateTime } from "@temp/misc";
import { RequestEventsEnum } from "gqlTypes/globalTypes";
import HorizontalStepper from "@components/organisms/HorizontalStepper";
import { translateRequestReplacementMsg, translateRequestStatus } from "@temp/intl";

var getCurrentStep = function getCurrentStep(lastEvent) {
  switch (lastEvent) {
    case RequestEventsEnum.CREATED:
      return 0;

    case RequestEventsEnum.BEING_PROCESSED:
      return 1;

    case RequestEventsEnum.APPROVED:
    case RequestEventsEnum.REJECTED:
    case RequestEventsEnum.INVOICE_SENT:
    case RequestEventsEnum.VOUCHER_ISSUED:
    case RequestEventsEnum.PRODUCT_RECEIVED:
    case RequestEventsEnum.PAYMENT_ISSUED:
      return 2;

    case RequestEventsEnum.CLOSED:
    case RequestEventsEnum.CANCELED:
      return 3;

    default:
      return 0;
  }
};

export var RequestDetailsPage = function RequestDetailsPage(_ref) {
  var _request$orderline, _request$orderline$un, _request$orderline2, _request$orderline3, _request$orderline4, _request$orderline4$v, _slice$pop;

  var request = _ref.request;
  var intl = useIntl();

  var getIssue = function getIssue() {
    if (request && request.events) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = request.events[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var e = _step.value;
          if (e.type === RequestEventsEnum.APPROVED) return "Acceptée";else if (e.type === RequestEventsEnum.REJECTED) return "Rejected";
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return "Issue";
    }
  };

  var steps = ["Prise", "En traitement", getIssue(), "Clotûrée"];
  var total = {
    currency: request.orderline.unitPrice.gross.currency,
    amount: // @ts-ignore
    request.quantitySelected * ((_request$orderline = request.orderline) === null || _request$orderline === void 0 ? void 0 : (_request$orderline$un = _request$orderline.unitPrice) === null || _request$orderline$un === void 0 ? void 0 : _request$orderline$un.gross.amount)
  };
  return React.createElement(S.Wrapper, null, React.createElement(S.Table, null, React.createElement(S.Row, null, React.createElement(S.Label, null, "\xC9tat :"), React.createElement(S.Value, null, translateRequestStatus(request.status, intl))), React.createElement(S.Row, null, React.createElement(S.Label, null, "Cr\xE9\xE9e le :"), React.createElement(S.Value, null, formatDateTime(new Date(request.created), intl))), React.createElement(S.Row, null, React.createElement(S.Label, null, "Commande n\xBA :"), React.createElement(S.Value, null, (_request$orderline2 = request.orderline) === null || _request$orderline2 === void 0 ? void 0 : _request$orderline2.orderNumber)), React.createElement(S.Row, null, React.createElement(S.Label, null, "Commentaire :"), React.createElement(S.Value, null, React.createElement("p", null, request.comment))), React.createElement(S.Row, null, React.createElement(S.Label, null, "Total :"), React.createElement(S.Value, null, React.createElement(S.Price, {
    money: total
  })))), React.createElement(S.Orderline, null, React.createElement(Thumbnail, {
    source: request.orderline
  }), React.createElement(S.Product, null, React.createElement("p", null, "".concat((_request$orderline3 = request.orderline) === null || _request$orderline3 === void 0 ? void 0 : _request$orderline3.productName, " - ").concat((_request$orderline4 = request.orderline) === null || _request$orderline4 === void 0 ? void 0 : (_request$orderline4$v = _request$orderline4.variant) === null || _request$orderline4$v === void 0 ? void 0 : _request$orderline4$v.name)), React.createElement(S.Quantity, null, React.createElement("p", null, "Quantit\xE9 : ", React.createElement("span", null, request.quantitySelected))))), React.createElement(S.Table, null, React.createElement(S.Row, null, React.createElement(S.Label, null, "Remplacement :"), React.createElement(S.Value, null, translateRequestReplacementMsg(request.replacement, intl)))), React.createElement(HorizontalStepper, {
    steps: steps,
    current: getCurrentStep((_slice$pop = request.events.slice(-1).pop()) === null || _slice$pop === void 0 ? void 0 : _slice$pop.type),
    progress: 0
  }));
};