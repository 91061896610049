import React from "react";
import { CheckboxColor } from "@components/atoms";
import * as S from "./styles";
export var AttributeValuesCheckListColor = function AttributeValuesCheckListColor(_ref) {
  var title = _ref.title,
      name = _ref.name,
      values = _ref.values,
      onValueClick = _ref.onValueClick;
  return React.createElement(S.Wrapper, null, title && React.createElement(S.Header, null, title), React.createElement(S.Colors, null, values && values.map(function (value, index) {
    return React.createElement(CheckboxColor, {
      name: name,
      checked: !!value.selected,
      onChange: function onChange() {
        return onValueClick(value);
      },
      color: value.slug
    });
  })), React.createElement(S.BottomBorder, null));
};
AttributeValuesCheckListColor.displayName = "AttributeValuesCheckListColor";
export default AttributeValuesCheckListColor;