import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  color: #929b92;\n  font-weight: 600;\n  font-size: 0.8rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 1rem;\n  font-size: 0.875rem;\n\n  @media (min-length: ", ") {\n    font-size: 1rem;\n  }\n\n  margin-bottom: 1rem;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 1rem;\n  font-size: 0.8rem;\n  color: gray;\n\n  span {\n    margin-left: 2rem;\n    font-weight: 550;\n    color: #79a29f;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem 0;\n\n  :not(:last-child) {\n    border-bottom: 1px solid #79a29f85;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 1rem 0 2rem 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Header = styled.h4(_templateObject2());
export var Item = styled.div(_templateObject3());
export var Author = styled.p(_templateObject4());
export var Rating = styled.div(_templateObject5());
export var Text = styled.p(_templateObject6(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Date = styled.p(_templateObject7());