import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query SearchProducts(\n    $query: String!\n    $attributes: [AttributeInput]\n    $pageSize: Int\n    $sortBy: ProductOrder\n    $after: String\n    $priceLte: Float\n    $priceGte: Float\n  ) {\n    products(\n      first: $pageSize\n      sortBy: $sortBy\n      after: $after\n      filter: {\n        search: $query\n        attributes: $attributes\n        minimalPrice: { gte: $priceGte, lte: $priceLte }\n      }\n    ) {\n      totalCount\n      edges {\n        node {\n          ...ProductPricingField\n          ...BasicProductFields\n          category {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n    attributes(filter: { filterableInStorefront: true }, first: 100) {\n      edges {\n        node {\n          id\n          name\n          slug\n          values {\n            id\n            name\n            slug\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query SearchAttributes($query: String!) {\n    products(filter: { search: $query }) {\n      attributes {\n        id\n        name\n        slug\n        values {\n          id\n          name\n          slug\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { basicProductFragment, productPricingFragment } from "../Product/queries";
export var searchAttributesQuery = gql(_templateObject());
export var TypedSearchAttributesQuery = TypedQuery(searchAttributesQuery);
export var searchProductsQuery = gql(_templateObject2(), productPricingFragment, basicProductFragment);
export var TypedSearchProductsQuery = TypedQuery(searchProductsQuery);