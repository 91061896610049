import classNames from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { commonMessages } from "@temp/intl";
import { baseUrl } from "../../app/routes";
import "./scss/index.scss";
import { generateCategoryUrl } from "@utils/core";
export var extractBreadcrumbs = function extractBreadcrumbs(category) {
  var constructLink = function constructLink(item) {
    return {
      link: generateCategoryUrl(item.slug),
      value: item.name
    };
  };

  var breadcrumbs = [constructLink(category)];

  if (category.ancestors.edges.length) {
    var ancestorsList = category.ancestors.edges.map(function (edge) {
      return constructLink(edge.node);
    });
    breadcrumbs = ancestorsList.concat(breadcrumbs);
  }

  return breadcrumbs;
};

var getBackLink = function getBackLink(breadcrumbs) {
  return breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2].link : "/";
};

var Breadcrumbs = function Breadcrumbs(_ref) {
  var breadcrumbs = _ref.breadcrumbs;
  return React.createElement("ul", {
    className: "breadcrumbs"
  }, React.createElement("li", null, React.createElement(Link, {
    to: baseUrl
  }, React.createElement(FormattedMessage, commonMessages.home))), breadcrumbs.map(function (breadcrumb, index) {
    return React.createElement("li", {
      key: breadcrumb.value,
      className: classNames({
        breadcrumbs__active: index === breadcrumbs.length - 1
      })
    }, React.createElement(Link, {
      to: breadcrumb.link
    }, breadcrumb.value));
  }));
};

export default Breadcrumbs;