import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  text-align: right;\n  margin-top: 40px;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  bottom: 3.5rem;\n  left: 0;\n  z-index: 250;\n  width: 100%;\n\n  background-color: white;\n\n  @media (min-width: ", ") {\n    background-color: unset;\n    position: unset;\n    margin: 0 0 0 0.5rem;\n    flex: 1;\n    width: unset;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  @media (min-width: ", ") {\n    flex: 2;\n    margin-right: 30px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    display: none;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 2rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  padding: 1rem 0;\n  flex-direction: column;\n  justify-content: space-between;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 1.25rem 0 4rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Main = styled.div(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Breadcrumbs = styled.div(_templateObject3());
export var Title = styled.h1(_templateObject4());
export var CartHeader = styled.div(_templateObject5(), media.mediumScreen(_templateObject6()));
export var Cart = styled.div(_templateObject7(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var CartFooter = styled.div(_templateObject8(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var ProceedButton = styled.div(_templateObject9());