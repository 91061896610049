import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query RequestFulfillment($id: ID!) {\n    fulfillment(id: $id) {\n      id\n      created\n      status\n      created\n      lines {\n        id\n        orderLine {\n          id\n          productName\n          productSku\n          quantityFulfilled\n          variant {\n            id\n            name\n          }\n          thumbnail {\n            alt\n            url\n          }\n          totalPrice {\n            gross {\n              amount\n              currency\n            }\n            net {\n              amount\n              currency\n            }\n          }\n          unitPrice {\n            gross {\n              amount\n              currency\n            }\n            net {\n              amount\n              currency\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedQuery } from "@temp/core/queries";
import gql from "graphql-tag";
var requestFulfillmentQuery = gql(_templateObject());
export var TypedRequestFulfillmentQuery = TypedQuery(requestFulfillmentQuery);