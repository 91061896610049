import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  margin: 0.4rem 0 0 0;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n\n  @media (min-width: ", ") {\n    font-size: 1rem;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n\n  margin-left: 1rem;\n\n  button {\n    height: 100%;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n  margin-bottom: 0.5rem;\n\n  @media (min-width: ", ") {\n    font-size: 1rem;\n    margin-bottom: 1rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var DiscountForm = styled.form(_templateObject());
export var Headline = styled.p(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Input = styled.div(_templateObject3(), function (props) {
  return props.theme.spacing.spacer;
});
export var InputWithButton = styled.div(_templateObject4());
export var InputWrapper = styled.div(_templateObject5());
export var ButtonWrapper = styled.div(_templateObject6());
export var ChipsHeadline = styled.span(_templateObject7(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var ChipsWrapper = styled.div(_templateObject8());