import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import Media from "react-responsive";
import { RichTextContent } from "@components/atoms";
import { mediumScreen } from "@styles/constants";
export var Page = function Page(_ref) {
  var navigation = _ref.navigation,
      page = _ref.page;
  return React.createElement("div", {
    className: "article-page"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "article-page__container"
  }, React.createElement("div", {
    className: "article-page__navigation"
  }, React.createElement(Media, {
    maxWidth: mediumScreen
  }, React.createElement("span", null, "Aller \xE0")), React.createElement("ul", null, navigation.map(function (menuElement) {
    return React.createElement("li", {
      className: classNames({
        "article-page__navigation-element": true,
        "article-page__navigation-element--active": menuElement.active
      }),
      key: menuElement.url
    }, React.createElement(Link, {
      to: menuElement.url
    }, menuElement.label));
  }))), React.createElement("div", {
    className: "article-page__content"
  }, React.createElement("h1", null, page.title), React.createElement(RichTextContent, {
    descriptionJson: page.contentJson
  })))));
};
export default Page;