import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useAuth } from "@lastmaj/saleor-sdk";
import { Container } from "../Container";
import { CartFooter } from "@components/atoms";
import * as S from "./styles";
import { commonMessages } from "@temp/intl";

/**
 * Cart template for cart page with list of products added by user.
 */
var Cart = function Cart(_ref) {
  var breadcrumbs = _ref.breadcrumbs,
      cart = _ref.cart,
      button = _ref.button,
      totalPrice = _ref.totalPrice,
      shippingTaxedPrice = _ref.shippingTaxedPrice,
      promoTaxedPrice = _ref.promoTaxedPrice,
      subtotalPrice = _ref.subtotalPrice,
      shippingPrice = _ref.shippingPrice;
  var history = useHistory();
  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  return React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement(S.Breadcrumbs, null, breadcrumbs), React.createElement(S.Title, {
    "data-test": "cartPageTitle"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.templates.Cart.Cart.464484266",
    defaultMessage: "Panier"
  })), React.createElement(S.Main, null, React.createElement(S.Cart, null, cart), React.createElement(S.CartFooter, null, React.createElement(CartFooter, _extends({
    buttonText: intl.formatMessage(commonMessages.checkout),
    onButtonClick: function onButtonClick() {
      return history.push(user ? "/checkout/" : "/login/");
    }
  }, {
    shippingPrice: shippingPrice,
    totalPrice: totalPrice,
    subtotalPrice: subtotalPrice,
    discountPrice: promoTaxedPrice
  }))))));
};

export { Cart };