import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import Media from "react-responsive";
import * as S from "./styles";
import { Button } from "..";
import { TaxedMoney } from "@components/containers";
import { largeScreen } from "@styles/constants";

/**
 * Cart footer to use with conjunction of cart rows
 */
var CartFooter = function CartFooter(_ref) {
  var subtotalPrice = _ref.subtotalPrice,
      shippingPrice = _ref.shippingPrice,
      discountPrice = _ref.discountPrice,
      totalPrice = _ref.totalPrice,
      onButtonClick = _ref.onButtonClick,
      buttonText = _ref.buttonText,
      withoutNav = _ref.withoutNav;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      expanded = _React$useState2[0],
      setExpanded = _React$useState2[1];

  return React.createElement(S.Container, null, React.createElement(Media, {
    maxWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, React.createElement(S.MobileExpanded, {
      className: expanded ? "active" : "hidden",
      type: withoutNav && "withoutNav"
    }, React.createElement("div", {
      className: "item"
    }, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.subtotal)), React.createElement(TaxedMoney, {
      taxedMoney: subtotalPrice
    })), (discountPrice === null || discountPrice === void 0 ? void 0 : discountPrice.net.amount) !== 0 && React.createElement("div", {
      className: "item discount"
    }, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.discount)), React.createElement(TaxedMoney, {
      taxedMoney: discountPrice,
      negative: true
    })), React.createElement("div", {
      className: "item"
    }, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.shipping)), React.createElement(TaxedMoney, {
      taxedMoney: shippingPrice
    }))), React.createElement(S.MobileWrapper, null, React.createElement(S.MobileContent, {
      onClick: function onClick() {
        return setExpanded(!expanded);
      }
    }, React.createElement(S.Chevron, {
      expanded: expanded
    }), React.createElement("div", null, React.createElement(S.Label, null, React.createElement(FormattedMessage, commonMessages.total)), React.createElement(TaxedMoney, {
      taxedMoney: totalPrice
    }))), React.createElement(Button, {
      testingContext: "proceedToCheckoutButton",
      onClick: onButtonClick
    }, buttonText))) : React.createElement(React.Fragment, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, commonMessages.totalOrder)), React.createElement(S.Wrapper, null, React.createElement("div", {
      className: "item"
    }, React.createElement(S.Label, null, React.createElement(FormattedMessage, commonMessages.subtotal)), React.createElement(TaxedMoney, {
      taxedMoney: subtotalPrice
    })), React.createElement("div", {
      className: "label"
    }, React.createElement(S.Label, null, React.createElement(FormattedMessage, commonMessages.shipping)), React.createElement(TaxedMoney, {
      taxedMoney: shippingPrice
    })), (discountPrice === null || discountPrice === void 0 ? void 0 : discountPrice.net.amount) !== 0 && React.createElement("div", {
      className: "label discount"
    }, React.createElement(S.Label, null, React.createElement(FormattedMessage, commonMessages.promoCode)), React.createElement(TaxedMoney, {
      taxedMoney: discountPrice
    })), React.createElement("div", {
      className: "label"
    }, React.createElement(S.Label, null, React.createElement(FormattedMessage, commonMessages.total)), React.createElement(TaxedMoney, {
      taxedMoney: totalPrice
    }))), React.createElement(S.Button, null, React.createElement(Button, {
      testingContext: "proceedToCheckoutButton",
      onClick: onButtonClick
    }, React.createElement(FormattedMessage, commonMessages.checkout))));
  }));
};

export { CartFooter };