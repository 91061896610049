import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgRequestCreate = function SvgRequestCreate(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 26.855 21.484"
  }, props), React.createElement("path", {
    "data-name": "Trac\\xE9 229",
    d: "M24.169 0H2.685A2.693 2.693 0 0 0 0 2.685V18.8a2.693 2.693 0 0 0 2.685 2.685h21.484a2.693 2.693 0 0 0 2.686-2.685V2.685A2.693 2.693 0 0 0 24.169 0ZM2.685 1.343h21.484a1.34 1.34 0 0 1 1.124.612L13.427 13.821 1.562 1.955a1.34 1.34 0 0 1 1.123-.612Zm21.484 18.8H2.685A1.344 1.344 0 0 1 1.343 18.8V3.635l12.084 12.084L25.512 3.635V18.8a1.344 1.344 0 0 1-1.343 1.341Z"
  }));
};

export default SvgRequestCreate;