import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgGreenTick = function SvgGreenTick(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48"
  }, props), React.createElement("g", {
    "data-name": "Layer 2"
  }, React.createElement("g", {
    "data-name": "Layer 1"
  }, React.createElement("path", {
    style: {
      fill: "none"
    },
    d: "M0 0h48v48H0z"
  }), React.createElement("path", {
    d: "M24 8.08A15.92 15.92 0 1 0 39.91 24 15.92 15.92 0 0 0 24 8.08ZM21.5 33.9 14 24.08l2.65-3.46L21.5 27l9.73-12.69 2.65 3.45Z",
    style: {
      fill: "#65c965"
    }
  }))));
};

export default SvgGreenTick;