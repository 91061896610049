import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment MainMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n      slug\n    }\n    url\n    collection {\n      id\n      name\n      slug\n    }\n    page {\n      slug\n    }\n    parent {\n      id\n    }\n  }\n\n  query MainMenu {\n    shop {\n      navigation {\n        main {\n          id\n          items {\n            ...MainMenuSubItem\n            children {\n              ...MainMenuSubItem\n              children {\n                ...MainMenuSubItem\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var mainMenu = gql(_templateObject());
export var TypedMainMenuQuery = TypedQuery(mainMenu);