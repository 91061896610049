import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import React from "react";
import { useIntl } from "react-intl";
import { InputSelect, PhoneField, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
import { Controller, useForm } from "react-hook-form";
import { transformFormHelperText, transformPhoneNumber } from "@temp/misc";
export var AddressFormContent = function AddressFormContent(_ref) {
  var formRef = _ref.formRef,
      formId = _ref.formId,
      values = _ref.values,
      errors = _ref.errors,
      testingContext = _ref.testingContext,
      countryAreas = _ref.countryAreas,
      cities = _ref.cities,
      onSubmit = _ref.onSubmit,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? false : _ref$includeEmail;
  var intl = useIntl();

  var _useForm = useForm(),
      control = _useForm.control,
      handleSubmit = _useForm.handleSubmit,
      watch = _useForm.watch,
      setValue = _useForm.setValue,
      getValues = _useForm.getValues;

  var watchCountryArea = watch("countryArea");
  var cityRef = React.useRef(null);
  var fieldErrors = {};

  if (errors) {
    errors.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;
      fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
        message: message
      }]) : [{
        message: message
      }];
    });
  }

  React.useEffect(function () {
    if (watchCountryArea) {
      setTimeout(function () {
        setValue("city", "");
      }, 100);
    }
  }, [watchCountryArea]);
  return React.createElement(S.AddressForm, {
    id: formId,
    ref: formRef,
    onSubmit: handleSubmit(onSubmit),
    "data-test": testingContext
  }, React.createElement(S.Wrapper, null, React.createElement(S.RowWithTwoCells, null, React.createElement(Controller, {
    control: control,
    name: "firstName",
    defaultValue: values.firstName || "",
    rules: {
      required: true
    },
    render: function render(_ref3) {
      var _ref3$field = _ref3.field,
          onChange = _ref3$field.onChange,
          value = _ref3$field.value,
          _ref3$fieldState = _ref3.fieldState,
          error = _ref3$fieldState.error,
          invalid = _ref3$fieldState.invalid;
      return React.createElement(TextField, {
        name: "firstName",
        label: intl.formatMessage(commonMessages.firstName),
        value: value,
        autoComplete: "given-name",
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })] || fieldErrors.firstName,
        onChange: onChange
      });
    }
  }), React.createElement(Controller, {
    control: control,
    name: "lastName",
    defaultValue: values.lastName || "",
    rules: {
      required: true
    },
    render: function render(_ref4) {
      var _ref4$field = _ref4.field,
          onChange = _ref4$field.onChange,
          value = _ref4$field.value,
          _ref4$fieldState = _ref4.fieldState,
          error = _ref4$fieldState.error,
          invalid = _ref4$fieldState.invalid;
      return React.createElement(TextField, {
        name: "lastName",
        label: intl.formatMessage(commonMessages.lastName),
        value: value,
        onChange: onChange,
        autoComplete: "family-name",
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })] || fieldErrors.lastName
      });
    }
  })), React.createElement(S.RowWithTwoCells, null, React.createElement(Controller, {
    control: control,
    name: "countryArea",
    defaultValue: values.countryArea || "",
    rules: {
      required: true
    },
    render: function render(_ref5) {
      var _ref5$field = _ref5.field,
          onChange = _ref5$field.onChange,
          value = _ref5$field.value,
          _ref5$fieldState = _ref5.fieldState,
          error = _ref5$fieldState.error,
          invalid = _ref5$fieldState.invalid;
      return React.createElement(InputSelect, {
        name: "countryArea",
        label: intl.formatMessage(commonMessages.state),
        autoComplete: "address-city",
        options: countryAreas,
        defaultValue: values.countryArea,
        optionValueKey: "label",
        value: countryAreas.find(function (x) {
          return x.label === value;
        }),
        onChange: onChange,
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })] || fieldErrors.countryArea
      });
    }
  }), React.createElement(Controller, {
    control: control,
    name: "city",
    defaultValue: values.city || "",
    rules: {
      required: true
    },
    render: function render(_ref6) {
      var _ref6$field = _ref6.field,
          onChange = _ref6$field.onChange,
          value = _ref6$field.value,
          _ref6$fieldState = _ref6.fieldState,
          error = _ref6$fieldState.error,
          invalid = _ref6$fieldState.invalid;
      return React.createElement(InputSelect, {
        label: intl.formatMessage(commonMessages.city),
        isDisabled: false,
        ref: cityRef,
        openMenuOnFocus: true,
        options: watchCountryArea ? cities[countryAreas.findIndex(function (x) {
          return x.label === watchCountryArea.label;
        })] : values.countryArea ? cities[countryAreas.findIndex(function (x) {
          return x.label === values.countryArea.label;
        })] : [],
        optionValueKey: "label",
        value: value,
        defaultValue: values.city,
        onChange: onChange,
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })] || fieldErrors.city
      });
    }
  })), React.createElement(S.RowWithTwoCells, null, React.createElement(Controller, {
    control: control,
    name: "phone",
    defaultValue: values.phone ? transformPhoneNumber(values.phone) : "",
    rules: {
      required: {
        value: true,
        message: "This field is required"
      }
    },
    render: function render(_ref7) {
      var _ref7$field = _ref7.field,
          onChange = _ref7$field.onChange,
          value = _ref7$field.value,
          _ref7$fieldState = _ref7.fieldState,
          error = _ref7$fieldState.error,
          invalid = _ref7$fieldState.invalid;
      return React.createElement(PhoneField, _defineProperty({
        value: value,
        onChange: onChange,
        label: intl.formatMessage(commonMessages.phone),
        type: "tel",
        autoComplete: "phone",
        errors: invalid && [error] || fieldErrors.phone
      }, "type", "tel"));
    }
  }), React.createElement(Controller, {
    control: control,
    name: "postalCode",
    defaultValue: values.postalCode || "",
    rules: {
      minLength: {
        value: 4,
        message: "A postal code should be 4-digit long."
      }
    },
    render: function render(_ref8) {
      var _ref8$field = _ref8.field,
          _onChange = _ref8$field.onChange,
          value = _ref8$field.value,
          _ref8$fieldState = _ref8.fieldState,
          error = _ref8$fieldState.error,
          invalid = _ref8$fieldState.invalid;
      return React.createElement(TextField, {
        label: intl.formatMessage(_defineProperty({
          defaultMessage: "ZIP/Postal Code",
          "id": "@next.components.organisms.AddressForm.AddressFormContent.2796540622"
        }, "id", "@next.components.organisms.AddressForm.AddressFormContent.2796540622")),
        type: "tel",
        value: value,
        onChange: function onChange(e) {
          if (e.nativeEvent.inputType === "deleteContentBackward") return _onChange(e);
          if (e.nativeEvent.data.charCodeAt(0) < 48 || e.nativeEvent.data.charCodeAt(0) > 57 || e.target.value.length > 4) return;else _onChange(e);
          return;
        },
        autoComplete: "postal-code",
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })] || fieldErrors.postalCode
      });
    }
  })), React.createElement(S.RowWithOneCell, null, React.createElement(Controller, {
    control: control,
    name: "streetAddress1",
    defaultValue: values.streetAddress1 || "",
    rules: {
      required: true
    },
    render: function render(_ref9) {
      var _ref9$field = _ref9.field,
          onChange = _ref9$field.onChange,
          value = _ref9$field.value,
          _ref9$fieldState = _ref9.fieldState,
          error = _ref9$fieldState.error,
          invalid = _ref9$fieldState.invalid;
      return React.createElement(TextField, {
        label: intl.formatMessage(_defineProperty({
          defaultMessage: "Address line 1",
          "id": "@next.components.organisms.AddressForm.AddressFormContent.1363074570"
        }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1363074570")),
        value: value,
        onChange: onChange,
        autoComplete: "address-line1",
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })] || fieldErrors.streetAddress1
      });
    }
  })), React.createElement(S.RowWithOneCell, null, React.createElement(Controller, {
    control: control,
    name: "streetAddress2",
    defaultValue: values.streetAddress2 || "",
    render: function render(_ref10) {
      var _ref10$field = _ref10.field,
          onChange = _ref10$field.onChange,
          value = _ref10$field.value,
          _ref10$fieldState = _ref10.fieldState,
          error = _ref10$fieldState.error,
          invalid = _ref10$fieldState.invalid;
      return React.createElement(TextField, {
        label: intl.formatMessage(_defineProperty({
          defaultMessage: "Address line 2",
          "id": "@next.components.organisms.AddressForm.AddressFormContent.3121963259"
        }, "id", "@next.components.organisms.AddressForm.AddressFormContent.3121963259")),
        value: value,
        onChange: onChange,
        autoComplete: "address-line2",
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })] || fieldErrors.streetAddress2
      });
    }
  })), includeEmail && React.createElement(S.RowWithTwoCells, null, React.createElement(Controller, {
    control: control,
    name: "email",
    render: function render(_ref11) {
      var _ref11$field = _ref11.field,
          value = _ref11$field.value,
          onChange = _ref11$field.onChange;
      return React.createElement(TextField, {
        label: intl.formatMessage(commonMessages.shortEmail),
        value: value,
        onChange: onChange,
        autoComplete: "email",
        errors: fieldErrors.email
      });
    }
  }))));
};