import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgUserSettings = function SvgUserSettings(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48"
  }, props), React.createElement("defs", null, React.createElement("style", null, ".user-settings_svg__cls-2{fill:#231f20}")), React.createElement("g", {
    id: "user-settings_svg__Layer_2",
    "data-name": "Layer 2"
  }, React.createElement("g", {
    id: "user-settings_svg__Layer_1-2",
    "data-name": "Layer 1"
  }, React.createElement("path", {
    style: {
      fill: "none"
    },
    d: "M0 0h48v48H0z"
  }), React.createElement("g", {
    id: "user-settings_svg__Group_506",
    "data-name": "Group 506"
  }, React.createElement("path", {
    id: "user-settings_svg__Path_217",
    "data-name": "Path 217",
    className: "user-settings_svg__cls-2",
    d: "M23.4 8.59a8 8 0 1 0-10.8 10.8 14.44 14.44 0 0 0-10.12 14H20c.47-.5.92-1 1.3-1.46H4c.7-6.54 6-11.64 12.3-11.64a11.6 11.6 0 0 1 4.77 1c.16-.22.34-.44.52-.65.71.21 1.43.38 2.15.53A14.2 14.2 0 0 0 20 19.39a8 8 0 0 0 3.4-10.8Zm-11.72 8.33a6.54 6.54 0 1 1 4.62 1.92 6.5 6.5 0 0 1-4.62-1.92Z"
  }), React.createElement("path", {
    className: "user-settings_svg__cls-2",
    d: "M32.27 41.69a12.41 12.41 0 0 1-2.27-.22l-.3-.06-.23-.81c-.4-1.4-.89-3.14-1.48-3.48a1.4 1.4 0 0 0-.69-.13 17.27 17.27 0 0 0-3.62.69l-.31.09-.22-.24a12.08 12.08 0 0 1-2.21-3.78l-.1-.3.41-.42c1.09-1.12 2.45-2.51 2.45-3.28s-1.35-2.16-2.44-3.27l-.42-.43.1-.29A11.92 11.92 0 0 1 23.15 22l.21-.25.31.1a17.86 17.86 0 0 0 3.64.69 1.45 1.45 0 0 0 .7-.15c.56-.32 1-2.07 1.45-3.47l.23-.8L30 18a11.87 11.87 0 0 1 4.55 0l.3.06.23.81c.4 1.4.89 3.14 1.48 3.47a1.4 1.4 0 0 0 .69.14 18.8 18.8 0 0 0 3.62-.69l.31-.1.21.25a11.6 11.6 0 0 1 2.21 3.78l.11.29-.42.43c-1.09 1.12-2.44 2.5-2.44 3.27s1.35 2.16 2.44 3.27l.42.43-.11.3a11.56 11.56 0 0 1-2.21 3.77l-.21.25-.31-.09a17.88 17.88 0 0 0-3.64-.7 1.58 1.58 0 0 0-.7.15c-.56.33-1.05 2.08-1.45 3.48l-.23.8-.3.06a12.38 12.38 0 0 1-2.28.26Zm-1.79-1.15a11.25 11.25 0 0 0 3.57 0l.06-.2c.53-1.9 1-3.55 1.94-4.09a2.4 2.4 0 0 1 1.24-.25 18.25 18.25 0 0 1 3.54.64 10.66 10.66 0 0 0 1.71-2.93c-1.39-1.43-2.7-2.78-2.7-4s1.31-2.52 2.7-3.95a10.47 10.47 0 0 0-1.71-2.92 19.1 19.1 0 0 1-3.52.63 2.31 2.31 0 0 1-1.23-.25c-1-.55-1.44-2.19-2-4.08l-.03-.14a10.7 10.7 0 0 0-3.57 0l-.06.2c-.53 1.9-1 3.54-1.94 4.08a2.49 2.49 0 0 1-1.24.27 19 19 0 0 1-3.54-.64A10.75 10.75 0 0 0 22 25.8c1.4 1.43 2.71 2.78 2.71 3.95s-1.31 2.52-2.71 4a11.19 11.19 0 0 0 1.71 2.93 18.56 18.56 0 0 1 3.51-.68 2.35 2.35 0 0 1 1.23.25c1 .55 1.43 2.19 2 4.09Zm1.79-6.86a3.93 3.93 0 0 1-3.93-3.93 3.93 3.93 0 1 1 3.93 3.93Zm-2.93-3.93a2.93 2.93 0 1 0 2.93-2.93 2.93 2.93 0 0 0-2.93 2.93Z"
  })))));
};

export default SvgUserSettings;