import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  query RequestDetails($id: ID!) {\n    request(id: $id) {\n      id\n      status\n      quantitySelected\n      orderline {\n        orderNumber\n        orderId\n        thumbnail {\n          alt\n          url\n        }\n        productName\n        variant {\n          name\n        }\n        quantity\n        unitPrice {\n          gross {\n            amount\n            currency\n          }\n          net {\n            amount\n            currency\n          }\n        }\n        totalPrice {\n          gross {\n            amount\n            currency\n          }\n          net {\n            amount\n            currency\n          }\n        }\n      }\n      created\n      comment\n      replacement\n      reason\n      events {\n        date\n        type\n        id\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query OrderDetails($token: UUID!) {\n    orderByToken(token: $token) {\n      ...OrderDetailsFragment\n      deliveryPercentage\n      fulfillmentPercentage\n      lines {\n        request {\n          id\n          created\n          status\n          reason\n        }\n      }\n      deliveryPercentage\n      fulfillmentPercentage\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation AccountConfirm($email: String!, $token: String!) {\n    confirmAccount(email: $email, token: $token) {\n      accountErrors {\n        field\n        message\n        code\n      }\n      csrfToken\n      token\n      refreshToken\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { fragmentOrderDetails } from "@temp/fragments/orders";
import { TypedMutation } from "../../core/mutations";
import { TypedQuery } from "@temp/core/queries";
var accountConfirmMutation = gql(_templateObject());
export var TypedAccountConfirmMutation = TypedMutation(accountConfirmMutation);
var orderDetailsQuery = gql(_templateObject2(), fragmentOrderDetails);
export var TypedOrderDetailsQuery = TypedQuery(orderDetailsQuery);
var requestDetailsQuery = gql(_templateObject3());
export var TypedRequestDetailsQuery = TypedQuery(requestDetailsQuery);