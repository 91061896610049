import React from "react";
import { Notification } from "@components/molecules";
import * as S from "./styles";
import SvgRedX from "images/SvgRedX";
export var ClickToPayFail = function ClickToPayFail() {
  return React.createElement(S.Wrapper, null, React.createElement(Notification, {
    title: "Paiement refus\xE9",
    Svg: SvgRedX,
    color: "red",
    expl: "V\xE9rifiez les informations bancaires que vous venez d'entrer, et/ou votre solde ainsi que la validit\xE9 de votre carte"
  }));
};
ClickToPayFail.displayName = "ClickToPayFail";
export default ClickToPayFail;