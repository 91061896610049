import React from "react";
import * as S from "./styles";
import salesImage from "@temp/images/sales.png";
import collectionImage from "@temp/images/collection.png";
import firstCategoryImage from "@temp/images/category1.png";
import secondCategoryImage from "@temp/images/category2.png";
import productImage from "@temp/images/products.png";
export var HomepageGrid = function HomepageGrid() {
  return React.createElement("div", {
    className: "container"
  }, React.createElement(S.Gallery, null, React.createElement(S.Purple, null, React.createElement("img", {
    src: salesImage
  }), React.createElement("div", null, React.createElement("p", null, "Sale"), React.createElement("p", null, "Discover", " ", React.createElement("span", null, "our ", React.createElement("br", null), " sales")))), React.createElement(S.Green, null, React.createElement("img", {
    src: collectionImage
  }), React.createElement("div", null, React.createElement("p", null, "Limited Edition"), React.createElement("p", null, "Discover", " ", React.createElement("span", null, "our ", React.createElement("br", null), " sneakers")))), React.createElement(S.Pink, null, React.createElement("img", {
    src: firstCategoryImage
  })), React.createElement(S.Teal, null, React.createElement("img", {
    src: secondCategoryImage
  })), React.createElement(S.Yellow, null, React.createElement("img", {
    src: productImage
  }))));
};
HomepageGrid.displayName = "HomepageGrid";
export default HomepageGrid;