import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "../Category/scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import Media from "react-responsive";
import { commonMessages } from "@temp/intl";
import { ProductListHeader } from "../../@next/components/molecules";
import { ProductList, FilterSidebarMobile } from "../../@next/components/organisms";
import { Breadcrumbs, ProductsFeatured } from "../../components";
import { maybe } from "../../core/utils";
import { FilterSidebar } from "../../@next/components/organisms/FilterSidebar";
import * as S from "./styles";
import { largeScreen } from "@styles/constants";

var Page = function Page(_ref) {
  var activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      collection = _ref.collection,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      clearFilters = _ref.clearFilters,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange,
      activePriceFilter = _ref.activePriceFilter,
      loading = _ref.loading,
      onSubmitPriceFilter = _ref.onSubmitPriceFilter,
      setAttributeFilters = _ref.setAttributeFilters,
      clearPriceFilter = _ref.clearPriceFilter;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });
  var hasProducts = canDisplayProducts && !!products.totalCount;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var intl = useIntl();
  var breadcrumbs = [{
    link: ["/collection", "/".concat(collection.slug)].join(""),
    value: collection.slug
  }];

  var getAttribute = function getAttribute(attributeSlug, valueSlug) {
    return {
      attributeSlug: attributeSlug,
      valueName: attributes.find(function (_ref2) {
        var slug = _ref2.slug;
        return attributeSlug === slug;
      }).values.find(function (_ref3) {
        var slug = _ref3.slug;
        return valueSlug === slug;
      }).name,
      valueSlug: valueSlug
    };
  };

  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  return React.createElement("div", {
    className: "collection"
  }, React.createElement(S.Container, null, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  })), React.createElement(S.LoadingContainer, null, React.createElement(S.Loading, {
    loading: loading
  }), React.createElement(S.Container, null, React.createElement(S.MainContainer, null, React.createElement(Media, {
    maxWidth: largeScreen
  }, function (matches) {
    return matches ? React.createElement(FilterSidebarMobile, _extends({
      show: showFilters,
      hide: function hide() {
        return setShowFilters(false);
      },
      onAttributeFiltersChange: onAttributeFiltersChange,
      onAttributeFiltersSubmit: setAttributeFilters
    }, {
      attributes: attributes,
      filters: filters,
      onSubmitPriceFilter: onSubmitPriceFilter,
      activePriceFilter: activePriceFilter,
      setAttributeFilters: setAttributeFilters
    })) : React.createElement(FilterSidebar, {
      onSubmitPriceFilter: onSubmitPriceFilter,
      activePriceFilter: activePriceFilter,
      filters: filters,
      attributes: attributes,
      onAttributeFiltersChange: onAttributeFiltersChange
    });
  }), React.createElement(S.Right, null, React.createElement(ProductListHeader, {
    onShowFilters: function onShowFilters() {
      return setShowFilters(true);
    },
    clearPriceFilter: clearPriceFilter,
    priceFilter: activePriceFilter,
    activeSortOption: activeSortOption,
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }), canDisplayProducts && React.createElement(ProductList, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  }))))), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;