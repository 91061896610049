import React from "react";
import SvgGreenTick from "images/SvgGreenTick";
import { Notification } from "@components/molecules";
import * as S from "./styles";
export var ClickToPaySuccess = function ClickToPaySuccess() {
  return React.createElement(S.Wrapper, null, React.createElement(Notification, {
    title: "Paiement effectu\xE9 avec succ\xE9s",
    color: "green",
    Svg: SvgGreenTick,
    expl: "Redirection dans quelques secondes."
  }));
};
ClickToPaySuccess.displayName = "ClickToPaySuccess";
export default ClickToPaySuccess;