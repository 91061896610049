import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 2rem;\n  width: 100%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem;\n  font-size: 1rem;\n  text-align: center;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    font-size: 8rem;"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 15rem;\n  font-weight: 700;\n  line-height: 100%;\n  color: ", ";\n  padding-bottom: 20px;\n  text-align: center;\n\n  border-bottom: 1px solid\n    ", ";\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 2rem auto;\n\n  max-width: 500px;\n  width: 95%;\n  font-size: 3rem;\n  padding-bottom: 2rem;\n  margin-bottom: 40px;\n  border-bottom: 1px solid\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Header = styled.h2(_templateObject2(), function (props) {
  return props.theme.colors.darkGreen;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, media.smallScreen(_templateObject3()));
export var Text = styled.div(_templateObject4());
export var Button = styled.div(_templateObject5());