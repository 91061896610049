import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  border-bottom: 1px solid ", ";\n  width: 95%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.875rem;\n  font-weight: ", ";\n  padding-bottom: 0.5rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  margin-top: 1rem;\n\n  font-size: 0.75rem;\n\n  -webkit-tap-highlight-color: transparent;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Header = styled.h6(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Colors = styled.div(_templateObject3());
export var BottomBorder = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.divider;
});