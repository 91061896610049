import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 20rem;\n  border-radius: 10px;\n  &:first-of-type {\n    margin-bottom: 2rem;\n  }\n\n  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,\n    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;\n\n  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;\n\n  background: url(", ");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n\n  @media (min-width: ", ") {\n    width: 48%;\n\n    &:first-of-type {\n      margin-bottom: unset;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin-bottom: 4rem;\n\n  @media (min-width: ", ") {\n    flex-direction: row;\n    margin-bottom: 6rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from "styled-components";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Card = styled.div(_templateObject2(), function (props) {
  return props.background;
}, function (props) {
  return props.theme.breakpoints.smallScreen;
});