import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 2rem auto;\n\n  min-height: 90vh;\n  max-width: 500px;\n  width: 95%;\n  font-size: 3rem;\n  padding-bottom: 2rem;\n  border-bottom: 1px solid\n    ", ";\n  margin-bottom: 40px;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, media.smallScreen(_templateObject2(), function (props) {
  return props.theme.typography.h1FontSize;
}));