import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
export var SvgCustomerService = function SvgCustomerService(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 87.473 78.5050"
  }, props), React.createElement("g", {
    "data-name": "Groupe 145",
    transform: "translate(0 -.442)"
  }, React.createElement("g", {
    transform: "translate(0 15.39)"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 77",
    d: "M63.557 20.613a5.082 5.082 0 0 0-1.249.172A29.243 29.243 0 0 0 34.355 0H34.213A29.247 29.247 0 0 0 6.27 20.744a5.091 5.091 0 0 0-1.117-.131A5.168 5.168 0 0 0 0 25.766v6.871a5.168 5.168 0 0 0 5.153 5.153 5.144 5.144 0 0 0 2.712-.8 5.22 5.22 0 0 0 1.433-1.333 25.542 25.542 0 0 1-.711-3.77 26.23 26.23 0 0 1-.141-2.69 25.809 25.809 0 0 1 .143-2.692 25.362 25.362 0 0 1 .711-3.77A25.786 25.786 0 0 1 34.217 3.436h.072a25.765 25.765 0 1 1-6.311 50.734 5.2 5.2 0 0 0-1.383 1.419 5.427 5.427 0 0 0-.849 2.812 5.168 5.168 0 0 0 5.153 5.153h6.9a5.168 5.168 0 0 0 5.145-5.154 5.082 5.082 0 0 0-.172-1.249 29.307 29.307 0 0 0 19.536-19.532 5.082 5.082 0 0 0 1.249.172 5.168 5.168 0 0 0 5.153-5.153v-6.872a5.168 5.168 0 0 0-5.153-5.153Z"
  }), React.createElement("circle", {
    "data-name": "Ellipse 17",
    cx: 3.436,
    cy: 3.436,
    transform: "translate(24.049 15.455)",
    r: 3.436
  }), React.createElement("circle", {
    "data-name": "Ellipse 18",
    cx: 3.5,
    cy: 3.5,
    r: 3.5,
    transform: "translate(37.473 15.052)"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 78",
    d: "M46.236 29.202h-3.435a8.447 8.447 0 0 1-16.894 0h-3.434a11.882 11.882 0 0 0 23.763 0Z"
  })), React.createElement("ellipse", {
    "data-name": "Ellipse 24",
    cx: 19,
    cy: 18.5,
    rx: 19,
    ry: 18.5,
    transform: "translate(49.473 .442)",
    fill: "#babadd"
  })));
};
export default SvgCustomerService;