import React from "react";
import * as S from "./styles";
import { Link } from "react-router-dom";
export var ProductTileCircles = function ProductTileCircles(_ref) {
  var _product$pricing, _product$pricing$pric, _product$pricing2, _product$pricing2$pri;

  var product = _ref.product,
      variant = _ref.variant;
  var price = (_product$pricing = product.pricing) === null || _product$pricing === void 0 ? void 0 : (_product$pricing$pric = _product$pricing.priceRange) === null || _product$pricing$pric === void 0 ? void 0 : _product$pricing$pric.start;
  var priceUndiscounted = (_product$pricing2 = product.pricing) === null || _product$pricing2 === void 0 ? void 0 : (_product$pricing2$pri = _product$pricing2.priceRangeUndiscounted) === null || _product$pricing2$pri === void 0 ? void 0 : _product$pricing2$pri.start;

  var getPrice = function getPrice() {
    // assuming displayGrossPrices is true
    if (price.gross.amount === priceUndiscounted.gross.amount) return React.createElement(S.SinglePrice, {
      taxedMoney: price
    });
    priceUndiscounted.gross.currency = "";
    return React.createElement(React.Fragment, null, React.createElement(S.Undiscounted, {
      taxedMoney: priceUndiscounted
    }), "\xA0\xA0\xA0\xA0", React.createElement(S.Discounted, {
      taxedMoney: price
    }));
  };

  return React.createElement(S.Wrapper, {
    variant: variant
  }, React.createElement(Link, {
    to: "/product/".concat(product.slug)
  }, React.createElement(S.Avatar, null, React.createElement(S.Thumb, {
    source: product
  })), React.createElement(S.Box, null, React.createElement(S.ProductName, null, product.name), React.createElement("p", null, " ", getPrice()))));
};
ProductTileCircles.displayName = "ProductTileCircles";
export default ProductTileCircles;