import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { maybe } from "@temp/core/utils";
import { TypedOrderDetailsQuery } from "./queries";
import { OrderAddressesPayment, OrderFulfillment, OrderRequests } from "@components/molecules";
import { OrderUnfulfilledItems } from "@components/molecules";
import { OrderStatus } from "gqlTypes/globalTypes";
import HorizontalStepper from "@components/organisms/HorizontalStepper";
import { OrderCancelModal } from "@components/organisms";
import { TypedOrderCancelMutation } from "./mutations";
import { useAlert } from "react-alert";
import { useIntl } from "react-intl";
var steps = ["Prise", "Expediée", "Livrée"];

var showNotification = function showNotification(data, alert, intl) {
  var successful = maybe(function () {
    return !data.orderCancel.orderErrors.length;
  });

  if (successful) {
    alert.show({
      title: intl.formatMessage({
        defaultMessage: "Demande créée avec succès",
        "id": "views.Account.PastOrderDetails.1827982502"
      })
    }, {
      type: "success",
      timeout: 5000
    });
  } else {
    alert.show({
      title: "Une erreur inattendue est survenue."
    }, {
      type: "error",
      timeout: 5000
    });
  }
};

var PastOrderDetails = function PastOrderDetails(_ref) {
  var token = _ref.token,
      onLoadDetails = _ref.onLoadDetails;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      deleting = _React$useState2[0],
      setDeleting = _React$useState2[1];

  var alert = useAlert();
  var intl = useIntl();
  return React.createElement(TypedOrderDetailsQuery, {
    variables: {
      token: token
    },
    fetchPolicy: "cache-and-network"
  }, function (_ref2) {
    var _data$orderByToken$pa;

    var data = _ref2.data,
        loading = _ref2.loading;
    var currentStep = data.orderByToken.status === OrderStatus.DELIVERED ? 2 : data.orderByToken.status === OrderStatus.FULFILLED ? 1 : 0;
    var progress = data.orderByToken.status === OrderStatus.FULFILLED ? data.orderByToken.deliveryPercentage : data.orderByToken.fulfillmentPercentage;
    if (loading) return React.createElement("p", null, "loading ...");
    if (loading === false && data !== undefined) onLoadDetails();
    var order = maybe(function () {
      return data.orderByToken;
    });
    var unfulfilled = maybe(function () {
      return data.orderByToken.lines;
    }, []).filter(function (line) {
      return line.quantityFulfilled < line.quantity;
    });
    var canCancel = data.orderByToken.status === OrderStatus.UNFULFILLED && new Date().getTime() - Date.parse(data.orderByToken.created) < 3600000;
    var linesWithRequest = order.lines.filter(function (line) {
      return !!line.request;
    });
    setTimeout(function () {
      return scroll();
    }, 200);
    return React.createElement(React.Fragment, null, React.createElement(HorizontalStepper, {
      steps: steps,
      current: currentStep,
      progress: progress
    }), order.fulfillments.map(function (f) {
      return React.createElement(OrderFulfillment, {
        fulfillment: f,
        key: f.id
      });
    }), unfulfilled.length > 0 && React.createElement(OrderUnfulfilledItems, _extends({
      lines: unfulfilled,
      isCanceled: order.status === OrderStatus.CANCELED
    }, {
      canCancel: canCancel
    }, {
      onCancel: function onCancel() {
        return setDeleting(true);
      }
    })), linesWithRequest.length > 0 && React.createElement(OrderRequests, {
      lines: linesWithRequest
    }), React.createElement(OrderAddressesPayment, {
      shipping: data.orderByToken.shippingAddress,
      billing: data.orderByToken.billingAddress,
      payment: (_data$orderByToken$pa = data.orderByToken.payments) === null || _data$orderByToken$pa === void 0 ? void 0 : _data$orderByToken$pa[0],
      shippingPrice: data.orderByToken.shippingPrice,
      total: data.orderByToken.total,
      discount: data.orderByToken.discount
    }), deleting && React.createElement(TypedOrderCancelMutation, {
      onCompleted: function onCompleted(data) {
        window.location.reload();
        showNotification(data, alert, intl);
      }
    }, function (cancelOrder, _ref3) {
      var loading = _ref3.loading;
      return React.createElement(OrderCancelModal, {
        hideModal: function hideModal() {
          return setDeleting(false);
        },
        onSubmit: function onSubmit() {
          return cancelOrder({
            variables: {
              id: data.orderByToken.id
            }
          });
        }
      });
    }));
  });
};

export default PastOrderDetails;