// colors need to be in rgb format so that the function calculating the tick color could work
var colorMap = {
  rouge: "rgb(199,35,58)",
  "rouge-bordeaux": "rgb(137, 25, 25)",
  bleu: "rgb(33, 91, 209)",
  "bleu-marine": "rgb(13,26,79)",
  "bleu-ciel": "rgb(113, 179, 255)",
  vert: "rgb(45, 125, 48)",
  "vert-khaki": "rgb(139, 153, 85)",
  beige: "rgb(224, 205, 169)",
  marron: "rgb(126, 90, 30)",
  violet: "rgb(132, 39, 126)",
  rose: "rgb(236, 93, 196)",
  orange: "rgb(245, 166, 35)",
  jaune: "rgb(255, 230, 0)",
  gris: "rgb(221,221,221)",
  blanc: "rgb(255,255,255)",
  noir: "rgb(0,0,0)",
  camel: "rgb(191,138,61)",
  ecru: "rgb(255,248,213)"
};

var convertColor = function convertColor(color) {
  return colorMap[color];
};

export default convertColor;