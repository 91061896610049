import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { maybe } from "@temp/core/utils";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import { useIntl, FormattedMessage } from "react-intl";
import { Loader } from "@components/atoms";
import { RequestOrderlines, RequestPartialForm } from "@components/organisms";
import { RequestReplacement } from "@components/molecules";
import { Button } from "@components/atoms";
import { TypedRequestBulkCreateMutation } from "./mutations";
import { getRequestCreateErrorMessage } from "./errors";
import { TypedRequestFulfillmentQuery } from "./queries";
import "./scss/index.scss";

var showNotification = function showNotification(data, alert, intl) {
  var successful = maybe(function () {
    return !data.requestBulkCreate.errors.length;
  });

  if (successful) {
    alert.show({
      title: intl.formatMessage({
        defaultMessage: "Demande créée avec succès",
        "id": "account.RequestCreate.RequestCreate.1827982502"
      })
    }, {
      type: "success",
      timeout: 5000
    });
  } else {
    alert.show({
      title: getRequestCreateErrorMessage(data.requestBulkCreate.errors[0], intl)
    }, {
      type: "error",
      timeout: 5000
    });
  }
};

var RequestCreate = function RequestCreate(_ref) {
  var id = _ref.id;

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      checked = _React$useState2[0],
      setChecked = _React$useState2[1];

  var _useForm = useForm(),
      handleSubmit = _useForm.handleSubmit,
      control = _useForm.control;

  var alert = useAlert();
  var intl = useIntl();

  var prepareMutationPayload = function prepareMutationPayload(data) {
    var requests = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = checked[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var c = _step.value;
        requests[c] = {};
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    for (var _i = 0, _Object$entries = Object.entries(data); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];

      var field = key.split("-")[0];
      if (field === "replacement") continue;
      var _id = key.split("-")[1];

      if (field === "quantitySelected") {
        if (typeof value == "number") requests[_id][field] = value;else requests[_id][field] = value.value;
        continue;
      }

      requests[_id][field] = value;
    }

    var replacement = data.replacement;
    var finalData = [];

    for (var _i2 = 0, _Object$entries2 = Object.entries(requests); _i2 < _Object$entries2.length; _i2++) {
      var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
          key = _Object$entries2$_i[0],
          value = _Object$entries2$_i[1];

      finalData.push(_objectSpread({
        orderline: key,
        fulfillment: id,
        replacement: replacement
      }, value));
    }

    return finalData;
  };

  var handleCheck = function handleCheck(id) {
    if (checked.includes(id)) setChecked(checked.filter(function (x) {
      return x !== id;
    }));else setChecked([].concat(_toConsumableArray(checked), [id]));
  };

  return React.createElement(TypedRequestFulfillmentQuery, {
    variables: {
      id: id
    }
  }, function (_ref2) {
    var _data$fulfillment$lin;

    var data = _ref2.data,
        loading = _ref2.loading;
    if (loading) return React.createElement(Loader, null);
    var orderlines = (_data$fulfillment$lin = data.fulfillment.lines) === null || _data$fulfillment$lin === void 0 ? void 0 : _data$fulfillment$lin.map(function (l) {
      return l === null || l === void 0 ? void 0 : l.orderLine;
    });
    return React.createElement("div", {
      className: "request-create-container"
    }, React.createElement(RequestOrderlines, {
      lines: orderlines,
      handleCheck: handleCheck,
      checkedItems: checked
    }), React.createElement(TypedRequestBulkCreateMutation, {
      onCompleted: function onCompleted(data) {
        return showNotification(data, alert, intl);
      }
    }, function (createRequests, _ref3) {
      var loading = _ref3.loading,
          data = _ref3.data;
      return checked.length > 0 && React.createElement("form", {
        onSubmit: handleSubmit(function (formData) {
          createRequests({
            variables: {
              requests: prepareMutationPayload(formData)
            }
          });
        })
      }, orderlines.map(function (line) {
        if (checked.includes(line.id)) return React.createElement(RequestPartialForm, {
          id: line.id,
          control: control,
          quantity: line.quantityFulfilled,
          thumbnail: line.thumbnail,
          productName: "".concat(line.productName, " - ").concat(line.variant.name),
          key: line.id
        });
      }), checked.length > 0 && React.createElement(React.Fragment, null, React.createElement(RequestReplacement, {
        control: control
      }), React.createElement(Button, null, React.createElement(FormattedMessage, {
        id: "account.RequestCreate.RequestCreate.2828880379",
        defaultMessage: "Confirm"
      }))));
    }));
  });
};

export default RequestCreate;