import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  @media (max-width: ", ") {\n    margin: 0 1rem;\n    a {\n      text-align: center;\n    }\n  }\n\n  a {\n    font-size: 0.75rem;\n    font-weight: 500;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: auto;\n  height: 2rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 540px;\n  margin: 0 auto 1rem;\n\n  @media (max-width: ", ") {\n    padding: 0 1rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Container = styled.footer(_templateObject(), function (props) {
  return props.theme.breakpoints.smallScreen;
});
export var Wrapper = styled.div(_templateObject2());
export var Item = styled.div(_templateObject3(), function (props) {
  return props.theme.breakpoints.smallScreen;
});