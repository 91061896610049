import React from "react";
import { RichTextContent } from "@components/atoms";
import * as S from "./styles";
import { CachedImage } from "..";
export var ProductDescription = function ProductDescription(_ref) {
  var productName = _ref.productName,
      _ref$description = _ref.description,
      description = _ref$description === void 0 ? "" : _ref$description,
      _ref$descriptionJson = _ref.descriptionJson,
      descriptionJson = _ref$descriptionJson === void 0 ? "" : _ref$descriptionJson,
      _ref$imageUrl = _ref.imageUrl,
      imageUrl = _ref$imageUrl === void 0 ? "" : _ref$imageUrl,
      _ref$imageAlt = _ref.imageAlt,
      imageAlt = _ref$imageAlt === void 0 ? "" : _ref$imageAlt,
      attributes = _ref.attributes;
  return React.createElement(S.Wrapper, null, React.createElement(S.title, null, "D\xE9tails du produit"), React.createElement(S.Container, null, React.createElement(S.Thumbnail, null, React.createElement(CachedImage, {
    url: imageUrl,
    alt: imageAlt
  })), React.createElement(S.Text, null, descriptionJson ? React.createElement(React.Fragment, null, React.createElement(S.ProductName, null, productName), React.createElement(S.RichText, null, React.createElement(RichTextContent, {
    descriptionJson: descriptionJson
  }))) : React.createElement("p", null, description), React.createElement(S.AttributeList, null, attributes && attributes.map(function (attribute, index) {
    return React.createElement("li", {
      key: index
    }, React.createElement(S.AttributeName, null, attribute.attribute.name, ":", " "), " ", attribute.values.map(function (value) {
      return value.name;
    }).join(", "));
  })))));
};