import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgReturn = function SvgReturn(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 23.005 23.612"
  }, props), React.createElement("path", {
    "data-name": "Trac\\xE9 226",
    d: "M21.189 14.217V2.422A2.429 2.429 0 0 0 18.767 0H4.237a2.429 2.429 0 0 0-2.422 2.422v10.553a3 3 0 0 0-1.211 4.583c.186.247 4.616 6.054 10.9 6.054h11.5v-6.66a3.388 3.388 0 0 0-1.815-2.735Zm.609 8.183h-10.3c-5.683 0-9.758-5.342-9.929-5.569a1.817 1.817 0 0 1 2.9-2.183c.03.042 3.166 4.12 7.024 4.12h4.843v-1.21h-4.838a5.1 5.1 0 0 1-1.761-.338 7.418 7.418 0 0 1-1.222-.587c.367-1.339 2.981-2.708 6.616-2.708 3.924 0 6.66 1.6 6.66 3.027ZM13.924 1.211v4.843H9.08V1.211Zm-9.687 0H7.87v6.054h7.265V1.211h3.633a1.212 1.212 0 0 1 1.21 1.211v11.172a12.792 12.792 0 0 0-4.843-.88c-3.752 0-6.814 1.346-7.647 3.21a12.684 12.684 0 0 1-2.04-2 3.016 3.016 0 0 0-2.422-1.2V2.422a1.212 1.212 0 0 1 1.211-1.211Z",
    fill: "#231f20"
  }));
};

export default SvgReturn;