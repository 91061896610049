import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";
var items = [{
  label: "Conditions générales",
  path: "/"
}, {
  label: "Politique de Confidentialité",
  path: "/"
}, {
  label: "Mentions légales",
  path: "/"
}];
export var FooterAuth = function FooterAuth() {
  return React.createElement(S.Container, null, React.createElement(S.Wrapper, null, items.map(function (item) {
    return React.createElement(S.Item, null, React.createElement(Link, {
      to: item.path
    }, item.label));
  })));
};
FooterAuth.displayName = "FooterAuth";
export default FooterAuth;