import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  margin: 2.5rem 0;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-template-columns: 1fr 1fr;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 0.75rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  padding: 0 5px;\n\n  @media (max-width: ", ") {\n    padding: 10px;\n  }\n\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  grid-column-gap: 20px;\n  grid-row-gap: 10px;\n  overflow-y: hidden !important;\n\n  ", "\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import InfiniteScroll from "react-infinite-scroll-component";
export var Wrapper = styled.div(_templateObject());
export var Infinite = styled(InfiniteScroll)(_templateObject2(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, media.largeScreen(_templateObject3()), media.smallScreen(_templateObject4()));
export var Card = styled.div(_templateObject5());
export var Loader = styled.div(_templateObject6());