import SvgRedX from "images/SvgRedX";
import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
export var EmptyProductList = function EmptyProductList() {
  return React.createElement(S.Wrapper, null, React.createElement(S.Notif, {
    color: "red",
    title: React.createElement(FormattedMessage, {
      id: "@next.components.atoms.EmptyProductList.EmptyProductList.4084570468",
      defaultMessage: "Ooops! Pas de produits!"
    }),
    expl: React.createElement(FormattedMessage, {
      id: "@next.components.atoms.EmptyProductList.EmptyProductList.1576593131",
      defaultMessage: "Essayez de supprimer un ou plusieurs filtres. Si vous avez fait une recherche, v\xE9rifiez l'orthographe ou essayez avec d'autres mots."
    }),
    Svg: SvgRedX
  }));
};
EmptyProductList.displayName = "EmptyProductList";
export default EmptyProductList;