import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  text-decoration: line-through;\n  color: ", ";\n  font-weight: regular;\n  font-weight: 500;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 600;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  letter-spacing: -0.2px;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 80%;\n  border-radius: 10px;\n  background-color: #ffffffc7;\n  margin: 0 auto;\n  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px;\n  font-size: 0.8rem;\n  padding: 3px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 50%;\n  border: 2px solid white;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: 10rem;\n  width: 10rem;\n  margin: 10px auto;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n\n  background-repeat: no-repeat;\n  border-radius: 40px;\n\n  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,\n    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;\n\n  width: 180px;\n  height: 270px;\n\n  &:hover {\n    cursor: pointer;\n    background: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TaxedMoney } from "@components/containers";
import { styled } from "@styles";
import { Thumbnail } from "..";
import cirlcesImage from "@temp/images/tileCircles.svg";
var background = {
  "hot-deals": "linear-gradient(to top, transparent, #f8d9dac9),url(".concat(cirlcesImage, ");"),
  "flash-sale": "linear-gradient(to top, transparent, #daeddec9),url(".concat(cirlcesImage, ");"),
  "hot-deals-hover": "url(".concat(cirlcesImage, ");"),
  "flash-sale-hover": "url(".concat(cirlcesImage, ")")
};
export var Wrapper = styled.div(_templateObject(), function (props) {
  return background[props.variant];
}, function (props) {
  return background["".concat(props.variant, "-hover")];
});
export var Avatar = styled.div(_templateObject2());
export var Thumb = styled(Thumbnail)(_templateObject3());
export var Box = styled.div(_templateObject4());
export var ProductName = styled.p(_templateObject5());
export var SinglePrice = styled(TaxedMoney)(_templateObject6());
export var Undiscounted = styled(TaxedMoney)(_templateObject7(), function (props) {
  return props.theme.colors.redPrice;
});
export var Discounted = styled(TaxedMoney)(_templateObject8());