import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { InputSelect } from "../InputSelect";
import { requestReasonMsg } from "@temp/intl";
import { transformFormHelperText } from "@temp/misc";
export var RequestFormMotive = function RequestFormMotive(props) {
  var intl = useIntl();
  var control = props.control,
      id = props.id;
  var options = [{
    label: intl.formatMessage(requestReasonMsg.didNotLike),
    value: "DID_NOT_LIKE"
  }, {
    label: intl.formatMessage(requestReasonMsg.differentProduct),
    value: "DIFFERENT_PRODUCT"
  }, {
    label: intl.formatMessage(requestReasonMsg.damagedProducts),
    value: "DAMAGED_PRODUCTS"
  }, {
    label: intl.formatMessage(requestReasonMsg.productNotSameAsAd),
    value: "PRODUCT_NOT_SAME_AS_ADVERTISED"
  }, {
    label: intl.formatMessage(requestReasonMsg.accessoriesMissing),
    value: "ACCESSORIES_MISSING"
  }, {
    label: intl.formatMessage(requestReasonMsg.badQuality),
    value: "BAD_QUALITY"
  }, {
    label: intl.formatMessage(requestReasonMsg.smallerSize),
    value: "SMALLER_SIZE"
  }, {
    label: intl.formatMessage(requestReasonMsg.biggerSize),
    value: "BIGGER_SIZE"
  }, {
    label: intl.formatMessage(requestReasonMsg.invoiceErroneous),
    value: "INVOICE_ERRONEOUS"
  }, {
    label: intl.formatMessage(requestReasonMsg.invoiceNotReceived),
    value: "INVOICE_NOT_RECEIVED"
  }];
  return React.createElement(Controller, {
    control: control,
    name: "reason-".concat(id),
    rules: {
      required: true
    },
    render: function render(_ref) {
      var _ref$field = _ref.field,
          _onChange = _ref$field.onChange,
          value = _ref$field.value,
          _ref$fieldState = _ref.fieldState,
          error = _ref$fieldState.error,
          invalid = _ref$fieldState.invalid;
      return React.createElement(InputSelect, {
        name: "reason",
        label: "S\xE9lectionner la raison",
        options: options,
        value: options.find(function (o) {
          return o.value === value;
        }),
        onChange: function onChange(value) {
          return _onChange(value.value);
        },
        hasError: invalid || error,
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })]
      });
    }
  });
};
RequestFormMotive.displayName = "RequestFormMotive";
export default RequestFormMotive;