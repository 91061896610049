import React from "react";
import * as S from "./styles";
export var ErrorMessage = React.forwardRef(function (_ref, ref) {
  var errors = _ref.errors;
  return errors && errors.length ? React.createElement(S.ErrorMessage, {
    "data-test": "errorMessage",
    ref: ref,
    className: "errors"
  }, errors.map(function (error, index) {
    return React.createElement(S.ErrorParagraph, {
      key: index,
      "data-test-id": index
    }, error.message);
  })) : null;
});