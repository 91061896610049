import _objectSpread from "@babel/runtime/helpers/objectSpread";
import baseStyled from "styled-components";
import * as C from "./constants";
import { theme as T } from "./constants";
export var defaultTheme = {
  breakpoints: {
    xxSmallScreen: "320px",
    xSmallScreen: "420px",
    smallScreen: "540px",
    mediumScreen: "720px",
    largeScreen: "992px",
    xLargeScreen: "1280px",
    xxLargeScreen: "1600px",
    xxxLargeScreen: "1920px"
  },
  button: {
    animation: {
      transition: "0.3s"
    },
    colors: {
      primary: {
        activeBackground: C.theme.primaryDark,
        background: T.buttonBackgroundBlack,
        color: C.white,
        hoverBackground: C.black,
        hoverColor: C.white
      },
      secondary: {
        activeBackground: C.gray,
        background: C.white,
        color: C.theme.secondary,
        hoverBackground: C.theme.secondary,
        hoverColor: C.white
      },
      outline: {
        activeBackground: "transparent",
        background: "transparent",
        color: C.black,
        hoverBackground: "transparent",
        hoverColor: C.black
      }
    },
    padding: {
      main: "0.9rem 3.7rem",
      small: "0.9rem 1rem"
    },
    typography: {
      fontSize: "1.125rem",
      fontWeight: "600",
      lineHeight: "1.25rem",
      smallFontSize: "1rem",
      textTransform: "uppercase"
    }
  },
  carousel: {
    carouselControlPadding: "0.2rem 0.5rem",
    carouselControlShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)"
  },
  chip: {
    colors: {
      primary: {
        activeBackground: C.theme.primaryTransparent,
        background: C.theme.primaryLight,
        color: C.theme.primaryDark,
        hoverBackground: "none",
        hoverColor: C.theme.primaryDark
      },
      secondary: {
        activeBackground: C.theme.primaryTransparent,
        background: C.theme.secondaryLight,
        color: C.theme.secondaryDark,
        hoverBackground: "none",
        hoverColor: C.theme.secondaryDark
      }
    },
    typography: {
      fontSize: "1rem",
      smallFontSize: "0.75rem"
    }
  },
  colors: _objectSpread({}, C.theme),
  container: {
    width: 1200
  },
  demoBanner: {
    height: "60px"
  },
  dropdown: {
    backgroundColor: C.theme.white,
    boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.15)"
  },
  dropdownMenu: {
    boxShadow: "0px 0 10px 0 rgba(0, 0, 0, 0.15)"
  },
  grid: {
    containerWidth: 1200
  },
  iconButton: {
    backgroundColor: C.theme.white,
    hoverBackgroundColor: C.theme.secondary,
    hoverForegroundColor: C.theme.white,
    size: 36
  },
  input: {
    border: C.grayDark,
    labelColor: C.grayDark,

    /**
     * 12px in default theme
     */
    labelFontSize: "0.75rem",
    selectMenuShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.15)"
  },
  link: {
    base: {
      color: C.gray,
      hoverColor: C.grayMedium
    },
    secondary: {
      color: C.blue,
      hoverColor: C.blueLight
    }
  },
  message: {
    backgroundColor: C.white,
    contentMargin: "".concat(C.spacer, "rem 0 0"),
    letterSpacing: "0.5px",
    padding: "1rem 1.5rem",
    titleMargin: "0 ".concat(C.spacer * 1.5, "rem 0 0"),
    titleTransform: "uppercase",
    titleWeight: C.extraBoldFontWeight,
    width: "25rem"
  },
  modal: {
    modalMinHeight: 455,
    modalWidth: 555
  },
  productItem: {
    productItemCategoryColor: C.gray,
    productItemPriceFontWeight: C.boldFontWeight,
    productItemPriceMargin: "".concat(C.spacer, "rem 0 0"),
    productItemTitleFontWeight: C.boldFontWeight,
    productItemTitleHeight: "2.5rem",
    productItemTitleMargin: "".concat(C.spacer / 2, "rem 0 0"),
    productItemTitleTextTransform: "uppercase"
  },
  spacing: {
    /**
     * 30px in default theme
     */
    fieldSpacer: C.fieldSpacer,

    /**
     * 30px in default theme
     */
    gutter: "1.875rem",

    /**
     * 16px in default theme
     */
    spacer: "".concat(C.spacer, "rem")
  },
  tile: {
    backgroundColor: C.grayLight,
    divisionLine: C.grayMedium,
    hoverBorder: C.blueDark
  },
  typography: {
    baseFontFamily: C.baseFontFamily,

    /**
     * 16px in default theme
     */
    baseFontSize: C.baseFontSize,

    /**
     * 20px in default theme
     */
    baseLineHeight: C.baseLineHeight,
    boldFontWeight: C.boldFontWeight,
    extraBoldFontWeight: C.extraBoldFontWeight,

    /**
     * 64px in default theme
     */
    h1FontSize: C.h1FontSize,
    h1LineHeight: C.h1LineHeight,

    /**
     * 48px in default theme
     */
    h2FontSize: C.h2FontSize,

    /**
     * 24px in default theme
     */
    h3FontSize: C.h3FontSize,

    /**
     * 18px in default theme
     */
    h4FontSize: C.h4FontSize,

    /**
     * 14px in default theme
     */
    smallFontSize: C.smallFontSize,

    /**
     * 96px in default theme
     */
    ultraBigFontSize: C.ultraBigFont
  },
  boxShadow: {
    border: "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
    productThumbnail: "0px 6px 10px 0px rgba(0, 0, 0, 0.15)",
    orderFulfillment: "0px 0px 3px 0px rgba(0, 0, 0, 0.15)",
    pastOrderRow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    profileMenu: "0px 6px 10px 0px rgba(0, 0, 0, 0.15)"
  },
  notification: {
    red: {
      background: T.redOrderDetailsRequest,
      fontColor: T.redNotification
    },
    yellow: {
      background: T.yellowLightBackground,
      fontColor: T.yellowText
    },
    green: {
      background: T.greenDelivery,
      fontColor: T.blackGrayText
    },
    gray: {
      background: T.grayLightBackground,
      fontColor: "inherit"
    }
  }
};
export var styled = baseStyled;