import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import SvgAddToCart from "images/SvgAddToCart";
export var AddToCartButton = function AddToCartButton(_ref) {
  var onSubmit = _ref.onSubmit,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled;
  return React.createElement(Button, {
    fullWidth: true,
    testingContext: "addProductToCartButton",
    onClick: onSubmit,
    color: "primary",
    disabled: disabled
  }, React.createElement(SvgAddToCart, null), React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AddToCartButton.AddToCartButton.569822812",
    defaultMessage: "Ajouter Au Panier"
  }));
};
AddToCartButton.displayName = "AddToCartButton";
export default AddToCartButton;