import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgRedX = function SvgRedX(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 48 48"
  }, props), React.createElement("g", {
    "data-name": "Layer 2"
  }, React.createElement("g", {
    "data-name": "Layer 1"
  }, React.createElement("path", {
    style: {
      fill: "none"
    },
    d: "M0 0h48v48H0z"
  }), React.createElement("path", {
    d: "M24 8.09A15.91 15.91 0 1 0 39.91 24 15.91 15.91 0 0 0 24 8.09Zm8.11 21.2-2.82 2.82L24 26.82l-5.28 5.29-2.83-2.82L21.18 24l-5.29-5.28 2.83-2.82L24 21.18l5.29-5.29 2.82 2.82L26.82 24Z",
    style: {
      fill: "#e03b3b"
    }
  }))));
};

export default SvgRedX;