import "./scss/index.scss";
import React from "react";
import OrderFulfillment from "./OrderFulfilment";

var OrderFulfillments = function OrderFulfillments(props) {
  var fulfillments = props.fulfillments;
  return React.createElement("div", {
    className: "fulfillments-container"
  }, fulfillments.map(function (fulfillment) {
    return React.createElement(OrderFulfillment, {
      fulfillment: fulfillment
    });
  }));
};

export default OrderFulfillments;