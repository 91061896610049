import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { AttributeValuesChecklist, AttributeValuesCheckListColor, PriceFilter } from "@components/molecules";
import * as S from "./styles";
import { useForm } from "react-hook-form";

var checkIfAttributeIsChecked = function checkIfAttributeIsChecked(filters, value, slug) {
  if (filters.attributes && filters.attributes.hasOwnProperty(slug)) {
    if (filters.attributes[slug].find(function (filter) {
      return filter === value.slug;
    })) {
      return true;
    }

    return false;
  }

  return false;
};

export var FilterSidebar = function FilterSidebar(_ref) {
  var filters = _ref.filters,
      attributes = _ref.attributes,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange,
      activePriceFilter = _ref.activePriceFilter,
      onSubmitPriceFilter = _ref.onSubmitPriceFilter;

  var _useForm = useForm(),
      control = _useForm.control,
      setValue = _useForm.setValue,
      getValues = _useForm.getValues,
      handleSubmit = _useForm.handleSubmit;

  return React.createElement(S.Wrapper, {
    "data-test": "filterSidebar"
  }, attributes.map(function (_ref2) {
    var id = _ref2.id,
        name = _ref2.name,
        slug = _ref2.slug,
        values = _ref2.values;
    return slug === "couleur" ? React.createElement(AttributeValuesCheckListColor, {
      key: id,
      title: name,
      name: slug,
      values: values.map(function (value) {
        return _objectSpread({}, value, {
          selected: checkIfAttributeIsChecked(filters, value, slug)
        });
      }),
      valuesShowLimit: true,
      onValueClick: function onValueClick(value) {
        return onAttributeFiltersChange(slug, value.slug);
      }
    }) : React.createElement(AttributeValuesChecklist, {
      key: id,
      title: name,
      name: slug,
      values: values.map(function (value) {
        return _objectSpread({}, value, {
          selected: checkIfAttributeIsChecked(filters, value, slug)
        });
      }),
      valuesShowLimit: true,
      onValueClick: function onValueClick(value) {
        return onAttributeFiltersChange(slug, value.slug);
      }
    });
  }), attributes && attributes.length > 0 && React.createElement(PriceFilter, _extends({
    onSubmitPriceFilter: onSubmitPriceFilter,
    activePriceFilter: activePriceFilter,
    control: control,
    getValues: getValues,
    setValue: setValue,
    handleSubmit: handleSubmit
  }, {
    isMobile: false
  })));
};