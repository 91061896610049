import * as React from "react";
import { CollectionsBannerWithCarousel } from "@components/organisms";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import { BestSellers, NewArrivals, FlashSale, HotDeals } from "./Views";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categories = _ref.categories,
      collections = _ref.collections,
      backgroundImage = _ref.backgroundImage,
      shop = _ref.shop;
  return React.createElement("div", {
    className: "ContainerFullScreen"
  }, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement(CollectionsBannerWithCarousel, {
    collections: collections
  }), React.createElement(NewArrivals, null), React.createElement(BestSellers, null), React.createElement(HotDeals, null), React.createElement(FlashSale, null));
};

export default Page;