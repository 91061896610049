import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

var SvgTruckCircle = function SvgTruckCircle(props) {
  return React.createElement("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 54 54"
  }, props), React.createElement("g", {
    "data-name": "Groupe 534",
    transform: "translate(-1220 -839)"
  }, React.createElement("circle", {
    "data-name": "Ellipse 73",
    cx: 27,
    cy: 27,
    r: 27,
    transform: "translate(1220 839)",
    fill: "#65c965"
  }), React.createElement("g", {
    fill: "#fff"
  }, React.createElement("path", {
    "data-name": "Trac\\xE9 75",
    d: "M1235.511 854h15.063a2.518 2.518 0 0 1 2.511 2.511v2.511a7.554 7.554 0 0 1 7.532 7.532v7.532h-2.511a3.766 3.766 0 0 1-7.532 0h-5.021a3.766 3.766 0 0 1-7.532 0h-2.51a2.518 2.518 0 0 1-2.511-2.512v-15.063a2.518 2.518 0 0 1 2.511-2.511Zm-1.256 17.574a1.257 1.257 0 0 0 1.255 1.255h2.73a3.761 3.761 0 0 1 7.094 0h5.24a1.234 1.234 0 0 0 .227-.023 3.766 3.766 0 0 1 1.028-1.523v-14.772a1.257 1.257 0 0 0-1.255-1.255h-15.063a1.257 1.257 0 0 0-1.256 1.255Zm5.021 2.511a2.511 2.511 0 1 0 2.511-2.511 2.513 2.513 0 0 0-2.51 2.511Zm12.553 0a2.511 2.511 0 1 0 2.511-2.511 2.513 2.513 0 0 0-2.51 2.511Zm1.255-13.808v5.649h6.245a6.285 6.285 0 0 0-6.244-5.65Zm6.277 12.553v-5.649h-6.276v3.357a3.75 3.75 0 0 1 4.8 2.292Z"
  }), React.createElement("path", {
    "data-name": "Rectangle 79",
    d: "M1239.992 859.762h7.308v1.096h-7.308z"
  }), React.createElement("path", {
    "data-name": "Rectangle 80",
    d: "M1242.185 864.512h5.115v1.462h-5.115z"
  }))));
};

export default SvgTruckCircle;