import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n\n  width: 100vw;\n  height: 100vh;\n  background-color: #00000096;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  overflow-y: auto;\n  top: 0;\n  z-index: 200;\n  transition: opacity 0.2s ease;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: 90vh;\n  margin: auto;\n  border: 1px solid royalBlue;\n\n  img {\n    height: 90vh;\n    margin: auto;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border: 1px solid mediumAquamarine;\n  > div {\n    background: transparent;\n    margin-left: auto;\n    > svg > path {\n      fill: white;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Header = styled.div(_templateObject());
export var Content = styled.div(_templateObject2()); // overlay start

export var Overlay = styled.div(_templateObject3());
Overlay.displayName = "S.Overlay";
export var Lightbox = styled.div(_templateObject4());
Lightbox.displayName = "S.Lightbox"; // overlay end