import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { useIntl } from "react-intl";
import { requestReplacementMsg } from "@temp/intl";
import { Controller } from "react-hook-form";
import { InputSelect } from "../InputSelect";
import { transformFormHelperText } from "@temp/misc";
import * as S from "./styles";
export var RequestReplacement = function RequestReplacement(props) {
  var control = props.control;
  var intl = useIntl();
  var options = [{
    label: intl.formatMessage(requestReplacementMsg.voucher),
    value: "VOUCHER"
  }, {
    label: intl.formatMessage(requestReplacementMsg.refund),
    value: "REFUND"
  }, {
    label: intl.formatMessage(requestReplacementMsg.exchange),
    value: "EXCHANGE"
  }];
  return React.createElement(S.Wrapper, null, React.createElement(Controller, {
    control: control,
    name: "replacement",
    rules: {
      required: true
    },
    render: function render(_ref) {
      var _ref$field = _ref.field,
          _onChange = _ref$field.onChange,
          value = _ref$field.value,
          _ref$fieldState = _ref.fieldState,
          error = _ref$fieldState.error,
          invalid = _ref$fieldState.invalid;
      return React.createElement(InputSelect, {
        name: "replacement",
        label: "Choisir le remplacement",
        options: options,
        hasError: invalid || error,
        errors: invalid && [_objectSpread({}, error, {
          message: transformFormHelperText(error.type)
        })],
        value: options.find(function (o) {
          return o.value === value;
        }),
        onChange: function onChange(value) {
          return _onChange(value.value);
        }
      });
    }
  }));
};
RequestReplacement.displayName = "RequestReplacement";
export default RequestReplacement;