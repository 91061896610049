import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    font-size: 0.8rem;\n    column-width: 100%;\n    columns: 1;\n    margin-top: 10px;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  columns: 2;\n  column-width: 50%;\n  margin-top: 2rem;\n  letter-spacing: -0.2px;\n  width: 100%;\n  padding: 0;\n\n  ", ";\n\n  li {\n    @media (min-width: ", ") {\n      margin-bottom: 1rem;\n      font-size: ", ";\n    }\n  }\n\n  li::before {\n    content: \"\u2022\";\n    margin-right: 8px;\n    color: ", ";\n\n    @media (min-width: ", ") {\n      margin-right: 20px;\n    }\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  li::before {\n    content: \"\u2022\";\n    margin-right: 8px;\n    color: ", ";\n\n    @media (min-width: ", ") {\n      margin-right: 20px;\n    }\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 500;\n  margin: 0 0 1rem 0;\n\n  @media (min-width: ", ") {\n    width: 80%;\n    font-size: 1.5rem;\n    margin: 2rem 0;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  letter-spacing: -0.2px;\n  width: 70%;\n  font-size: 0.8rem;\n  padding-left: 8px;\n\n  @media (min-width: ", ") {\n    width: 100%;\n    margin-left: 2rem;\n    padding-left: unset;\n    font-size: 1rem;\n    line-height: 1.5;\n  }\n\n  ul: (first-of-kind) {\n    list-style: inside;\n\n    li {\n      margin-bottom: 5px;\n    }\n\n    li::before {\n      content: \"\u2022\";\n      margin-right: 20px;\n      color: ", ";\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 30%;\n  height: 45%;\n\n  img {\n    width: 100%;\n    border-radius: 10px;\n  }\n\n  box-sizing: content-box;\n  position: sticky;\n  top: 1rem;\n\n  @media (min-width: ", ") {\n    height: 320px;\n    width: auto;\n\n    img {\n      width: auto;\n      height: 100%;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin: 1rem 0;\n  padding: 8px;\n\n  @media (min-width: ", ") {\n    margin: 3rem;\n    padding: 1rem 0;\n  }\n\n  display: flex;\n  height: fit-content;\n  justify-content: space-between;\n\n  background-color: #fbfbfb;\n  border-radius: 5px;\n\n  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 1rem;\n  width: fit-content;\n  margin: auto;\n\n  position: relative;\n\n  &::before {\n    border-bottom: 1px solid #000;\n    content: \"\";\n    position: absolute;\n    bottom: 0;\n    right: 25%;\n    width: 50%;\n    margin: 0 auto;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  padding-top: 2rem;\n\n  &::before {\n    border-bottom: 1px solid #000;\n    content: \"\";\n    position: absolute;\n    left: 0;\n    right: 0;\n    width: calc(100% - 6rem);\n    top: 0;\n    margin: 0 auto;\n  }\n\n  &::after {\n    border-bottom: 1px solid #000;\n    content: \"\";\n    position: absolute;\n    left: 0;\n    right: 0;\n    width: calc(100% - 6rem);\n    margin: 0 auto;\n  }\n\n  @media (min-length: ", ") {\n    margin-top: 2rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var title = styled.h3(_templateObject2());
export var Container = styled.div(_templateObject3(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Thumbnail = styled.div(_templateObject4(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Text = styled.div(_templateObject5(), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.colors.listBullet;
});
export var ProductName = styled.h4(_templateObject6(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var RichText = styled.div(_templateObject7(), function (props) {
  return props.theme.colors.listBullet;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var AttributeList = styled.ul(_templateObject8(), media.largeScreen(_templateObject9()), function (props) {
  return props.theme.breakpoints.largeScreen;
}, function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.colors.listBullet;
}, function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var AttributeName = styled.span(_templateObject10(), function (props) {
  return props.theme.colors.listAttributeName;
});