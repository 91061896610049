import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import * as S from "./styles";
export var ReactCarousel = function ReactCarousel(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, ["children"]);

  return React.createElement(S.Wrapper, null, React.createElement(Carousel, _extends({
    infinite: true
  }, props), children));
};
ReactCarousel.displayName = "ReactCarousel";
export default ReactCarousel;