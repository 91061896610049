import React from "react";
import isEqual from "lodash/isEqual";
import { Link } from "react-router-dom";
import Media from "react-responsive";
import { Stars, Button } from "@components/atoms";
import { CachedImage } from "..";
import { TaxedMoney } from "@temp/@next/components/containers";
import * as S from "./styles";
import { FormattedMessage } from "react-intl";
export var ProductTileAlternative = function ProductTileAlternative(_ref) {
  var _product$pricing, _product$pricing$pric, _product$pricing2, _product$pricing2$pri;

  var product = _ref.product;
  var price = (_product$pricing = product.pricing) === null || _product$pricing === void 0 ? void 0 : (_product$pricing$pric = _product$pricing.priceRange) === null || _product$pricing$pric === void 0 ? void 0 : _product$pricing$pric.start;
  var priceUndiscounted = (_product$pricing2 = product.pricing) === null || _product$pricing2 === void 0 ? void 0 : (_product$pricing2$pri = _product$pricing2.priceRangeUndiscounted) === null || _product$pricing2$pri === void 0 ? void 0 : _product$pricing2$pri.start;

  var getPrice = function getPrice() {
    var mobile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

    if (isEqual(price, priceUndiscounted)) {
      return React.createElement(TaxedMoney, {
        taxedMoney: price
      });
    }

    if (mobile) priceUndiscounted.gross.currency = "";else priceUndiscounted.gross.currency = price.gross.currency;
    return React.createElement(React.Fragment, null, React.createElement(S.Undiscounted, null, React.createElement(TaxedMoney, {
      taxedMoney: priceUndiscounted
    })), React.createElement(TaxedMoney, {
      taxedMoney: price
    }));
  };

  return React.createElement(S.Wrapper, null, React.createElement(Media, {
    minWidth: 620
  }, function (matches) {
    return matches ? React.createElement(Link, {
      to: "/product/".concat(product.slug, "/"),
      key: product.id
    }, React.createElement(S.Thumb, null, React.createElement(CachedImage, {
      url: product.thumbnail.url,
      alt: product.thumbnail.alt
    })), React.createElement(S.Content, null, React.createElement(S.Name, {
      rating: !!product.rating
    }, product.name), product.rating && React.createElement(S.Rating, null, React.createElement(S.StarsContainer, null, React.createElement(Stars, {
      size: "sm",
      rating: product.rating
    })), React.createElement("span", null, "(".concat(product.ratingsCount, ")"))), React.createElement(S.Price, null, getPrice()), React.createElement(S.ButtonContainer, null, React.createElement(Button, null, React.createElement(FormattedMessage, {
      id: "@next.components.molecules.ProductTileAlternative.ProductTileAlternative.2980507932",
      defaultMessage: "J'achete"
    }))))) : React.createElement(Link, {
      to: "/product/".concat(product.slug, "/"),
      key: product.id
    }, React.createElement(S.Thumb, null, React.createElement(CachedImage, {
      url: product.thumbnail.url,
      alt: product.thumbnail.alt
    })), React.createElement(S.Content, null, React.createElement(S.Name, {
      rating: !!product.rating
    }, product.name), React.createElement(S.Price, null, getPrice(true))));
  }));
};
ProductTileAlternative.displayName = "ProductTileAlternative";
export default ProductTileAlternative;