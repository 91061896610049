/* eslint-disable global-require */
import { generatePageUrl } from "./utils";
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 12;
export var REVIEWS_PER_PAGE = 10;
export var SUPPORT_EMAIL = "support@example.com";
export var LOW_STOCK_QUANTITY = 2;
export var TIMEOUT_HOMEPAGE_BANNER = 5000;
export var PROVIDERS = {
  DELIVERY: {
    label: "Delivery"
  },
  SMT: {
    label: "Smt"
  }
};
export var STATIC_PAGES = [{
  label: "À propos",
  url: generatePageUrl("a-propos")
}, {
  label: "Conditions Générales de Vente",
  url: generatePageUrl("conditions-generales-de-vente")
}, {
  label: "Protection de données",
  url: generatePageUrl("protection-de-donnees")
}, {
  label: "Politique de confidentialité",
  url: generatePageUrl("politique-de-confidentialite")
}, {
  label: "Mentions légales",
  url: generatePageUrl("mentions-legales")
}, {
  label: "Service Client",
  url: generatePageUrl("service-client")
}, {
  label: "Vendez sur Wonder",
  url: generatePageUrl("vendez-sur-wonder")
}, {
  label: "Conditions de retour",
  url: generatePageUrl("conditions-de-retour")
}, {
  label: "Conditions d'échange",
  url: generatePageUrl("conditions-dechange")
}];
export var SOCIAL_MEDIA = [{
  ariaLabel: "facebook",
  href: "https://www.facebook.com/",
  path: require("../images/facebook-icon.svg")
}, {
  ariaLabel: "instagram",
  href: "https://www.instagram.com/",
  path: require("../images/instagram-icon.svg")
}, {
  ariaLabel: "twitter",
  href: "https://twitter.com",
  path: require("../images/twitter-icon.svg")
}, {
  ariaLabel: "youtube",
  href: "https://www.youtube.com/",
  path: require("../images/youtube-icon.svg")
}];
export var META_DEFAULTS = {
  custom: [],
  description: "The storefront application for Wonder",
  image: "".concat(window.location.origin).concat(require("../images/logo.svg")),
  title: "Wonder",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Payment"] = 2] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 3] = "Review";
  CheckoutStep[CheckoutStep["PaymentConfirm"] = 4] = "PaymentConfirm";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  link: "/checkout/address",
  name: "Address",
  nextActionName: "Continue to Shipping",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Address
}, {
  index: 1,
  link: "/checkout/payment",
  name: "Payment",
  nextActionName: "Continue to Review",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 2,
  link: "/checkout/review",
  name: "Review",
  nextActionName: "Place order",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}, {
  index: 3,
  link: "/checkout/payment-confirm",
  name: "Payment confirm",
  onlyIfShippingRequired: false,
  step: CheckoutStep.PaymentConfirm,
  withoutOwnView: true
}];