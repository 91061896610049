import { defineMessages } from "react-intl";
import { ProductErrorCode } from "gqlTypes/globalTypes";
var messages = defineMessages({
  requestAlreadyExists: {
    "id": "account.RequestCreate.errors.requestAlreadyExists",
    defaultMessage: "A pending request exists already for this product"
  },
  default: {
    "id": "account.RequestCreate.errors.default",
    defaultMessage: "An unexpected error has occured"
  }
});
export var getRequestCreateErrorMessage = function getRequestCreateErrorMessage(err, intl) {
  switch (err === null || err === void 0 ? void 0 : err.code) {
    case ProductErrorCode.ALREADY_EXISTS:
      return intl.formatMessage(messages.requestAlreadyExists);

    default:
      return intl.formatMessage(messages.default);
  }
};