import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import * as S from "./styles";
var STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped"
};
export var Countdown = function Countdown(_ref) {
  var difference = _ref.difference;

  var _React$useState = React.useState(difference / 1000),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      secondsRemaining = _React$useState2[0],
      setSecondsRemaining = _React$useState2[1];

  var _React$useState3 = React.useState(STATUS.STARTED),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      status = _React$useState4[0],
      setStatus = _React$useState4[1];

  var secondsToDisplay = Math.floor(secondsRemaining % 60);
  var minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  var minutesToDisplay = Math.floor(minutesRemaining % 60);
  var hoursRemaining = Math.floor((minutesRemaining - minutesToDisplay) / 60);
  var hoursToDisplay = Math.floor(hoursRemaining % 24);
  var daysToDisplay = Math.floor(hoursRemaining / 24);
  useInterval(function () {
    if (secondsRemaining < 0) setStatus(STATUS.STOPPED);
    setSecondsRemaining(secondsRemaining - 1);
  }, status === STATUS.STARTED ? 1000 : null // passing null stops the interval
  );
  return React.createElement(React.Fragment, null, secondsRemaining < 0 ? React.createElement(S.WrapperExpired, null, React.createElement("p", null, "Recommence tr\xE8s prochainement")) : React.createElement(S.Wrapper, null, React.createElement(S.Box, null, React.createElement("span", null, twoDigits(daysToDisplay)), React.createElement("span", null, "Jours")), React.createElement(S.Box, null, React.createElement("span", null, twoDigits(hoursToDisplay)), React.createElement("span", null, "Heures")), React.createElement(S.Box, null, React.createElement("span", null, twoDigits(minutesToDisplay)), React.createElement("span", null, "Minutes")), React.createElement(S.Box, null, React.createElement("span", null, twoDigits(secondsToDisplay)), React.createElement("span", null, "Secondes"))));
};
Countdown.displayName = "Countdown";
export default Countdown;

var twoDigits = function twoDigits(num) {
  return String(num).padStart(2, "0");
};

function useInterval(callback, delay) {
  var savedCallback = React.useRef(); // Remember the latest callback.

  React.useEffect(function () {
    savedCallback.current = callback;
  }, [callback]); // Set up the interval.

  React.useEffect(function () {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      var id = setInterval(tick, delay);
      return function () {
        return clearInterval(id);
      };
    }
  }, [delay]);
}