import React from "react";
import { FormattedMessage } from "react-intl";
import { Stars } from "@components/atoms";
import { Breadcrumbs } from "@temp/components";
import * as S from "./styles";

var populateBreadcrumbs = function populateBreadcrumbs(product) {
  return [{
    link: "/product/".concat(product.slug, "/"),
    value: product.name.length > 14 ? "".concat(product.name.substring(0, 10), "...") : product.name
  }, {
    link: "/product/".concat(product.slug, "/reviews/"),
    value: "reviews"
  }];
};

export var ProductPreview = function ProductPreview(_ref) {
  var _product$reviewsSumma, _product$reviewsSumma2, _product$reviewsSumma3;

  var product = _ref.product;
  populateBreadcrumbs(product);
  return React.createElement(React.Fragment, null, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(product)
  }), React.createElement(S.Wrapper, null, React.createElement(S.Name, null, product.name), React.createElement(S.Inner, null, React.createElement(S.Rating, null, React.createElement(S.Thumb, {
    source: product
  }), React.createElement(S.RatingInner, null, React.createElement(S.Title, null, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductPreview.ProductPreview.3846824194",
    defaultMessage: "Avis des clients"
  })), React.createElement(S.RatingText, null, "".concat(product.rating, " / 5")), React.createElement(Stars, {
    rating: product.rating,
    size: "md"
  }), React.createElement(S.RatingTotal, null, "".concat((_product$reviewsSumma = product.reviewsSummary) === null || _product$reviewsSumma === void 0 ? void 0 : _product$reviewsSumma.totalRatings, " avis")))), React.createElement(S.Summary, null, React.createElement("div", null, (_product$reviewsSumma2 = product.reviewsSummary) === null || _product$reviewsSumma2 === void 0 ? void 0 : (_product$reviewsSumma3 = _product$reviewsSumma2.ratingsSummary) === null || _product$reviewsSumma3 === void 0 ? void 0 : _product$reviewsSumma3.map(function (r) {
    return React.createElement(S.SummaryItem, null, React.createElement(S.Stars, null, r.stars), React.createElement(Stars, {
      rating: r.stars,
      size: "sm"
    }), React.createElement(S.Gauge, null, React.createElement(S.paintedGauge, {
      percentage: 100 * r.count / product.reviewsSummary.totalRatings
    })), React.createElement(S.CountSpan, null, r === null || r === void 0 ? void 0 : r.count));
  }))))));
};
ProductPreview.displayName = "ProductPreview";
export default ProductPreview;