import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

function SvgLogodots(props) {
  return React.createElement("svg", _extends({
    "data-name": "Groupe 51",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 279.777 172.306"
  }, props), React.createElement("path", {
    "data-name": "Trac\\xE9 62",
    d: "M79.113 39.56a39.523 39.523 0 11-11.588-28 39.556 39.556 0 0111.588 28z",
    fill: "#96cbc7"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 61",
    d: "M179.392 39.56A39.556 39.556 0 11139.872 0a39.556 39.556 0 0139.52 39.56z",
    fill: "#9bd7f5"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 60",
    d: "M279.774 39.56A39.556 39.556 0 11240.22 0a39.556 39.556 0 0139.554 39.56z",
    fill: "#daeddd"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 59",
    d: "M226.837 132.716a39.556 39.556 0 11-39.557-39.523 39.556 39.556 0 0139.556 39.523z",
    fill: "#f394b8"
  }), React.createElement("path", {
    "data-name": "Trac\\xE9 58",
    d: "M132.12 132.717a39.589 39.589 0 11-11.677-28.01 39.556 39.556 0 0111.677 28.01z",
    fill: "#babadd"
  }));
}

export default SvgLogodots;