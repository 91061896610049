import React from "react";
import Media from "react-responsive";
import { FormattedMessage } from "react-intl";
import { checkoutMessages } from "@temp/intl";
import { DiscountForm } from "../DiscountForm";
import * as S from "./styles";
import { largeScreen } from "@styles/constants";
/**
 * Payment options used in checkout.
 */

var CheckoutPayment = function CheckoutPayment(_ref) {
  var promoCodeErrors = _ref.promoCodeErrors,
      promoCodeDiscountFormId = _ref.promoCodeDiscountFormId,
      promoCodeDiscountFormRef = _ref.promoCodeDiscountFormRef,
      promoCodeDiscount = _ref.promoCodeDiscount,
      addPromoCode = _ref.addPromoCode,
      removeVoucherCode = _ref.removeVoucherCode,
      submitUnchangedDiscount = _ref.submitUnchangedDiscount,
      paymentGateways = _ref.paymentGateways;

  var handleSubmitPromoCode = function handleSubmitPromoCode(discountForm) {
    var newPromoCode = discountForm === null || discountForm === void 0 ? void 0 : discountForm.promoCode;
    var savedPromoCode = promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode;

    if (!newPromoCode && savedPromoCode) {
      removeVoucherCode(savedPromoCode);
      "first";
    } else if (newPromoCode && newPromoCode !== savedPromoCode) {
      addPromoCode(newPromoCode);
    } else {
      submitUnchangedDiscount();
    }
  };

  return React.createElement(S.Wrapper, null, React.createElement("section", null, React.createElement(Media, {
    minWidth: largeScreen
  }, React.createElement(S.Divider, null)), React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.paymentMethod)), React.createElement("div", null, paymentGateways), React.createElement(S.Divider, null), React.createElement(DiscountForm, {
    discount: {
      promoCode: promoCodeDiscount === null || promoCodeDiscount === void 0 ? void 0 : promoCodeDiscount.voucherCode
    },
    formId: promoCodeDiscountFormId,
    formRef: promoCodeDiscountFormRef,
    handleSubmit: handleSubmitPromoCode,
    errors: promoCodeErrors
  })));
};

export { CheckoutPayment };