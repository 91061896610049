import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n  position: absolute;\n  right: -2px;\n  top: 42px;\n  min-width: 230px;\n  background-color: white;\n\n  &.visible {\n    display: block;\n    z-index: 350;\n    border: 2px solid ", ";\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n    border-top-left-radius: 10px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  border: 2px solid transparent;\n\n  &.visible {\n    border: 2px solid ", ";\n    border-bottom-color: transparent;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var MenuDropdown = styled.div(_templateObject(), function (props) {
  return props.theme.colors.logoGreen;
});
export var MenuBody = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.logoGreen;
});