import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import PastOrderRow from "@components/molecules/PastOrderRow";
import PastOrderDetails from "./PastOrderDetails";

var PastOrder = function PastOrder(_ref) {
  var order = _ref.order;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      expanded = _useState2[0],
      setExpanded = _useState2[1];

  var toggle = function toggle() {
    setExpanded(!expanded);
  };

  return React.createElement(React.Fragment, null, React.createElement("p", null, "order"), React.createElement(PastOrderRow, {
    order: order,
    toggle: toggle
  }), expanded && React.createElement(PastOrderDetails, {
    token: order.token
  }));
};

export default PastOrder;