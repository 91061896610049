import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import Media from "react-media";
import { ThemeContext } from "styled-components";
import { ProductTileCircles } from "@components/molecules";
import { ReactCarousel } from "@components/organisms";
import hotDeals from "@temp/images/hotDeals.svg";
import * as S from "./styles";
var responsive = {
  mobile: {
    breakpoint: {
      max: 720,
      min: 0
    },
    items: 1,
    paritialVisibilityGutter: 30
  }
};
export var HomepageHotDeals = function HomepageHotDeals(_ref) {
  var products = _ref.products;
  var theme = React.useContext(ThemeContext);
  return React.createElement("div", {
    className: "container"
  }, React.createElement(S.Wrapper, null, React.createElement(S.Banner, null, React.createElement(S.Inner, null, React.createElement("img", {
    src: hotDeals
  }), React.createElement("h1", null, "HOT Deals")), React.createElement("span", null, "Nos coups de coeur")), React.createElement(S.ProductsContainer, null, products.length && React.createElement(Media, {
    query: {
      maxWidth: theme.breakpoints.mediumScreen
    }
  }, function (matches) {
    return React.createElement(React.Fragment, null, matches ? React.createElement(ReactCarousel, _extends({
      containerClass: "carousel"
    }, {
      responsive: responsive
    }), products.map(function (product) {
      return React.createElement(ProductTileCircles, _extends({
        product: product
      }, {
        variant: "hot-deals"
      }));
    })) : products.map(function (product) {
      return React.createElement(ProductTileCircles, _extends({
        product: product
      }, {
        variant: "hot-deals"
      }));
    }));
  }))));
};
HomepageHotDeals.displayName = "HomepageHotDeals";
export default HomepageHotDeals;