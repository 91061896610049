import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: 600;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.75rem;\n  position: absolute;\n  bottom: 0;\n\n  @media (min-width: ", ") {\n    position: unset;\n    bottom: unset;\n    margin-top: 1rem;\n  }\n\n  p {\n    font-weight: 600;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  max-width: 60%;\n  margin-left: 2rem;\n  position: relative;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  padding: 1rem;\n  margin: 1rem 0;\n\n  img {\n    height: 100px;\n    width: auto;\n    border-radius: 10%;\n    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);\n    -webkit-box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 75%);\n    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);\n\n    @media (min-width: ", ") {\n      height: 170px;\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  flex: 0 0 60%;\n\n  @media (min-width: ", ") {\n    flex: 0 0 70%;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  flex: 0 0 40%;\n  font-weight: 600;\n\n  @media (min-width: ", ") {\n    flex: 0 0 30%;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  flex: 1 1 auto;\n  width: 100%;\n  display: flex;\n  margin-bottom: 1.5rem;\n\n  :last-child {\n    margin-bottom: 0;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  padding: 1rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n\n  @media (min-width: ", ") {\n    font-size: 0.875rem;\n  }\n\n  @media (min-width: ", ") {\n    font-size: 0.875rem;\n    padding-top: 2.5rem;\n  }\n\n  @media (min-width: ", ") {\n    font-size: 1rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { Money } from "@components/containers";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.breakpoints.xSmallScreen;
}, function (props) {
  return props.theme.breakpoints.smallScreen;
}, function (props) {
  return props.theme.breakpoints.xSmallScreen;
});
export var Table = styled.div(_templateObject2());
export var Row = styled.div(_templateObject3());
export var Label = styled.div(_templateObject4(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Value = styled.div(_templateObject5(), function (props) {
  return props.theme.breakpoints.largeScreen;
});
export var Orderline = styled.div(_templateObject6(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Product = styled.div(_templateObject7());
export var Quantity = styled.div(_templateObject8(), function (props) {
  return props.theme.breakpoints.mediumScreen;
});
export var Price = styled(Money)(_templateObject9(), function (props) {
  return props.theme.colors.listBullet;
});