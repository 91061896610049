import React from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "@components/atoms";
import * as S from "./styles";
export var RequestOrderlines = function RequestOrderlines(props) {
  var lines = props.lines,
      checkedItems = props.checkedItems,
      handleCheck = props.handleCheck;
  return React.createElement(React.Fragment, null, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.RequestOrderlines.RequestOrderlines.2792107834",
    defaultMessage: "S\xE9lectionner le(s) produit(s) que vous voulez retourner"
  })), React.createElement(S.Lines, null, lines.map(function (l) {
    return React.createElement(S.Line, {
      key: l.id
    }, React.createElement(Checkbox, {
      checked: checkedItems.includes(l.id),
      onChange: function onChange() {
        return handleCheck(l.id);
      }
    }, React.createElement(S.Thumb, {
      source: l,
      key: l.id
    }), React.createElement("p", null, l.productName, " - ", l.variant.name)));
  })));
};
RequestOrderlines.displayName = "RequestOrderlines";
export default RequestOrderlines;